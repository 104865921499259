import React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

const CtaPadding = '10px 15px';

export const StyledButton = styled(({ ...props }: ButtonProps) => (
  <Button
    type={props?.type || 'button'}
    disabled={props?.disabled || false}
    {...props}
  />
))((styledProps: StyledButtonProps) => ({
  width: styledProps.fullWidth ? '100%' : styledProps.width || 'auto',
  minWidth: styledProps.minWidth,
  height: styledProps.customHeight || '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: styledProps.padding || CtaPadding,
  textAlign: 'center',
  outline: 'none',
  opacity: styledProps.disabled ? '0.5' : '1',
  backgroundColor: '#fff',
  borderRadius: '4px',
  border: '1px solid #e4e7ec',
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.12)',
  textTransform: styledProps?.textTransform || 'capitalize',
}));

type StyledButtonProps = {
  type?: 'submit' | 'reset' | 'button';
  id?: string;
  width?: any;
  minWidth?: string;
  padding?: string;
  fullWidth?: boolean;
  textTransform?: any;
  customHeight?: string;
  children: React.ReactNode;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
};

export default StyledButton;
