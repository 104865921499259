export const documentTypeList = [
  { id: 1, label: 'PPM', value: 'PPM' },
  { id: 2, label: 'Educational', value: 'EDUCATIONAL', disabled: true },
];

export const apiEndPoints = {
  getPresignedUrl: 'enterprise/getPresignedUrl',
  uploadFundDocument: 'enterprise/uploadFundDocument',
  getNotificationsSSE: 'v2/getNotificationsSSE',
};
