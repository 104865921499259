import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { actions as chatActions } from '@features/Chat/slice';
import { actions as globalActions } from '@features/Global/slice';

import SearchBox from '@components/SearchBox';
import { SecondaryButton } from '@components/Button';
import { ParagraphLightText } from '@components/Typography';
import { FooterContainer } from '@layout/styles';

import { Container } from './styles';

function Finish(): React.JSX.Element {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      stopProductTour();
    }, 1500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const onInputSubmit = (query: string) => {
    navigate('/conversations');
    dispatch(chatActions.searchQueryRequest({ query }));
    stopProductTour();
  };

  const stopProductTour = () => {
    dispatch(globalActions.finishProductTour(false));
  };

  return (
    <Container
      container
      flexDirection={'column'}
      alignItems={'flex-start'}
      justifyContent={'flex-end'}
    >
      <ParagraphLightText
        fontSize={'32px'}
        color={theme.palette.primary['grey6']}
      >
        And with that the tour is complete
      </ParagraphLightText>
      <SecondaryButton
        variant={'text'}
        onClick={stopProductTour}
        endIcon={<ArrowForwardIcon />}
        sx={{ marginTop: 2 }}
      >
        Lets get you started
      </SecondaryButton>
      <FooterContainer container alignItems={'flex-end'} pb={1}>
        <SearchBox
          type={'input'}
          color={theme.palette.primary['main']}
          isStreaming={false}
          onInputSubmit={onInputSubmit}
          colored={true}
        />
      </FooterContainer>
    </Container>
  );
}

export default Finish;
