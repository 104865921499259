//Enterprises
import { AllEffect, all, call, put, takeLatest } from 'redux-saga/effects';
import { actions as globalActions } from '@features/Global/slice';
import { addEnterprises, getAllEnterprises, updateEnterprise } from './api';
import { actions } from './slice';

function* getAllEnterrisesList(): Generator<any, void, any> {
  try {
    yield put(actions.loadEnterprisesListFetch());
    const response: any = yield call(getAllEnterprises);
    if (!response || (response.data && !response.data.length)) {
      throw new Error('Failed to fetch Enterprise list');
    }
    const sortedData = response.data.sort(
      (a: any, b: any) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );
    yield put(
      actions.loadEnterprisesListSuccess({
        data: sortedData || [],
        loading: false,
      }),
    );
  } catch (error) {
    console.log(error);
    yield put(
      actions.loadEnterprisesListSuccess({
        loading: false,
      }),
    );
  }
}

function* addNewEnterprise(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    const { name, dataProviderId, unsubscribes } = payload;

    yield put(actions.addEnterprisesFetch(true));
    const response: any = yield call(addEnterprises, {
      name,
      dataProviderId,
      unsubscribes,
    });
    if (!response || response.error) {
      throw new Error('Failed to add Enterprise');
    }
    if (response.status === 200) {
      yield put(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'success',
          toastMessage: 'Enterprise added',
        }),
      );
    }
    yield put(actions.getEnterprisesRequest());
    yield put(actions.addEnterprisesSuccess(false));
    if (payload.callback && typeof payload.callback === 'function') {
      payload.callback(response);
    }
  } catch (error) {
    yield put(
      globalActions.displayToast({
        duration: 3000,
        toastType: 'error',
        toastMessage: 'Failed to add Enterprise',
      }),
    );
  }
}

function* updateEnterprisefn(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    const { name, dataProviderId, unsubscribes, id } = payload;

    const response: any = yield call(updateEnterprise, {
      name,
      dataProviderId,
      unsubscribes,
      id,
    });

    if (!response || response.error) {
      throw new Error('Failed to update Enterprise');
    }
    if (response.status === 200) {
      yield put(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'success',
          toastMessage: 'Enterprise updated',
        }),
      );
      yield put(actions.getEnterprisesRequest());
    }
  } catch (error) {
    yield put(
      globalActions.displayToast({
        duration: 3000,
        toastType: 'error',
        toastMessage: 'Failed to update Enterprise',
      }),
    );
  }
}

export function* enterprisesSaga(): Generator<AllEffect<any>, void, unknown> {
  yield all([
    takeLatest(actions.getEnterprisesRequest.type, getAllEnterrisesList),
    takeLatest(actions.addEnterprisesRequest.type, addNewEnterprise),
    takeLatest(actions.updateEnterpriseRequest.type, updateEnterprisefn),
  ]);
}
