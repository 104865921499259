import * as React from 'react';

function ServerError(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="15em"
      height="12.5em"
      viewBox="0 0 240 200"
      fill="none"
      {...props}
    >
      <circle cx={120} cy={100} r={100} fill="#FFF4E3" />
      <path d="M0 44h240v128a8 8 0 01-8 8H8a8 8 0 01-8-8V44z" fill="#FEE3B9" />
      <path d="M0 28a8 8 0 018-8h224a8 8 0 018 8v16H0V28z" fill="#FFBF5F" />
      <rect
        x={120}
        y={62}
        width={70.711}
        height={70.711}
        rx={8}
        transform="rotate(45 120 62)"
        fill="#FFBF5F"
      />
      <path
        d="M138.75 100.75l-7.5-7.5L120 104.5l-11.25-11.25-7.5 7.5L112.5 112l-11.25 11.25 7.5 7.5L120 119.5l11.25 11.25 7.5-7.5L127.5 112l11.25-11.25z"
        fill="#FFF4E3"
      />
      <circle cx={12} cy={32} r={4} fill="#FFF4E3" />
      <circle cx={24} cy={32} r={4} fill="#FFF4E3" />
      <circle cx={36} cy={32} r={4} fill="#FFF4E3" />
    </svg>
  );
}

const MemoServerError = React.memo(ServerError);
export default MemoServerError;
