import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ExplorerWithAIButton from '@components/ExplorerWithAI';
import {
  TableContainer,
  TableHeader,
  TableLabelContainer,
  TableContent,
} from './styles';
import { ExtTableColumn, ExtTableRow } from '../types';
import { ExtTableNode_Column } from '@pages/Conversation/types';
import ExtTableBasic from '../ExtTableBasic';

import { actions as chatActions } from '@features/Chat/slice';

const VALID_COL_KEYS = [
  'Manager Name',
  'Fund Name',
  'Style',
  'Focus',
  'vintage_year',
  'Status',
  'has_ppm',
  'Has Ppm',
];

type ChatFundListTableProps = {
  columns: ExtTableNode_Column[];
  rows: ExtTableRow[];
};

function ChatFundListTable({
  columns,
  rows,
}: ChatFundListTableProps): React.JSX.Element {
  // Selected rows
  const [selectedRows, setSelectedRows] = useState<ExtTableRow[]>([]);
  // Original rows minus the selected ones
  const data = rows;
  const dispatch = useDispatch();

  let finalColumns: ExtTableColumn[] = [];

  finalColumns = finalColumns.filter(column =>
    VALID_COL_KEYS.includes(column.key),
  );

  finalColumns = [
    {
      key: 'compare',
      label: 'Compare',
      width: 30,
      type: 'custom',
      cellRenderer: curRow => {
        const isChecked = !!selectedRows.find(
          item =>
            (item.fund_name || item['Fund Name']) ===
            (curRow.fund_name || curRow['Fund Name']),
        );

        return (
          <Checkbox
            checked={isChecked}
            onChange={event => {
              if (event.target.checked) {
                setSelectedRows([...selectedRows, curRow]);
              } else {
                setSelectedRows(
                  selectedRows.filter(
                    item =>
                      (item.fund_name || item['Fund Name']) !==
                      (curRow.fund_name || curRow['Fund Name']),
                  ),
                );
              }
            }}
            disabled={selectedRows.length > 1 && !isChecked}
          />
        );
      },
    },
    ...columns.filter(col => !['Has Ppm', 'has_ppm'].includes(col.key)),
  ];

  const handleViewDetails = (_q: string, no_ppm: boolean) => {
    // If no 'fund' in name, add it
    const fund_name = _q.toLocaleLowerCase().includes('fund')
      ? _q
      : `${_q} fund`;
    dispatch(
      chatActions.searchQueryRequest({
        query: no_ppm
          ? `View details on ${fund_name}`
          : `Tell me more about ${fund_name}`,
      }),
    );
  };

  const hasFundName: boolean = columns.some(obj => obj.label === 'Fund Name');

  if (hasFundName) {
    finalColumns.push({
      key: '_view_details',
      label: '',
      type: 'custom',
      cellRenderer: curRow => {
        const cur_row_has_ppm: any =
          curRow.has_ppm || curRow['Has Ppm'] || false;
        const no_ppm = cur_row_has_ppm === false || cur_row_has_ppm === 'False';
        return (
          <ExplorerWithAIButton
            style={{
              position: 'absolute',
              top: '-14px',
              width: 'auto',
              fontSize: '12px',
              borderRadius: '10px',
              height: '28px',
            }}
            label={no_ppm ? 'View Details' : 'Explore with AI'}
            containerStyle={{ position: 'relative', width: '150px' }}
            onClick={() =>
              handleViewDetails(
                `${curRow.fund_name || curRow['Fund Name']}`,
                no_ppm,
              )
            }
          />
        );
      },
    });
  }

  finalColumns = finalColumns.map(col => {
    const widths = {
      compare: 30,
      'Vintage Year': 70,
      'Manager Name': 155,
      'Fund Name': 175,
      Style: 70,
      Focus: 60,
      Status: 60,
      'Net IRR (%)': 65,
      _view_details: 140,
    };
    return {
      ...col,
      maxWidth: widths[col.key] || widths[col.label] || 100,
    } as ExtTableColumn;
  });

  const onCompare = () => {
    const selected_fund_names = selectedRows.map(
      row => row.fund_name || row['Fund Name'],
    );
    const prompt = 'Compare ' + selected_fund_names.join(' and ');
    dispatch(chatActions.searchQueryRequest({ query: prompt }));
  };

  const theme = createTheme({
    components: {
      // Probably unused
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: '0px 1px 2px 0px #1018281f',
            border: '1px solid #e4e7ec',
            borderRadius: '8px',
          },
        },
      },
      // Probably unused
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '4px 8px 4px 16px',
          },
          head: {
            padding: '12px 8px 12px 16px',
            background: '#FFF',
            fontWeight: '400',
            fontSize: '12px',
            color: '#86858B',
          },
        },
      },
      // Probably unused
      MuiTableHead: {
        styleOverrides: {
          root: {
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            backgroundColor: '#FFF',
          },
        },
      },
      // Probably unused
      MUIDataTable: {
        styleOverrides: {
          // @ts-expect-error @typescript-eslint/ban-ts-comment
          responsiveBase: {
            maxHeight: '412px',
            /* '&::-webkit-scrollbar': {
              display: 'none',
            },*/
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '10px',
            },
          },
        },
      },
      // For MRT Table
      MuiTableBody: {
        styleOverrides: {
          root: {
            '& .selected-list-row': {
              backgroundColor: '#ebf3f2 !important',
            },
          },
        },
      },
    },
  });

  // Reorder rows, put selected first
  const reorderedRows = selectedRows.concat(
    data.filter(row => !selectedRows.includes(row)),
  );

  const isRowSelected = (row: ExtTableRow) => {
    return !!selectedRows.find(
      item =>
        (item.fund_name || item['Fund Name']) ===
        (row.fund_name || row['Fund Name']),
    );
  };

  return (
    <TableContainer>
      <TableHeader>
        <TableLabelContainer
          container
          flexDirection={'column'}
          justifyContent={'center'}
        ></TableLabelContainer>

        <ExplorerWithAIButton
          label={'Compare'}
          buttonWidth={'auto'}
          buttonProps={{
            variant: 'outlined',
            customColor: '#FFFFFF',
            customBorderColor: '#2C3748',
            textColor: '#2C3748',
          }}
          style={{ height: 'auto' }}
          disabled={selectedRows.length < 2}
          onClick={() => onCompare()}
        />
      </TableHeader>

      <TableContent>
        <ThemeProvider theme={theme}>
          <ExtTableBasic
            title=""
            rows={reorderedRows}
            columns={finalColumns}
            isRowSelected={isRowSelected}
          />
        </ThemeProvider>
      </TableContent>
    </TableContainer>
  );
}

export default ChatFundListTable;
