import React, { useState } from 'react';
import { useTheme } from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { PrimaryButton } from '@components/Button';
import { IIntegration } from '@features/Global/types';
import AskQuestionIcon from '@icons/Q&A';
import SwapIcon from '@icons/swap';
import ConnectIcon from '@icons/ConnectIcon';
import HlIcon from '@logos/231027_HelixByHL_Logo_Color.svg';
import { ParagraphBoldText, ParagraphLightText } from '@components/Typography';
import InputDrawer from './InputDrawer';
import {
  WealthboxAuthUrl,
  HubspotAuthUrl,
  SalesforceAuthUrl,
  INTEGRATIONS_NAME,
} from './config';
import {
  CrmLogo,
  StyledAccordionContainer,
  StyledCardContainer,
  StyledCardWrapper,
  DetailsDrawerContainer,
  StyledFooterContainer,
  StyledImgDiv,
  StyledLoginWrapper,
  StyledLogoContainer,
  StyledSFAccordion,
  StyledSFAccordionSummary,
  StyledTextContainer,
} from './styles';

interface data {
  logo: React.ReactElement | string;
  title: string;
  description: string;
}

const accordionCardData: data[] = [
  {
    logo: <ConnectIcon style={{ width: '90%' }} />,
    title: 'Trouble Connecting?',
    description: 'Follow the steps below',
  },
  {
    logo: <AskQuestionIcon />,
    title: 'Commonly Asked Questions',
    description: 'See the most common FAQs below',
  },
];

interface CRMDrawerProps {
  integration: IIntegration;
  handleDrawerClose: () => void;
  reloadAllIntegrations: () => void;
}

function CRMDrawer({
  integration,
  handleDrawerClose,
  reloadAllIntegrations,
}: CRMDrawerProps): React.JSX.Element {
  const theme = useTheme();
  const [showConnectSF, setShowConnectSF] = useState<boolean>(false);

  const handleLoginClick = () => {
    switch (integration.crmName) {
      case INTEGRATIONS_NAME.WEALTHBOX:
        window.location.href = WealthboxAuthUrl;
        break;
      case INTEGRATIONS_NAME.HUBSPOT:
        window.location.href = HubspotAuthUrl;
        break;
      case INTEGRATIONS_NAME.SALESFORCE:
        window.location.href = SalesforceAuthUrl;
        break;
      case INTEGRATIONS_NAME.REDTAIL:
        setShowConnectSF(true);
        break;
    }
  };

  return (
    <DetailsDrawerContainer container p={4}>
      <StyledLoginWrapper
        container
        p={4}
        mb={4}
        alignItems={'center'}
        justifyContent={'center'}
        borderColor={theme.palette.primary['borderColor']}
        background={theme.palette.primary['background10']}
      >
        <StyledLogoContainer>
          <CrmLogo src={HlIcon} alt="Icon" />
          <SwapIcon style={{ margin: '0 20px' }} />
          <CrmLogo
            src={require(`@icons/${integration.icon}`)}
            alt={`${integration.crmName} Icon`}
          />
        </StyledLogoContainer>
        <StyledTextContainer
          container
          pb={3}
          alignItems={'center'}
          flexDirection={'column'}
        >
          <ParagraphBoldText pb={'4px'} fontSize={'20px'}>
            Connect to {integration.crmName}
          </ParagraphBoldText>
          <ParagraphLightText textAlign={'center'}>
            Enter your Master Account Number to pull in clients and portfolios{' '}
          </ParagraphLightText>
        </StyledTextContainer>
        {!showConnectSF ? (
          <PrimaryButton sx={{ marginBottom: 3 }} onClick={handleLoginClick}>
            Login to connect
          </PrimaryButton>
        ) : (
          <InputDrawer
            integrationData={integration}
            handleDrawerClose={handleDrawerClose}
            reloadAllIntegrations={reloadAllIntegrations}
          />
        )}
      </StyledLoginWrapper>
      <StyledAccordionContainer container flexDirection={'column'} rowGap={3}>
        {accordionCardData.map((item, index) => (
          <StyledSFAccordion key={index} disableGutters elevation={0}>
            <StyledSFAccordionSummary
              expandIcon={<ExpandMoreOutlinedIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <StyledCardContainer>
                <StyledImgDiv>{item?.logo}</StyledImgDiv>
                <StyledCardWrapper>
                  <ParagraphBoldText fontSize={'20px'}>
                    {item?.title}
                  </ParagraphBoldText>
                  <ParagraphLightText
                    fontSize={'14px'}
                    color={theme.palette.primary['charcoalBlack']}
                  >
                    {item?.description}
                  </ParagraphLightText>
                </StyledCardWrapper>
              </StyledCardContainer>
            </StyledSFAccordionSummary>
          </StyledSFAccordion>
        ))}
      </StyledAccordionContainer>
      <StyledFooterContainer>
        <ParagraphLightText>Still need help?</ParagraphLightText>
        <ParagraphLightText
          ml={1}
          fontWeight={500}
          sx={{ textDecoration: 'underline' }}
        >
          Contact Us
        </ParagraphLightText>
      </StyledFooterContainer>
    </DetailsDrawerContainer>
  );
}

export default CRMDrawer;
