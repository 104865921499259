import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
//sentry
import * as Sentry from '@sentry/react';

import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { ThemeProvider as MuiStylesThemeProvider } from '@mui/styles';
import GlobalStyle from '@styles/global';
import { ThemeProvider } from 'styled-components';

import { COLORS } from 'utils/styles/colors';
import theme from 'utils/styles/theme';

import { configureAppStore } from 'store/configureStore';
import reportWebVitals from './reportWebVitals';

// Import root app
import Router from 'app/Router';
import { AppProvider } from 'app/AppContext';
import AppModals from 'app/AppModals';
import NotificationListener from 'app/NotificationListener';

const store = configureAppStore();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

// Initialise React Query Client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 1,
      retryDelay: (attemptIndex: number) => 1000 * 2 ** attemptIndex,
      cacheTime: 1000 * 60 * 60, // 60 minutes of cache time
      staleTime: 1000 * 30, // 30 seconds of stale time
    },
  },
});

//Initialize Sentry
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation: useLocation,
      useNavigationType: useNavigationType,
      createRoutesFromChildren: createRoutesFromChildren,
      matchRoutes: matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
});

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <MuiStylesThemeProvider theme={theme}>
            <MuiThemeProvider theme={theme}>
              <ThemeProvider theme={COLORS}>
                <GlobalStyle />
                <AppProvider>
                  <AppModals />
                  <NotificationListener />
                  <Router />
                </AppProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </MuiStylesThemeProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </HelmetProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
