import { SmallText } from '@components/Typography';
import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { ReactComponent as GreenRightArrow } from '@icons/GreenRightArrow.svg';
import { ReactComponent as BlueRightArrow } from '@icons/BlueRightArrow.svg';
import { useNavigate } from 'react-router-dom';

type SuggestionCardProps = {
  id: number;
  prompt: string;
  category: string;
};

const SuggestionCard: React.FC<SuggestionCardProps> = ({
  id,
  prompt,
  category,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const handleViewDetails = () => {
    navigate('/conversations', { state: { category: category, prompt } });
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        background: '#192335',
        padding: '10px 12px',
        height: 'clamp(40px, 5.7vw, 55px)',
        borderRadius: '8px',
        cursor: 'pointer',
        '&:hover': {
          background: '#4D5E71',
        },
      }}
      key={id}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleViewDetails}
    >
      <SmallText
        color="#FFFFFF"
        fontSize="clamp(10px, 1vw, 14px)"
        marginRight="10px"
        width="90%"
      >
        {prompt}
      </SmallText>
      <div>{isHovered ? <BlueRightArrow /> : <GreenRightArrow />}</div>
    </Stack>
  );
};

export default SuggestionCard;
