import styled from 'styled-components';
import { Grid } from '@mui/material';

export const FundDetailsDrawerContainer = styled(Grid)<any>`
  width: 100%;
  height: 100%;
`;

export const StatsCardContainer = styled(Grid)<any>``;

export const StatsCardWrapper = styled(Grid)<any>`
  border-radius: 4px;
  border: 1px solid #e4e7ec;
  background: #fcfdfd;
`;
