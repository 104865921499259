import { isUserLoggedIn } from '@common/auth';
import LayoutBackdrop from '@components/Creative/LayoutBackdrop';
import PreLoader from '@components/Creative/Preloader';
import ToastAlert from '@components/ToastAlert';
import { actions as globalActions } from '@features/Global/slice';
import { actions as profileActions } from '@features/Profile/slice';
import { AuthUserType } from '@features/Profile/types';
import NotFoundPage from '@helper/NotFoundPage';
import ApiInstance from '@instances/index';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import DemoPage from '@pages/Demo';
import ProductTour from '@pages/Tour';
import * as Sentry from '@sentry/react';
import React, { Fragment, Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { oktaAuth } from '@services/oktaAuth';
import {
  openRoutes,
  OpenRoutesProps,
  securedRoutes,
  SecuredRoutesProps,
  stateRoutes,
} from './routes';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const RouteComponentWrapper = ({
  path,
  access,
  visible,
  isSecure,
  component: Component,
}: any): React.JSX.Element => {
  try {
    const accessType = localStorage.getItem(
      'tifin-ai-user-type',
    ) as AuthUserType;
    const isLoggedIn: boolean = isUserLoggedIn({
      apiKey: localStorage.getItem('tifin-ai-api-key') as string,
      apiToken: localStorage.getItem('x-tifin-ai-token') as string,
      userType: accessType,
    });

    if (visible && !visible.inRoutes) {
      return <Navigate to="/404" replace />;
    }

    if (isSecure && !isLoggedIn) {
      return <Navigate to="/login" replace />;
    }

    if (isSecure && access && accessType === 'SUPER_ADMIN' && path === '/') {
      return <Navigate to="/manage-users" replace />;
    }
    if (isSecure && access && !access.includes(accessType)) {
      return <Navigate to="/404" replace />;
    }

    return <Component path={path} access={access} isSecure={isSecure} />;
  } catch (e) {
    console.log('Error in RouteComponentWrapper : ', e);
    return <Navigate to="/" />;
  }
};

const Router = (): React.JSX.Element => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accessType = localStorage.getItem('tifin-ai-user-type') as AuthUserType;
  const isLoggedIn: boolean = isUserLoggedIn({
    apiKey: localStorage.getItem('tifin-ai-api-key') as string,
    apiToken: localStorage.getItem('x-tifin-ai-token') as string,
    userType: accessType,
  });

  const logoRender = (state: any) => {
    console.log('logoRender successfully', state);
  };

  const customAuthHandler = () => {
    navigate('/login');
  };

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || '', window.location.origin), {
      replace: true,
    });
  };

  ApiInstance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error && error.response && error.response.status === 401) {
        console.log('====== Auth issue 401 : Unauthorized User!! ======');
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'error',
            toastMessage: `Session expired. Please log in again`,
          }),
        );
        // Clear cookies
        document.cookie.split(';').forEach(function (cookie) {
          document.cookie = cookie
            .replace(/^ +/, '')
            .replace(
              /=.*/,
              '=;expires=' + new Date().toUTCString() + ';path=/',
            );
        });
        dispatch(profileActions.logoutOktaUserRequest());
        localStorage.clear();
        sessionStorage.clear();
        navigate('/');
      }
      return Promise.reject(error);
    },
  );

  useEffect(() => {
    const tokenExpiredHandler = async () => {
      try {
        const freshToken = await oktaAuth.tokenManager.renew('accessToken');

        if (freshToken) {
          if ('accessToken' in freshToken) {
            localStorage.setItem(
              'x-tifin-ai-token',
              `Bearer ${freshToken.accessToken}`,
            );
          }
        }
      } catch (err: any) {
        if (err.name === 'OAuthError' || err.name === 'AuthSdkError') {
          console.log(err);
        }
      }
    };

    oktaAuth.tokenManager.on('expired', tokenExpiredHandler);

    return () => {
      oktaAuth.tokenManager.off('expired', tokenExpiredHandler);
    };
  }, []);

  return (
    <Fragment>
      <Helmet
        titleTemplate="%s - HelixbyHL"
        defaultTitle="HelixbyHL : Assistant"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="HelixbyHL : Assistant" />
      </Helmet>
      <Suspense fallback={<PreLoader callback={logoRender} />}>
        <Security
          restoreOriginalUri={restoreOriginalUri}
          oktaAuth={oktaAuth}
          onAuthRequired={customAuthHandler}
        >
          <SentryRoutes>
            {!isLoggedIn &&
              openRoutes.map(
                ({
                  path,
                  index,
                  element,
                  isSecure,
                  redirectTo = null,
                }: OpenRoutesProps) => (
                  <Route
                    key={path}
                    path={path}
                    index={index}
                    element={
                      redirectTo ? (
                        <Navigate to={redirectTo} replace />
                      ) : (
                        <RouteComponentWrapper
                          path={path}
                          isSecure={isSecure}
                          component={element}
                        />
                      )
                    }
                  />
                ),
              )}
            {isLoggedIn &&
              securedRoutes.map(
                ({
                  path,
                  index,
                  element,
                  access,
                  isSecure,
                  visible,
                  children,
                }: SecuredRoutesProps) => (
                  <Route
                    key={path}
                    path={path}
                    index={index as any}
                    element={
                      <RouteComponentWrapper
                        path={path}
                        visible={visible}
                        access={access}
                        isSecure={isSecure}
                        component={element}
                      />
                    }
                  >
                    {children &&
                      children.map(
                        ({
                          path: childPath,
                          name: childName,
                          index: childIndex,
                          element: childElement,
                          access: childAccess,
                          visible: childVisible,
                        }: any) => (
                          <Route
                            key={childPath}
                            path={childPath}
                            element={
                              <RouteComponentWrapper
                                path={childPath}
                                name={childName}
                                index={childIndex}
                                isSecure={isSecure}
                                access={childAccess}
                                visible={childVisible}
                                component={childElement}
                              />
                            }
                          />
                        ),
                      )}
                  </Route>
                ),
              )}
            {stateRoutes.map(({ path, element, isSecure }: OpenRoutesProps) => (
              <Route
                key={path}
                path={path}
                element={
                  <RouteComponentWrapper
                    path={path}
                    isSecure={isSecure}
                    component={element}
                  />
                }
              />
            ))}
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/demo" element={<DemoPage />} />
          </SentryRoutes>
        </Security>
      </Suspense>
      <ToastAlert />
      <ProductTour />
      <LayoutBackdrop />
    </Fragment>
  );
};

export default Router;
