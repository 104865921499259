import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  Grid,
  Table,
  Rating,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import styled, { css } from 'styled-components';

export const ChatPageContainer = styled(Grid)`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ChatContentContainer = styled(Grid)<{
  customHeight?: number;
  isLoading?: boolean;
}>`
  width: 100%;
  height: ${({ customHeight }) => `calc(100% - (${customHeight}px + 90px))`};

  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px !important;
    height: 2px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.grey4} !important;
    border-radius: 20px !important;
    margin-bottom: 10px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
    margin-top: 12px !important;
  }

  transition:
    padding cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
    display cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
    opacity cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.3s;

  ${({ isMinimized }: any) =>
    !isMinimized &&
    css`
      padding: 0;
    `}

  ${({ isLoading }: any) =>
    isLoading &&
    css`
      opacity: 0;
    `}
`;

export const ChatBoxContainer = styled(Grid)<{ isSender?: boolean }>`
  position: relative;
  width: 100%;
  padding: 1rem;
  margin-bottom: 10px;

  ${({ isSender }: any) =>
    isSender &&
    css`
      border-radius: 48px;
      border: 1px solid rgba(174, 198, 249, 0.3);
      background: rgba(204, 233, 250, 0.3);
    `}
  .interrupted {
    color: #aaa;
    font-size: 12px;
  }
`;

export const ChatBoxContent = styled(Grid)<{ isSender?: boolean }>`
  max-width: ${props =>
    props.isSender ? '100%' : 'calc(100% - 50px) !important'};
  flex-direction: ${props =>
    props.isSender ? 'column !important' : 'column !important'};
  justify-content: ${props => (props.isSender ? 'flex-end' : 'flex-start')};
  align-items: ${props => (props.isSender ? 'flex-end' : 'flex-start')};
`;

export const SliderChatWrapper = styled(Grid)`
  width: 100%;
  padding: 10px;
  color: ${props => props.theme.textBlack};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 2px 10px 10px 10px;
  background: linear-gradient(
    92deg,
    ${props => props.theme.gradientGreen} 0%,
    ${props => props.theme.gradientBlue} 100%
  );
`;

export const HoldingListWrapper = styled(Grid)`
  width: 100%;
  max-width: 100%;
  padding: 12px;
  border-radius: 4px;
  background: rgba(249, 250, 251, 0.7);
  backdrop-filter: blur(2px);
  border: 1px solid #e4e7ec;
`;

export const AvatarWrapper = styled(Grid)<any>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ChatBoxContentWrapper = styled(Grid)<{
  width?: string;
  isSender?: boolean;
  isTableContent?: boolean;
}>`
  width: ${props => props.width || 'max-content'};
  max-width: 100%;
  color: ${props => props.theme.textBlack};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 2px 10px 10px 10px;

  span {
    color: ${props => props.theme.textBlack};
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  ${({ isTableContent }: any) =>
    isTableContent
      ? css`
          padding: 0;
          border-radius: 8px;
          box-shadow: 0px 4px 16px 0px rgba(22, 16, 40, 0.1);
        `
      : null};

  ${({ isSender }: any) =>
    isSender &&
    css`
      font-weight: 500;
    `}
`;

export const ChatTableContainer = styled(TableContainer)`
  &::-webkit-scrollbar {
    width: 4px !important;
    height: 2px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.grey4} !important;
    border-radius: 20px !important;
    margin-bottom: 10px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
    margin-top: 12px !important;
  }
`;

export const ChatTableWrapper = styled(Table)`
  display: block;
  border: none;
  padding: 0;
  overflow-x: scroll;
  border-collapse: collapse;
  white-space: nowrap;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.borderColor};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);

  & .MuiTableHead-root {
    & .MuiTableRow-root {
      th {
        padding: 12px 20px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        color: ${props => props.theme.solidWhite};
        text-align: left;
        background: ${props => props.theme.black};
        backdrop-filter: blur(2px);
        border: none;
        border-bottom: 1px solid ${props => props.theme.borderColor};

        &:first-child {
          padding: 12px 16px;
          text-align: left;
          border-top-left-radius: 6px;
        }
        &:last-child {
          padding: 12px 16px;
          text-align: left;
          border-top-right-radius: 6px;
        }
      }
    }
  }
  & .MuiTableBody-root {
    & .MuiTableRow-root {
      td {
        &:first-child {
          padding: 12px 16px;
          text-align: left;
        }
        &:last-child {
          padding: 12px 16px;
          text-align: left;
        }
      }
      &:last-child {
        td {
          border-bottom: 0px;
          &:first-child {
            border-bottom-left-radius: 6px;
          }
          &:last-child {
            border-bottom-right-radius: 6px;
          }
        }
      }
    }
  }
`;

export const ChatTableHead = styled(TableHead)`
  th {
    font-weight: 500 !important;
    text-transform: capitalize !important;
    background: ${props => props.theme.black} !important;
    color: #fff !important;
  }
  &:first-child {
    border-top-left-radius: 6px !important;
  }
  &:last-child {
    border-top-right-radius: 6px !important;
  }
`;

export const ChatTableBody = styled(TableBody)``;

export const ChatTableRow = styled(TableRow)``;

export const ChatTableCell = styled(TableCell)`
  padding: 12px 20px !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 300;
  line-height: 16px !important;
  color: ${props => props.theme.black};
  text-align: left;
  background: ${props => props.theme.solidWhite};
  backdrop-filter: blur(2px);
  border: none;
  border-bottom: 1px solid ${props => props.theme.borderColor} !important;
`;

export const InvestmentContainer = styled(Container)`
  margin-left: 0px;
  padding: 0px !important;
`;
export const InvestmentCard = styled(Card)`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
  boxshadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;
export const Description = styled('div')`
  font-size: 12px;
  color: #4d4d4d;
  margin-top: 5px;
  padding-right: 15px;
`;

export const ButtonBox = styled(Box)`
  flex: 30%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const ViewImplementationButton = styled(Button)`
  font-size: 12px !important;
  border: 1px solid #000;
  color: #000;
  border-radius: 16px !important;
  margin-right: 8px !important;
  text-transform: none !important;
`;

export const ApplyButton = styled(Button)`
  font-size: 12px !important;
  background-color: #000 !important;
  color: #fff !important;
  border: 1px solid #000 !important;
  border-radius: 16px !important;
  text-transform: none !important;
`;

export const CIOCard = styled(Card)`
  background-color: #f9fafb;
`;

export const CIOChip = styled(Chip)`
  color: #5a53bf !important;
  background-color: #efeff8 !important;
  font-size: 10px !important;
`;

export const TextWrapper = styled('p')`
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  text-align: left;
`;

export const ButtonContainer = styled(Grid)`
  border-radius: 8px;
  background: var(--neutral-100, #f2f4f7);
  padding: 16px;
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 124%;
  background: rgba(213, 213, 213, 0.2);
  padding: 15px;
  border-radius: 8px;
`;

export const QuestionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const QuestionItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  background: #fff;
  flex-basis: calc(
    50% - 10px
  ); /* Set width for two cards in a row with some margin */
  margin-bottom: 20px;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
`;

export const QuestionHead = styled.div`
  color: #333;
  font-size: 14px;
  padding: 15px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export const QuestionListItem = styled.div`
  color: #667085;
  font-size: 14px;
  padding: 10px 0px;
  cursor: pointer;

  :hover {
    font-weight: 900;
    transition: font-size 0.2s linear 0s;
  }
`;

export const GuidanceText = styled.div`
  padding: 10px;
  font-size: 14px;
  color: #000;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 25%;
  justify-content: space-between;
`;

export const StyledQuestionCardButton = styled(Button)`
  border-radius: 20px;
  color: #000;
  font-size: 14px;
  text-transform: none;
  border: 1px solid #e4e7ec;
  background: linear-gradient(0deg, #fff 0%, #d5d5d5 921.25%);
  background-color: transparent;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
`;

export const FooterSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuestionHeaderDiv = styled.div`
  display: flex;
  color: #000;
  padding-bottom: 15px;
  font-size: 14px;
`;

export const StyledDataTable = styled(MUIDataTable)`
  & .MuiTableCell-root {
    background-color: #333;
    color: #f7f7f7;
  }
`;

export const TickerDrawerContainer = styled.div`
  width: 94%;
  height: auto;
  padding: 20px 15px 15px 20px;
`;

export const DrawerWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const HeaderDivWrapper = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
`;

export const HeaderAnalyticDataDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DescriptionDivWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const DescText = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-bottom: 5px;
  font-size: 16px;
  color: #000;
  font-weight: 500;
`;

export const DescPara = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  color: #3c3c3c;
  font-weight: 400;
`;

export const ReturnsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const HeaderSection = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
`;

export const TickerDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 10px;
`;

export const TickerName = styled.div`
  font-size: 24px;
  color: #000;
  margin-right: 10px;
`;

export const TickerOrgName = styled.div`
  font-size: 14px;
  color: #000;
`;

export const LineGraphContainer = styled.div`
  width: 100%;
  display: flex;
  height: 300px;
`;

export const KeyStatisticsDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  justify-content: space-between;
`;

export const KeyStatisticsDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
`;

export const TitleText = styled.div`
  font-size: 14px;
  color: #667085;
  display: flex;
  width: 100%;
  padding-bottom: 10px;
`;

export const PercentageDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 7px;
  align-items: center;
`;

export const CurrentPercentage = styled.div`
  font-size: 16px;
  color: #000;
`;

export const CategoryPercentage = styled.div`
  font-size: 10px;
  color: #000;
`;

export const FundInfoWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const FundDataName = styled.div`
  font-size: 12px;
  color: #344054;
`;

export const FundValue = styled.div`
  font-size: 13px;
  color: #30302f;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SubText = styled.div`
  font-size: 10px;
  color: #979797;
`;

export const FundInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

export const FundInfoBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 30px;
`;

export const FundBoxHeader = styled.div`
  font-size: 16px;
  color: #000;
  padding-bottom: 5px;
`;

export const FundBoxSubHeader = styled.div`
  font-size: 12px;
  color: #98a2b3;
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageWrapper = styled('img')`
  width: 80%;
  height: auto;
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  box-shadow: #e8e8e8 2px 2px 3px 2px;
`;

export const ExitFundContainer = styled(Grid)<any>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;

  transition:
    height cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
    bottom cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
    opacity cubic-bezier(0.48, 0.65, 0.6, 0.79) 800ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    height cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s !important;

  ${({ visible }: any) =>
    visible &&
    css`
      bottom: 60px;
      opacity: 1;
    `}
`;

export const ExitFundWrapper = styled(Grid)`
  width: 100%;
  margin: 0 auto;
  align-items: center;
  border: 3px solid ${props => props.theme['borderColor']};
  border-radius: 4px 4px 0px 0px;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: ${props => props.theme['borderColor']};

  button {
    margin-right: -16px;
    font-size: 14px;
    color: ${props => props.theme.main} !important;
    text-transform: capitalize;
  }
`;

export const StyledRating = styled(Rating)<any>`
  width: 130px !important;
  justify-content: space-evenly;
`;

export const CopyButton = styled.div`
  position: absolute;
  bottom: 4px;
  font-size: 12px;
  cursor: pointer;
  color: #818181;

  &:hover {
    color: #222124;
    transition: color 0.2s linear 0s;
  }
`;

export const RecommendNodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
