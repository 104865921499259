import React from 'react';
import { useLocation } from 'react-router-dom';

import ErrorBoundary from 'app/helper/ErrorBoundary';

import { AppContainer } from './styles';

const LayoutRoute: React.FC<any> = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <AppContainer>
      Under Development
      <ErrorBoundary key={pathname}>{children}</ErrorBoundary>
    </AppContainer>
  );
};

export default LayoutRoute;
