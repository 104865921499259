import {
  FUND_LIST_FIXED_COLUMNS,
  MOCK_FUND_LIST,
} from '@features/Chat/mockdata';
import { Grid } from '@mui/material';
import ChatFundListTable from '@pages/Conversation/ChatExtTable/ChatFundListTable';
import React from 'react';

function Demo(): React.JSX.Element {
  return (
    <Grid position={'relative'} zIndex={9}>
      <ChatFundListTable
        columns={FUND_LIST_FIXED_COLUMNS}
        rows={MOCK_FUND_LIST}
      />
    </Grid>
  );
}

export default Demo;
