import React, { useState } from 'react';
import { Box, Tab, Grid, Drawer } from '@mui/material';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { makeStyles } from '@mui/styles';
import TabContext from '@mui/lab/TabContext';
import CRMCard from './CRMCard';
import CRMDrawer from './CRMDrawer';
import DetailsDrawer from './DetailsDrawer';
import { LayoutGutter } from '@styles/variables';
import useIntegrationsList from '@helper/CustomHooks/useIntegrationsList';
import { IIntegrationExt } from '@features/Global/types';

const useStyles = makeStyles({
  drawer: {
    width: 530,
  },
  drawerPaper: {
    top: `${LayoutGutter.rightSide.x} !important`,
    right: `${LayoutGutter.rightSide.y} !important`,
    width: 530,
    maxHeight: `calc(100vh - calc(${LayoutGutter.rightSide.x} + ${LayoutGutter.rightSide.y})) !important`,
    borderRadius: '16px',
  },
  root: {
    display: 'flex',
  },
});

const IntegrationsPage: React.FC = () => {
  const classes = useStyles();
  const [value, setValue] = useState('2');
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState<boolean>(false);
  const [selectedIntegration, setSelectedIntegration] =
    useState<IIntegrationExt | null>(null);
  const { data: integrations, refetch } = useIntegrationsList();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleConnectClick = (integration: IIntegrationExt) => {
    setSelectedIntegration(integration);
    setDrawerOpen(true);
  };

  const handleClose = () => {
    setSelectedIntegration(null);
    setDrawerOpen(false);
  };

  const handleViewDetail = (integration: IIntegrationExt) => {
    setSelectedIntegration(integration);
    setDetailsDrawerOpen(true);
  };

  const handleViewDetalDrawerClose = () => {
    setDetailsDrawerOpen(false);
    setSelectedIntegration(null);
  };

  const syncClientList = () => {};

  const reloadAllIntegrations = () => {
    console.log('reloadAllIntegrations...');
    refetch();
  };

  const handleDisconnect = () => {};

  const syncClient = (/*integration: IIntegrationExt*/) => {};

  return (
    <Grid
      container
      px={2}
      flexDirection={'column'}
      alignItems={'flex-start'}
      justifyContent={'flex-end'}
    >
      <Box>
        <TabContext value={value}>
          <Box
            className="tab-container"
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <TabList
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#101828',
                },
              }}
            >
              <Tab
                sx={{
                  textTransform: 'none',
                  '&.Mui-selected': {
                    color: '#101828',
                  },
                }}
                label="Custodian Platforms"
                value="1"
              />
              <Tab
                sx={{
                  textTransform: 'none',
                  '&.Mui-selected': {
                    color: '#101828',
                  },
                }}
                label="CRM Platforms"
                value="2"
              />
            </TabList>
          </Box>
          <TabPanel value="1">Coming Soon...</TabPanel>
          <TabPanel value="2" sx={{ padding: 0, paddingTop: 3 }}>
            {integrations && integrations.length > 0 && (
              <CRMCard
                sync={syncClient}
                list={integrations}
                handleViewDetail={handleViewDetail}
                handleConnectClick={handleConnectClick}
              />
            )}
          </TabPanel>
        </TabContext>
      </Box>

      <Box className={classes.root}>
        <Drawer
          anchor="right"
          variant="temporary"
          open={drawerOpen}
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          onClose={handleClose}
        >
          {selectedIntegration && drawerOpen && (
            <CRMDrawer
              integration={selectedIntegration}
              handleDrawerClose={handleClose}
              reloadAllIntegrations={reloadAllIntegrations}
            />
          )}
        </Drawer>
        <Drawer
          anchor="right"
          variant="temporary"
          open={detailsDrawerOpen}
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          onClose={handleViewDetalDrawerClose}
        >
          {selectedIntegration && detailsDrawerOpen && (
            <DetailsDrawer
              connectionData={selectedIntegration}
              syncClient={syncClientList}
              handleDrawerClose={handleViewDetalDrawerClose}
              handleDisconnect={handleDisconnect}
            />
          )}
        </Drawer>
      </Box>
    </Grid>
  );
};

export default IntegrationsPage;
