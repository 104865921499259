import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductTourState } from '@features/Global/selector';
import { actions as globalActions } from '@features/Global/slice';
import PreLoader from '@components/Creative/Preloader';
import { TourModal, Wrapper } from './styles';
import Layout from './Layout';

const Tour: React.FC<any> = () => {
  const dispatch = useDispatch();
  const productTourState = useSelector(getProductTourState);
  const [animate, startAnimation] = useState<any>(false);
  const allScriptsState: any = Object.entries(
    JSON.parse(JSON.stringify(productTourState)),
  )
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(
      ([key, value]) =>
        !['loadScripts', 'dashboardScripts'].includes(key) &&
        Array.isArray(value),
    )
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

  useEffect(() => {
    const timeout = setTimeout(() => {
      startAnimation(true);
    }, 1000);
    return () => {
      clearTimeout(timeout);
      startAnimation(false);
    };
  }, []);

  const stopProductTour = () => {
    dispatch(globalActions.finishProductTour(false));
  };

  const logoRender = (state: any) => {
    console.log('logoRender successfully', state);
  };

  const getLoadScriptsByComponentName = (componentName: string) => {
    return productTourState.loadScripts?.find(
      (k: any) => k.component === componentName,
    );
  };

  const style = getLoadScriptsByComponentName('RightSideContent')
    ? {
        position: 'relative',
        zIndex: 1,
      }
    : {};

  const layoutStyle = getLoadScriptsByComponentName('Sidebar')
    ? {
        background:
          'linear-gradient(180deg, #172337 0%, rgba(23, 35, 55, 0) 492.33% )',
      }
    : {};

  const totalScriptsLength =
    allScriptsState && Object.entries(allScriptsState).length;
  const calculateProgress = (state: any): number => {
    const totalScripts = Object.keys(state).filter(
      key => Array.isArray(state[key]) && state[key].length > 0,
    ).length;
    return (totalScripts / totalScriptsLength) * 100; // 9 is the total number of arrays SCRIPTS
  };

  const tourProgress = calculateProgress(allScriptsState);

  return (
    <Fragment>
      <TourModal
        keepMounted={false}
        open={productTourState.start}
        onClose={stopProductTour}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Wrapper>
          <Layout
            sx={layoutStyle}
            animate={animate}
            tourProgress={tourProgress}
            {...productTourState}
          />
          <PreLoader
            sx={style}
            hideLogo={true}
            logoMountDelay={100}
            logoUnmountDelay={3000}
            callback={logoRender}
          />
        </Wrapper>
      </TourModal>
    </Fragment>
  );
};

export default Tour;
