import React from 'react';
import {
  Switch,
  Stack,
  Box,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useRESTQuery } from 'utils/hooks';
import { isEmpty } from 'utils/common/string';

interface PropsType {
  defaultValue?: boolean;
  runId: string;
  onSuccess: any;
  handleToastMsg?: any;
  index?: number;
}

function BaseLineSwitch(props: PropsType): React.JSX.Element {
  const {
    defaultValue = false,
    runId = '',
    onSuccess = () => {},
    handleToastMsg = () => {},
  } = props;
  const [value, setValue] = React.useState(defaultValue);
  const [isSettingBaseLine, setIsSettingBaseLine] = React.useState(false);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onChangeSwitch = (event: any) => {
    setValue(event?.target?.checked);
    setIsSettingBaseLine(true);
  };

  const onErrorResponse = (error: any) => {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message &&
      typeof error.response.data.message === 'string'
    ) {
      handleToastMsg(error.response.data.message, 'error');
    } else {
      handleToastMsg('WENT_WRONG');
    }
  };

  useRESTQuery(
    [`testResultsSetBaseline-${props?.index}`],
    {
      endpoint: `tests/baseline`,
      postData: {
        id: runId,
        checked: value,
      },
      method: 'POST',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      retry: false,
      cacheTime: 0,
      enabled: isSettingBaseLine,
      onSuccess: res => {
        setIsSettingBaseLine(false);
        if (!isEmpty(res) && !isEmpty(res.message)) {
          if (res.message === 'Success') {
            handleToastMsg('Baseline set successfully');
            onSuccess();
          } else {
            handleToastMsg(res.message, 'error');
          }
        } else {
          handleToastMsg('WENT_WRONG');
        }
      },
      onError: error => {
        onErrorResponse(error);
        setIsSettingBaseLine(false);
      },
    },
  );

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 32,
    height: 18,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 16,
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      transform: 'translateX(2px)',
      '&.Mui-checked': {
        transform: 'translateX(14px)',
        color: '#E7EEF3',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#FFFFFF',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 18 / 2,
      opacity: 1,
      backgroundColor: '#8F96A4',
      boxSizing: 'border-box',
    },
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
        <Stack direction="row" spacing={1} alignItems="center" sx={{}}>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 400,
              color: '#464646',
            }}
          >
            Set Baseline
          </Typography>
          <FormControlLabel
            control={
              <AntSwitch
                checked={value}
                onChange={onChangeSwitch}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={''}
            labelPlacement={'start'}
            sx={{
              '& .MuiFormControlLabel-label': {
                lineHeight: 1,
              },
            }}
          />
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 400,
              color: '#464646',
            }}
          >
            {value ? `On` : `Off`}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
}

export default React.memo(BaseLineSwitch);
