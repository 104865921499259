import {
  AllEffect,
  call,
  all,
  put,
  takeLatest,
  delay,
} from 'redux-saga/effects';
import { oktaAuth } from '@services/oktaAuth';
import {
  ProfileDataProps,
  setUserAuthDetails,
  getProfileDataForLocal,
} from '@common/auth';
import { actions } from './slice';
import { actions as globalActions } from '../Global/slice';
import {
  getUserInfo,
  verifyHash,
  activateAccount,
  resetPasswordApi,
} from './api';

function* errorNotifications(error: any) {
  if (error && error.message) {
    yield put(
      globalActions.displayToast({
        duration: 3000,
        toastType: 'error',
        toastMessage: error?.message,
      }),
    );
  } else {
    yield put(
      globalActions.displayToast({
        duration: 3000,
        toastType: 'error',
        toastMessage: 'An error occurred while processing.',
      }),
    );
  }
}

function* userInfo(request: any): Generator<any, void, any> {
  try {
    const { payload } = request;
    if (!payload.token) throw '-- Token is missing in payload --';

    yield put(actions.userInfoFetch());
    const response: any = yield call(getUserInfo, payload.token);
    if (response) {
      setUserAuthDetails({
        skipCache: '1',
        apiKey: response.apiKey,
        apiToken: payload.token,
        userType: response.role,
        userId: response.userId,
        advisorId: response.advisorId,
        enterpriseId: response.enterpriseId,
        loggedUser: JSON.stringify(response), // NOTE: remove this logic asap!
        dataProviderId: response.dataProviderId,
      });

      const profileData: ProfileDataProps = getProfileDataForLocal();
      yield delay(600);

      yield put(
        actions.userInfoSuccess({
          apiToken: payload.token,
          userType: response?.role,
          apiKey: response?.apiKey,
          name: profileData.name,
          email: profileData.email,
          firstName: profileData.firstName,
          lastName: profileData.lastName,
          userId: response?.userId,
          advisorId: response?.advisorId,
          enterpriseId: response?.enterpriseId,
          dataProviderId: response?.dataProviderId,
        }),
      );

      yield put(
        globalActions.updateUserConfigSuccess({
          data: {
            tone: response?.tone || false,
            tAndC: response?.tAndC,
            feedback: response?.feedback || false,
            skipCache: response?.skipCache || false,
            crmIntegrations: response?.crmIntegrations || false,
            isProductTourViewed: response?.isProductTourViewed || false,
            custodialIntegration: response?.custodialIntegration || false,
          },
          loading: false,
        }),
      );

      if (response?.tAndC) {
        localStorage.setItem('terms-and-conditions', 'accepted');
      }

      if (payload.callback && typeof payload.callback === 'function') {
        payload.callback(response);
      }
    } else {
      throw response;
    }
  } catch (error: any) {
    console.error(error);
    yield errorNotifications(error);
    yield put(
      actions.userInfoSuccess({
        apiKey: null,
        apiToken: null,
        userType: null as any,
        userId: null,
        advisorId: null,
        enterpriseId: null,
        dataProviderId: null,
      }),
    );
  }
}

function* logoutOktaUser(): Generator<any, void, any> {
  try {
    yield put(actions.logoutOktaUserFetch());
    yield oktaAuth.signOut();
    yield put(
      actions.logoutOktaUserSuccess({
        apiKey: null,
        apiToken: null,
        userType: null as any,
        userId: null,
        advisorId: null,
        enterpriseId: null,
        dataProviderId: null,
      }),
    );
  } catch (error: any) {
    console.error(error);
    errorNotifications(error);
    yield put(
      actions.logoutOktaUserSuccess({
        apiKey: null,
        apiToken: null,
        userType: null as any,
        userId: null,
        advisorId: null,
        enterpriseId: null,
        dataProviderId: null,
      }),
    );
  } finally {
    // NOTE: Lets remove this LocalStorage item dependencies from code!
    const keepLoggedInCache = localStorage.getItem('tifin-ai-keepLoggedIn');
    localStorage.clear();
    sessionStorage.clear();
    if (keepLoggedInCache) {
      localStorage.setItem('tifin-ai-keepLoggedIn', keepLoggedInCache);
    }
  }
}

function* acceptTermsAndConditions(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    localStorage.setItem('terms-and-conditions', 'accepted');
    yield put(actions.acceptedTermsAndConditionsFetch(true));
    yield put(
      globalActions.updateUserConfigRequest({
        tAndC: payload.accptedTNC || true,
        isProductTourViewed: true,
      }),
    );

    yield put(actions.acceptedTermsAndConditionsSuccess(false));
    yield put(globalActions.startProductTourRequest());
    if (payload.callback && typeof payload.callback === 'function') {
      payload.callback();
    }
  } catch (error) {
    console.error('Async action error:', error);
    yield put(actions.acceptedTermsAndConditionsSuccess(false));
  }
}

function* validateHashKey(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    const { hashKey } = payload;
    yield put(actions.validateHashKeyFetch(true));
    const response: any = yield call(verifyHash, hashKey);
    yield put(actions.validateHashKeySuccess(false));
    if (payload.callback && typeof payload.callback === 'function') {
      payload.callback(response);
    }
  } catch (error) {
    console.error('Async action error:', error);
    yield errorNotifications(error);
    yield put(actions.validateHashKeySuccess(false));
  }
}

function* accountActivation(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    const { newPassword, retypePassword, hashKey } = payload;
    yield put(actions.accountActivationFetch(true));
    if (!newPassword && !retypePassword && !hashKey) {
      yield put(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage:
            'Both fields are required to proceed. Please fill in all the details.',
        }),
      );
      throw new Error(
        'Both fields are required to proceed. Please fill in all the details.',
      );
    }
    const response: any = yield call(activateAccount, {
      payload: {
        password: newPassword,
        retypePassword,
      },
      headers: {
        'x-tifin-ai-hash-key': hashKey,
      },
    });
    yield put(actions.accountActivationSuccess(false));
    if (payload.callback && typeof payload.callback === 'function') {
      payload.callback(response);
    }
  } catch (error: any) {
    console.error('Async action error:', error);
    yield errorNotifications(error);
    yield put(actions.accountActivationSuccess(false));
  }
}

function* resetPassword(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    const { oldPassword, newPassword } = payload;
    yield put(actions.resetPasswordFetch(true));
    if (!newPassword && !newPassword) {
      yield put(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage:
            'Both fields are required to proceed. Please fill in all the details.',
        }),
      );
      throw new Error(
        'Both fields are required to proceed. Please fill in all the details.',
      );
    }
    const response: any = yield call(resetPasswordApi, {
      payload: {
        oldPassword,
        newPassword,
      },
    });
    yield put(
      globalActions.displayToast({
        duration: 3000,
        toastType: 'success',
        toastMessage: response.message,
      }),
    );
    yield put(actions.resetPasswordSuccess(false));
    if (payload.callback && typeof payload.callback === 'function') {
      payload.callback(response);
    }
    yield put(actions.logoutOktaUserRequest());
  } catch (error: any) {
    console.error('Async action error:', error);
    yield errorNotifications(error);
    yield put(actions.resetPasswordSuccess(false));
  }
}

export function* profileSaga(): Generator<AllEffect<any>, void, unknown> {
  yield all([
    takeLatest(actions.userInfoRequest.type, userInfo),
    takeLatest(actions.logoutOktaUserRequest.type, logoutOktaUser),
    takeLatest(actions.accountActivationRequest.type, accountActivation),
    takeLatest(actions.resetPasswordRequest.type, resetPassword),
    takeLatest(actions.validateHashKeyRequest.type, validateHashKey),
    takeLatest(
      actions.acceptedTermsAndConditionsRequest.type,
      acceptTermsAndConditions,
    ),
  ]);
}
