import { ExtTableNode_Column } from '@pages/Conversation/types';

export const MOCK_FUND_LIST = [
  {
    fund_id: '1',
    fund_name: '270 Life Sciences Private Capital',
    fund_style: 'Venture Capital',
    fund_manager: 'Kerry Priceton',
    fund_size: 162206,
  },
  {
    fund_id: '2',
    fund_name: 'Placeholder Fund I',
    fund_style: 'Venture Capital',
    fund_manager: 'Kerry Priceton',
    fund_size: 187345,
  },
  {
    fund_id: '3',
    fund_name: 'Placeholder Fund II',
    fund_style: 'Venture Capital',
    fund_manager: 'Kerry Priceton',
    fund_size: 100000,
  },
  {
    fund_id: '4',
    fund_name: 'Placeholder Fund II',
    fund_style: 'Venture Capital',
    fund_manager: 'Kerry Priceton',
    fund_size: 100000,
  },
  {
    fund_id: '5',
    fund_name: 'Placeholder Fund II',
    fund_style: 'Venture Capital',
    fund_manager: 'Kerry Priceton',
    fund_size: 100000,
  },
  {
    fund_id: '6',
    fund_name: 'Placeholder Fund II',
    fund_style: 'Venture Capital',
    fund_manager: 'Kerry Priceton',
    fund_size: 100000,
  },
  {
    fund_id: '7',
    fund_name: 'Placeholder Fund II',
    fund_style: 'Venture Capital',
    fund_manager: 'Kerry Priceton',
    fund_size: 100000,
  },
  {
    fund_id: '8',
    fund_name: 'Placeholder Fund II',
    fund_style: 'Venture Capital',
    fund_manager: 'Kerry Priceton',
    fund_size: 100000,
  },
  {
    fund_id: '9',
    fund_name: 'Placeholder Fund II',
    fund_style: 'Venture Capital',
    fund_manager: 'Kerry Priceton',
    fund_size: 100000,
  },
];

export const FUND_LIST_FIXED_COLUMNS: ExtTableNode_Column[] = [
  {
    key: 'fund_id',
    label: 'Fund ID',
    type: 'string',
  },
  {
    key: 'fund_name',
    label: 'Fund Name',
    type: 'string',
  },
  {
    key: 'fund_style',
    label: 'Style',
    type: 'string',
  },
  {
    key: 'fund_manager',
    label: 'Manager Name',
    type: 'string',
  },
  {
    key: 'fund_size',
    label: 'Size Weighted',
    type: 'string',
  },
];
