import { Grid, Typography } from '@mui/material';
import styled from 'styled-components';

export const GreyLabelText = styled(Typography)<any>`
  font-family: ${props => props?.fontFamily ?? 'Inter'} !important;
  font-size: ${props => props?.fontSize ?? '14px'} !important;
  font-style: ${props => props?.fontStyle ?? 'normal'} !important;
  font-weight: ${props => props.fontWeight ?? '500'} !important;
  line-height: ${props => props.lineHeight ?? 'normal'} !important;
  color: ${props => props.color ?? props.theme.grey['500']} !important;
  width: ${props => props.width ?? '100%'} !important;
`;

export const GreySubLabelText = styled(Typography)<any>`
  font-size: ${props => props?.fontSize ?? '10px'} !important;
  font-weight: ${props => props.fontWeight ?? '400'} !important;
  line-height: ${props => props.lineHeight ?? 'normal'} !important;
  color: ${props => props.color ?? props.theme.lightGrey} !important;
`;

export const Grey2SubLabelText = styled(Grid)<any>`
  font-size: ${props => props?.fontSize ?? '10px'} !important;
  font-weight: ${props => props.fontWeight ?? '400'} !important;
  line-height: ${props => props.lineHeight ?? 'normal'} !important;
  color: ${props => props.color ?? props.theme.grey3} !important;
`;

export const ParagraphBoldText = styled(Typography)<any>`
  font-family: ${props => props?.fontFamily ?? 'Inter'} !important;
  font-size: ${props => props?.fontSize ?? '14px'} !important;
  font-style: ${props => props?.fontStyle ?? 'normal'} !important;
  font-weight: ${props => props.fontWeight ?? '500'} !important;
  line-height: ${props => props.lineHeight ?? '1.5rem'} !important;
  color: ${props => props.color ?? props.theme.textBlack} !important;
`;

export const ParagraphLightText = styled(Typography)<any>`
  font-family: ${props => props?.fontFamily ?? 'Inter'} !important;
  font-size: ${props => props?.fontSize ?? '14px'} !important;
  font-style: ${props => props?.fontStyle ?? 'normal'} !important;
  font-weight: ${props => props.fontWeight ?? '400'} !important;
  line-height: ${props => props.lineHeight ?? 'normal'} !important;
  color: ${props => props.color ?? props.theme.grey2} !important;
`;

export const TextAvatar = styled(Grid)<any>`
  width: ${props => props?.width ?? '36px'} !important;
  height: ${props => props?.height ?? '36px'} !important;
  font-family: ${props => props?.fontFamily ?? 'Inter'} !important;
  font-size: ${props => props?.fontSize ?? '14px'} !important;
  font-style: ${props => props?.fontSize ?? 'normal'} !important;
  font-weight: ${props => props.fontWeight ?? '600'} !important;
  line-height: ${props => props.lineHeight ?? '1'} !important;
  color: ${props => props.color ?? props.theme.orange1} !important;
  border-radius: 50%;
  background: ${props => props.theme.yellow};
`;

export const Heading = styled(Typography)<any>`
  width: ${props => props?.width ?? '198px'} !important;
  height: ${props => props?.height ?? '24px'} !important;
  font-family: ${props => props?.fontFamily ?? 'Inter'} !important;
  font-size: ${props => props?.fontSize ?? '20px'} !important;
  font-weight: ${props => props.fontWeight ?? '500'} !important;
  line-height: ${props => props.lineHeight ?? '24px'} !important;
`;

export const PageHeading = styled(Typography)<any>`
  font-size: ${props => props?.fontSize ?? '28px'} !important;
  font-weight: ${props => props?.fontWeight ?? '500'} !important;
  line-height: ${props => props?.lineHeight ?? '34px'} !important;
`;

export const SubHeading = styled(Typography)<any>`
  font-family: ${props => props?.fontFamily ?? 'Inter'} !important;
  font-size: ${props => props?.fontSize ?? '16px'} !important;
  font-weight: ${props => props?.fontWeight ?? '500'} !important;
  line-height: ${props => props.lineHeight ?? '19px'} !important;
`;

export const DateHeader = styled(Typography)<any>`
  font-size: ${props => props?.fontSize ?? '14px'} !important;
  font-weight: ${props => props?.fontWeight ?? '400'} !important;
  line-height: ${props => props?.lineHeight ?? '19px'} !important;
  color: ${props => props.color ?? props.theme.grey4} !important;
`;

export const LightSubHeading = styled(Typography)<any>`
  font-size: ${props => props?.fontSize ?? '14px'} !important;
  font-weight: ${props => props?.fontWeight ?? '500'} !important;
  line-height: ${props => props?.lineHeight ?? '17px'} !important;
  color: ${props => props.color ?? props.theme.grey6} !important;
  margin: ${props => props.margin} !important;
  &:hover {
    color: ${props => props?.hoverColor ?? ''} !important;
  }
`;

export const SmallText = styled(Typography)<any>`
  font-size: ${props => props?.fontSize ?? '12px'} !important;
  font-weight: ${props => props?.fontWeight ?? '500'} !important;
  line-height: ${props => props?.lineHeight ?? '16px'} !important;
  margin: ${props => props.margin} !important;
  color: ${props => props.color ?? '#000000'} !important;
`;
