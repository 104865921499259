import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/types';
import { initialState } from './slice';

const selectSlice = (state: RootState) => state?.queries || initialState;

export const getQueriesLoader = createSelector(
  [selectSlice],
  state => state.loading,
);

export const getQueriesFromStore = createSelector(
  [selectSlice],
  state => state.queries,
);
