import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/types';
import { initialState } from './slice';

const selectSlice = (state: RootState) =>
  state?.vectorMigrationData || initialState;

export const getVectorMigrationStreamData = createSelector(
  [selectSlice],
  state => state,
);
