import TextField from '@mui/material/TextField';
import React, { ChangeEvent, FC } from 'react';

interface CustomizeTextFieldProps {
  variant?: any;
  type?: string;
  name: string;
  value: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleKeyPress?: any;
  width?: string;
  height?: string;
  padding?: string;
  disabled?: boolean;
  sx?: Record<string, any>;
  suffixIcon?: React.ReactNode;
  prefixIcon?: React.ReactNode;
}

const CustomizeTextField: FC<CustomizeTextFieldProps> = ({
  variant = 'outlined',
  type = 'text',
  name,
  value,
  onChange,
  handleKeyPress,
  placeholder = '',
  onBlur = () => {},
  width = '400px',
  height = '40px',
  padding = '0px 14px',
  disabled = false,
  sx = {},
  prefixIcon = null,
  suffixIcon = null,
}) => {
  return (
    <TextField
      type={type}
      autoComplete="reset-password"
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        onChange ? onChange(event) : null
      }
      onBlur={(event: React.FocusEvent<HTMLInputElement>) => onBlur(event)}
      variant={variant}
      title={''}
      label={''}
      disabled={disabled}
      sx={{
        '& .MuiInputBase-input': {
          height: height,
          padding: padding,
        },
        '&.MuiFormControl-root.MuiTextField-root': {
          width: width,
        },
        ...sx,
      }}
      InputProps={{
        startAdornment: prefixIcon && <>{prefixIcon}</>,
        endAdornment: suffixIcon && <>{suffixIcon}</>,
      }}
      onKeyPress={handleKeyPress}
    />
  );
};

export default React.memo(CustomizeTextField);
