import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { Grid, Typography } from '@mui/material';
import { ParagraphBoldText } from '@components/Typography';
import CustomTooltip from '@components/Tooltip';
import {
  getBreadcrumMode,
  getContextualFundDetails,
  isProductTourActive,
} from '@features/Global/selector';
import { getConversationsState } from '@features/Chat/selector';
import { actions as globalActions } from '@features/Global/slice';
import { BreadcrumbMode } from '@features/Global/types';
import Suggestions from './Suggestions';
import FundDetailsWrapper from './FundDetails';
import { ContentDrawerContainer } from './styles';
import useInsights from '@helper/CustomHooks/useInsights';

const Breadcrumb = styled(Grid)`
  padding: 2rem 2rem 0.5rem 2rem;
`;

const Option = styled(Typography)<{ active: boolean; disabled: boolean }>`
  width: auto;
  height: 32px;
  padding: 0 10px;
  font-family: Inter;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 32px !important;
  color: ${props => (props.active ? '#fff' : props.theme.main)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 1 !important;
  border: none !important;
  border-radius: 4px;
  box-shadow: none !important;
  background-color: ${props => props.theme['fadedBlack']} !important;

  ${({ active }: any) =>
    !active &&
    css`
      color: ${props => props.theme.main} !important;
      opacity: 1 !important;
      border: none !important;
      box-shadow: none !important;
      background-color: rgba(16, 24, 40, 0.04) !important;
    `}
`;

function ContentDrawer({ sx }: { sx?: any }): React.JSX.Element {
  const dispatch = useDispatch();
  const mode = useSelector(getBreadcrumMode);
  const { conversation_history: chatHistoryState } = useSelector(
    getConversationsState,
  );
  const isProductTour = useSelector(isProductTourActive);
  const {data: suggestions, isLoading: loading} = useInsights(isProductTour);
  const fundDetails = useSelector(getContextualFundDetails);
  const [preloader, setPreLoader] = useState(true);
  const [options, updateOptions] = useState<any[]>([
    {
      title: 'Explore Topics',
      source: 'Dashboard',
    },
  ]);

  useEffect(() => {
    setTimeout(() => {
      setPreLoader(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (fundDetails.name) {
      updateOptions([
        {
          title: 'Explore Topics',
          source: 'Dashboard',
        },
        {
          title: fundDetails.name,
          source: 'Contextual',
        },
      ]);
    } else {
      updateOptions([
        {
          title: 'Explore Topics',
          source: 'Dashboard',
        },
      ]);
    }
  }, [mode, fundDetails]);

  const navToInsight = (selectedMode: BreadcrumbMode) => {
    if (selectedMode === 'Contextual') {
      let _fundDetails: any = fundDetails;
      if (chatHistoryState.length) {
        const recentChat = chatHistoryState[chatHistoryState.length - 1];
        _fundDetails = recentChat?.contextual_data;
      }
      dispatch(globalActions.updateFundDetails(_fundDetails));
    }
    dispatch(globalActions.breadcrumbMode(selectedMode));
  };

  return (
    <ContentDrawerContainer sx={sx}>
      <Breadcrumb
        id={'drawer-label-container'}
        container
        alignItems={'center'}
        flexWrap={'nowrap'}
      >
        {options.length === 1 ? (
          <ParagraphBoldText fontSize={'20px'}>
            Explore Topics
          </ParagraphBoldText>
        ) : (
          options.map((l: any, li: number) => (
            <>
              {li === options.length - 1 ? (
                <CustomTooltip placement={'left'} title={l.title}>
                  <Option
                    key={li}
                    minWidth={'134px'}
                    textAlign={'center'}
                    disabled={li !== options.length - 1}
                    active={l.source === mode}
                    sx={{ height: '32px', cursor: 'pointer' }}
                    onClick={() => navToInsight(l.source)}
                  >
                    {l.title}
                  </Option>
                </CustomTooltip>
              ) : (
                <Option
                  key={li}
                  minWidth={'134px'}
                  textAlign={'center'}
                  disabled={li !== options.length - 1}
                  active={l.source === mode}
                  sx={{ height: '32px', cursor: 'pointer' }}
                  onClick={() => navToInsight(l.source)}
                >
                  {l.title}
                </Option>
              )}
            </>
          ))
        )}
      </Breadcrumb>
      <Fragment>
        {mode === 'Dashboard' ? (
          <Suggestions loading={preloader || loading} data={suggestions||null} />
        ) : (
          <FundDetailsWrapper
            ticker={fundDetails.ticker}
            name={fundDetails.name}
            data={fundDetails.data}
          />
        )}
      </Fragment>
    </ContentDrawerContainer>
  );
}

export default ContentDrawer;
