import React from 'react';
import useConversations from '@helper/ReactQuery/useConversations';
import { NavButton } from '@components/Button';

export function ConversationNav(): React.JSX.Element {
  const { data: conversations } = useConversations();

  return (
    <>
      {conversations && (
        <div
          style={{
            display: 'none',
            flexDirection: 'column',
            color: 'white',
            padding: '10px',
            gap: '5px',
          }}
        >
          {conversations.map((conversation, index) => (
            <NavButton key={index} cursor="pointer">
              {conversation.title}
            </NavButton>
          ))}
        </div>
      )}
    </>
  );
}
