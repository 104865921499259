import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FundsList, IFundWithDocuments } from './types';

export const initialState: FundsList = {
  result: [],
  totalCount: 0,
  loading: false,
  selectedFund: {
    fundName: '',
    fundId: '',
  },
};

const fundsSlice = createSlice({
  name: 'funds',
  initialState,
  reducers: {
    searchFundsByValueFetch(state) {
      state.loading = true;
    },
    searchFundsByValueSuccess(
      state: any,
      action: PayloadAction<{
        loading: boolean;
        result: IFundWithDocuments[];
        totalCount: number;
      }>,
    ) {
      return {
        ...state,
        loading: false,
        result: action?.payload?.result,
        totalCount: action?.payload?.totalCount,
      };
    },
    selectFund(
      state,
      action: PayloadAction<{ fundName: string; fundId: string }>,
    ) {
      state.selectedFund = {
        fundName: action.payload.fundName,
        fundId: action.payload.fundId,
      };
    },
    clearSelectedFund(state) {
      state.selectedFund = {
        fundName: '',
        fundId: '',
      };
    },
    resetState() {
      return initialState;
    },
  },
});

export const { actions, reducer, name: sliceKey } = fundsSlice;
export const { selectFund, clearSelectedFund, resetState } = actions;
