import { OktaAuth } from '@okta/okta-auth-js';
import HlLogo from '@logos/HelixByHl.svg';

const SCOPES: string[] = ['openid', 'profile', 'email', 'offline_access'];
const CLIENT_ID: string = process.env.REACT_APP_OKTA_CLIENT_ID || '';
const REDIRECT_URI: string = `${window.location.origin}/login/callback` || '';
const ISSUER: string = process.env.REACT_APP_OKTA_ISSUER || '';
const POST_LOGOUT_REDIRECT: string = `${window.location.origin}/`;
const LABELS = {
  en: {
    // Labels
    'primaryauth.title': 'Sign In',
    'primaryauth.username.placeholder': 'Email',
    'primaryauth.username.tooltip': 'Email',
    'error.username.required': 'Please enter an email',
    remember: 'Keep me signed in',
  },
};

interface TokenManagerConfig {
  autoRenew: boolean;
  expireEarlySeconds: number;
  syncStorage: boolean;
}

interface OidcConfig {
  issuer: string;
  clientId: string;
  scopes: string[];
  redirectUri: string;
  pkce: boolean;
  disableHttpsCheck: boolean;
  tokenManager?: TokenManagerConfig;
}

interface WidgetConfig {
  issuer: string;
  i18n: Record<string, any>;
  clientId: string;
  scopes: string[];
  redirectUri: string;
  postLogoutRedirectUri: string;
  logo: string;
  useInteractionCodeFlow: boolean;
  colors: {
    brand: string;
  };
}

export const config: { oidc: OidcConfig; widget: WidgetConfig } = {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: SCOPES,
    pkce: true,
    disableHttpsCheck: false,
    tokenManager: {
      autoRenew: false,
      expireEarlySeconds: 300,
      syncStorage: true,
    },
  },
  widget: {
    issuer: ISSUER,
    i18n: LABELS,
    clientId: CLIENT_ID,
    scopes: SCOPES,
    redirectUri: REDIRECT_URI,
    postLogoutRedirectUri: POST_LOGOUT_REDIRECT,
    logo: HlLogo,
    useInteractionCodeFlow: false, //process.env.REACT_APP_ENV === 'PROD', // temporary check until the Okta dev instance is upgraded to Identity Engine
    colors: {
      brand: '#398381',
    },
  },
};

export const oktaAuth = new OktaAuth(config.oidc);
