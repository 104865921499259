import React from 'react';
import styled from 'styled-components';
import { Card as MuiCard, CardActionArea } from '@mui/material';

type Props = {
  id?: string;
  width?: any;
  padding?: any;
  marginBottom?: string;
  alignItems?: string;
  flexDirection?: string;
  justifyContent?: string;
  cardAction?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  customHeight?: string;
  sx?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  children: React.ReactNode;
  customColor?: string;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
};

const CustomCard = styled(MuiCard)<Props>(
  ({
    theme,
    width,
    padding,
    marginBottom,
    customHeight,
    disabled,
    customColor,
    alignItems,
    flexDirection,
    justifyContent,
  }) => `
  display: flex;
  align-items: ${alignItems || 'center'};
  justify-content: ${justifyContent || 'center'};
  flex-direction: ${flexDirection || 'column'};
  width: ${width ?? 'auto'};
  height: ${customHeight ?? 'auto'};
  margin-bottom: ${marginBottom ?? '16px'};
  padding: ${padding ?? '16px'};
  border-radius: 8px;
  opacity: ${disabled ? '0.5' : '1'};
  background: ${customColor || theme.solidWhite} !important;
  background-color: ${customColor || theme.solidWhite} !important;
  border: 1px solid ${customColor || theme?.borderColor} !important;
  box-shadow: 0px 1px 2px 0px #1018281f;

  &:hover {

  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  .MuiLoadingButton-loadingIndicator {
    color: ${theme.solidWhite};
    position: unset;
  }
`,
);

function Card({
  sx,
  alignItems,
  flexDirection = 'row',
  justifyContent,
  cardAction = false,
  width = 'auto',
  fullWidth = false,
  customColor = '',
  customHeight,
  children,
  onClick,
  disabled = false,
  ...rest
}: Props): React.JSX.Element {
  return (
    <CustomCard
      sx={sx}
      width={width}
      alignItems={alignItems}
      flexDirection={flexDirection}
      justifyContent={justifyContent}
      fullWidth={fullWidth}
      customHeight={customHeight}
      customColor={customColor}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {cardAction ? <CardActionArea>{children}</CardActionArea> : children}
    </CustomCard>
  );
}

export default Card;
