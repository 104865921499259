import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { getAllFundsWithDocuments } from './api';
import { GetAllFundsParams, IFundWithDocuments } from './types';
// React Query hook, replacing Redux

type IUseFundsListResult = {
  funds: IFundWithDocuments[];
  totalCount: number;
};

export default function useFundsList(
  params: Omit<GetAllFundsParams, 'page' | 'limit'>,
): UseInfiniteQueryResult<IUseFundsListResult, unknown> {
  return useInfiniteQuery({
    queryKey: ['funds', params.q, params.mode],
    queryFn: qparams => fetchFunds(qparams, params),
    getNextPageParam: (lastPage, pages) =>
      lastPage.totalCount > pages.length * 10 ? pages.length + 1 : undefined, // TODO: check this
  });
}

async function fetchFunds(
  { pageParam = 1 },
  params: Omit<GetAllFundsParams, 'page' | 'limit'>,
): Promise<IUseFundsListResult> {
  const response = await getAllFundsWithDocuments({
    ...params,
    page: pageParam,
    limit: 10,
  });
  if (!response || (response.result && !response.result.length)) {
    throw new Error('Failed to fetch funds list');
  }
  const sortedData = response.result.sort(
    (a: any, b: any) =>
      new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );
  return {
    funds: sortedData,
    totalCount: response.totalCount,
  };
}
