import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import OutlineButton from './OutlineButton';
import NavButton from './NavButton';
import TextButton from './TextButton';
import HomeButton from './HomeButton';
import CustomOutlineButton from './CustomOutlineButton';

import { HeaderMenu } from './styles';

export {
  TextButton,
  PrimaryButton,
  SecondaryButton,
  OutlineButton,
  HeaderMenu,
  NavButton,
  PrimaryButton as Button,
  CustomOutlineButton,
  HomeButton,
};
