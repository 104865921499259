/* eslint-disable react/prop-types */

import React from 'react';

function AddRoundIcon(): React.JSX.Element {
  return (
    <svg
      width="57"
      height="57"
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4.5" y="4.5" width="48" height="48" rx="24" fill="#98A2B3" />
      <rect
        x="4.5"
        y="4.5"
        width="48"
        height="48"
        rx="24"
        stroke="#E4E7EC"
        strokeWidth="8"
      />
      <path
        d="M20.25 28.5H36.75"
        stroke="#FCFDFD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.5 20.25V36.75"
        stroke="#FCFDFD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoConnectIcon = React.memo(AddRoundIcon);
export default MemoConnectIcon;
