import React, { useEffect, useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import ErrorBoundary from '@helper/ErrorBoundary';
import HelixLogo from '@logos/HelixByHLDark.svg';

import SidebarIndicator from '@components/SidebarIndicator';
import ContentDrawer from '@components/ContentDrawer';
import { ProfileDataProps, getProfileDataForLocal } from '@common/auth';

import {
  getPageConfig,
  getLayoutMenus,
  PageConfigType,
  LayoutMenusType,
} from 'app/routes';

import SideNav from './SideNav';
import { LayoutProps } from './types';

import {
  LayoutContainer,
  LayoutWrapper,
  LeftSideContainer,
  RightSideContainer,
  RightSideContentBody,
  RightSideContentWrapper,
  HeaderContainer,
  HelixLogoWrapper,
  ContentWrapper,
  RightSideDrawerContainer,
} from './styles';
import { ParagraphBoldText } from '@components/Typography';
import { useDispatch } from 'react-redux';
import { actions as globalActions } from '@features/Global/slice';

const LayoutRoute: React.FC<LayoutProps> = ({ animate = false }) => {
  const location = useLocation();
  const { pathname } = location;
  const customMenuList: Array<LayoutMenusType> | null = getLayoutMenus('/');
  const config: PageConfigType | null = getPageConfig('/', pathname, true);
  const [isLeftSideBarCollapsed, updateLeftSideOpenState] = useState(true);
  const [isRideSideDrawerCollapsed, updateRightSideOpenState] = useState(false);

  const profileData: ProfileDataProps = getProfileDataForLocal();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      config &&
      config.path === '/' &&
      config.visible.rightSideDrawer &&
      profileData.userType === 'SUPER_ADMIN'
    ) {
      config.visible.rightSideDrawer = false;
    }
  }, [config, profileData]);

  const onLeftArrowClick = () => {
    updateLeftSideOpenState(!isLeftSideBarCollapsed);
    dispatch(globalActions.triggerResize());
  };

  const onRightArrowClick = () => {
    dispatch(globalActions.triggerResize());
    updateRightSideOpenState(!isRideSideDrawerCollapsed);
    if (isRideSideDrawerCollapsed) {
      updateLeftSideOpenState(true);
    }
  };

  return (
    <LayoutContainer container>
      <LeftSideContainer item collapse={isLeftSideBarCollapsed}>
        <SideNav menus={customMenuList} collapse={isLeftSideBarCollapsed} />
        <SidebarIndicator
          isOpen={!isLeftSideBarCollapsed}
          placement={'left'}
          size={'large'}
          tooltip={{
            enable: true,
            placement: 'right',
            text: `${isLeftSideBarCollapsed ? 'Open' : 'Close'} Sidebar`,
          }}
          onClick={onLeftArrowClick}
        />
      </LeftSideContainer>
      <RightSideContainer item container collapse={isLeftSideBarCollapsed}>
        <RightSideContentWrapper container>
          <RightSideContentBody
            container
            visible={config?.visible?.rightSideDrawer}
            collapse={isRideSideDrawerCollapsed}
          >
            {config?.visible?.logoInPage ? (
              <HeaderContainer
                container
                animate={animate}
                alignItems={'flex-end'}
              >
                <HelixLogoWrapper width="auto" height="32px" src={HelixLogo} />
              </HeaderContainer>
            ) : (
              config?.visible?.titleInPage && (
                <HeaderContainer
                  container
                  animate={animate}
                  alignItems={'flex-end'}
                >
                  <ParagraphBoldText fontSize={'24px'}>
                    {config?.name}
                  </ParagraphBoldText>
                </HeaderContainer>
              )
            )}
            <ErrorBoundary key={pathname}>
              <ContentWrapper animate={animate}>
                <LayoutWrapper animate={animate}>
                  <Outlet />
                </LayoutWrapper>
              </ContentWrapper>
            </ErrorBoundary>
          </RightSideContentBody>
          {config?.visible?.rightSideDrawer && (
            <RightSideDrawerContainer
              container
              collapse={isRideSideDrawerCollapsed}
            >
              {!isRideSideDrawerCollapsed && <ContentDrawer />}
              <SidebarIndicator
                isOpen={!isRideSideDrawerCollapsed}
                placement={'right'}
                size={'large'}
                tooltip={{
                  enable: true,
                  placement: 'left',
                  text: `${isRideSideDrawerCollapsed ? 'Open' : 'Close'} Drawer`,
                }}
                onClick={onRightArrowClick}
              />
            </RightSideDrawerContainer>
          )}
        </RightSideContentWrapper>
      </RightSideContainer>
    </LayoutContainer>
  );
};

export default LayoutRoute;
