import { AllEffect, call, all, put, takeLatest } from 'redux-saga/effects';
import { oktaAuth } from '@services/oktaAuth';
import { actions } from './slice';
import { actions as globalActions } from '../Global/slice';
import { activateAccount, resetPasswordApi } from './api';

function* errorNotifications(error: any) {
  if (error && error.message) {
    yield put(
      globalActions.displayToast({
        duration: 3000,
        toastType: 'error',
        toastMessage: error?.message,
      }),
    );
  } else {
    yield put(
      globalActions.displayToast({
        duration: 3000,
        toastType: 'error',
        toastMessage: 'An error occurred while processing.',
      }),
    );
  }
}

// TODO: REMOVE THIS FUNCTION, call useCurrentUser().logout()
function* logoutOktaUser(): Generator<any, void, any> {
  try {
    yield put(actions.logoutOktaUserFetch());
    yield oktaAuth.signOut();
    yield put(actions.logoutOktaUserSuccess());
  } catch (error: any) {
    console.error(error);
    errorNotifications(error);
    yield put(actions.logoutOktaUserSuccess());
  } finally {
    localStorage.clear();
    sessionStorage.clear();
  }
}

function* acceptTermsAndConditions(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    localStorage.setItem('terms-and-conditions', 'accepted');
    yield put(actions.acceptedTermsAndConditionsFetch(true));
    yield put(
      globalActions.updateUserConfigRequest({
        tAndC: payload.accptedTNC || true,
        isProductTourViewed: true,
      }),
    );

    yield put(actions.acceptedTermsAndConditionsSuccess(false));
    yield put(globalActions.startProductTourRequest());
    if (payload.callback && typeof payload.callback === 'function') {
      payload.callback();
    }
  } catch (error) {
    console.error('Async action error:', error);
    yield put(actions.acceptedTermsAndConditionsSuccess(false));
  }
}

function* accountActivation(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    const { newPassword, retypePassword, hashKey } = payload;
    yield put(actions.accountActivationFetch(true));
    if (!newPassword && !retypePassword && !hashKey) {
      yield put(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage:
            'Both fields are required to proceed. Please fill in all the details.',
        }),
      );
      throw new Error(
        'Both fields are required to proceed. Please fill in all the details.',
      );
    }
    const response: any = yield call(activateAccount, {
      payload: {
        password: newPassword,
        retypePassword,
      },
      headers: {
        'activation-hashkey': hashKey,
      },
    });
    yield put(actions.accountActivationSuccess(false));
    if (payload.callback && typeof payload.callback === 'function') {
      payload.callback(response);
    }
  } catch (error: any) {
    console.error('Async action error:', error);
    yield errorNotifications(error);
    yield put(actions.accountActivationSuccess(false));
  }
}

function* resetPassword(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    const { oldPassword, newPassword } = payload;
    yield put(actions.resetPasswordFetch(true));
    if (!newPassword && !newPassword) {
      yield put(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage:
            'Both fields are required to proceed. Please fill in all the details.',
        }),
      );
      throw new Error(
        'Both fields are required to proceed. Please fill in all the details.',
      );
    }
    const response: any = yield call(resetPasswordApi, {
      payload: {
        oldPassword,
        newPassword,
      },
    });
    yield put(
      globalActions.displayToast({
        duration: 3000,
        toastType: 'success',
        toastMessage: response.message,
      }),
    );
    yield put(actions.resetPasswordSuccess(false));
    if (payload.callback && typeof payload.callback === 'function') {
      payload.callback(response);
    }
    yield logoutOktaUser();
  } catch (error: any) {
    console.error('Async action error:', error);
    yield errorNotifications(error);
    yield put(actions.resetPasswordSuccess(false));
  }
}

export function* profileSaga(): Generator<AllEffect<any>, void, unknown> {
  yield all([
    takeLatest(actions.accountActivationRequest.type, accountActivation),
    takeLatest(actions.resetPasswordRequest.type, resetPassword),
    takeLatest(
      actions.acceptedTermsAndConditionsRequest.type,
      acceptTermsAndConditions,
    ),
  ]);
}
