/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */

// store/reducers.ts
import { combineReducers } from '@reduxjs/toolkit';
import { reducer as chatReducer } from 'app/features/Chat/slice';
import { reducer as globalReducer } from 'app/features/Global/slice';
import { reducer as profileReducer } from 'app/features/Profile/slice';
import { reducer as queriesReducer } from 'app/features/Queries/slice';
import { reducer as feedbackHistoryReducer } from 'app/features/FeedbackHistory/slice';
import { reducer as fundsReducer } from 'app/features/Funds/slice';
import { reducer as usersReducer } from 'app/features/Users/slice';
import { reducer as dataProvidersReducer } from 'app/features/DataProviders/slice';
import { reducer as enterprisesReducer } from 'app/features/Enterprises/slice';
import { reducer as documentsReducer } from 'app/features/Documents/slice';
import { reducer as vectorMigrationReducer } from 'app/features/VectorMigration/slice';
import { all } from 'redux-saga/effects';
import { chatSaga } from 'app/features/Chat/saga';
import { globalSaga } from 'app/features/Global/saga';
import { profileSaga } from 'app/features/Profile/saga';
import { queriesSaga } from 'app/features/Queries/saga';
import { feedbackHistorySaga } from '@features/FeedbackHistory/saga';
import { usersSaga } from '@features/Users/saga';
import { dataProvidersSaga } from '@features/DataProviders/saga';
import { enterprisesSaga } from '@features/Enterprises/saga';
import { documentsSaga } from '@features/Documents/saga';
import { vectorMigrationSaga } from '@features/VectorMigration/saga';

export const rootReducer = combineReducers({
  chat: chatReducer,
  global: globalReducer,
  profile: profileReducer,
  queries: queriesReducer,
  feedbackHistory: feedbackHistoryReducer,
  funds: fundsReducer,
  users: usersReducer,
  dataProviders: dataProvidersReducer,
  enterprises: enterprisesReducer,
  documents: documentsReducer,
  vectorMigrationData: vectorMigrationReducer,
  // ... add other reducers as needed
});

export function* rootSaga() {
  yield all([
    chatSaga(),
    globalSaga(),
    profileSaga(),
    queriesSaga(),
    feedbackHistorySaga(),
    usersSaga(),
    dataProvidersSaga(),
    enterprisesSaga(),
    documentsSaga(),
    vectorMigrationSaga(),
    // ... add other sagas
  ]);
}

export type RootState = ReturnType<typeof rootReducer>;
