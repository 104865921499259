import React from 'react';
import { InputField, InputBoxContainer, SuffixIcon } from './styles';

interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'style'> {
  ref?: React.Ref<HTMLInputElement>;
  color?: string;
  padding?: string;
  variant?: 'search' | 'input';
  style?: React.CSSProperties;
  sx?: React.CSSProperties;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  borderWidth?: string;
  borderColor?: string;
  containerHeight?: string;
  suffixIcon?: React.ReactNode;
  onIconClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
}

function Input(props: Props): React.ReactElement {
  const {
    sx,
    ref,
    style,
    variant,
    suffixIcon,
    onIconClick,
    containerHeight,
    color,
    fontSize,
    fontWeight,
    lineHeight,
    borderWidth,
    borderColor,
    padding,
    ...rest
  } = props;
  return (
    <InputBoxContainer container height={containerHeight}>
      <InputField
        {...rest}
        ref={ref}
        style={style || sx}
        color={color}
        variant={variant}
        padding={padding}
        fontSize={fontSize}
        fontWeight={fontWeight}
        lineHeight={lineHeight}
        borderWidth={borderWidth}
        borderColor={borderColor}
      />
      {suffixIcon && (
        <SuffixIcon onClick={onIconClick}>{suffixIcon}</SuffixIcon>
      )}
    </InputBoxContainer>
  );
}

export default Input;
