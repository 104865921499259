import React, { useEffect, useRef } from 'react';
import { PrimaryButton } from '@components/Button';
import { ReactComponent as PromptVector } from '@images/promptVector.svg';
import { PrimaryButtonProps } from '@components/Button/PrimaryButton';
import { SxProps } from '@mui/material';

type RotatingPrimaryButtonProps = Omit<
  PrimaryButtonProps,
  'onMouseEnter' | 'onMouseLeave'
> & {
  bgNoHover?: string;
  bgHover?: string;
};

const RotatingPrimaryButton = ({
  isHover = true,
  variant = 'outlined',
  customColor = '#FFFFFF',
  customBorderColor = '#2C3748',
  bgNoHover = '#FFFFFF !important',
  bgHover = '#2C3748',
  textColor = '#2C3748',
  ...restProps
}: RotatingPrimaryButtonProps): JSX.Element => {
  const [rotations, setRotations] = React.useState<number>(0);
  const [hoverStates, setHoverStates] = React.useState<boolean>(false);
  const intervalRefs = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      if (intervalRefs.current) {
        clearInterval(intervalRefs.current);
      }
    };
  }, []);

  const handleMouseEnter = () => {
    if (intervalRefs.current) {
      clearInterval(intervalRefs.current);
    }
    setHoverStates(true);

    intervalRefs.current = window.setInterval(() => {
      setRotations(() => (rotations || 0) + 360);
    }, 1000);
  };

  const handleMouseLeave = () => {
    setHoverStates(false);
    setRotations(0);

    if (intervalRefs.current) {
      clearInterval(intervalRefs.current);
      intervalRefs.current = null;
    }
  };

  return (
    <PrimaryButton
      isHover={isHover}
      variant={variant}
      customColor={customColor}
      customBorderColor={customBorderColor}
      textColor={textColor}
      sx={
        {
          minHeight: '40px',
          height: 'auto',
          color: hoverStates ? bgNoHover : bgHover,
          textAlign: 'left !important',
          lineHeight: `1.2 !important`,
          ...restProps.sx,
        } as SxProps
      }
      startIcon={
        <PromptVector
          style={{
            transition: 'transform 3s',
            transform: `rotate(${rotations || 0}deg)`,
            fill: hoverStates ? 'white' : 'inherit',
          }}
        />
      }
      {...restProps}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
};

export default RotatingPrimaryButton;
