import { Box, Grid } from '@mui/material';
import styled from 'styled-components';

export const ContentWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 80vh;
  margin-top: 16px;
  padding: 15px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #999999;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const LabelText = styled('div').withConfig({
  shouldForwardProp: prop => prop !== 'marginBottom',
})<{
  marginBottom?: string;
}>`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: ${props => props?.marginBottom ?? ''};
`;

export const StatsWrapper = styled(Box)`
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 1px 2px 0px #1018281f;
  width: 100%;
  background: #ffffff;
`;

export const ProcessingBullets = styled.ul`
  li {
    font-size: 18px;
    font-weight: 400;
    line-height: 17px;
    margin-bottom: 16px;
    color: #475467;
    width: 70%;
  }
`;
