/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ApiInstance from '@instances/index';

export const getAllDataProviders = async (): Promise<any> => {
  return await ApiInstance.get(`/enterprise/getAllDataProviders`).then(
    res => res.data,
  );
};

export const addDataProviders = async (payload: any): Promise<any> => {
  return await ApiInstance.post(`/enterprise/createDataProvider`, payload)
    .then(res => res.data)
    .catch(({ response }: any) => response);
};
