import { Box, ButtonGroup, Divider } from '@mui/material';
import React, { useState } from 'react';
import {
  AccountBox,
  AssetAllocationContainer,
  CashFlowWrapper,
  Column,
  DebtWrapper,
  DrawerContainer,
  DrawerHeaderBox,
  EquityFlowWrapper,
  ExpectedFundDataContainer,
  ExpectedFundDataWrapper,
  HoldingWrapper,
  LegendColorDiv,
  LegendWrapper,
  PortfolioContainer,
  PortfolioWrapper,
  ProgressBarLegendContainer,
  ProgressBarWrapper,
  ReturnsHeader,
  StyledColumn1,
  StyledColumn2,
} from './styles';
import {
  Heading,
  LightSubHeading,
  ParagraphLightText,
  SmallText,
  SubHeading,
} from '@components/Typography';
import CustomChip from '@components/Chip/CustomChip';
import { PrimaryButton, SecondaryButton } from '@components/Button';
import ProfileIcon from '@icons/ProfileIcon';
import CloseIcon from '@mui/icons-material/Close';
import { COLORS } from '@styles/colors';
const DrawerHoldingsData = [
  {
    fund_name: 'Cash',
    fund_percent: '56.64%',
    fund_description: 'Cash',
    fund_amount: '$708,000',
  },
  {
    fund_name: 'ACG',
    fund_percent: '22.99%',
    fund_description: 'iShares Core US Agg Bond ETF',
    fund_amount: '$708,000',
  },
  {
    fund_name: 'EEM',
    fund_percent: '5.84%',
    fund_description: 'iShares MSCI Emerging Mkts',
    fund_amount: '$708,000',
  },
  {
    fund_name: 'EEM',
    fund_percent: '5.84%',
    fund_description: 'iShares MSCI Emerging Mkts',
    fund_amount: '$708,000',
  },
  {
    fund_name: 'ACG',
    fund_percent: '22.99%',
    fund_description: 'iShares Core US Agg Bond ETF',
    fund_amount: '$708,000',
  },
  {
    fund_name: 'Cash',
    fund_percent: '56.64%',
    fund_description: 'Cash',
    fund_amount: '$708,000',
  },
  {
    fund_name: 'ACG',
    fund_percent: '22.99%',
    fund_description: 'iShares Core US Agg Bond ETF',
    fund_amount: '$708,000',
  },
  {
    fund_name: 'Cash',
    fund_percent: '56.64%',
    fund_description: 'Cash',
    fund_amount: '$708,000',
  },
];
interface DrawerDataProps {
  handleDrawerClose: () => void;
  selectedClient: any;
}
const buttons = [
  { title: 'Trading Account' },
  { title: 'Pvt Market Account' },
  { title: 'Portfolio History' },
];

function DrawerData({
  handleDrawerClose,
  selectedClient,
}: DrawerDataProps): React.JSX.Element {
  const [activeButton, setActiveButton] = useState('Trading Account');

  const handleButtonClick = buttonName => {
    setActiveButton(buttonName);
  };

  return (
    <DrawerContainer>
      <DrawerHeaderBox>
        <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
          <Box>
            <ProfileIcon />
          </Box>
          <Box>
            <Heading>{selectedClient[0]}</Heading>
            <ParagraphLightText>
              {selectedClient[1]?.props?.children}
            </ParagraphLightText>
          </Box>
        </Box>

        <CloseIcon onClick={handleDrawerClose} sx={{ cursor: 'pointer' }} />
      </DrawerHeaderBox>

      <AccountBox>
        <ButtonGroup variant="text" aria-label="Basic button group">
          {buttons.map(button => (
            <React.Fragment key={button.title}>
              {activeButton === button.title ? (
                <PrimaryButton
                  onClick={() => handleButtonClick(button.title)}
                  style={{ textTransform: 'none' }}
                >
                  {button.title}
                </PrimaryButton>
              ) : (
                <SecondaryButton
                  onClick={() => handleButtonClick(button.title)}
                >
                  {button.title}
                </SecondaryButton>
              )}
            </React.Fragment>
          ))}
        </ButtonGroup>
      </AccountBox>
      <ExpectedFundDataContainer>
        <ExpectedFundDataWrapper
          style={{ flexDirection: 'column', marginRight: '15px' }}
        >
          <LightSubHeading style={{ marginBottom: '13px' }}>
            Expected Risk
          </LightSubHeading>
          <SubHeading>2.61%</SubHeading>
        </ExpectedFundDataWrapper>
        <ExpectedFundDataWrapper style={{ flexDirection: 'column' }}>
          <LightSubHeading style={{ marginBottom: '13px' }}>
            Expected Returns
          </LightSubHeading>
          <SubHeading>4.71%</SubHeading>
        </ExpectedFundDataWrapper>
        <ExpectedFundDataWrapper style={{ flexDirection: 'column' }}>
          <LightSubHeading style={{ marginBottom: '13px' }}>
            Risk Appetite
          </LightSubHeading>
          <CustomChip type={'info'} text={'Low'}></CustomChip>
        </ExpectedFundDataWrapper>
      </ExpectedFundDataContainer>

      <Divider
        style={{ marginBottom: '24px', marginTop: '24px', width: '100%' }}
      />
      <Box>
        <ParagraphLightText>
          <strong>Investment Objective:</strong> Lorem ipsum dolor sit amet,
          consectetur adipiscing elit, sed do eiusmod tempor ut labore. Lorem
          ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor ut labore
        </ParagraphLightText>
      </Box>
      <Divider
        style={{ marginBottom: '24px', marginTop: '24px', width: '100%' }}
      />
      <AssetAllocationContainer>
        <SubHeading>Asset Allocation</SubHeading>
        <ProgressBarWrapper>
          <CashFlowWrapper />
          <EquityFlowWrapper />
          <DebtWrapper />
        </ProgressBarWrapper>
        <ProgressBarLegendContainer>
          <LegendWrapper>
            <LegendColorDiv style={{ background: COLORS.seaGreen }} />
            <SmallText>Equity</SmallText>
          </LegendWrapper>
          <LegendWrapper>
            <LegendColorDiv style={{ background: COLORS.purple }} />
            <SmallText>Debt</SmallText>
          </LegendWrapper>
          <LegendWrapper>
            <LegendColorDiv style={{ background: COLORS.cashColor }} />
            <SmallText> Cash</SmallText>
          </LegendWrapper>
        </ProgressBarLegendContainer>
      </AssetAllocationContainer>

      <Divider style={{ marginBottom: '20px', width: '100%' }} />

      <ReturnsHeader>
        <SubHeading>Returns Chart</SubHeading>
      </ReturnsHeader>

      <Divider style={{ width: '100%', marginBottom: '20px' }} />

      <PortfolioContainer>
        <Column
          style={{
            width: '100%',
            position: 'absolute',
            padding: '0px 10px 5px 10px',
          }}
        >
          Portfolio Holdings
        </Column>
        <HoldingWrapper>
          {DrawerHoldingsData.map((data, index) => (
            <>
              <PortfolioWrapper key={index}>
                <StyledColumn1>
                  <Column>{data?.fund_name}</Column>

                  <StyledColumn2>{data.fund_percent}</StyledColumn2>
                </StyledColumn1>
                <Column style={{ width: '70%' }}>
                  {data.fund_description}
                </Column>
                <Column>{data.fund_amount}</Column>
              </PortfolioWrapper>
              <Divider style={{ width: '100%' }} />
            </>
          ))}
        </HoldingWrapper>
      </PortfolioContainer>
    </DrawerContainer>
  );
}

export default DrawerData;
