/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import ApiInstance from '@instances/index';
import {
  GetAllFundsWithDocumentsParams,
  IFundDocumentType,
  IFundWithDocuments,
  IFundWithDocumentsResponse,
} from './types';
import { apiEndPoints } from '@pages/UploadDocuments/_constants';

/*
export const getAllFunds = async (
  payload: GetAllFundsParams,
): Promise<FundResponseInterface> => {
  return await ApiInstance.post(`/enterprise/getAllFunds`, payload).then(
    res => res.data,
  );
};
*/

export const getAllFundsWithDocuments = async (
  payload: GetAllFundsWithDocumentsParams,
): Promise<IFundWithDocumentsResponse> => {
  return await ApiInstance.post(`/enterprise/getAllFunds`, {
    ...payload,
    with_documents: true,
  }).then(res => res.data);
};

type FundCheckNameResponse =
  | {
      error: null;
      message: string;
    }
  | {
      error: string;
      internal_fund_id: string;
      external_fund_id: string | null;
    };

export const checkFundName = async (payload: {
  name: string;
}): Promise<FundCheckNameResponse> => {
  return await ApiInstance.post(`/enterprise/checkFundName`, payload).then(
    res => res.data,
  );
};

type CreateCustomFundResponse =
  | {
      error: null;
      data: IFundWithDocuments;
    }
  | {
      error: string;
      data: undefined;
    };

export const createNewCustomFund = async (payload: {
  name: string;
}): Promise<CreateCustomFundResponse> => {
  return await ApiInstance.post(
    `/enterprise/createFund/newCustomFund`,
    payload,
  ).then(res => res.data);
};

export const bulkUpload = async (data: any): Promise<any> => {
  return await ApiInstance.post('enterprise/createFund/bulkUpload', {
    jsonData: data,
  }).then(res => res.data);
};

export type IPresignedUrlFile = {
  internal_fund_id?: string;
  external_fund_id?: string;
  fileType: string;
  documentType: IFundDocumentType;
  fileName: string;
};

type IPresignedUrlFileResponse = {
  fileName: string;
  preSignedUrl: string;
};

export const getPresignedUrlsForFiles = async (
  data: IPresignedUrlFile[],
): Promise<IPresignedUrlFileResponse[]> => {
  return await ApiInstance.post(apiEndPoints.getPresignedUrl, {
    jsonData: data,
  }).then(res => res.data);
};

export type IFundDocumentData = {
  fileName: string;
  filePath: string;
  fileType: IFundDocumentType;
  internal_fund_id: string | null;
  external_fund_id: string | null;
};

export const uploadFundDocument = async (
  data: IFundDocumentData[],
): Promise<any> => {
  return await ApiInstance.post('enterprise/uploadFundDocument', {
    jsonData: data,
  }).then(res => res.data);
};
