export const LayoutSize = {
  headerHeght: '62px',
  contentHeight: 'calc(100vh - 42px)',
  footerHeight: '80px',
  drawer: {
    x: '2rem',
    y: '0.5rem',
    padding: '2rem 2rem 0.5rem 2rem',
    scrollableHeight: 'calc(100% - 72px)',
  },
};

export const LayoutGutter = {
  x: '1rem',
  y: '1rem',
  sidebar: {
    x: '1rem',
    y: '2rem',
    expandWidth: 206,
    collapseWidth: 94,
  },
  rightSide: {
    x: '8px',
    y: '8px',
    content: {
      x: '1rem',
      y: '1rem',
    },
    drawer: {
      expandWidth: 440,
      collapseWidth: 0,
    },
  },
};
