/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import ApiInstance from '@instances/index';
import { END, EventChannel, eventChannel } from 'redux-saga';

export const getStatsListApi = async (payload: any): Promise<any> => {
  return await ApiInstance.post(`/enterprise/pineconeIndexStats`, payload).then(
    res => res.data,
  );
};

export function getVictorMigrationResponse(
  payloadAndHeaders: any,
  streamHasEnded?: () => void, // Callback function to be called when stream ends
): EventChannel<any | END> {
  return eventChannel(emitter => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}enterprise/migratePineconeVectors`,
      {
        method: 'POST',
        ...payloadAndHeaders,
      },
    )
      .then(response => {
        const stream = response.body;
        if (stream) {
          const reader = stream.getReader();
          const decoder = new TextDecoder();

          const readChunk = async () => {
            let buffer = '';

            try {
              // eslint-disable-next-line no-constant-condition
              while (true) {
                const { value, done } = await reader.read();
                if (done) {
                  emitter(END); // End the event channel
                  if (streamHasEnded) {
                    streamHasEnded(); // Call the callback function
                  }
                  return;
                }
                buffer += decoder.decode(value, { stream: true });
                let boundary = buffer.indexOf('\n\n');
                while (boundary !== -1) {
                  const chunk = buffer.slice(0, boundary);
                  buffer = buffer.slice(boundary + 2);

                  const lines = chunk.split('\n');
                  const dataField = lines.find(line =>
                    line.startsWith('data:'),
                  );
                  const dataString = dataField
                    ? dataField.slice('data:'.length).trim()
                    : '';

                  if (dataString) {
                    try {
                      const response = JSON.parse(dataString);
                      emitter(response);

                      // Check if the status is "Complete" and end the stream if so
                      if (
                        response.data &&
                        response.data.status === 'Complete'
                      ) {
                        emitter(END); // End the event channel
                        if (streamHasEnded) {
                          streamHasEnded(); // Call the callback function
                        }
                        return;
                      }
                    } catch (e) {
                      console.error('Error parsing JSON:', e);
                    }
                  }

                  // Introduce a delay before processing the next chunk
                  await new Promise(resolve => setTimeout(resolve, 5000));

                  boundary = buffer.indexOf('\n\n');
                }
              }
            } catch (error) {
              console.error('Stream reading error:', error);
              emitter(END);
              if (streamHasEnded) {
                streamHasEnded(); // Call the callback function
              }
            }
          };

          // Start reading the first chunk
          readChunk();
        }
      })
      .catch(error => {
        console.error(error);
        emitter(error); // Emit error to the event channel
        emitter(END); // End the event channel in case of error
        if (streamHasEnded) {
          streamHasEnded(); // Call the callback function
        }
      })
      .finally(() => {
        console.log('streamHasEnded...');
        if (streamHasEnded) {
          streamHasEnded(); // Call the callback function
        }
      });

    // Return the unsubscribe function
    return () => {
      // Close any open connections or perform cleanup if needed
      if (streamHasEnded) {
        streamHasEnded(); // Call the callback function
      }
    };
  });
}
