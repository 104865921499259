import { IIntegration, IIntegrationExt } from '@features/Global/types';
import { useQuery, UseQueryResult } from 'react-query';
import ApiInstance from 'utils/instances';

type IUseIntegrationListResponse = {
  message: 'Success';
  data: IIntegration[];
};

export default function useIntegrationsList(): UseQueryResult<
  IIntegrationExt[]
> {
  return useQuery(
    'integrationsList',
    async () => {
      return await ApiInstance.get<IUseIntegrationListResponse>(
        `/integrations/getAllIntegrations`,
      )
        .then(res => res.data)
        .then(data => {
          const ext: IIntegrationExt[] = [];
          for (const integrationData of data.data) {
            if (integrationData.status === 'SYNCING') {
              //                    yield put(actions.getIntegrationDataByIdRequest(integrationData));
            }
            ext.push({ ...integrationData, loading: false });
          }
          const desiredOrder = [
            'REDTAIL',
            'WEALTHBOX',
            'HUBSPOT',
            'SALESFORCE',
          ];
          const sortedIntegrationList = ext.sort((a, b) => {
            return (
              desiredOrder.indexOf(a.crmName) - desiredOrder.indexOf(b.crmName)
            );
          });

          return sortedIntegrationList;
        });
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: 100000,
    },
  );
}
