import * as React from 'react';

function NoData(): React.JSX.Element {
  return (
    <svg
      width="366"
      height="258"
      viewBox="0 0 366 258"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="320.918"
        height="187.515"
        rx="49"
        transform="matrix(0.969726 -0.244194 0.292232 0.956347 0 78.3662)"
        fill="#F2F4F7"
      />
      <rect
        x="30.5"
        y="31.5"
        width="313.534"
        height="195.349"
        rx="4.5"
        fill="white"
        stroke="#E4E7EC"
      />
      <rect
        x="39.5859"
        y="54.1997"
        width="86.8712"
        height="15.2779"
        rx="5"
        fill="#F2F4F7"
      />
      <rect
        x="39.5859"
        y="133.418"
        width="172.544"
        height="15.2779"
        rx="5"
        fill="#F2F4F7"
      />
      <rect
        x="39.5859"
        y="154.355"
        width="172.544"
        height="15.2779"
        rx="5"
        fill="#F2F4F7"
      />
      <rect
        x="39.5859"
        y="175.291"
        width="172.544"
        height="15.2779"
        rx="5"
        fill="#F2F4F7"
      />
      <rect
        x="39.5859"
        y="196.227"
        width="172.544"
        height="15.2779"
        rx="5"
        fill="#F2F4F7"
      />
      <rect
        x="39.5859"
        y="112.482"
        width="172.544"
        height="15.2779"
        rx="5"
        fill="#F2F4F7"
      />
      <rect
        x="39.5859"
        y="91.5456"
        width="172.544"
        height="15.2779"
        rx="5"
        fill="#F2F4F7"
      />
      <path
        d="M327.16 93.809C327.16 100.863 324.843 107.777 320.47 113.774C316.096 119.771 309.84 124.612 302.404 127.754C294.968 130.895 286.648 132.212 278.379 131.557C270.11 130.901 262.22 128.299 255.597 124.043C248.974 119.787 243.88 114.046 240.889 107.465C237.897 100.885 237.127 93.7255 238.664 86.7932C240.2 79.8609 243.984 73.4303 249.588 68.2246C255.193 63.0189 262.396 59.2446 270.388 57.3262L282.526 93.809H327.16Z"
        fill="#F2F4F7"
      />
      <ellipse
        opacity="0.5"
        cx="282.526"
        cy="93.809"
        rx="44.6338"
        ry="37.9117"
        fill="#F2F4F7"
      />
      <ellipse
        cx="282.827"
        cy="93.809"
        rx="33.5503"
        ry="28.8582"
        fill="white"
      />
      <line
        y1="-1.5"
        x2="56.0222"
        y2="-1.5"
        transform="matrix(0.0108033 0.999942 -0.999954 0.00963707 234.898 154.769)"
        stroke="#E4E7EC"
        strokeWidth="3"
      />
      <line
        x1="332.554"
        y1="211.722"
        x2="236.097"
        y2="211.722"
        stroke="#E4E7EC"
        strokeWidth="3"
      />
      <path
        d="M270.845 174.857L271.164 173.936L270.535 173.728L270.089 174.186L270.845 174.857ZM300.801 184.759L300.482 185.679L301.237 185.929L301.664 185.3L300.801 184.759ZM321.171 154.769L310.674 159.763L320.644 166.005L321.171 154.769ZM239.849 208.064L271.602 175.528L270.089 174.186L238.336 206.722L239.849 208.064ZM270.526 175.777L300.482 185.679L301.12 183.839L271.164 173.936L270.526 175.777ZM301.664 185.3L317.074 162.613L315.347 161.532L299.937 184.218L301.664 185.3Z"
        fill="#F2F4F7"
      />
    </svg>
  );
}

const MemoNoData = React.memo(NoData);
export default MemoNoData;
