import React from 'react';
import {
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Checkbox,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { ButtonContainer, ModalContainer, PopupHeader } from './styles';
import { PrimaryButton, SecondaryButton } from '@components/Button';
import { Heading, LightSubHeading } from '@components/Typography';
import InputField from '@components/core/InputField';
import { checkValidArray } from '@common/array';
import { actions as enterpriseActions } from '@features/Enterprises/slice';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';

interface ISubscription {
  modulesNotAllowed: Array<string>;
}

interface IEnterpriseData {
  id: string;
  name: string;
  dataProviderId: string;
  subscriptions: Array<ISubscription>;
}

interface UpdateModalProps {
  data: Array<any>;
  enterpriseData: IEnterpriseData;
  onClose: () => void;
}

interface IFormData {
  dataProviderId: string;
  id: string;
  name: string;
  unsubscribes: Array<string>;
}

const subscriptionModules = [
  { id: 1, value: 'fund_specific', label: 'Fund Specific' },
  { id: 2, value: 'market_research', label: 'Market Research' },
  { id: 3, value: 'educational', label: 'Educational' },
  { id: 4, value: 'client_communication', label: 'Client Communication' },
  { id: 5, value: 'client_personalization', label: 'Client Personalization' },
];

const UpdateModal: React.FC<UpdateModalProps> = ({
  data = [],
  enterpriseData,
  onClose,
}) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    setValue,
    watch,
  } = useForm<IFormData>({
    defaultValues: {
      dataProviderId: enterpriseData.dataProviderId,
      name: enterpriseData.name,
      unsubscribes: enterpriseData?.subscriptions?.[0]?.modulesNotAllowed || [],
      id: enterpriseData.id,
    },
    mode: 'onChange',
  });

  const formData = watch();

  const onChange = (key: keyof IFormData, value: string | Array<string>) => {
    setValue(key, value, { shouldDirty: true });
  };

  const onSubmit = (formData: IFormData) => {
    dispatch(enterpriseActions.updateEnterpriseRequest(formData));
    onClose();
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: { value: string },
  ) => {
    const { checked } = event.target;
    setValue(
      'unsubscribes',
      checked
        ? formData.unsubscribes.filter(value => value !== item.value)
        : [...formData.unsubscribes, item.value],
      { shouldDirty: true },
    );
  };

  return (
    <ModalContainer open={true} onClose={onClose}>
      <FormControl
        className="modal-content"
        sx={{ width: 400, bgcolor: 'background.paper', p: 3 }}
      >
        <PopupHeader>
          <Heading>Update Enterprise</Heading>
          <IconButton className="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </PopupHeader>

        <LightSubHeading mb={1} color="#000">
          Enterprise Name
        </LightSubHeading>
        <Box sx={{ marginBottom: '2rem' }}>
          <Controller
            name="name"
            control={control}
            rules={{ required: 'Enterprise name is required' }}
            render={({ field }) => (
              <InputField
                {...field}
                variant="input"
                placeholder="Enter the Enterprise Name"
                sx={{ height: '40px' }}
              />
            )}
          />
          {errors.name && (
            <p role="alert" style={{ color: 'red', marginTop: '10px' }}>
              {errors.name.message}
            </p>
          )}
        </Box>

        {checkValidArray(data) && (
          <>
            <LightSubHeading mb={1} color="#000">
              Select Data Provider
            </LightSubHeading>
            <Controller
              name="dataProviderId"
              control={control}
              rules={{ required: 'Data Provider is required' }}
              render={({ field }) => (
                <Select
                  {...field}
                  displayEmpty
                  onChange={(event: SelectChangeEvent) =>
                    onChange('dataProviderId', event.target.value)
                  }
                  inputProps={{
                    'aria-label': 'Without label',
                    placeholder: 'Select Data Provider',
                  }}
                  sx={{
                    width: '100%',
                    height: '40px',
                    marginBottom: '2rem',
                    background: '#FFFFFF',
                    boxShadow: '1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset',
                  }}
                >
                  {data.map(k => (
                    <MenuItem key={k.id} value={k.id}>
                      {k.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors.dataProviderId && (
              <p style={{ color: 'red', margin: '0 0 2rem 0' }}>
                {errors.dataProviderId.message}
              </p>
            )}
          </>
        )}

        {checkValidArray(subscriptionModules) &&
          subscriptionModules.map(item => (
            <FormControlLabel
              key={item.id}
              control={
                <Controller
                  name="unsubscribes"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      checked={!field.value.includes(item.value)}
                      onChange={event => handleCheckboxChange(event, item)}
                    />
                  )}
                />
              }
              label={item.label}
            />
          ))}

        <ButtonContainer>
          <SecondaryButton width="100%" onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            startIcon={<CheckIcon />}
            onClick={handleSubmit(onSubmit)}
            width="100%"
            disabled={!isDirty || !isValid}
          >
            Update
          </PrimaryButton>
        </ButtonContainer>
      </FormControl>
    </ModalContainer>
  );
};

export default UpdateModal;
