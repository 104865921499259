import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import ErrorScreen from '../ErrorScreen';
import useCurrentUser from '@helper/CustomHooks/useCurrentUser';

function NotFoundPage(): React.JSX.Element {
  const navigate = useNavigate();
  const { user_type } = useCurrentUser();

  const handleError = () => {
    if (user_type === 'SUPER_ADMIN') {
      navigate('/manage-users');
    } else {
      navigate('/');
    }
  };
  return (
    <Grid position={'relative'} zIndex={9}>
      <Helmet>
        <title>404 Page Not Found</title>
        <meta name="description" content="Page not found" />
      </Helmet>
      <ErrorScreen
        title="Something's wrong here"
        message="This is a 404 error which means either you've clicked on a bad link or entered an invalid URL."
        variant="not-found"
        tryAgain={() => {
          handleError();
        }}
        ctaContext={'Return to dashboard'}
      />
    </Grid>
  );
}

export default NotFoundPage;
