import styled from 'styled-components';
import { Grid } from '@mui/material';
import { Box, Modal } from '@mui/material';
export const AppContainer = styled(Grid)``;

export const Header = styled(Grid)`
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ContentWrapper = styled(Grid)`
  height: calc(100vh - 220px);
  margin-right: -15px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-content: flex-start;
  padding: 32px 15px 32px 15px;

  &::-webkit-scrollbar {
    width: 4px !important;
    height: 2px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.grey4} !important;
    border-radius: 20px !important;
    margin-bottom: 10px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
    margin-top: 12px !important;
  }
`;

export const ModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PopupHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const FieldWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 20px;
`;
export const FileDraggerWrap = styled('div')`
  width: 100%;
  .custom-dropzone {
    min-height: 124px;
    padding-bottom: 0rem;
  }
`;
export const EnterpriseWrap = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const LogoContainer = styled(Box)`
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

export const DetailsContainer = styled(Grid)``;

export const CardWrap = styled(Box)``;

export const EnterpriseAdministratorsBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const EnterpriseAdministratorsItem = styled(Box)`
  display: flex;
  align-items: center;
  gap: 72px;
`;
export const EnterpriseAdministratorsRightWrap = styled(Box)`
  display: flex;
  align-items: center;
  gap: 20px;
`;
