import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/types';
import { initialState } from './slice';

const selectSlice = (state: RootState) => state?.chat || initialState;

export const getQueryLoaderState = createSelector(
  [selectSlice],
  state => state.processingQuery,
);

export const getSearchedQuery = createSelector(
  [selectSlice],
  state => state.query,
);

export const getChatInitResponseState = createSelector([selectSlice], state => {
  return {
    loading: state.loading,
    initIsValid: state.initIsValid,
    initToken: state.initToken,
    conversation_history: state.conversation_history,
  };
});

export const getConversationsState = createSelector([selectSlice], state => {
  return {
    conversation_history: state.conversation_history,
    conversation_current: state.conversation_current,
    loading: state.loading,
  };
});

export const getChatFeedbackState = createSelector(
  [selectSlice],
  state => state.feedback,
);

export const isQueryResponseStreaming = createSelector(
  [selectSlice],
  state => state.streaming,
);
