import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Box, Grid, CircularProgress } from '@mui/material';
import { PrimaryButton } from '@components/Button';
import { isEmpty } from '@common/string';
import { InputField } from '@components/UpdatePasswordForm/styles';

interface FormData {
  username: string;
  password: string;
}

interface InputDrawerProps {
  integrationData: any;
  handleDrawerClose: () => void;
  reloadAllIntegrations: () => void;
}

const InputDrawer: React.FC<InputDrawerProps> = ({
  integrationData,
}: InputDrawerProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const handleFormSubmit: SubmitHandler<FormData> = data => {
    if (data.username && data.password) {
      window.alert('Feature not available');
      /*
      dispatch(
        globalActions.connectIntegrationRequest({
          ...integrationData,
          username: getValues('username'),
          password: getValues('password'),
          advisorId: loggedUser?.advisorId,
          callback: (response: any) => {
            console.log('response : ', response);
            handleDrawerClose();
          },
        }),
      );
      */
    }
  };

  return (
    <Box
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Grid container rowGap={3}>
        <Controller
          name="username"
          control={control}
          defaultValue=""
          rules={{
            validate: {
              isNotEmpty: val =>
                !isEmpty(val.trim()) || 'Please enter username',
            },
            minLength: 14,
          }}
          render={({ field }) => (
            <InputField
              required
              mb={2}
              type={'text'}
              containerHeight={'40px'}
              error={!!errors.username}
              placeholder={'Enter your username'}
              helperText={errors.username?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{
            validate: {
              isNotEmpty: val =>
                !isEmpty(val.trim()) || 'Please enter a password',
            },
          }}
          render={({ field }) => (
            <InputField
              required
              mb={2}
              type={'password'}
              containerHeight={'40px'}
              error={!!errors.password}
              placeholder={'Enter your password'}
              helperText={errors.password?.message}
              {...field}
            />
          )}
        />
        <PrimaryButton
          sx={{ marginBottom: 3 }}
          width={'100%'}
          type={'submit'}
          disabled={integrationData.loading}
        >
          {integrationData.loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Connect'
          )}
        </PrimaryButton>
      </Grid>
    </Box>
  );
};

export default InputDrawer;
