import React, { useState } from 'react';
import { FormControl, FormControlLabel, IconButton } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonContainer, ModalContainer, PopupHeader } from './styles';
import { PrimaryButton, SecondaryButton } from '@components/Button';
import { Heading, LightSubHeading } from '@components/Typography';
import InputField from '@components/core/InputField';
import CheckIcon from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';
import { checkValidArray } from '@common/array';
interface AddModalProps {
  title: string;
  loading?: boolean;
  data?: Array<any>;
  subscriptionModules?: Array<ICheckbox>;
  onClose: () => void;
  handleSubmit: (formData: any) => void;
}

interface IFormData {
  dataProviderId: string;
  name: string;
  unsubscribes: Array<string>;
}

interface ICheckbox {
  id: number;
  value: string;
  label: string;
}

const AddModal: React.FC<AddModalProps> = ({
  title,
  data = [],
  loading = false,
  subscriptionModules = [],
  onClose,
  handleSubmit,
}) => {
  const [formData, setFormData] = useState<IFormData>({
    dataProviderId: '',
    name: '',
    unsubscribes: [],
  });

  const onCloseModal = () => {
    onClose();
  };

  const onChange = (key: string, value: string) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  console.log({ formData });

  const onSubmit = () => {
    if (
      checkValidArray(data) &&
      formData.dataProviderId.length > 0 &&
      formData.name.length > 0
    ) {
      handleSubmit(formData);
      onCloseModal();
    } else {
      if (formData.name.length > 0) {
        handleSubmit(formData);
        onCloseModal();
      } else {
        console.log('error');
        console.log(formData.dataProviderId, formData.name);
      }
    }
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: ICheckbox,
  ) => {
    const { checked } = event.target;
    setFormData(prevFormData => {
      const updatedSubscription = checked
        ? prevFormData.unsubscribes.filter(value => value !== item.value)
        : [...prevFormData.unsubscribes, item.value];

      return {
        ...prevFormData,
        unsubscribes: updatedSubscription,
      };
    });
  };

  return (
    <ModalContainer open={true} onClose={onCloseModal}>
      <FormControl
        className="modal-content"
        sx={{ width: 400, bgcolor: 'background.paper', p: 3 }}
      >
        <PopupHeader>
          <Heading>{`Add ${title}`}</Heading>
          <IconButton className="close" onClick={onCloseModal}>
            <CloseIcon />
          </IconButton>
        </PopupHeader>
        <LightSubHeading mb={1} color="#000">
          {' '}
          {`${title} Name`}
        </LightSubHeading>
        <InputField
          required
          variant={'input'}
          name={'name'}
          placeholder={`Enter the ${title} Name`}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = event.target;
            onChange(name, value);
          }}
          sx={{ height: '40px', marginBottom: '2rem' }}
        />

        {checkValidArray(data) && (
          <>
            <LightSubHeading mb={1} color="#000">
              Select Data Provider
            </LightSubHeading>
            <Select
              displayEmpty
              name={'dataProviderId'}
              disabled={loading}
              onChange={(event: SelectChangeEvent) => {
                const { name, value } = event.target;
                onChange(name, value);
              }}
              inputProps={{
                'aria-label': 'Without label',
                placeholder: 'Select Data Provider',
              }}
              sx={{
                width: '100%',
                height: '40px',
                marginBottom: '2rem',
                background: '#FFFFFF',
                boxShadow: '1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset',
              }}
            >
              {data.map(k => (
                <MenuItem key={k.id} value={k.id}>
                  {k.name}
                </MenuItem>
              ))}
            </Select>
          </>
        )}

        {checkValidArray(subscriptionModules) &&
          subscriptionModules.map(item => (
            <FormControlLabel
              key={item.id}
              control={
                <Checkbox
                  checked={!formData.unsubscribes.includes(item.value)}
                  onChange={event => handleCheckboxChange(event, item)}
                />
              }
              label={item.label}
            />
          ))}

        <ButtonContainer>
          <SecondaryButton width="100%" onClick={onCloseModal}>
            Cancel
          </SecondaryButton>

          <PrimaryButton
            startIcon={<CheckIcon />}
            onClick={onSubmit}
            width="100%"
          >
            Confirm
          </PrimaryButton>
        </ButtonContainer>
      </FormControl>
    </ModalContainer>
  );
};

export default AddModal;
