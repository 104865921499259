import { TableRow } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
export const fontStyles = {
  fontSize: '12px',
  fontWeight: 400,
};
export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#464646',
    fontSize: '12px',
    fontWeight: 500,
    padding: '8px 16px',
    minWidth: '70px',
    backgroundColor: 'rgb(249, 250, 251)',
    border: '0.5px solid #eee',
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: 'transparent',
    background: 'transparent',
    verticalAlign: 'baseline',
    padding: '8px 16px',
    ...fontStyles,
    color: '#464646',
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {},
  // hide last border
  '&:last-child td, &:last-child th': {},
}));
