/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
export const regExpEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const isEmailValid = (emailAddress: string): boolean => {
  const regexEmail = new RegExp(regExpEmail);
  return regexEmail.test(emailAddress);
};

export const checkTableContent = (content: string): boolean =>
  typeof content === 'string' && content?.includes('<table');

export const checkParseTableData = (data: any): boolean =>
  typeof data === 'object' &&
  Array.isArray(data?.headers) &&
  Array.isArray(data?.body);

export const isReactComponent = (component: any): boolean => {
  return (
    typeof component === 'function' &&
    component.prototype instanceof React.Component
  );
};

export const isComponent = (component: any): boolean => {
  return (
    typeof component === 'object' &&
    Object.prototype.hasOwnProperty.call(component, '$$typeof') &&
    Object.prototype.hasOwnProperty.call(component, 'type')
  );
};

export const hasHTMLTags = (inputString: string): boolean => {
  const regex = /<\/?[\w\s="'.,;#-/?]+>/;
  return regex.test(inputString);
};

export const parseData = (data: any): any => {
  const parsedData: any = data;
  if (
    data &&
    data.type === 'enhancement_queue' &&
    data.formatted_data &&
    Array.isArray(data.formatted_data)
  ) {
    parsedData.formatted_data = data.formatted_data.map((k: any) => {
      if (k?.type === 'text' && hasHTMLTags(k.content)) {
        k.content = parseHtmlTableData(k.content);
      }
      return k;
    });
  } else if (hasHTMLTags(parsedData)) {
    return parseHtmlTableData(parsedData);
  }
  return parsedData;
};

export function parseHtmlTableData(htmlString: string): any {
  // Create a new DOMParser
  const parser = new DOMParser();
  // Parse the HTML string into a DOM document
  const doc = parser.parseFromString(htmlString, 'text/html');
  // Find the table element in the DOM
  const table = doc.querySelector('table');

  if (!table) {
    return null;
  }

  // Get the headers from the table
  const headers: any = Array.from(table.querySelectorAll('th')).map(th =>
    th.textContent?.trim(),
  );
  // Get all the rows in the table
  const rows: any = Array.from(table.querySelectorAll('tr')).slice(1); // Skip the first row (header row)
  // Parse the data from the rows
  const data: any = rows.map((row: any) => {
    const cells = Array.from(row.querySelectorAll('td')).map((td: any) =>
      td?.textContent?.trim(),
    );
    const rowData: Record<string, any> = {};
    headers.forEach((header: any, index: any) => {
      rowData[header] = cells[index];
    });
    return rowData;
  });
  return {
    headers,
    body: data,
  };
}

export function filterAndParseData(
  htmlString: string,
): (string | JSX.Element)[] {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const body = doc.body;
  if (!body) {
    return [];
  }

  const output: (string | JSX.Element)[] = [];
  let currentString = '';
  let insideTable = false;

  const processNode = (node: Node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      currentString += node.textContent || '';
    } else if (node.nodeName === 'BR') {
      if (currentString.trim() !== '') {
        output.push(currentString);
      }
      currentString = '';
      output.push(React.createElement(node.nodeName));
    } else if (node.nodeName === 'TABLE') {
      if (currentString.trim() !== '') {
        output.push(currentString);
      }
      currentString = '';
      insideTable = !insideTable;
      output.push(React.createElement(node.nodeName));
    } else {
      for (const childNode of Array.from(node.childNodes)) {
        processNode(childNode);
      }
    }
  };

  for (const childNode of Array.from(body.childNodes)) {
    processNode(childNode);
  }

  if (currentString.trim() !== '') {
    output.push(currentString);
  }

  console.log({ output });

  return output;
}

interface ParsedItem {
  type: 'text' | 'html';
  content: string | JSX.Element;
}

export function parser(htmlString: string): any[] {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const parsedItems: ParsedItem[] = [];
  let currentText = '';
  let currentHTML = '';

  const lineBreakTags = new Set(['BR', 'P']); // Add tags that indicate a new line

  const handleNode = (node: Node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      currentText += node.textContent;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const tag = node.nodeName;
      if (lineBreakTags.has(tag)) {
        // If the tag indicates a line break, push the accumulated text and reset it
        if (currentText.trim() !== '') {
          parsedItems.push({ type: 'text', content: currentText });
          currentText = '';
        }
      } else if (tag === 'B' || tag === 'STRONG') {
        // Handle <b> or <strong> tags as text
        const content = node.textContent?.trim();
        if (content) {
          currentText += content;
        }
      } else if (tag === 'TABLE') {
        // Handle tables as HTML and push individually
        const content = new XMLSerializer().serializeToString(node);
        if (currentHTML.trim() !== '') {
          parsedItems.push({ type: 'html', content: currentHTML });
          currentHTML = '';
        }
        parsedItems.push({ type: 'html', content });
      }
    }
  };

  doc.body.childNodes.forEach(handleNode);

  // Push any remaining accumulated text or HTML
  if (currentText.trim() !== '') {
    parsedItems.push({ type: 'text', content: currentText });
  }
  if (currentHTML.trim() !== '') {
    parsedItems.push({ type: 'html', content: currentHTML });
  }

  return parsedItems;
}

export const isEmpty = (value: any): boolean => {
  if (value == null) {
    return true;
  }

  if (
    Array.isArray(value) ||
    typeof value === 'string' ||
    value instanceof String
  ) {
    return value.length === 0;
  }

  if (typeof value === 'object') {
    for (const key in value) {
      if (Object.prototype.hasOwnProperty.call(value, key)) {
        return false;
      }
    }
    return true;
  }

  return false;
};

export const toCapitalCase = (str: string): string => {
  const words = str.split(' ');
  const capitalizedWords = words.map(
    word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
  );
  return capitalizedWords.join(' ');
};

export const getElementHeight = (elementId: string): number =>
  document.getElementById(elementId)?.offsetHeight || 0;

export const getFormatedFileSize = (size: number): string => {
  const KB = 1024;
  const MB = KB * 1024;

  if (size < KB) {
    return size + ' b';
  } else if (size < MB) {
    return (size / KB).toFixed(2) + ' kb';
  } else {
    return (size / MB).toFixed(2) + ' mb';
  }
};
