import React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';

const CtaPadding = '10px 15px';

export const HomeButton = styled(
  ({ title, subtitle, ...props }: StyledButtonProps) => (
    <Button
      type={props?.type || 'button'}
      disabled={props?.disabled || false}
      fullWidth={props?.fullWidth ?? true}
      {...props}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
        px={2}
      >
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            textTransform: 'none',
          }}
        >
          {title && (
            <Typography
              variant="body1"
              component="div"
              sx={{
                fontWeight: 'bold',
                color: '#101828',
                fontSize: 'clamp(10px, 1vw, 16px)',
              }}
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography
              variant="body2"
              component="div"
              sx={{
                color: '#6B7280',
                fontSize: '12px',
                paddingBlockStart: '2px',
              }}
            >
              {subtitle}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Button>
  ),
)((styledProps: StyledButtonProps) => ({
  width: '100%',
  minWidth: styledProps.minWidth,
  height: styledProps.customHeight || '77px',
  display: 'flex',
  alignItems: 'left',
  justifyContent: 'left',
  padding: styledProps.padding || CtaPadding,
  textAlign: 'left',
  outline: 'none',
  backgroundColor: '#fff',
  borderRadius: '8px',
  border: '1px solid #e4e7ec',
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.12)',
}));

type StyledButtonProps = ButtonProps & {
  title?: string;
  subtitle?: string;
  customHeight?: string;
  minWidth?: string;
  padding?: string;
};

export default HomeButton;
