export const QUERY_TYPE = {
  EDUCATIONAL: { value: 'educational', label: 'educational' },
  MARKET_RESEARCH: { value: 'market_research', label: 'market_research' },
  FUND_SPECIFIC: { value: 'fund_specific', label: 'fund_specific' },
  CLIENT_COMMS: { value: 'client_comms', label: 'client_comms' },
  CONTEXTUAL: { value: 'contextual', label: 'contextual' },
  INSIGHT: { value: 'insight', label: 'insight' },
};
export const OPTIONS_QUERY_TYPE = [
  QUERY_TYPE.EDUCATIONAL,
  QUERY_TYPE.MARKET_RESEARCH,
  QUERY_TYPE.FUND_SPECIFIC,
  QUERY_TYPE.CLIENT_COMMS,
  QUERY_TYPE.CONTEXTUAL,
  QUERY_TYPE.INSIGHT,
];
