import React from 'react';
import { useAppContext } from './AppContext';
import AddFundsModal from '@pages/MyFunds/AddFundsModal/AddFundsModal';
import AddFundDocumentModal from '@pages/MyFunds/AddFundDocumentModal/AddFundDocumentModal';

const AppModals = (): React.JSX.Element => {
  const { modal, setModal } = useAppContext();
  if (modal) {
    if (modal.type === 'ADD_NEW_FUND') {
      return (
        <AddFundsModal
          default_fund_name={modal.default_fund_name}
          onHide={() => setModal(null)}
        />
      );
    } else if (modal.type === 'ADD_FUND_DOCUMENT') {
      return (
        <AddFundDocumentModal
          mode={modal.mode}
          fund_name={modal.fund_name}
          internal_fund_id={modal.internal_fund_id || null}
          external_fund_id={modal.external_fund_id || null}
          document_type={modal.document_type}
          onHide={() => setModal(null)}
        />
      );
    }
  }
  return <></>;
};

export default AppModals;
