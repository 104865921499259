import React, { useState } from 'react';
import { Box } from '@mui/material';
import styled, { css } from 'styled-components';
import { PrimaryButton } from '@components/Button';
import { ReactComponent as PencilIcon } from '@icons/PenIcon.svg';

const HoveringPencilIcon = styled(PencilIcon).withConfig({
  shouldForwardProp: prop => !['isHovered', 'fill'].includes(prop),
})<{
  isHovered: boolean;
  fill: string;
}>`
  /* Apply animation to the icon */
  transition: transform 0.3s;
  ${props =>
    props.isHovered &&
    css`
      color: white;
    `}
`;

interface CustomCellProps {
  style?: any;
  query?: string;
  label?: string;
  buttonProps?: any;
  disabled?: boolean;
  containerStyle?: any;
  icon?: 'PENCIL';
  onClick: () => void;
}

const CustomizeButton = ({
  style = {
    height: 'auto',
    minWidth: '180px',
    marginLeft: '20px',
  },
  label = 'Customize',
  disabled = false,
  buttonProps = {
    variant: 'outlined',
    customColor: '#FFFFFF',
    customBorderColor: '#2C3748',
    textColor: '#2C3748',
  },
  containerStyle = {},
  onClick,
  icon,
}: CustomCellProps): React.JSX.Element => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box sx={containerStyle}>
      <PrimaryButton
        isHover
        variant="outlined"
        textColor="#2C3748"
        customColor="transparent"
        {...buttonProps}
        disabled={disabled}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
        sx={{
          color: isHovered ? '#FFFFFF' : '#2C3748',
          ...style,
        }}
        startIcon={
          icon === 'PENCIL' && (
            <HoveringPencilIcon
              isHovered={isHovered}
              fill={isHovered ? 'white' : 'inherit'}
            />
          )
        }
      >
        {label}
      </PrimaryButton>
    </Box>
  );
};

export default CustomizeButton;
