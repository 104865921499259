import { createGlobalStyle } from 'styled-components';
import { COLORS } from './colors';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    margin: 0;
    font-family: Inter;
    scroll-behavior: smooth;
    font-family: 'Inter', sans-serif;
    background: ${COLORS.themeBackground};

    #root {
      width: 100%;
      height: 100%;
    }

    #webpack-dev-server-client-overlay {
      display: none;
      pointer-events: none;
    }
    #okta-sign-in.auth-container.main-container {
      margin: 0;
    }
  }

  p {
    margin: 0;
  }

  /* ... other styles ... */

  ::-webkit-scrollbar-track {
    background-color: ${COLORS.scrollbarTrackColor} !important;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${COLORS.scrollbarThumbColor} !important;
  }

  /* ... other styles ... */

  .slick-dots li {
    margin-left: 0.25rem !important;
    border-radius: 51px;
    width: 14px !important;
    height: 14px !important;

    button {
      width: 14px !important;
      height: 14px !important;
      padding: 0;
      &:before {
        width: 14px !important;
        height: 14px !important;
        line-height: 14px !important;
      }
    }
  }

  .slick-dots li.slick-active {
    button {
      color: ${COLORS.activeDotColor};
    }
  }

  /* ... other styles ... */
`;

export default GlobalStyle;
