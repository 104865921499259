/* eslint-disable react/prop-types */

import React from 'react';

function TotalMembers(): React.JSX.Element {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="4" fill="#FFBE5F" />
      <path
        d="M19.5 25.5C21.5711 25.5 23.25 23.8211 23.25 21.75C23.25 19.6789 21.5711 18 19.5 18C17.4289 18 15.75 19.6789 15.75 21.75C15.75 23.8211 17.4289 25.5 19.5 25.5Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.25 19.5H35.25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.25 24H35.25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.5 28.5H35.25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6875 30C14.018 28.7094 14.7686 27.5655 15.821 26.7486C16.8734 25.9317 18.1677 25.4883 19.5 25.4883C20.8323 25.4883 22.1266 25.9317 23.179 26.7486C24.2314 27.5655 24.982 28.7094 25.3125 30"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoConnectIcon = React.memo(TotalMembers);
export default MemoConnectIcon;
