import React, { useRef, useState } from 'react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PrimaryButton } from '@components/Button';
import { ReactComponent as PromptVector } from '../../../assets/images/promptVector.svg';
import { useDispatch } from 'react-redux';
import { actions as chatActions } from '@features/Chat/slice';
import { Box } from '@mui/material';

interface ChatTableProps {
  data: any[];
  tableHeader?: any;
}

const ChatTable: React.FC<ChatTableProps> = ({ data, tableHeader }: any) => {
  const [rotation, setRotation] = React.useState(0);
  const [isHovered, setIsHovered] = React.useState(false);
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);

  const dispatch = useDispatch();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const handleViewDetails = (data: any) => {
    const fundNameIndex = Headers.findIndex(obj => obj.name === 'Fund Name');
    const query = `Tell me more about ${data[fundNameIndex > 0 ? fundNameIndex : 0]}`;
    dispatch(chatActions.searchQueryRequest({ query }));
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      setRotation(rotation => rotation + 360);
    }, 1000);
  };

  const handleRowMouseEnter = index => {
    setHoveredRowIndex(index);
  };
  const handleRowMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setRotation(0);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const transformIRRAndTWR = (header: string): string => {
    return header.replace(/(irr)|(twr)|(mm)/gi, match => match.toUpperCase());
  };
  let DataList: any = [];
  let Headers: any = [];

  const formatPrice = amount => {
    const number = Number(amount);
    const hasDecimal = number % 1 !== 0;

    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: hasDecimal ? 2 : 0,
      maximumFractionDigits: 2,
    }).format(number);
  };

  const processValue = (value: string): string | number => {
    if (value.includes('$')) {
      const cleanedValue = value.replace(/\$|,/g, '');
      return formatPrice(cleanedValue);
    }
    return formatPrice(value);
  };
  const isYearHeader = (header: string): boolean => {
    const year = parseInt(header, 10);
    return year >= 2000 && year <= 2024;
  };
  const removeDollarSign = (value: string): string => {
    return value?.replace(/^\$/, '');
  };

  const formatValue = (
    value: any,
    decimals: number = 2,
    defaultValue: string = '-',
  ) => {
    if (value && (value === 'nan' || value.toString().includes('e'))) {
      return defaultValue;
    }
    const num = parseFloat(value);
    if (
      typeof num === 'undefined' ||
      num === null ||
      isNaN(num) ||
      isNaN(Number(num))
    ) {
      return defaultValue;
    }
    if (Number.isInteger(num)) {
      return num.toString();
    } else {
      return num.toFixed(decimals);
    }
  };

  if (data && data.headers) {
    DataList = data.body;

    Headers = data.headers.map((header: any) => ({
      name: transformIRRAndTWR(header),
      label: transformIRRAndTWR(header),
      options: {
        setCellHeaderProps: () => {
          return {
            style: {
              textAlign:
                header === 'Annualized Total Return' ||
                header === 'Annualized Return' ||
                header.includes('(%twr)') ||
                header === 'Size Weighted Irr (%)' ||
                header === 'Net IRR (%)' ||
                header === 'Total Distribution Base' ||
                header.includes('Year') ||
                header.includes('(%)') ||
                header.includes('($mm)') ||
                header === 'No Of Funds' ||
                header === 'No Of Funds Fundraising' ||
                isYearHeader(header)
                  ? 'right'
                  : 'center',
            },
          };
        },
        customBodyRender: (value: any) => {
          if (
            header === 'Annualized Total Return' ||
            header === 'Annualized Return' ||
            header.includes('(%twr)') ||
            header === 'Size Weighted Irr (%)' ||
            header === 'Net IRR (%)' ||
            header.includes('(%)') ||
            isYearHeader(header)
          ) {
            return (
              <div style={{ textAlign: 'right' }}>{formatValue(value)}</div>
            );
          } else if (header === 'Total Distribution Base') {
            return (
              <div style={{ textAlign: 'right' }}>{processValue(value)}</div>
            );
          } else if (header.includes('($mm)')) {
            return (
              <div style={{ textAlign: 'right' }}>
                {removeDollarSign(value)}
              </div>
            );
          } else if (header.includes('Year')) {
            return (
              <div style={{ textAlign: 'right' }}>{value ? value : '-'}</div>
            );
          } else if (
            header === 'No Of Funds' ||
            header === 'No Of Funds Fundraising'
          ) {
            return (
              <div style={{ textAlign: 'right' }}>{value ? value : '-'}</div>
            );
          }

          return <div style={{ textAlign: 'center' }}>{value}</div>;
        },
      },
    }));
  } else if (tableHeader && data && Array.isArray(data) && data.length > 0) {
    const headers = tableHeader || [];

    Headers = headers.map(header => ({
      name: transformIRRAndTWR(header),
      label: transformIRRAndTWR(header),
      options: {
        setCellHeaderProps: () => {
          return {
            style: {
              textAlign:
                header === 'Annualized Total Return' ||
                header === 'Annualized Return' ||
                header.includes('(%twr)') ||
                header === 'Size Weighted Irr (%)' ||
                header === 'Net IRR (%)' ||
                header === 'Total Distribution Base' ||
                header.includes('Year') ||
                header.includes('(%)') ||
                header.includes('($mm)') ||
                header === 'No Of Funds' ||
                header === 'No Of Funds Fundraising' ||
                isYearHeader(header)
                  ? 'right'
                  : 'center',
            },
          };
        },
        customBodyRender: (value: any) => {
          if (
            header === 'Annualized Total Return' ||
            header === 'Annualized Return' ||
            header.includes('(%twr)') ||
            header === 'Size Weighted Irr (%)' ||
            header === 'Net IRR (%)' ||
            header.includes('(%)') ||
            isYearHeader(header)
          ) {
            return (
              <div style={{ textAlign: 'right' }}>{formatValue(value)}</div>
            );
          } else if (header.includes('Year')) {
            return (
              <div style={{ textAlign: 'right' }}>{value ? value : '-'}</div>
            );
          } else if (header === 'Total Distribution Base') {
            return (
              <div style={{ textAlign: 'right' }}>{processValue(value)}</div>
            );
          } else if (header.includes('($mm)')) {
            return (
              <div style={{ textAlign: 'right' }}>
                {removeDollarSign(value)}
              </div>
            );
          } else if (
            header === 'No Of Funds' ||
            header === 'No Of Funds Fundraising'
          ) {
            return (
              <div style={{ textAlign: 'right' }}>{value ? value : '-'}</div>
            );
          }
          return <div style={{ textAlign: 'center' }}>{value}</div>;
        },
      },
    }));
    DataList = data;
  }

  // This MUST DISAPPEAR (TODO) - we're using exttable for this
  const hasFundName = Headers.some(obj => obj.name === 'Fund Name');
  if (hasFundName) {
    Headers.push({
      name: '',
      label: '',
      options: {
        customBodyRender: (value: any, tableMeta: any) => {
          const rowData = tableMeta.rowData;
          const rowIndex = tableMeta.rowIndex;
          return (
            <Box sx={{ position: 'relative', width: '150px' }}>
              <PrimaryButton
                sx={{
                  position: 'absolute',
                  top: '-14px',
                  width: '140px',
                  fontSize: '12px',
                  borderRadius: '10px',
                  height: '28px',
                  color:
                    hoveredRowIndex === rowIndex && isHovered
                      ? '#FFFFFF !important'
                      : '#2C3748',
                }}
                isHover
                variant="outlined"
                customColor="transparent"
                textColor="#2C3748"
                startIcon={
                  <PromptVector
                    style={{
                      ...(hoveredRowIndex === rowIndex &&
                        isHovered && {
                          transition: isHovered && 'transform 3s',
                          transform: isHovered && `rotate(${rotation}deg)`,
                        }),
                    }}
                    fill={
                      hoveredRowIndex === rowIndex && isHovered
                        ? 'white'
                        : 'inherit'
                    }
                  />
                }
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleViewDetails(rowData)}
              >
                Explore with AI
              </PrimaryButton>
            </Box>
          );
        },
      },
    });
  }
  if (!DataList || !Headers) {
    return <div>No data available</div>;
  }

  const options: MUIDataTableOptions = {
    pagination: false,
    selectableRows: 'none',
    search: false,
    print: false,
    sort: false,
    download: false,
    viewColumns: false,
    filter: false,
    responsive: 'standard', // Enable responsive design
    serverSide: true,
    setRowProps: (row, dataIndex) => ({
      onMouseEnter: () => handleRowMouseEnter(dataIndex),
      onMouseLeave: handleRowMouseLeave,
    }),
  };
  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              fontSize: 14,
              color: '#101828',
              fontWeight: 500,
              fontFamily: 'inter',
              background: '#F9FAFB',
              borderBottom: '1px solid #E4E7EC',
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              fontSize: 12,
              color: '#101828',
              fontWeight: 500,
              fontFamily: 'inter',
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          },
        },
      },
    });

  const capitalizeIRRInDataList = (_dataList: any) => {
    return _dataList.map(item => {
      const newItem: any = {};
      Object.keys(item).forEach(key => {
        const newKey = transformIRRAndTWR(key);
        newItem[newKey] = item[key] === 'nan' ? '-' : item[key];
      });
      return newItem;
    });
  };

  const transformedDataList = capitalizeIRRInDataList(DataList);
  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        title=""
        columns={Headers}
        data={transformedDataList}
        options={options}
      />
    </ThemeProvider>
  );
};

export default React.memo(ChatTable);
