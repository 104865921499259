import { Grid, styled } from '@mui/material';

export const BackdropContainer = styled(Grid)<any>`
  position: absolute;
  z-index: ${props => props.zIndex || 1};
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const OverlapGroupWrapper = styled(Grid)`
  width: 100%;
  height: auto;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(250.75, 250.75, 250.75, 1) 0%,
    rgba(242.25, 242.25, 242.25, 1) 100%
  );
  background-color: rgba(255, 255, 255, 1);
  background-position: 50% 50%;
  background-size: cover;
`;
