import React, { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HelixAvatar from '@icons/HelixAvatar';

import { Grey2SubLabelText, ParagraphLightText } from '@components/Typography';

import FeedbackIcon from '@icons/FeedbackIcon';
import StarFilledIcon from '@icons/StarFilledIcon';
import StarEmptyIcon from '@icons/StarEmptyIcon';
import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded';

import { getConfigState } from '@features/Global/selector';
import { getChatDateFormat } from '@common/dateTime';
import { actions as chatActions } from '@features/Chat/slice';

import {
  StyledRating,
  AvatarWrapper,
  ChatBoxContent,
  ChatBoxContainer,
  ChatBoxContentWrapper,
  CopyButton,
} from './styles';
import {
  HistoricConversation,
  StreamedOptionNodeItem,
  StreamedRecommendationNode,
  StreamingConversation,
} from '@features/Chat/types';
import ConversationBoxNode from './ConversationBoxNode';
import ConversationBoxPrompt from './ConversationBoxPrompt';
import PulseLoader from '@components/Creative/PulseLoader';
import CustomTooltip from '@components/Tooltip';
import { useAppContext } from 'app/AppContext';
import { motion } from 'framer-motion';
import useCurrentUser from '@helper/CustomHooks/useCurrentUser';

const listVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, x: -10 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.3 },
  },
};

type ConversationBoxProps = {
  conversation: StreamingConversation | HistoricConversation;
  isStreaming: boolean;
  chatContentRef?: React.MutableRefObject<HTMLDivElement | null>;
  onRecommendationClicked: (selected: StreamedRecommendationNode) => void;
  onFeedbackSubmit: (props: any) => void;
};

function ConversationBox({
  chatContentRef,
  conversation,
  isStreaming,
  onFeedbackSubmit,
  onRecommendationClicked,
}: ConversationBoxProps): React.JSX.Element {
  const theme = useTheme();
  const { setModal } = useAppContext();
  const navigate = useNavigate();
  const configState = useSelector(getConfigState);
  const dispatch = useDispatch();
  const { user_type } = useCurrentUser();

  const submitFeedbackChange = (selectedRating: any) => {
    onFeedbackSubmit({
      chatLogId: conversation.chat_log_id,
      scale: selectedRating,
      comment: null,
    });
  };

  if (!conversation) {
    return (
      <ChatBoxContentWrapper>
        {`Sorry, I wasn't able to process this request.`}
      </ChatBoxContentWrapper>
    );
  }

  const onOptionsSelect = (selected: StreamedOptionNodeItem) => {
    if (selected.type === 'redirect' && selected.route) {
      navigate(selected.route);
    } else if (selected.type === 'callback') {
      navigate('/conversations', { replace: true, state: {} });
      if (selected.display_text && selected.id) {
        const query = selected.display_text;
        dispatch(chatActions.searchQueryRequest({ query }));
      }
    } else if (selected.type === 'add-fund') {
      if (selected.display_text === 'Add PPM') {
        setModal({
          type: 'ADD_FUND_DOCUMENT',
          fund_name: selected.fund_name,
          external_fund_id: selected.fund_id,
          mode: 'APPEND_TO_FUND',
          document_type: 'PPM',
        });
      } else if (
        selected.display_text.toLocaleLowerCase() === 'add pitch deck'
      ) {
        setModal({
          type: 'ADD_FUND_DOCUMENT',
          fund_name: selected.fund_name,
          external_fund_id: selected.fund_id,
          mode: 'APPEND_TO_FUND',
          document_type: 'PITCH_DECK',
        });
      } else {
        setModal({
          type: 'ADD_FUND_DOCUMENT',
          fund_name: selected.fund_name,
          external_fund_id: selected.fund_id,
          mode: 'APPEND_TO_FUND',
          document_type: null,
        });
      }
      /*
      setModal({
        type: 'ADD_NEW_FUND',
        default_fund_name: selected.fund_name || '',
      });
      */
    }
  };

  const copyIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const [copyState, setCopyState] = React.useState<boolean>(false);

  const copyAIResponse = async () => {
    if (copyIntervalRef.current) {
      clearInterval(copyIntervalRef.current);
    }
    setCopyState(true);

    const textContent = conversation.nodes
      .filter(node => node.type === 'text' && node.data)
      .map(node => node.type === 'text' && node.data);

    const data = textContent.join('\n');

    try {
      await navigator.clipboard.writeText(data);
    } catch (err) {
      console.error(err);
    } finally {
      copyIntervalRef.current = setInterval(() => {
        setCopyState(false);
      }, 1000);
    }
  };

  useEffect(() => {
    return () => {
      if (copyIntervalRef.current) {
        clearInterval(copyIntervalRef.current);
      }
    };
  }, []);

  return (
    <div>
      {conversation.visible_prompt && (
        <ConversationBoxPrompt
          visible_prompt={conversation.visible_prompt}
          time={conversation.time}
          chatContentRef={chatContentRef}
        />
      )}
      <motion.div variants={listVariants} initial="hidden" animate="visible">
        <ChatBoxContainer
          container
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          isSender={false}
        >
          <Grid
            item
            container
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            columnGap={1}
            width={'100%'}
            flexWrap={'nowrap'}
          >
            <AvatarWrapper>
              <HelixAvatar
                borderOpacity={1}
                fill={theme.palette.primary.main}
              />
            </AvatarWrapper>

            <ChatBoxContent
              container
              sm={10}
              rowGap={2}
              minHeight={'36px'}
              justifyContent={'space-evenly'}
            >
              <Fragment>
                {(conversation.status === 'STARTED' ||
                  (conversation.status === 'IN_PROGRESS' &&
                    conversation.nodes.filter(
                      node => node.type !== 'start_conversation',
                    ).length === 0)) && (
                  <ChatBoxContent
                    sx={{
                      marginTop: '12px',
                      marginLeft: '25px',
                      width: '250px',
                      height: '20px',
                      flexDirection: 'row',
                      display: 'flex',
                    }}
                  >
                    <PulseLoader />
                  </ChatBoxContent>
                )}
                {conversation.nodes
                  .filter(
                    l =>
                      ![
                        'start_conversation',
                        'end_of_conversation',
                        'debug',
                      ].includes(l.type),
                  )
                  .filter(
                    l => l.type !== 'recommendations' || l.data.length > 0,
                  )
                  .map((k, ki: number) => {
                    return (
                      <ConversationBoxNode
                        node={k}
                        key={ki}
                        isSender={false}
                        onOptionsSelect={onOptionsSelect}
                        onRecommendationClicked={onRecommendationClicked}
                        variants={itemVariants}
                      />
                    );
                  })}
                {conversation.status === 'INTERRUPTED' && (
                  <div className="interrupted">(interrupted)</div>
                )}
              </Fragment>
              <div
                className="copy-and-feedback"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '12px',
                  alignItems: 'center',
                }}
              >
                {conversation.status === 'COMPLETED' && (
                  <div style={{ paddingTop: '6px' }}>
                    <CustomTooltip title={'Copy'} placement="bottom">
                      <CopyButton
                        onClick={copyAIResponse}
                        style={{ position: 'initial' }}
                      >
                        <ContentPasteRoundedIcon
                          style={{ color: copyState ? 'darkgreen' : '#101828' }}
                        />
                      </CopyButton>
                    </CustomTooltip>
                  </div>
                )}

                {(configState.feedback || user_type === 'FEEDBACK_USER') &&
                  !isStreaming &&
                  conversation.chat_log_id && (
                    <Grid
                      className="ratings-container"
                      container
                      width={'100%'}
                      alignItems={'center'}
                    >
                      <FeedbackIcon fill={theme.palette.primary['dark']} />
                      <ParagraphLightText
                        pl={'4px'}
                        pr={'10px'}
                        color={theme.palette.primary['charcoalBlack']}
                      >
                        How would you rate this response?
                      </ParagraphLightText>
                      <StyledRating
                        precision={1}
                        defaultValue={0}
                        name={'feedback'}
                        icon={
                          <StarFilledIcon
                            fill={theme.palette.primary['dark']}
                            fontSize="inherit"
                          />
                        }
                        emptyIcon={<StarEmptyIcon fontSize="inherit" />}
                        onChange={(event: any) =>
                          submitFeedbackChange(event?.target?.value)
                        }
                      />
                    </Grid>
                  )}
              </div>
            </ChatBoxContent>
            <div
              style={{
                marginTop: '10px',
                marginRight: '18px',
                whiteSpace: 'nowrap',
              }}
            >
              <Grey2SubLabelText>
                {getChatDateFormat(conversation.time)}
              </Grey2SubLabelText>
            </div>
          </Grid>
        </ChatBoxContainer>
      </motion.div>
    </div>
  );
}

export default ConversationBox;
