import { useState, useEffect } from 'react';

const useMaxHeight = (offset: number): number => {
  const [maxHeight, setMaxHeight] = useState(window.innerHeight - offset);
  useEffect(() => {
    const handleResize = () => {
      setMaxHeight(window.innerHeight - offset);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [offset]);
  return maxHeight;
};

export default useMaxHeight;
