/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import ApiInstance from '@instances/index';

export const getAllAdvisorsByEnterpriseId = async (
  payload: any,
): Promise<any> => {
  return await ApiInstance.post(
    `/advisor/getAllAdvisorsByEnterpriseId`,
    payload,
  ).then(res => res.data);
};

export const addNewAdvisor = async (payload: any): Promise<any> => {
  return await ApiInstance.post(`/advisor/createAdvisor`, payload)
    .then(res => res.data)
    .catch(({ response }: any) => response);
};
