import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';

interface ChatListProps {
  data: any[];
}
const useStyles = makeStyles({
  itemText: {
    fontStyle: 'italic !important',
    fontWeight: 500,
    '&::before': {
      content: '"• "',
      display: 'inline-block',
      marginRight: '8px',
    },
  },
});

const ChatList: React.FC<ChatListProps> = ({ data }) => {
  const classes = useStyles();

  return (
    <List component="nav" aria-label="mailbox folders">
      {data.map((item, index) => (
        <React.Fragment key={index}>
          <ListItem sx={{ p: 0 }}>
            <ListItemText
              classes={{ primary: classes.itemText }}
              primary={item}
            />
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  );
};

export default React.memo(ChatList);
