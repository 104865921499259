import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Grid } from '@mui/material';

import { actions as enterpriseActions } from '@features/Enterprises/slice';
import { actions as dataProvidersAction } from '@features/DataProviders/slice';
import { enterprisesList } from '@features/Enterprises/selector';
import { dataProviders } from '@features/DataProviders/selector';

import { SubHeading } from '@components/Typography';
import DataProviderCard from '@components/Card/DataProvider';
import AddRoundIcon from '@icons/AddRoundIcon';

import DataCard from '../DataCard';
import AddModal from '../AddModal';
import { ContentWrapper } from '../styles';

interface PageProps {
  onSelect: (event: Event) => void;
}

const SUBSCRIPTION_MODULES = [
  {
    id: 1,
    value: 'fund_specific',
    label: 'Fund Specific',
  },
  {
    id: 2,
    value: 'market_research',
    label: 'Market Research',
  },
  {
    id: 3,
    value: 'educational',
    label: 'Educational',
  },
  {
    id: 4,
    value: 'client_communication',
    label: 'Client Communication',
  },
  {
    id: 5,
    value: 'client_personalization',
    label: 'Client Personalization',
  },
];

function Enterprises({ onSelect }: PageProps): React.JSX.Element {
  const dispatch = useDispatch();
  const userProviderList = useSelector(dataProviders);
  const enterprisesData = useSelector(enterprisesList);
  const [open, handleModalState] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(enterpriseActions.getEnterprisesRequest());
  }, []);

  useEffect(() => {
    setLoading(enterprisesData?.loading);
  }, [enterprisesData?.loading]);

  const handlePopupOpen = () => {
    dispatch(dataProvidersAction.getUserProvidersRequest());
    handleModalState(!open);
  };

  const handleCloseModal = () => {
    handleModalState(false);
  };

  const handleSubmit = (formData: any) => {
    dispatch(
      enterpriseActions.addEnterprisesRequest({
        ...formData,
      }),
    );
  };

  return (
    <Grid item sm={12}>
      <ContentWrapper>
        {loading ? (
          <DataProviderCard
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid sx={{ marginBottom: '24px' }}>
              <CircularProgress />
            </Grid>
          </DataProviderCard>
        ) : (
          enterprisesData.data.map((data: any, index) => (
            <DataCard
              key={index}
              data={data}
              onClick={() => onSelect(data)}
              dataProviderList={userProviderList.data}
              type="enterprise"
            />
          ))
        )}

        <DataProviderCard alignItems="center" onClick={handlePopupOpen}>
          <Grid sx={{ marginBottom: '24px' }}>
            <AddRoundIcon />
          </Grid>
          <SubHeading>Add Enterprise</SubHeading>
        </DataProviderCard>
      </ContentWrapper>

      {open && (
        <AddModal
          title={'Enterprise'}
          loading={userProviderList.loading}
          data={userProviderList.data}
          onClose={handleCloseModal}
          subscriptionModules={SUBSCRIPTION_MODULES}
          handleSubmit={handleSubmit}
        />
      )}
    </Grid>
  );
}

export default Enterprises;
