import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: any = {
  streaming: false,
  loading: false,
  migrationStreamData: [],
  statsLoading: {
    from: false,
    to: false,
  },
  statsList: {
    from: [],
    to: [],
  },
};

const vectorMigrationSlice = createSlice({
  name: 'vectormigration',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getStatsList(
      state,
      action: PayloadAction<{
        formName: string;
      }>,
    ) {
      return {
        ...state,
        statsLoading: {
          ...state.statsLoading,
          [action.payload.formName]: true,
        },
      };
    },
    loadStatsListFetch(state, action: PayloadAction<any>) {
      return {
        ...state,
        statsLoading: {
          ...state.statsLoading,
          [action.payload]: true,
        },
      };
    },
    loadStatsListSuccess(
      state: any,
      action: PayloadAction<{
        data: Array<any>;
        statsLoading: boolean;
        formName: string;
      }>,
    ) {
      return {
        ...state,
        statsLoading: {
          ...state.statsLoading,
          [action.payload.formName]: false,
        },
        statsList: {
          ...state.statsList,
          [action.payload.formName]: action.payload.data,
        },
      };
    },
    restMigration(state: any) {
      return {
        ...state,
        streaming: false,
        loading: false,
        statsLoading: {
          from: false,
          to: false,
        },
        statsList: {
          from: [],
          to: [],
        },
        migrationStreamData: [],
      };
    },

    pushToChatHistory(state, action: PayloadAction<any>) {
      const CurrentChatHistory = state.migrationStreamData;
      state.loading = false;
      if (action.payload && typeof action.payload === 'object') {
        CurrentChatHistory.push(action.payload);
      }
      console.log(CurrentChatHistory, 'CurrentChatHistory');
      state = {
        ...state,
        migrationStreamData: CurrentChatHistory,
      };
    },

    /* --- CHAT SEARCH QUERY FETCH --- */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    searchQueryRequest(state, action: PayloadAction<any>) {
      state.processingQuery = true;
    },
    searchQueryFetch(state) {
      state.processingQuery = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    searchQuerySuccess(state, action: PayloadAction<any>) {
      state.processingQuery = false;
    },
    isQueryResponseStreaming(state, action: PayloadAction<boolean>) {
      state.streaming = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = vectorMigrationSlice;
