import React from 'react';

function QA(): React.JSX.Element {
  return (
    <svg
      width="64"
      height="54"
      viewBox="0 0 64 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.56055"
        y="3.17078"
        width="61.44"
        height="35.84"
        rx="2.56"
        fill="#FFD28C"
      />
      <path
        d="M14.4004 50.8629V37.7303H30.6232L16.5855 51.768C15.7791 52.5744 14.4004 52.0033 14.4004 50.8629Z"
        fill="#FFD28C"
      />
      <rect
        y="0.610718"
        width="61.44"
        height="35.84"
        rx="2.56"
        fill="#FFF4E3"
      />
      <path
        d="M27.5057 22.5891V22.282C27.5134 20.8571 27.6451 19.7235 27.9008 18.8812C28.1642 18.0388 28.5361 17.3618 29.0164 16.8501C29.4968 16.3305 30.0817 15.8542 30.7712 15.4213C31.2438 15.1221 31.6661 14.7954 32.038 14.4412C32.4176 14.079 32.7159 13.6775 32.9328 13.2367C33.1497 12.788 33.2582 12.2881 33.2582 11.737C33.2582 11.1151 33.1149 10.5758 32.8282 10.1192C32.5416 9.66262 32.1542 9.30836 31.6661 9.05644C31.1857 8.80453 30.6473 8.67857 30.0507 8.67857C29.5007 8.67857 28.9777 8.80059 28.4818 9.04463C27.9938 9.2808 27.587 9.64293 27.2616 10.131C26.944 10.6112 26.7658 11.2213 26.727 11.9614H22.5898C22.6286 10.4656 22.985 9.21389 23.659 8.20622C24.3408 7.19856 25.2395 6.44281 26.3551 5.93898C27.4785 5.43514 28.7181 5.18323 30.074 5.18323C31.5537 5.18323 32.8553 5.44695 33.9787 5.9744C35.1099 6.50185 35.9892 7.25367 36.6168 8.22984C37.2521 9.19814 37.5697 10.3475 37.5697 11.6779C37.5697 12.5754 37.4264 13.3784 37.1397 14.0869C36.8608 14.7954 36.4618 15.4252 35.9427 15.9763C35.4236 16.5273 34.8077 17.0194 34.0949 17.4523C33.4674 17.846 32.9522 18.2553 32.5493 18.6804C32.1542 19.1055 31.8598 19.6054 31.6661 20.1801C31.4801 20.7469 31.3833 21.4476 31.3756 22.282V22.5891H27.5057ZM29.5278 29.9576C28.8305 29.9576 28.2301 29.7057 27.7265 29.2019C27.2229 28.698 26.9711 28.084 26.9711 27.3597C26.9711 26.6512 27.2229 26.045 27.7265 25.5412C28.2301 25.0374 28.8305 24.7855 29.5278 24.7855C30.2173 24.7855 30.8139 25.0374 31.3174 25.5412C31.8288 26.045 32.0845 26.6512 32.0845 27.3597C32.0845 27.84 31.9644 28.2769 31.7242 28.6705C31.4918 29.0641 31.1819 29.379 30.7945 29.6152C30.4149 29.8435 29.9926 29.9576 29.5278 29.9576Z"
        fill="#FEE3B9"
      />
      <path
        d="M12.8 48.6234V36.3536H28.16L14.9851 49.5285C14.1788 50.3348 12.8 49.7637 12.8 48.6234Z"
        fill="#FFF4E3"
      />
      <path
        d="M28.7857 23.8692V23.5621C28.7934 22.1372 28.9251 21.0036 29.1808 20.1613C29.4442 19.3189 29.8161 18.6419 30.2965 18.1302C30.7768 17.6106 31.3617 17.1343 32.0513 16.7014C32.5239 16.4022 32.9461 16.0755 33.318 15.7212C33.6976 15.3591 33.9959 14.9576 34.2128 14.5168C34.4298 14.068 34.5382 13.5681 34.5382 13.0171C34.5382 12.3952 34.3949 11.8559 34.1082 11.3993C33.8216 10.9427 33.4342 10.5884 32.9461 10.3365C32.4658 10.0846 31.9273 9.95866 31.3308 9.95866C30.7807 9.95866 30.2577 10.0807 29.7619 10.3247C29.2738 10.5609 28.867 10.923 28.5416 11.4111C28.224 11.8913 28.0458 12.5014 28.0071 13.2414H23.8699C23.9086 11.7457 24.265 10.494 24.939 9.48632C25.6208 8.47865 26.5195 7.7229 27.6352 7.21907C28.7586 6.71523 29.9982 6.46332 31.354 6.46332C32.8338 6.46332 34.1354 6.72704 35.2588 7.25449C36.3899 7.78194 37.2692 8.53376 37.8968 9.50993C38.5321 10.4782 38.8497 11.6276 38.8497 12.958C38.8497 13.8555 38.7064 14.6585 38.4198 15.367C38.1408 16.0755 37.7418 16.7053 37.2228 17.2564C36.7037 17.8074 36.0877 18.2994 35.375 18.7324C34.7474 19.126 34.2322 19.5354 33.8293 19.9605C33.4342 20.3856 33.1398 20.8855 32.9461 21.4602C32.7602 22.027 32.6633 22.7277 32.6556 23.5621V23.8692H28.7857ZM30.8078 31.2377C30.1105 31.2377 29.5101 30.9858 29.0065 30.482C28.5029 29.9781 28.2511 29.3641 28.2511 28.6398C28.2511 27.9313 28.5029 27.3251 29.0065 26.8213C29.5101 26.3175 30.1105 26.0656 30.8078 26.0656C31.4973 26.0656 32.0939 26.3175 32.5975 26.8213C33.1088 27.3251 33.3645 27.9313 33.3645 28.6398C33.3645 29.12 33.2444 29.557 33.0042 29.9506C32.7718 30.3442 32.4619 30.6591 32.0745 30.8953C31.6949 31.1236 31.2726 31.2377 30.8078 31.2377Z"
        fill="#F58824"
      />
    </svg>
  );
}

const MemoNoData = React.memo(QA);
export default MemoNoData;
