import { Grid, IconButton } from '@mui/material';
import styled, { css } from 'styled-components';
import { IndicatorProps, BarProps } from './types';

const AnimationName = 'ease';
const AnimationTime = '0.5s';

const BarWidth = '4px';
const BarHeight = '15px';

export const IndicatorContainer = styled(Grid)<IndicatorProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 99;
  width: 25px;
  height: 34px;
  margin: auto auto;
  padding: 2px 0;

  ${({ placement }) =>
    placement === 'left'
      ? css`
          right: -25px;
        `
      : placement === 'right' &&
        css`
          left: -25px;
        `}
`;

export const ToggleButton = styled(IconButton)<BarProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 !important;
  font-size: 20px !important;
  border-radius: 4px !important;
  background: none !important;
  border: 0 !important;

  transition:
    margin 250ms ${AnimationName} ${AnimationTime},
    width 250ms ${AnimationName} ${AnimationTime},
    right ${AnimationName} ${AnimationTime},
    transform 250ms ${AnimationName} ${AnimationTime} !important;

  ${({ isOpen, placement }) =>
    placement === 'left'
      ? css`
          &:hover {
            .up-bar {
              transform: ${isOpen
                ? 'translateY(0.15rem) rotate(15deg) translateZ(0px)'
                : ''};
            }
            .down-bar {
              transform: ${isOpen
                ? 'translateY(-0.15rem) rotate(-15deg) translateZ(0px)'
                : ''};
            }
          }
        `
      : placement === 'right' &&
        css`
          &:hover {
            .up-bar {
              transform: ${isOpen
                ? 'translateY(0.15rem) rotate(-15deg) translateZ(0px)'
                : 'translateY(0.15rem) rotate(15deg) translateZ(0px)'};
            }
            .down-bar {
              transform: ${isOpen
                ? 'translateY(-0.15rem) rotate(15deg) translateZ(0px)'
                : ''};
            }
          }
        `}
`;

export const UpBar = styled(Grid)<BarProps>`
  width: ${BarWidth};
  height: ${BarHeight};
  border-radius: 50px;
  background-color: ${props => props.theme.main};
  transform: translateY(0.15rem) translateZ(0px);
  transition: transform ${AnimationName} ${AnimationTime} !important;

  ${({ isOpen, placement }) =>
    placement === 'left'
      ? css`
          transform: ${isOpen
            ? ''
            : 'translateY(0.15rem) rotate(-15deg) translateZ(0px)'};
        `
      : placement === 'right' &&
        css`
          transform: ${isOpen
            ? ''
            : 'translateY(0.15rem) rotate(15deg) translateZ(0px)'};
        `}
`;

export const DownBar = styled(Grid)<BarProps>`
  width: ${BarWidth};
  height: ${BarHeight};
  border-radius: 50px;
  background-color: ${props => props.theme.main};
  transform: translateY(-0.15rem) translateZ(0px);
  transition: transform ${AnimationName} ${AnimationTime} !important;

  ${({ isOpen, placement }) =>
    placement === 'left'
      ? css`
          transform: ${isOpen
            ? ''
            : 'translateY(-0.15rem) rotate(15deg) translateZ(0px)'};
        `
      : placement === 'right' &&
        css`
          transform: ${isOpen
            ? ''
            : 'translateY(-0.15rem) rotate(-15deg) translateZ(0px)'};
        `}
`;
