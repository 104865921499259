import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Grid, Stack, useTheme } from '@mui/material';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import CloseIcon from '@mui/icons-material/Close';
import TotalClients from '@icons/TotalClients';
import Disconnect from '@icons/Disconnect';
import ArrowsClockwise from '@icons/ArrowsClockwise';
import { PrimaryButton, SecondaryButton } from '@components/Button';
import CustomChip from '@components/Chip/CustomChip';
import {
  GreyLabelText,
  DateHeader,
  PageHeading,
  SubHeading,
} from '@components/Typography';
import { getDateTime } from '@common/dateTime';
import {
  CustomButton,
  ChipWrapper,
  CrmLogo,
  DetailsContainer,
  DetailsDataCardContainer,
  DetailsDrawerContainer,
  LogoContainer,
  StyledFooterContainer,
  StyledTextDiv1,
  StyledTextDiv2,
} from './styles';

interface data {
  logo: React.ReactElement | string;
  title: string;
  value: number | string;
}
interface DetailsDrawerProps {
  syncClient: (state: boolean) => void;
  handleDrawerClose: () => void;
  connectionData: any;
  handleDisconnect: () => void;
}

function DetailsDrawer({
  syncClient,
  connectionData,
  handleDisconnect,
  handleDrawerClose,
}: DetailsDrawerProps): React.JSX.Element {
  const theme = useTheme();
  const navigate = useNavigate();

  const accessClient = () => {
    navigate(`/clients`);
  };

  const downloadCsv = (file_name: string, url: string) => {
    console.log('downloadCsv...', url);
    const anchor = document.createElement('a');
    anchor.href = s3Object?.success?.file_url;
    anchor.download = file_name;
    anchor.click();
  };

  const s3Object = JSON.parse(connectionData.s3_object);

  if (s3Object) {
    console.log('s3_object : ', s3Object);
  }

  const accordionCardData: data[] = [
    {
      logo: <TotalClients />,
      title: 'Total Clients',
      value: connectionData?.totalClients,
    },
  ];

  return (
    <DetailsDrawerContainer
      container
      p={3}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Grid container>
        <Grid container p={2}>
          <Grid item sm={4}>
            <CrmLogo
              src={require(`@icons/${connectionData?.icon}`)}
              alt={`${connectionData?.crmName} Icon`}
              style={{ height: '80px' }}
            />
          </Grid>
          <Grid
            item
            sm={6}
            container
            flexDirection={'column'}
            justifyContent={'space-evenly'}
          >
            <SubHeading>{connectionData?.crmName}</SubHeading>
            <DateHeader
              sx={{ padding: ' 0 0 5px' }}
            >{`Last updated: ${getDateTime(
              connectionData?.updateAt,
            )}`}</DateHeader>
            <ChipWrapper sx={{ padding: 0 }}>
              {['CONNECTED', 'SYNCED'].includes(connectionData?.status) && (
                <CustomChip
                  type={'success'}
                  text={'Connected'}
                  sx={{ padding: '5px 10px' }}
                />
              )}
              {['SYNCING'].includes(connectionData?.status) && (
                <p>Syncing...</p>
              )}
            </ChipWrapper>
          </Grid>

          <Grid
            container
            sm={2}
            alignItems={'flex-start'}
            justifyContent={'flex-end'}
          >
            <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleDrawerClose} />
          </Grid>
        </Grid>

        {accordionCardData.map((item, index) => (
          <DetailsDataCardContainer
            container
            key={index}
            p={2}
            mb={3}
            alignItems={'center'}
            borderColor={theme.palette.primary['borderColor']}
          >
            <LogoContainer>{item?.logo}</LogoContainer>
            <DetailsContainer>
              <GreyLabelText>{item?.title}</GreyLabelText>
              <PageHeading>{item?.value}</PageHeading>
            </DetailsContainer>
          </DetailsDataCardContainer>
        ))}

        <CustomButton
          width={'100%'}
          onClick={accessClient}
          loading={connectionData.loading}
          borderColor={theme.palette.primary['borderColor']}
        >
          View All Clients
        </CustomButton>
        <Stack sx={{ width: '100%' }} spacing={2}>
          {s3Object?.alreadyExists?.length > 0 && (
            <Alert
              variant="outlined"
              severity="warning"
              onClose={() => {}}
              style={{ marginTop: '20px' }}
            >
              Client Already Exists: {s3Object?.alreadyExists.join()}
            </Alert>
          )}
          {s3Object?.success?.file_url && (
            <Alert
              variant="outlined"
              severity="error"
              style={{ marginTop: '20px' }}
              action={
                <CustomButton
                  variant={'text'}
                  color={'error'}
                  loading={connectionData.loading}
                  onClick={() =>
                    downloadCsv(
                      s3Object?.success?.file_name,
                      s3Object?.success?.file_url,
                    )
                  }
                  endIcon={<FilePresentIcon />}
                  style={{
                    width: '30px',
                    alignItems: 'self-end',
                    justifyContent: 'flex-end',
                    minWidth: '36px',
                  }}
                />
              }
            >
              Failed To Sync Clients
            </Alert>
          )}
        </Stack>
      </Grid>

      <Grid container>
        <StyledFooterContainer>
          <StyledTextDiv1>Still need help?</StyledTextDiv1>
          <StyledTextDiv2>Conatct Us</StyledTextDiv2>
        </StyledFooterContainer>
        <Grid
          container
          pt={3}
          columnGap={2}
          sx={{
            borderTop: `1px solid ${theme.palette.primary['borderColor']}`,
          }}
        >
          <SecondaryButton
            onClick={handleDisconnect}
            startIcon={<Disconnect />}
            width="233px"
            style={{
              textTransform: 'none',
              color: 'red',
            }}
          >
            Disconnect Account
          </SecondaryButton>
          <PrimaryButton
            startIcon={!connectionData.loading && <ArrowsClockwise />}
            onClick={() => syncClient(true)}
            loading={connectionData.loading}
            width="233px"
            style={{ textTransform: 'none' }}
          >
            Re-sync Clients
          </PrimaryButton>
        </Grid>
      </Grid>
    </DetailsDrawerContainer>
  );
}

export default DetailsDrawer;
