/* eslint-disable react/prop-types */

import React from 'react';

function TotalAccounts(): React.JSX.Element {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="5.50175" fill="#E1F6F1" />
      <path
        d="M44.3509 24.1403H19.6492C19.0291 24.1403 18.5264 24.643 18.5264 25.2631V43.228C18.5264 43.8481 19.0291 44.3508 19.6492 44.3508H44.3509C44.971 44.3508 45.4737 43.8481 45.4737 43.228V25.2631C45.4737 24.643 44.971 24.1403 44.3509 24.1403Z"
        stroke="#00B186"
        strokeWidth="2.69474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.6141 24.1403V21.8947C37.6141 21.2991 37.3775 20.728 36.9563 20.3068C36.5352 19.8857 35.964 19.6491 35.3684 19.6491H28.6316C28.036 19.6491 27.4648 19.8857 27.0437 20.3068C26.6226 20.728 26.386 21.2991 26.386 21.8947V24.1403"
        stroke="#00B186"
        strokeWidth="2.75088"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5264 36.4913H45.4737"
        stroke="#00B186"
        strokeWidth="2.75088"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoConnectIcon = React.memo(TotalAccounts);
export default MemoConnectIcon;
