import React from 'react';
import CustomTooltip from '@components/Tooltip';
import { SidebarProps } from './types';
import { IndicatorContainer, ToggleButton, UpBar, DownBar } from './styles';

const SidebarIndicator: React.FC<SidebarProps> = ({
  sx = {},
  size,
  isOpen,
  placement,
  tooltip,
  onClick,
}) => {
  const RenderButton = (
    <ToggleButton
      isOpen={isOpen}
      placement={placement}
      onClick={onClick}
      size={size}
    >
      <UpBar
        className={'up-bar'}
        item
        sm={6}
        placement={placement}
        isOpen={isOpen}
      />
      <DownBar
        className={'down-bar'}
        item
        sm={6}
        placement={placement}
        isOpen={isOpen}
      />
    </ToggleButton>
  );
  return (
    <IndicatorContainer
      sx={sx}
      width={'24px'}
      height={'35px'}
      container
      placement={placement}
      justifyContent={'center'}
      alignItems={'center'}
    >
      {tooltip && tooltip.enable && tooltip.text ? (
        <CustomTooltip placement={tooltip.placement} title={tooltip.text}>
          {RenderButton}
        </CustomTooltip>
      ) : (
        RenderButton
      )}
    </IndicatorContainer>
  );
};

export default SidebarIndicator;
