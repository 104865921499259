import { useQuery, UseQueryResult } from 'react-query';
import ApiInstance from 'utils/instances';
import { BackendChatInitResponse } from 'app/features/Chat/types';
import { useDispatch } from 'react-redux';
import { actions } from 'app/features/Chat/slice';
/**
 * Using ReactQuery, get the /init endpoint
 */

const useInit = (): UseQueryResult<BackendChatInitResponse> => {
  const dispatch = useDispatch();
  return useQuery<BackendChatInitResponse>('init', async () => {
    dispatch(actions.setInitialChatHistoryLoading());
    const response = await ApiInstance.post(`/chat/init`, {}, {}).then(
      res => res.data,
    );
    dispatch(
      actions.storeChatToken({
        initToken: response.user,
        isValid: response.isValid,
      }),
    );
    if (response) {
      console.log('Initial chat history:', response.default_conversation);
      dispatch(actions.storeInitialChatHistory(response));
    } else {
      throw '-- Failed to response --';
    }

    return response;
  });
};

export default useInit;
