import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import moment from 'moment';
import { isEmpty } from 'utils/common/string';
import TestResultListSkeleton from './TestResultListSkeleton';
import BaseLineSwitch from './BaseLineSwitch';
import Row from './Row';
import { StyledTableRow, StyledTableCell, fontStyles } from './styles';
import { useRESTQuery } from 'utils/hooks';
import { COLUMNS_INITIAL, COLUMNS_LOADING } from './constants';
import { actions as globalActions } from 'app/features/Global/slice';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import SettingsIcon from '@mui/icons-material/Settings';
import { getDateTime } from 'utils/common/dateTime';
import { ToastTypes } from 'app/components/ToastAlert/types';
import { getSortedData } from 'utils/common/array';
import { CSVLink } from 'react-csv';
import { PrimaryButton } from '@components/Button';
import CustomTooltip from '@components/Tooltip';

function TestResultList(): React.JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [columns, setColumns] = useState([
    ...COLUMNS_INITIAL,
    ...COLUMNS_LOADING,
  ]);
  const [rows, setRows] = useState<any>([]);
  const [queryData, setQueryData] = useState<Array<any>>([]);
  const [responseData, setResponseData] = useState<Array<any>>([]);
  const [csvData, setCsvData] = useState<Array<any>>([]);
  const [isQueryTestRun, setIsQueryTestRun] = React.useState(false);
  const [baseLineActive, setBaseLineActive] = React.useState<any>('');
  const [runTimeIds, setRunTimeIds] = React.useState<{ [key: string]: string }>(
    {},
  );
  const [isEnableAddQuery, setIsEnableAddQuery] = React.useState<boolean>(true);

  const handleToastMsg = (message: string, type: ToastTypes = 'success') => {
    if (message === 'WENT_WRONG') {
      message = 'Something went wrong.';
      type = 'error';
    }
    dispatch(
      globalActions.displayToast({
        duration: 3000,
        toastType: type,
        toastMessage: message,
      }),
    );
  };

  useEffect(() => {
    if (!isEmpty(queryData) && !isEmpty(responseData)) {
      const responsesColumns: Array<any> = [];
      const queryRowsTemp = JSON.parse(JSON.stringify(queryData));
      let columnsTemp: Array<any> = [];

      if (!isEmpty(runTimeIds) && baseLineActive) {
        const rtis = Object.keys(runTimeIds);
        const n = rtis.length;
        let i = rtis.findIndex(v => v === baseLineActive);
        while (i < n) {
          const tmp = rtis[i];
          rtis.splice(i, 1);
          rtis.unshift(tmp);
          i++;
        }

        rtis
          .filter(k => k)
          .forEach(rt => {
            const runTimeId = runTimeIds[rt] ? runTimeIds[rt] : '';
            responsesColumns.push({
              label: getDateTime(rt),
              value: rt,
              baseLineColumn: runTimeId ? true : false,
              baseLineActive: baseLineActive === rt ? true : false,
              id: runTimeId,
            });
          });
        columnsTemp = [...COLUMNS_INITIAL, ...responsesColumns];
        setColumns(columnsTemp);
      }
      for (const key in responseData) {
        queryRowsTemp.forEach((row: any) => {
          if (!row.results) {
            row['results'] = [];
          }
          const matchedResponse = responseData[key].find(
            (x: any) => x.queryId === row._id,
          );
          if (matchedResponse) {
            row[`${key}`] = matchedResponse.response;
            row['results'][`${key}`] = {
              response: matchedResponse.response,
              runTime: matchedResponse.runTime,
              resultTime: key,
              latency: matchedResponse.latency || null,
              _id: matchedResponse._id,
              matchBaseline: matchedResponse?.executed_successfully,
            };
          } else {
            row[`${key}`] = ``;
            row['results'][`${key}`] = {
              response: ``,
              runTime: ``,
              resultTime: key,
              latency: null,
              _id: '',
            };
          }
        });
      }
      setRows(queryRowsTemp);

      const csvDataTemp: Array<any> = [];
      let csvDataRow: Array<any> = [];
      columnsTemp.map(column => {
        csvDataRow.push(column.label);
      });
      csvDataTemp.push(csvDataRow);
      queryRowsTemp.map((row: any) => {
        csvDataRow = [];
        columnsTemp.map(column => {
          csvDataRow.push(
            !isEmpty(row[column.value]) ? row[column.value] : 'NA',
          );
        });
        csvDataTemp.push(csvDataRow);
      });
      setCsvData(csvDataTemp);
    }
  }, [queryData, responseData]);

  const onErrorResponse = (error: any) => {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message &&
      typeof error.response.data.message === 'string'
    ) {
      handleToastMsg(error.response.data.message, 'error');
    } else {
      handleToastMsg('WENT_WRONG');
    }
  };

  const { isFetching: isLoadingQueries, isError: isErrorQueries } =
    useRESTQuery(
      ['testResultsListQueries'],
      {
        endpoint: `tests/queries`,
        queryParams: {},
        method: 'GET',
        headers: {
          'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
        },
      },
      {
        retry: false,
        cacheTime: 0,
        // enabled: isFormSubmitted ? true : false,
        onSuccess: res => {
          if (!isEmpty(res) && !isEmpty(res.message)) {
            if (res.message === 'Success') {
              if (res.data && !isEmpty(res.data)) {
                const sortData = getSortedData(
                  res.data,
                  'sortIdx',
                  'asc',
                  true,
                );
                setRows(sortData);
                setQueryData(sortData);
              } else {
                //remove dummy date-colum if response is empty
                setColumns([...COLUMNS_INITIAL]);
              }
            } else {
              handleToastMsg(res.message, 'error');
            }
          } else {
            handleToastMsg('WENT_WRONG');
          }
        },
        onError: error => {
          onErrorResponse(error);
        },
      },
    );

  const { isSuccess: isSuccessResponses } = useRESTQuery(
    ['testResultsListResponses'],
    {
      endpoint: `tests/responses`,
      queryParams: {},
      method: 'GET',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      retry: false,
      cacheTime: 0,
      onSuccess: async res => {
        if (!isEmpty(res) && !isEmpty(res.message)) {
          if (res.message === 'Success') {
            setBaseLineActive(res.baselineTime || true);
            if (!isEmpty(res.runTimeIds)) {
              setRunTimeIds(res.runTimeIds);
            }
            setIsEnableAddQuery(res.addQueryEnabled);
            if (!isEmpty(res.data)) {
              setResponseData(res.data);
            }
          } else {
            handleToastMsg(res.message, 'error');
          }
        } else {
          handleToastMsg('WENT_WRONG');
        }
      },
      onError: error => {
        onErrorResponse(error);
      },
    },
  );

  useRESTQuery(
    ['testResultListRund', isQueryTestRun],
    {
      endpoint: `tests/run`,
      method: 'POST',
      headers: {
        'X-Tifin-Ai-Auth': localStorage.getItem('x-tifin-ai-token'),
      },
    },
    {
      retry: false,
      cacheTime: 0,
      enabled: isQueryTestRun,
      onSuccess: res => {
        if (!isEmpty(res) && !isEmpty(res.message)) {
          if (res.message === 'Success') {
            handleToastMsg(res.data);
          } else {
            handleToastMsg(res.message, 'error');
          }
        } else {
          handleToastMsg('WENT_WRONG');
        }
        onCancelRun();
      },
      onError: error => {
        onErrorResponse(error);
        onCancelRun();
      },
    },
  );

  const onClickQueries = () => {
    navigate('/test/queries');
  };

  const onClickRun = () => {
    setIsQueryTestRun(true);
  };

  const onCancelRun = () => {
    setIsQueryTestRun(false);
  };

  const escapeSpecialChars = str => {
    return '"' + str.replace(/"/g, '""') + '"';
  };

  const onClickDownload = () => {
    const csvRows = csvData.map(row => row.map(escapeSpecialChars).join(','));
    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `TIFIN-AI_Test_Results-${moment().format(
      'DD-MM-YYYY',
    )}.csv`;
    link.click();
  };

  const onRefresh = () => {
    queryClient.invalidateQueries({ queryKey: ['testResultsListResponses'] });
    queryClient.invalidateQueries({ queryKey: ['testResultsListQueries'] });

    setColumns([...COLUMNS_INITIAL, ...COLUMNS_LOADING]);
    setRows([]);
    setQueryData([]);
    setResponseData([]);
    setCsvData([]);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <Box>
          <Box
            sx={{
              gap: 1.5,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'right',
              marginBottom: '20px',
            }}
          >
            <PrimaryButton
              startIcon={<RefreshOutlinedIcon />}
              sx={{
                visibility: !isEmpty(rows) ? 'visible' : 'hidden',
              }}
              onClick={() => onRefresh()}
              disabled={isLoadingQueries || !isSuccessResponses}
            >
              {'Refresh'}
            </PrimaryButton>
            <CustomTooltip
              placement={'bottom'}
              title={
                !isEnableAddQuery
                  ? 'Test run is in progress, Please check after sometime.'
                  : ''
              }
            >
              <div>
                <PrimaryButton
                  startIcon={<AccessAlarmsIcon />}
                  sx={{
                    visibility: !isEmpty(rows) ? 'visible' : 'hidden',
                  }}
                  onClick={() => onClickRun()}
                  disabled={
                    isLoadingQueries ||
                    !isSuccessResponses /* || !isEnableAddQuery */
                  }
                >
                  {'Run Now'}
                </PrimaryButton>
              </div>
            </CustomTooltip>

            <PrimaryButton
              startIcon={<DownloadIcon />}
              sx={{
                visibility: !isEmpty(rows) ? 'visible' : 'hidden',
              }}
              onClick={() => onClickDownload()}
              disabled={isLoadingQueries || !isSuccessResponses}
            >
              {'Download'}
            </PrimaryButton>
            <CustomTooltip
              placement={'bottom'}
              title={
                !isEnableAddQuery
                  ? "Test run is already initiated, you can't add/delete queries now"
                  : ''
              }
            >
              <div>
                <PrimaryButton
                  startIcon={<SettingsIcon />}
                  onClick={() => onClickQueries()}
                  disabled={
                    isLoadingQueries || !isSuccessResponses || !isEnableAddQuery
                  }
                >
                  {'Queries'}
                </PrimaryButton>
              </div>
            </CustomTooltip>
          </Box>
          <CSVLink
            data={csvData}
            filename={`TIFIN-AI_Test_Results-${moment().format(
              'DD-MM-YYYY',
            )}.csv`}
            id={'idDownloadCsvTestResults'}
            style={{
              visibility: 'hidden',
              opacity: 0,
              width: 0,
              height: 0,
            }}
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            height: '100%',
            p: '0px 0 0 0px',
          }}
        >
          {(isLoadingQueries || !isSuccessResponses) && (
            <TestResultListSkeleton />
          )}

          {!isLoadingQueries && !isErrorQueries && isSuccessResponses && (
            <TableContainer
              component={Paper}
              sx={{
                maxHeight: 'calc(100vh - 124px)', //if sticky-header requred
                boxShadow: 'none',
                background: '#fff',
              }}
            >
              <Table
                sx={{ width: '100%' }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column, columnIndex) => {
                      return (
                        <StyledTableCell
                          align="left"
                          key={`th-${columnIndex}-${column.value}`}
                        >
                          {column.label}
                          {column.baseLineColumn && (
                            <React.Fragment>
                              <br />
                              <BaseLineSwitch
                                index={columnIndex}
                                runId={column.id}
                                defaultValue={
                                  column.value === baseLineActive ? true : false
                                }
                                onSuccess={onRefresh}
                                handleToastMsg={handleToastMsg}
                              />
                            </React.Fragment>
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((runTime: any, rowIndex: any) => {
                    return (
                      <Row
                        key={rowIndex}
                        index={rowIndex}
                        row={runTime}
                        columns={columns}
                        baseLineActive={baseLineActive}
                      />
                    );
                  })}
                </TableBody>
                {isEmpty(rows) && (
                  <TableFooter>
                    <StyledTableRow key={'test-results-table-foot'}>
                      <StyledTableCell
                        colSpan={columns.length + 1}
                        align="center"
                        sx={{
                          '&.MuiTableCell-root': {
                            color: '#222',
                          },
                        }}
                      >
                        <Typography
                          component={'div'}
                          sx={{
                            color: '#fff',
                            ...fontStyles,
                          }}
                        >{`Test results not found`}</Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableFooter>
                )}
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default TestResultList;
