/**
 * This implements a common extended table, with rows, columns, etc.
 * It has its own types and props. It uses MUIDataTable. Columns and rows definitions
 * follow MUIDataTable's.
 */

import React, { useMemo } from 'react';
import { TableCell } from '@mui/material';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { ExtTableRow } from './types';
import './style.css';
import { ExtTableBasicProps } from './ExtTableBasic';

export default function ExtTableBasic_MUI({
  title,
  columns,
  rows,
  cellRenderer,
  show_column_headers = true,
  show_select_column = false,
  isRowSelected = () => false,
  setRowSelected = () => {},
  elevation = 0,
}: ExtTableBasicProps): JSX.Element {
  let final_columns = columns;
  if (show_select_column) {
    final_columns = [
      {
        key: '_checkbox',
        label: '',
        type: 'custom',
        width: 15,
        cellRenderer: (row: ExtTableRow) => {
          return (
            <div className="styled-checkbox">
              <input
                className="checkbox"
                type="checkbox"
                checked={isRowSelected(row)}
                onChange={e => setRowSelected(row, e.target.checked)}
              />
            </div>
          );
        },
      },
      ...final_columns,
    ];
  }

  const mui_columns: MUIDataTableColumnDef[] = useMemo(
    () =>
      final_columns.map(column => ({
        name: column.label,
        options: {
          filter: false,
          sort: false,
          customHeadRender: columnMeta => {
            const headerStyle = {
              width: column.width,
              minWidth: column.minWidth,
              maxWidth: column.maxWidth,
              color: column.color,
              fontSize: column.fontSize,
            };
            if (!show_column_headers) {
              return <th key={columnMeta.index} style={headerStyle} />;
            }
            return (
              <TableCell key={columnMeta.index} style={headerStyle}>
                {column.headerRenderer
                  ? column.headerRenderer(column)
                  : column.label}
              </TableCell>
            );
          },
          customBodyRender:
            cellRenderer || column.cellRenderer
              ? (_value, tableMeta) => {
                  const row = rows[tableMeta.rowIndex];
                  if (column.cellRenderer) {
                    return column.cellRenderer(row, column);
                  }
                  return cellRenderer ? cellRenderer(row, column) : undefined;
                }
              : undefined,
        },
      })),
    [columns],
  );

  const mui_rows = useMemo(
    () =>
      rows.map(row => {
        const mui_row = {};
        columns.forEach(column => {
          mui_row[column.label] = row[column.key];
        });
        return mui_row;
      }),
    [columns, rows],
  );

  return (
    <>
      <MUIDataTable
        title={title}
        columns={mui_columns}
        data={mui_rows}
        options={{
          download: false,
          print: false,
          viewColumns: false,
          expandableRows: false,
          filter: false,
          search: false,
          jumpToPage: false,
          pagination: false,
          selectableRows: 'none',
          selectableRowsHeader: false,
          selectableRowsHideCheckboxes: true,
          elevation,
        }}
      />
    </>
  );
}
