import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonContainer } from '../styles';
import { PrimaryButton, SecondaryButton } from '@components/Button';
import { BootstrapDialog } from './style';
import { useDispatch } from 'react-redux';
import { actions as globalActions } from 'app/features/Global/slice';
import { actions as fundActions } from '@features/Funds/slice';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from '@mui/material';
import AddFundsForm from './AddFundsForm';
import { bulkUpload, checkFundName } from '@features/Funds/api';
import { useAppContext } from 'app/AppContext';

interface AddFundsModalProps {
  default_fund_name: string;
  onHide: () => void;
}

type FormData = {
  name: string;
  manager_name: string;
  family_name: string;
  style: string;
  focus: string;
  geography: string;
  status: string;
};

const AddFundsModal: React.FC<AddFundsModalProps> = ({
  default_fund_name,
  onHide,
}) => {
  const { setFundSearchMode, setModal } = useAppContext();
  const [, setUploadedFiles] = React.useState<File[]>([]);
  const [, setContinueClicked] = React.useState(false);
  const [, setCreatedFundsCount] = React.useState(0);
  const initialFormData = {
    name: default_fund_name,
    manager_name: '',
    family_name: '',
    style: '',
    focus: '',
    geography: '',
    status: '',
  };
  const [formData, setFormData] = React.useState<FormData>(initialFormData);
  const isFormDataComplete = () => {
    return Object.values(formData).every(value => value.trim() !== '');
  };
  const [checkedStates, setCheckedStates] = React.useState({
    dataSegregated: false,
  });

  const handleChange = event => {
    setCheckedStates({
      ...checkedStates,
      [event.target.name]: event.target.checked,
    });
  };
  const [selectedFund, setSelectedFund] = React.useState({
    fund_name: '',
    internal_fund_id: '',
  });
  const [disclosureState, setDisclosureState] = React.useState(false);

  const [errorMessage, setErroMessage] = React.useState(false);
  const [showConfirmMessage, setShowConfirmMessage] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const handleFinalConfirm = () => {
    onFinalConfirmed();
  };
  const dispatch = useDispatch();
  const handleConfirmMEssage = () => {
    setDisclosureState(false);

    setShowConfirmMessage(true);
  };
  const handleClose = () => {
    setContinueClicked(false);
    setUploadedFiles([]);
    setShowConfirmMessage(false);
    setFormData(initialFormData);
    setDisclosureState(false);
    onHide();
  };

  const handleDisclosureText = () => {
    checkFundName({ name: formData.name })
      .then(res => {
        if (res.error) {
          setErroMessage(true);
        } else {
          setDisclosureState(true);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const fundId = `${Math.floor(Math.random() * 1000000)}`;

  const dataToSubmit = [
    {
      ...formData,
      fund_id: fundId,
      owner: checkedStates.dataSegregated ? 'ENTERPRISE' : 'GLOBAL',
      created_at: new Date(),
      updated_at: new Date(),
    },
  ];

  const onFinalConfirmed = () => {
    setIsLoading(true);
    bulkUpload(dataToSubmit)
      .then(res => {
        if (res?.created?.list && res.created.list.length > 0) {
          setSelectedFund({
            fund_name: res.created.list[0].name,
            internal_fund_id: res.created.list[0].id,
          });
        }

        const messages: string[] = [];
        if (res.created.total > 0) {
          messages.push(`Funds created successfully`);
          setCreatedFundsCount(res.created.total);
        }

        if (res.invalid.total > 0) {
          messages.push(
            `Funds which are invalid and not created: ${res.invalid.total}`,
          );
        }

        if (res.existing.total > 0) {
          messages.push(`Funds already exists: ${res.existing.total}`);
        }

        if (messages.length > 0) {
          const toastMessage = messages.join(', ');
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'success',
              toastMessage,
            }),
          );
        }
        setFormData(initialFormData);
        handleConfirmMEssage();
        setFundSearchMode('ENTERPRISE');
      })
      .catch(error => {
        if (error.response) {
          if (
            error.response.data &&
            error.response.data.message &&
            typeof error.response.data.message === 'string'
          ) {
            dispatch(
              globalActions.displayToast({
                duration: 3000,
                toastType: 'error',
                toastMessage: error.response.data.message || '',
              }),
            );
          }
        } else {
          console.warn('Unhandled error type', error);
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: 'Error adding fund',
            }),
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onUploadDocumentsForThisFund = () => {
    dispatch(
      fundActions.selectFund({
        fundName: selectedFund?.fund_name,
        fundId: selectedFund?.internal_fund_id,
      }),
    );

    handleClose();
    setModal({
      type: 'ADD_FUND_DOCUMENT',
      mode: 'APPEND_TO_FUND',
      fund_name: selectedFund?.fund_name,
      internal_fund_id: selectedFund?.internal_fund_id,
      document_type: null,
    });
    /*
    navigate('/conversations', {
      state: {
        forcedPrompt: `I would like to add a document for the ${selectedFund?.fundName}`,
      },
    });
    */
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        {disclosureState ? (
          <DialogTitle id="customized-dialog-title">
            {'Data Use Disclosure'}
          </DialogTitle>
        ) : (
          <DialogTitle id="customized-dialog-title">
            {showConfirmMessage
              ? 'Fund Created Successfully'
              : 'Add a New Fund'}
          </DialogTitle>
        )}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 10,
            top: 8,
            color: '#101828',
          }}
        >
          <CloseIcon />
        </IconButton>
        {showConfirmMessage ? (
          <>
            <DialogContent>
              <Typography>
                Do you want to continue to upload documents for the fund you
                created?
              </Typography>
            </DialogContent>

            <DialogActions>
              <ButtonContainer width={'100%'}>
                <SecondaryButton width={'50%'} onClick={handleClose}>
                  cancel
                </SecondaryButton>
                <PrimaryButton
                  width={'50%'}
                  onClick={onUploadDocumentsForThisFund}
                >
                  Continue
                </PrimaryButton>
              </ButtonContainer>
            </DialogActions>
          </>
        ) : (
          <>
            {disclosureState ? (
              <DialogContent>
                <Box>
                  <Typography sx={{ fontSize: '16px', mb: 2 }}>
                    Data from this PPM document will be added to our fund
                    universe to support fund comparison and manager research.
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontWeight: 500,
                          fontSize: '16px',
                        },
                      }}
                      control={
                        <Checkbox
                          name="dataSegregated"
                          checked={checkedStates.dataSegregated}
                          onChange={handleChange}
                        />
                      }
                      label="Keep my data segregated"
                    />
                    <Typography
                      sx={{ fontSize: '14px', mt: -1, ml: 4, color: '#86858B' }}
                    >
                      By choosing this, note that you will not be able to
                      compare this fund with others in our universe.
                    </Typography>
                  </Box>
                </Box>
              </DialogContent>
            ) : (
              <DialogContent>
                <AddFundsForm
                  formData={formData}
                  setFormData={setFormData}
                  errorMessage={errorMessage}
                  setErroMessage={setErroMessage}
                />
              </DialogContent>
            )}
            <DialogActions>
              <ButtonContainer width={'100%'}>
                <SecondaryButton width={'50%'} onClick={handleClose}>
                  Cancel
                </SecondaryButton>

                <PrimaryButton
                  width={'50%'}
                  disabled={!isFormDataComplete() || isLoading}
                  onClick={
                    disclosureState ? handleFinalConfirm : handleDisclosureText
                  }
                >
                  {isLoading ? (
                    <CircularProgress sx={{ color: 'white' }} size={24} />
                  ) : disclosureState ? (
                    'Add Fund'
                  ) : (
                    'Continue'
                  )}
                </PrimaryButton>
              </ButtonContainer>
            </DialogActions>
          </>
        )}
      </BootstrapDialog>
    </React.Fragment>
  );
};
export default AddFundsModal;
