import React, { useEffect, useState } from 'react';
import { Grid, useTheme } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import SearchBox from '@components/SearchBox';
import { TextButton } from '@components/Button';
import { ParagraphBoldText, ParagraphLightText } from '@components/Typography';
import HelixLogo from '@logos/HelixByHLDark.svg';
import DefaultMask from '@images/DefaultMask.svg';
import { TourState } from '@features/Global/types';

import { HelixLogoWrapper, FooterContainer } from '../Layout/styles';
import {
  Container,
  CardWrapper,
  CardMask,
  ContentWrapper,
  HeaderContainer,
} from './styles';

function Dashboard({
  scripts = [],
}: {
  activeScripts?: string;
  scripts: any;
}): React.JSX.Element {
  const theme = useTheme();
  const [forced_query, setForcedQuery] = useState('');

  const getComponentAnimateProps = (scripts: any, componentName: string) => {
    return scripts?.find((k: any) => k.component === componentName);
  };
  const isObjectPresent = (d: any): boolean => d && typeof d === 'object';

  let UserDetails: any = localStorage.getItem('tifin-ai-loggedUser') || null;
  if (UserDetails && JSON.parse(UserDetails)) {
    UserDetails = JSON.parse(UserDetails);
  }

  useEffect(() => {
    const d: any = getRecentStateFromProductTour();
    console.log('d : ', d?.component);
    if (d.queryText && d.component === 'ActiveInputField') {
      setForcedQuery(d.queryText);
    }
    if (d.component === 'SendQueryFromInputField') {
      setForcedQuery('');
    }
  }, [scripts]);

  const onInputSubmit = () => {};

  const getRecentStateFromProductTour = (): TourState => {
    return scripts[scripts.length - 1];
  };

  return (
    <ContentWrapper
      animate={isObjectPresent(getComponentAnimateProps(scripts, 'Welcome'))}
    >
      <Container
        container
        flexDirection={'column'}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
      >
        <HeaderContainer
          container
          alignItems={'flex-end'}
          justifyContent={'space-between'}
          sx={{ height: 'auto', padding: '0 !important' }}
          animate={isObjectPresent(
            getComponentAnimateProps(scripts, 'Welcome'),
          )}
        >
          <CardWrapper>
            <Grid mb={4}>
              <ParagraphBoldText
                mb={2}
                color={theme.palette.primary['grey4']}
                fontWeight={400}
                lineHeight={'normal'}
                fontSize={'clamp(24px, 1.5vw, 2.3vw)'}
              >
                Welcome to
              </ParagraphBoldText>
              <HelixLogoWrapper width="auto" height="52px" src={HelixLogo} />
            </Grid>
            <Grid position={'relative'} maxWidth={'65%'} zIndex={9}>
              <ParagraphLightText
                lineHeight={1.5}
                fontSize={'clamp(20px, 1.7vw, 24px)'}
              >
                Helix is your personalized AI co-pilot designed to help you
                navigate Private Markets.
              </ParagraphLightText>
            </Grid>
            <CardMask
              style={{
                backgroundImage: `url(${DefaultMask})`,
              }}
            />
          </CardWrapper>
          <TextButton
            variant={'text'}
            customHeight={'auto'}
            style={{
              marginTop: '10px',
              fontSize: 'clamp(18px, 1.7vw, 20px)',
            }}
            startIcon={
              <ArrowForwardIcon
                sx={{
                  width: '20px !important',
                  minHeight: '20px !important',
                  margin: '0 !important',
                  fontSize: 'inherit !important',
                }}
              />
            }
            sx={{ textTransform: 'unset' }}
          >
            Let’s take a product tour to show you what Helix can do
          </TextButton>
        </HeaderContainer>
        <FooterContainer container alignItems={'flex-end'} pb={1}>
          <SearchBox
            type={'input'}
            borderWidth={'3px'}
            color={theme.palette.primary['main']}
            isStreaming={false}
            onInputSubmit={onInputSubmit}
            force_query={forced_query}
            colored={true}
          />
        </FooterContainer>
      </Container>
    </ContentWrapper>
  );
}

export default Dashboard;
