import { SmallText } from '@components/Typography';
import { Stack } from '@mui/material';
import React from 'react';
/* import { ReactComponent as GreenRightArrow } from '@icons/GreenRightArrow.svg';
import { ReactComponent as BlueRightArrow } from '@icons/BlueRightArrow.svg'; */
import { useNavigate } from 'react-router-dom';

type SuggestionCardProps = {
  id: number;
  title: string;
  prompt: string;
  category: string;
};

const SuggestionCard = ({
  id,
  prompt,
  title,
  category,
}: SuggestionCardProps): JSX.Element => {
  /*   const [isHovered, setIsHovered] = useState(false);
   */ const navigate = useNavigate();

  const handleViewDetails = () => {
    navigate('/conversations', { state: { category: category, prompt } });
  };

  return (
    <Stack
      direction="column"
      alignItems="top"
      sx={{
        background: '#192335',
        padding: '20px 20px',
        height: 'clamp(130px, 136px, 150px)',
        borderRadius: '10px',
        transition: 'background 0.2s ease-in-out',
        cursor: 'pointer',
        '&:hover': {
          background: '#4D5E71',
        },
      }}
      key={id}
      /*    onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)} */
      onClick={handleViewDetails}
    >
      <SmallText
        color="#FFFFFF"
        fontSize="clamp(10px, 1vw, 14px)"
        marginRight="10px"
        width="90%"
      >
        {title}
      </SmallText>
      <SmallText
        color="#C0C0C5"
        fontSize="clamp(8px, 0.8vw, 12px)"
        marginRight="10px"
        marginTop="10px"
        width="90%"
      >
        {prompt}
      </SmallText>
      {/*       <div>{isHovered ? <BlueRightArrow /> : <GreenRightArrow />}</div>{' '}
       */}{' '}
    </Stack>
  );
};

export default SuggestionCard;
