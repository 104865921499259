/* eslint-disable react/prop-types */
import * as React from 'react';

function SageBlackLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '249'}
      height={props.height || '64'}
      viewBox="0 0 249 64"
      fill="none"
      style={props.style}
    >
      <path
        d="M45.5858 1.86816V58.7265H33.9572V34.9271H11.6304V58.7265H0.00195312V1.86816H11.6304V24.449H33.9572V1.86816H45.5858Z"
        fill="white"
      />
      <path
        d="M49.1543 38.1764C49.1543 27.7794 55.201 17.1387 68.4575 17.1387C82.2568 17.1387 87.7609 27.7794 87.7609 37.1201C87.7609 38.6636 87.6835 39.8818 87.6059 40.7754H59.5423C60.3176 46.5426 63.8061 50.1165 69.2328 50.1165C73.5741 50.1165 76.4426 48.3295 77.2953 44.8368H87.8385C86.288 53.6903 78.8457 59.3763 69.2328 59.3763C55.9763 59.3763 49.1543 48.4922 49.1543 38.1764ZM76.9852 33.6274C76.6751 29.0791 73.6517 25.4238 68.4575 25.4238C63.5736 25.4238 60.6277 27.9417 59.6974 33.6274H76.9852Z"
        fill="white"
      />
      <path d="M91.4062 0H102.182V58.7264H91.4062V0Z" fill="white" />
      <path
        d="M117.143 17.7886H106.367V58.7266H117.143V17.7886Z"
        fill="white"
      />
      <path
        d="M120.4 58.7266L134.509 37.3643L121.408 17.7886H133.269L140.401 28.5105L147.456 17.7886H159.317L146.216 37.3643L160.325 58.7266H148.774L140.401 46.1363L131.951 58.7266H120.4Z"
        fill="white"
      />
      <path
        d="M169.17 58.106V59.8764H168V46.1016H169.17V51.8411C169.746 50.6932 171.045 49.915 172.419 49.915C175.391 49.915 176.969 52.1718 176.969 54.9735C176.969 57.7752 175.391 60.0321 172.419 60.0321C171.045 60.0321 169.746 59.2538 169.17 58.106ZM172.382 58.9037C174.406 58.9037 175.725 57.3472 175.725 54.9735C175.725 52.5999 174.406 51.0434 172.382 51.0434C170.358 51.0434 169.04 52.5999 169.04 54.9735C169.04 57.3472 170.358 58.9037 172.382 58.9037Z"
        fill="white"
      />
      <path
        d="M180.478 60.1101L176.95 50.0708H178.176L181.035 58.2618L183.895 50.0708H185.139L182.038 58.9428L180.46 63.8457H179.327L180.478 60.1101Z"
        fill="white"
      />
      <path
        d="M217.585 54.3275V38H212.327V38.0542V48.5543V55.7272C212.327 57.9929 214.087 59.8361 216.25 59.8361H229.235V54.3275H217.585Z"
        fill="white"
      />
      <path
        d="M195.174 39.5668V38H189.901V49.2129C193.056 47.2692 195.174 43.6783 195.174 39.5668Z"
        fill="white"
      />
      <path
        d="M203.872 38V46.2448C203.872 46.2448 199.558 46.2205 199.172 46.2448C197.707 46.3371 196.347 46.9159 195.132 47.6507C194.98 47.7428 194.83 47.8396 194.683 47.9395C192.15 49.6825 190.367 52.5222 189.952 55.8153C189.952 55.8153 189.901 56.8996 189.9 57.2485V59.8903H195.122V51.7465H195.132L203.865 51.7452V51.7534V57.339V59.8903H203.872H209.059H209.08H209.145V51.7534V46.2448V38H203.872Z"
        fill="white"
      />
      <path
        d="M117.53 0H106.135V7.13039C106.135 9.78673 108.19 11.9401 110.725 11.9401H117.53V0Z"
        fill="#018683"
      />
    </svg>
  );
}

const MemoNoData = React.memo(SageBlackLogo);
export default MemoNoData;
