import { useQuery, UseQueryResult } from 'react-query';
import ApiInstance from 'utils/instances';
import { IBackendGroupedInsightData } from 'app/features/Chat/types';

type IUseGroupedInsightsResponse = IBackendGroupedInsightData[];

export default function useGroupedInsights(): UseQueryResult<
  IBackendGroupedInsightData[]
> {
  return useQuery(
    'grouped_insights',
    async () => {
      return await ApiInstance.get<IUseGroupedInsightsResponse>(
        `/chat/grouped_insights`,
      ).then(res => res.data);
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: 100000,
    },
  );
}
