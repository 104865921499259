import React from 'react';
import { Grid } from '@mui/material';
import {
  StreamedRecommendationNode,
  StreamedRecommendationsNode,
} from '@features/Chat/types';
import RotatingPrimaryButton from '@components/Button/RotatingPrimaryButton';
import { RecommendNodeWrapper } from './styles';

interface ChatRecommendationsNodeProps {
  node: StreamedRecommendationsNode;
  onRecommendationClicked: (options: StreamedRecommendationNode) => void;
}

const ChatRecommendationsNode = ({
  node,
  onRecommendationClicked,
}: ChatRecommendationsNodeProps): JSX.Element => {
  const data = node.data;
  if (!data) {
    return <div>No data available</div>;
  }

  return (
    <RecommendNodeWrapper>
      {data
        .filter(l => l.to_display)
        .map(k => (
          <Grid item key={k.id}>
            <RotatingPrimaryButton
              customBorderColor="#CCCCCC"
              sx={{
                height: 'auto',
                borderRadius: '12px',
                textAlign: 'left',
              }}
              onClick={() => onRecommendationClicked(k)}
            >
              {k.display_text}
            </RotatingPrimaryButton>
          </Grid>
        ))}
    </RecommendNodeWrapper>
  );
};

export default ChatRecommendationsNode;
