import React from 'react';
import Markdown, { Components, Options } from 'react-markdown';

interface AIAssistantMarkdownProps extends Readonly<Options> {}

const ourComponents: Partial<Components> = {
  h1: ({ ...props }) => (
    <h1
      style={{ fontSize: '22px', fontWeight: 'bold', marginBottom: '16px' }}
      {...props}
    />
  ),
  h2: ({ ...props }) => (
    <h2
      style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '16px' }}
      {...props}
    />
  ),
  h3: ({ ...props }) => (
    <h3
      style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '14px' }}
      {...props}
    />
  ),
  h4: ({ ...props }) => (
    <h4
      style={{ fontSize: '17px', fontWeight: 'bold', marginBottom: '12px' }}
      {...props}
    />
  ),
  h5: ({ ...props }) => (
    <h5
      style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}
      {...props}
    />
  ),
  h6: ({ ...props }) => (
    <h6
      style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '8px' }}
      {...props}
    />
  ),
  a: ({ ...props }) => <a {...props} target="_blank" />,
  ul: ({ ...props }) => (
    <ul
      style={{
        marginBottom: '16px',
        marginLeft: '16px',
        paddingLeft: '16px',
        listStyleType: 'disc',
      }}
      {...props}
    />
  ),
  em: ({ ...props }) => <em style={{ fontSize: '16px' }} {...props} />,
  strong: ({ ...props }) => (
    <strong style={{ fontWeight: 'bold', fontSize: '100%' }} {...props} />
  ),
  p: ({ ...props }) => <p style={{ marginBottom: '16px' }} {...props} />,
};

export function AIAssistantMarkdown({
  components = {},
  ...restProps
}: AIAssistantMarkdownProps): React.JSX.Element {
  return (
    <Markdown components={{ ...ourComponents, ...components }} {...restProps} />
  );
}
