import { Grid } from '@mui/material';
import styled from 'styled-components';

export const TableContainer = styled.div`
  padding: 16px;
  background: #ebf5fb;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 6px;
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TableContent = styled.div`
  position: relative;
  width: 100%;
`;

export const TableLabelContainer = styled(Grid)`
  gap: 4px;
`;

export const TableTitle = styled.div`
  color: #282829;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
`;

export const TableDescription = styled.div`
  color: #96969b;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;

export const StickyTableContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 57px;
`;

export const StickyTableTd = styled.td`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  padding: 4px 8px 4px 16px;
`;
