import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/types';
import { initialState } from './slice';

const selectSlice = (state: RootState) => state?.global || initialState;

export const selectToastState = createSelector(
  [selectSlice],
  state => state.toastInfo,
);

export const getChatWindowCollapseState = createSelector(
  [selectSlice],
  state => state.chatWindowCollapse,
);

export const getBreadcrumMode = createSelector(
  [selectSlice],
  state => state.breadcrumbMode,
);
export const getTriggerResize = createSelector(
  [selectSlice],
  state => state.resizeTrigger,
);

export const getContextualFundDetails = createSelector(
  [selectSlice],
  state => state.fundDetails,
);

export const isContextualModeEnable = createSelector([selectSlice], state => ({
  fundDetails: state.fundDetails,
  isContextualMode: state.isContextualMode,
}));

export const getProductOnboardingState = createSelector(
  [selectSlice],
  state => state.productOnboarding,
);

export const getProductTourState = createSelector(
  [selectSlice],
  state => state.productTour,
);

export const isProductTourActive = createSelector(
  [selectSlice],
  state => state.productTour.start,
);

export const getConfigState = createSelector(
  [selectSlice],
  state => state.config,
);
