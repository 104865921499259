import { Box, LinearProgress, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { ProcessingBullets } from './styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { PageHeading } from '@components/Typography';
import { useSelector } from 'react-redux';
import { getVectorMigrationStreamData } from 'app/features/VectorMigration/selector';

const ProcessingMethods: React.FC = () => {
  const proceedureList = useSelector(getVectorMigrationStreamData);
  const scrollToMessageEnd = () => {
    const progressBar = document.getElementById('progress_bar');
    progressBar?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    scrollToMessageEnd();
  }, [proceedureList.migrationStreamData]);
  return (
    <Box sx={{ marginTop: '16px' }}>
      <PageHeading>Process</PageHeading>
      <ProcessingBullets>
        {proceedureList?.migrationStreamData?.map(({ message }, index) => {
          return (
            <Stack direction="row" alignItems="center" key={index + 1}>
              <li>{message}</li>
              <CheckCircleOutlineIcon
                sx={{
                  marginBottom: '15px',
                  marginLeft: '20px',
                  color: '#2E7D32',
                }}
              />
            </Stack>
          );
        })}
        {proceedureList?.streaming && (
          <LinearProgress sx={{ width: '80%' }} id={'progress_bar'} />
        )}
      </ProcessingBullets>
    </Box>
  );
};
export default ProcessingMethods;
