/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ApiInstance from '@instances/index';

// Enterprises
export const getAllEnterprises = async (): Promise<any> => {
  return await ApiInstance.get(`/enterprise/getAllEnterprises`).then(
    res => res.data,
  );
};

export const addEnterprises = async (payload: any): Promise<any> => {
  return await ApiInstance.post(`/enterprise/createEnterprise`, payload)
    .then(res => res.data)
    .catch(({ response }: any) => response);
};

export const updateEnterprise = async (payload: any): Promise<any> => {
  return await ApiInstance.put(`/enterprise/updateEnterprise`, payload)
    .then(res => res)
    .catch(({ response }: any) => response);
};
