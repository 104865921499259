import React, { MouseEvent } from 'react';
import Button from '@mui/material/Button';

interface CustomizeButtonProps {
  sx?: any;
  title?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  children: any;
}

const CustomizeButton: React.FC<CustomizeButtonProps> = ({
  sx = {},
  title = '',
  onClick = () => {},
  disabled = false,
  variant = 'contained',
  children,
}) => {
  return (
    <Button
      variant={variant}
      sx={sx} // Add your custom styles here
      disabled={disabled}
      onClick={(event: MouseEvent<HTMLButtonElement>) => onClick(event)}
    >
      {title}
      {children}
    </Button>
  );
};

export default React.memo(CustomizeButton);
