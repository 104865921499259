/**
 * React Context that holds application wide state and functions.
 * Used to avoid prop drilling and to replace Redux.
 */

import { IFundDocumentType, IFundSearchMode } from '@features/Funds/types';
import { AuthUserType } from '@features/Profile/types';
import { useLocalStorage } from '@helper/CustomHooks/useLocalStorage';
import { AccessToken } from '@okta/okta-auth-js';
import { IAddFundDocumentMode } from '@pages/MyFunds/AddFundDocumentModal/AddFundDocumentForm';
import React from 'react';

type IAppContext_Modal =
  | null
  | {
      type: 'ADD_FUND_DOCUMENT';
      internal_fund_id?: string;
      external_fund_id?: string;
      fund_name: string | null; // Sometimes we want the fund to be created in the moment
      document_type: IFundDocumentType | null; // We might not now it yet
      mode: IAddFundDocumentMode;
    }
  | {
      type: 'ADD_NEW_FUND';
      default_fund_name: string;
    };

export type ICurrentUserData = {
  advisor_id: string;
  user_id: string;
  okta_uid: string;
  id: string;
  name: string;
  user_type: AuthUserType;
  email: string;
  backend_api_key: string;
  enterprise_id: string;
  data_provider_id: string;
  apiKeyGeneratedAt: string;
  createdAt: string;
  tone: boolean;
  tAndC: boolean;
  feedback: boolean;
  skipCache: boolean;
  crmIntegrations: boolean;
  isProductTourViewed: boolean;
  custodialIntegration: boolean;
};

type IAppContext = {
  // In some cases we need to show a modal AND navigate to a new page, so we need to store the modal state in the app context
  modal: IAppContext_Modal;
  setModal: (modal: IAppContext_Modal) => void;
  // Even though this is specific for the My Funds page, changes to it can be triggered from other pages
  fund_search_mode: IFundSearchMode;
  setFundSearchMode: (mode: IFundSearchMode) => void;
  getApiToken: () => string | null;
  current_user_data: ICurrentUserData | null;
  setCurrentUserData: (user_details: ICurrentUserData | null) => void;
  loading_user_info: boolean;
  setLoadingUserInfo: (loading: boolean) => void;
  okta_token: AccessToken | null;
  setOktaToken: (token: AccessToken | null) => void;
  conversation_id: string | null;
  setConversationId: (conversation_id: string | null) => void;
};

const initialState: IAppContext = {
  modal: null,
  setModal: () => {},
  fund_search_mode: 'DATA_PROVIDER',
  setFundSearchMode: () => {},
  getApiToken: () => null,
  current_user_data: null,
  setCurrentUserData: () => {},
  loading_user_info: false,
  setLoadingUserInfo: () => {},
  okta_token: null,
  setOktaToken: () => {},
  conversation_id: null,
  setConversationId: () => {},
};

const AppContext = React.createContext<IAppContext>(initialState);

export const AppProvider = ({ children }: { children: any }): JSX.Element => {
  const [modal, setModal] = React.useState<IAppContext_Modal>(null);
  const [fund_search_mode, setFundSearchMode] =
    React.useState<IFundSearchMode>('DATA_PROVIDER');
  const [current_user_data, setCurrentUserData] =
    React.useState<ICurrentUserData | null>(null);
  const [loading_user_info, setLoadingUserInfo] =
    React.useState<boolean>(false);
  const [okta_token, setOktaToken] = useLocalStorage<AccessToken | null>(
    'x-tifin-ai-token-2',
    null,
  );

  const getApiToken = () => {
    return localStorage.getItem('x-tifin-ai-token') || null;
  };

  return (
    <AppContext.Provider
      value={{
        modal,
        setModal,
        fund_search_mode,
        setFundSearchMode,
        getApiToken,
        current_user_data,
        setCurrentUserData,
        loading_user_info,
        setLoadingUserInfo,
        okta_token,
        setOktaToken,
        conversation_id: null,
        setConversationId: () => {},
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext: () => IAppContext = () =>
  React.useContext(AppContext);
