/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import { Box, Grid, styled, useTheme } from '@mui/material';
import { ParagraphBoldText, ParagraphLightText } from '@components/Typography';
import { checkValidNumber, currencyFormatter } from '@common/number';
import { generateShades } from '@common/color';

type ValueFormatterFunction = (label: number, unit: string) => any | string;
export interface BarMetaData {
  bar: Array<string>;
  'y-axis-label'?: string;
  'x-axis-label'?: string;
  chart_heading?: string;
  chart_sub_heading?: string;
}

const colors = ['#426C91', '#D5F4FF', '#E49D22'];
const textColor = '#86858B';
const legendColor = '#424242';
const referenceLineColor = '#EEEFE9';

const allShades: string[] = [
  ...colors,
  ...colors.flatMap(color => generateShades(color, 5)),
];

const StyledSpan = styled('span')`
  color: ${props => props.color || legendColor};
  font-size: 12px;
`;
const StyledWrapper = styled('div')`
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 4px !important;
    height: 4px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #98a2b3 !important;
    border-radius: 20px !important;
    margin-bottom: 10px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
    margin-top: 12px !important;
  }
`;
const BarChartContainer = styled(Grid)`
  position: relative;
  border-radius: 6px;
  background: rgb(235, 245, 251);
`;
const StyledContainer = styled(ResponsiveContainer)`
  .recharts-wrapper {
    .recharts-surface {
      background-color: #fff;
    }
    .recharts-legend-wrapper {
      width: 100% !important;
      bottom: -48px !important;
      left: 0px !important;
    }
  }
`;

export const CustomBarChart = ({
  data = [],
  meta,
}: {
  data: Array<any>;
  meta: BarMetaData;
}): React.JSX.Element | null => {
  if (
    typeof data === 'undefined' ||
    typeof data.length === 'undefined' ||
    data.length === 0
  )
    return null;

  const {
    bar,
    'y-axis-label': yAxisLabel = '',
    chart_heading,
    chart_sub_heading,
  } = meta;
  const chartHeight = 250;
  const minWidthPerBar = 40;
  const minChartWidth = 550;
  const numBars = Object.keys(data[0]).length - 1;
  const totalMinWidth = minWidthPerBar * numBars * data.length;
  const dynamicWidth = Math.max(totalMinWidth, minChartWidth);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const convertStringToNumber = (data: Array<any>, keys: string[]) => {
    return data.map(item => {
      const newItem = { ...item };
      keys.forEach(key => {
        if (typeof newItem[key] === 'string') {
          newItem[key] = parseFloat(newItem[key]);
        }
      });
      return newItem;
    });
  };

  const formattedData = useMemo(
    () => convertStringToNumber(data, bar),
    [data, bar],
  );

  const calculateDomain = (data, bars) => {
    const minValue = Math.min(
      ...data.map(item =>
        Math.min(
          ...bars.map(key => checkValidNumber(item[key], 2, null || '', '--')),
        ),
      ),
    );

    const maxValue = Math.max(
      ...data.map(item =>
        Math.max(
          ...bars.map(key => checkValidNumber(item[key], 2, null || '', '--')),
        ),
      ),
    );

    const domainRange = maxValue - minValue;

    if (minValue >= 0 && minValue <= domainRange * 0.2) {
      return [-minValue, 'auto'];
    }

    return [0, 'auto'];
  };

  const domain: any = useMemo(
    () => calculateDomain(formattedData, bar),
    [formattedData, bar],
  );

  const unit =
    yAxisLabel.includes('$') || yAxisLabel.includes('MM') ? '$' : '%';

  const CustomYAxisTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          fontSize={10}
          fill={textColor}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {valueFormatter(payload.value, unit)}
        </text>
      </g>
    );
  };

  const CustomXAxisTick = ({ x, y, payload }) => {
    const maxCharsPerLine = 15;

    const wrapText = (text, maxCharsPerLine) => {
      const words = text.split(' ');
      const lines: string[] = [];
      let currentLine: string = words[0];

      for (let i = 1; i < words.length; i++) {
        if ((currentLine + ' ' + words[i]).length <= maxCharsPerLine) {
          currentLine += ' ' + words[i];
        } else {
          lines.push(currentLine);
          currentLine = words[i];
        }
      }
      lines.push(currentLine);
      return lines;
    };

    const lines = wrapText(payload.value, maxCharsPerLine);

    return (
      <g transform={`translate(${x},${y + 20})`}>
        {lines.map((line, index) => (
          <text
            key={index}
            x={0}
            y={index * 16}
            dy={0}
            fontSize={10}
            fill={textColor}
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {line}
          </text>
        ))}
      </g>
    );
  };

  const handleMouseEnter = (_, index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  return (
    <StyledWrapper>
      <BarChartContainer
        p={2}
        style={{
          width: '100%',
          minWidth: `${dynamicWidth}px`,
          height: `calc(${chartHeight}px + 128px)`,
          borderRadius: '6px',
          background: '#EBF5FB',
        }}
      >
        <ParagraphBoldText
          fontSize={'14px'}
          fontWeight={700}
          textTransform={'uppercase'}
        >
          {chart_heading}
        </ParagraphBoldText>
        <ParagraphLightText mb={1} fontSize={'12px'} color={'#96969B'}>
          {chart_sub_heading}
        </ParagraphLightText>
        <StyledContainer width="100%" height={chartHeight}>
          <BarChart
            data={formattedData}
            barGap={0}
            barCategoryGap={10}
            maxBarSize={40}
            margin={{
              top: 40,
              right: 10,
              left: 0,
              bottom: -10,
            }}
          >
            <CartesianGrid
              vertical={false}
              fillOpacity={0.6}
              stroke={referenceLineColor}
            />
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={false}
              tick={CustomXAxisTick}
              interval={0}
            />
            <YAxis
              type="number"
              tickLine={false}
              axisLine={false}
              domain={domain}
              tick={CustomYAxisTick}
              interval={0}
              label={{
                value: yAxisLabel,
                angle: -90,
                style: {
                  fontSize: '10px',
                },
              }}
            />
            <Tooltip
              cursor={false}
              isAnimationActive={false}
              content={({ label, payload }) => (
                <BarCustomTooltip label={label} unit={unit} payload={payload} />
              )}
            />
            <Legend content={<CustomLegendContent />} />
            <ReferenceLine
              y={0}
              stroke={referenceLineColor}
              shapeRendering="crispEdges"
            />

            {bar.map((m: any, i: any) => (
              <Bar
                key={i}
                unit={unit}
                dataKey={m}
                fill={allShades[i % allShades.length]}
                isAnimationActive={false}
                style={{ stroke: '#fff', strokeWidth: 0.5 }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {data.map((v: any, iK: any) => (
                  <Cell
                    key={`cell-${iK}`}
                    fill={allShades[i % allShades.length]}
                    fillOpacity={activeIndex === iK ? 1 : 0.8}
                  />
                ))}
              </Bar>
            ))}
          </BarChart>
        </StyledContainer>
      </BarChartContainer>
    </StyledWrapper>
  );
};

const valueFormatter: ValueFormatterFunction = (label, unit): any => {
  if (unit === '$') {
    return currencyFormatter(label);
  }
  return checkValidNumber(label, 2, unit || '', '--');
};

const BarCustomTooltip = (props): React.JSX.Element => {
  const theme = useTheme();
  const { payload = [], label = '' } = props;
  return (
    <Grid
      container
      flexDirection={'column'}
      alignItems={'flex-start'}
      justifyContent={'center'}
      style={{
        padding: '12px',
        borderRadius: '8px',
        backgroundColor: theme.palette.primary['solidWhite'],
        boxShadow: theme.palette.primary['boxShadow1'],
      }}
    >
      <ParagraphLightText
        marginBottom={'8px'}
        fontWeight={700}
        fontSize={'14px'}
        color={theme.palette.primary['dark']}
      >
        {label}
      </ParagraphLightText>
      {Array.isArray(payload) &&
        payload?.length > 0 &&
        payload
          .filter(item => item.payload?.[item?.dataKey] !== '--')
          .map((e: any) => (
            <ParagraphLightText key={e?.dataKey} style={{ color: e?.fill }}>
              <StyledSpan style={{ paddingRight: '5px', color: e?.fill }}>
                {e?.dataKey}:{' '}
              </StyledSpan>
              {e?.unit === '$' && (
                <StyledSpan
                  style={{ color: theme.palette.primary['charcoalBlack'] }}
                >
                  {e?.unit}
                </StyledSpan>
              )}
              <StyledSpan
                style={{ color: theme.palette.primary['charcoalBlack'] }}
              >
                {e?.payload && !isNaN(+e?.payload?.[e?.dataKey])
                  ? e?.payload?.[e?.dataKey]
                  : checkValidNumber(
                      e?.payload?.[e?.dataKey],
                      2,
                      e?.unit || '',
                      e?.payload?.[e?.dataKey],
                    )}
              </StyledSpan>
              {e?.unit !== '$' && (
                <StyledSpan
                  style={{ color: theme.palette.primary['charcoalBlack'] }}
                >
                  {e?.unit}
                </StyledSpan>
              )}
            </ParagraphLightText>
          ))}
    </Grid>
  );
};

const CustomLegendContent: React.FC<{ payload?: any[] }> = ({ payload }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: '8px',
        borderRadius: '4px',
        background: theme.palette.primary['solidWhite'],
      }}
    >
      <Grid container>
        {payload &&
          payload.map((entry, index) => (
            <Grid
              key={`item-${index}`}
              container
              item
              sm="auto"
              marginRight={'20px'}
            >
              <Grid
                width={'16px'}
                height={'16px'}
                marginRight={'8px'}
                sx={{ background: entry.color }}
              />
              <Grid
                sx={{
                  color: legendColor,
                  fontSize: 10,
                  fontWeight: 400,
                  lineHeight: '16px',
                }}
              >
                {entry.value}
              </Grid>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default CustomBarChart;
