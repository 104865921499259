import React from 'react';
import styled from 'styled-components';

interface CardProps {
  width?: string;
  height?: string;
  flexDirection?: string;
  marginBottom?: string;
  padding?: string;
  border?: string;
  borderRadius?: string;
  alignItems?: string;
  background?: string;
  boxShadow?: string;
  style?: React.CSSProperties;
  cursor?: string;
}

const StyledDataProviderCard = styled.div<CardProps>`
  width: ${({ width }) => width || '221px !important'};
  height: ${({ height }) => height || '122px !important'};
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  padding: ${({ padding }) => padding || '20px 16px 16px 20px'};
  border: ${({ border }) => border || '1px solid #e4e7ec'};
  border-radius: ${({ borderRadius }) => borderRadius || '8px'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  background: ${({ background }) => background || '#fff'};
  box-shadow: ${({ boxShadow }) => boxShadow || ' 0px 1px 2px 0px #1018281F'};
  cursor: ${({ cursor }) => cursor || 'pointer'};
`;
interface DataProviderCardProps extends CardProps {
  children?: React.ReactNode;
  onClick?: () => void;
}

const DataProviderCard: React.FC<DataProviderCardProps> = ({
  children,
  onClick,
  style,
  ...props
}) => {
  return (
    <StyledDataProviderCard onClick={onClick} style={style} {...props}>
      {children}
    </StyledDataProviderCard>
  );
};

export default DataProviderCard;
