import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Grid, Tooltip } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { NavButton } from '@components/Button';
import { ParagraphBoldText, ParagraphLightText } from '@components/Typography';
import { ReactComponent as HelixLogo } from '../../../assets/logo/HelixByHl.svg';
import { LayoutMenusType } from 'app/routes';
import { NavMenuWrapper, ProfileIconWrapper } from './styles';
import useCurrentUser from '@helper/CustomHooks/useCurrentUser';
import { useProfileDataForLocal } from '@common/auth';
import { ConversationNav } from './ConversationNav';

interface SideNavProps {
  menus: Array<LayoutMenusType> | null;
  collapse: boolean;
}

export default function SideNav({
  menus = [],
  collapse = false,
}: SideNavProps): React.JSX.Element {
  const { logout, user_type } = useCurrentUser();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const profileData = useProfileDataForLocal();

  const handleLogout = async () => {
    localStorage.clear();
    await logout();
  };

  const onMenuClick = (path: any) => {
    navigate(path);
  };

  const handleProfileClick = () => {
    navigate('/profile');
  };

  const isRouteActive = (path: string) => {
    if (path === '/' && pathname === '/conversations') {
      return true;
    }
    if (path === '/') {
      return pathname === '/';
    }
    return pathname.startsWith(path);
  };

  return (
    <NavMenuWrapper
      container
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Grid
        item
        container
        px={2}
        flexBasis={'column'}
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        margin={'0 auto'}
        justifyContent={'flex-start'}
      >
        <Grid px={3} pt={2}>
          {collapse ? <></> : <HelixLogo width={'100%'} />}
        </Grid>

        {menus?.map((item: any, index: number) => (
          <Grid item key={index} sm={collapse ? 12 : 12}>
            <NavButton
              key={index}
              collapse={collapse}
              cursor={item.cursor}
              tooltipText={item.name}
              startIcon={item.icon && <item.icon />}
              active={isRouteActive(item.path)}
              enableNotifications={item.enableNotifications}
              onClick={() =>
                item.cursor !== 'not-allowed' && onMenuClick(item.path)
              }
            >
              {item.name}
            </NavButton>
          </Grid>
        ))}
      </Grid>

      {/* Work in progress */}
      {user_type !== 'SUPER_ADMIN' && <ConversationNav />}

      <Grid
        item
        container
        px={3}
        flexBasis={'column'}
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 1, sm: 1, md: 1 }}
        margin={'0 auto'}
        alignItems={'flex-end'}
        justifyContent={'flex-start'}
      >
        <Grid item sm={collapse ? 12 : 6} pb={2} sx={{ width: '180px' }}>
          {!collapse && (
            <>
              <Tooltip title={profileData.name} placement="right">
                <ParagraphBoldText
                  fontSize={'16px'}
                  color={theme.solidWhite}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {profileData.name}
                </ParagraphBoldText>
              </Tooltip>
              <Tooltip title={profileData.email} placement="right">
                <ParagraphLightText
                  fontSize={'12px'}
                  fontWeight={400}
                  sx={{
                    whiteSpace: 'no-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginTop: '5px',
                  }}
                  color={theme.inactiveWhite}
                >
                  {profileData.email}
                </ParagraphLightText>
              </Tooltip>
            </>
          )}
        </Grid>

        <Grid
          item
          container
          pb={2}
          columns={{ xs: 1, sm: 1, md: 1 }}
          alignItems={'left'}
          justifyContent={'left'}
          sm={collapse ? 12 : 6}
          style={{
            flexDirection: collapse ? 'row' : 'row-reverse',
          }}
        >
          <NavButton
            collapse={true}
            cursor={'pointer'}
            tooltipText={'Logout'}
            startIcon={<Logout />}
            active={false}
            enableNotifications={false}
            onClick={() => handleLogout()}
            style={{
              width: '50px',
              height: '50px',
              padding: '14px',
              borderColor: 'transparent',
            }}
          />

          <NavButton
            collapse={true}
            cursor={'pointer'}
            tooltipText={'My Profile'}
            enableNotifications={false}
            active={isRouteActive('/profile')}
            onClick={() => handleProfileClick()}
            startIcon={
              <ProfileIconWrapper
                alt={profileData.initialName}
                onClick={() => handleProfileClick()}
              >
                {profileData.initialName}
              </ProfileIconWrapper>
            }
            startIconStyles={{
              marginLeft: '0px',
              marginRight: '0px',
              fontSize: '24px',
            }}
            style={{
              width: '50px',
              height: '50px',
              padding: '14px',
              justifyContent: 'center',
              borderColor: 'transparent',
            }}
          />
        </Grid>
      </Grid>
    </NavMenuWrapper>
  );
}
