import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledWelcomeBack = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  gap: 0.5rem;
  width: 100%;
`;

const Divider = styled(Box)`
  background: #d0d5dd;
  display: flex;
  width: calc(50% - 30px);
  height: 1px;
`;

const Circle = styled(Box)`
  border: 1px solid #d0d5dd;
  background: #d0d5dd;
  color: #222;
  border-radius: 32%;
  width: 60px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTypography = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
  color: #222;
`;

const WelcomeBack = (): React.JSX.Element => {
  return (
    <StyledWelcomeBack className="chat-welcome-back" key="chat-welcome-back">
      <Grid
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        width={'100%'}
      >
        <Divider />
        <Circle>
          <StyledTypography variant="body2">Now</StyledTypography>
        </Circle>
        <Divider />
      </Grid>
      <Box
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <StyledTypography>{`Welcome back to Helix by HL`}</StyledTypography>
      </Box>
    </StyledWelcomeBack>
  );
};

export default WelcomeBack;
