import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Button, ButtonGroup, useTheme } from '@mui/material';
import { actions as fundActions } from '@features/Funds/slice';
import { CustomizeTextField } from '@components/core';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@icons/SearchIcon';
import MyFundsList from './MyFundsList/MyFundsList';
import { ButtonContainer, Header } from './styles';
import { SecondaryButton } from '@components/Button';
import { ParagraphBoldText } from '@components/Typography';
import AddIcon from '@mui/icons-material/Add';
import useDebouce from '@hooks/useDebounce';
import { IFundSearchMode } from '@features/Funds/types';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'app/AppContext';

type ButtonType = {
  title: string;
  disabled: boolean;
  mode: IFundSearchMode;
};
const buttons: ButtonType[] = [
  { title: 'Data Provider', disabled: false, mode: 'DATA_PROVIDER' },
  { title: 'Custom', disabled: false, mode: 'ENTERPRISE' },
];

function MyFundsPage(): React.JSX.Element {
  const { setModal, fund_search_mode, setFundSearchMode } = useAppContext();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const clearValue = () => {
    setInputValue('');
    dispatch(
      fundActions.searchFundsByValueSuccess({
        loading: false,
        result: [],
        totalCount: 0,
      }),
    );
  };

  const debouncedSearchValue = useDebouce(inputValue, 500);
  const onAddNewFundClick = () => {
    /*
    setModal({
      type: 'ADD_NEW_FUND',
      default_fund_name: '',
    });
    */
    setModal({
      type: 'ADD_FUND_DOCUMENT',
      fund_name: null,
      document_type: null,
      mode: 'CREATE_NEW_FUND',
    });
    navigate('/conversations');
  };
  return (
    <>
      <Header alignItems={'flex-end'}>
        <ParagraphBoldText fontSize={'24px'}>My Funds</ParagraphBoldText>
        <ButtonContainer>
          <SecondaryButton
            // disabled
            onClick={onAddNewFundClick}
            startIcon={<AddIcon />}
          >
            Add New Fund
          </SecondaryButton>
        </ButtonContainer>
      </Header>

      <Grid container>
        <Fragment>
          <Header item sm={12}>
            <ButtonGroup variant="text" aria-label="Basic button group">
              {buttons.map(button => (
                <React.Fragment key={button.title}>
                  <Button
                    onClick={() => setFundSearchMode(button.mode)}
                    style={{
                      textTransform: 'none',
                      minWidth: '162px',
                      borderRadius:
                        button.title === buttons[0].title
                          ? '8px 0 0 8px'
                          : button.title === buttons[buttons.length - 1].title
                            ? '0 8px 8px 0'
                            : '0',
                    }}
                    disabled={button.disabled}
                    variant={
                      fund_search_mode === button.mode
                        ? 'contained'
                        : 'outlined'
                    }
                    color={
                      fund_search_mode === button.mode ? 'primary' : 'primary'
                    }
                  >
                    {button.title} Funds
                  </Button>
                </React.Fragment>
              ))}
            </ButtonGroup>
            <CustomizeTextField
              prefixIcon={<SearchIcon />}
              name={''}
              value={inputValue}
              width={'340px'}
              placeholder="Search"
              onChange={handleChange}
              sx={{
                maxWidth: '400px',
                border: '1px solid #E4E7EC',
                boxShadow: '1px 2px 2px 0px #1018281F inset',
                borderRadius: '4px',
                marginLeft: 'auto',
                backgroundColor: '#fff',
              }}
              suffixIcon={
                inputValue && (
                  <CancelIcon
                    sx={{
                      fontSize: '16px',
                      cursor: 'pointer',
                      color: theme.palette.primary['charcoalBlack'],
                    }}
                    onClick={clearValue}
                  />
                )
              }
            />
          </Header>
          <MyFundsList
            searchQuery={debouncedSearchValue}
            onAddNewFundClick={onAddNewFundClick}
            setInputValue={setInputValue}
          />
        </Fragment>
      </Grid>
    </>
  );
}

export default MyFundsPage;
