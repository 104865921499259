import React, { Fragment } from 'react';
import { Grid, useTheme, styled } from '@mui/material';
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface LinearProgressInterface extends LinearProgressProps {
  value: number;
  children?: any;
  renderText?: boolean;
}

const LinearProgressContainer = styled(Grid)<any>`
  width: auto;
  position: relative;
`;

const AbsoluteWrapper = styled(Grid)<any>`
  position: absolute;
  bottom: ${props => props.bottom || '-5px'};
  z-index: 99;
  width: 100%;
`;

const LinearProgressBar = styled(LinearProgress)<{ customColor?: string }>`
  &.MuiLinearProgress-root {
    background-color: rgb(169 173 180 / 32%);
    .MuiLinearProgress-bar {
      background-color: ${props => props.customColor};
    }
  }
`;

function LinearProgressWithLabel({
  sx = {},
  value,
  children,
  renderText,
  ...rest
}: LinearProgressInterface): React.JSX.Element {
  const theme = useTheme();
  return (
    <LinearProgressContainer container alignItems={'center'} sx={sx}>
      {children ? (
        <Fragment>
          {children}
          <AbsoluteWrapper px={'10px'} py={0}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgressBar
                value={value}
                variant={'determinate'}
                customColor={theme.palette.primary['purple']}
                {...rest}
              />
            </Box>
            {renderText && (
              <Box sx={{ minWidth: 35 }}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                >{`${Math.round(value)}%`}</Typography>
              </Box>
            )}
          </AbsoluteWrapper>
        </Fragment>
      ) : (
        <Fragment>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgressBar
              value={value}
              variant={'determinate'}
              customColor={theme.palette.primary['purple']}
              {...rest}
            />
          </Box>
          {renderText && (
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                value,
              )}%`}</Typography>
            </Box>
          )}
        </Fragment>
      )}
    </LinearProgressContainer>
  );
}

export default LinearProgressWithLabel;
