import {
  AuthDetailsProps,
  AUTH_USER_TYPES,
  AuthUserType,
} from '@features/Profile/types';
import { isEmpty } from './string';

export interface UserDetails extends AuthDetailsProps {
  advisorId: string | null;
  skipCache: string | null;
  loggedUser: string | null;
}

export const isValidAuthUserType = (
  userType: string,
): userType is AuthUserType => {
  return AUTH_USER_TYPES.includes(userType as AuthUserType);
};

export const getUserAuthDetails: AuthDetailsProps = {
  apiKey: localStorage.getItem('tifin-ai-api-key') as string,
  apiToken: localStorage.getItem('x-tifin-ai-auth') as string,
  userType: localStorage.getItem('tifin-ai-user-type') as AuthUserType,
};

export const setUserAuthDetails = (props: UserDetails): void => {
  localStorage.setItem('tifin-ai-api-key', props.apiKey ?? '');
  localStorage.setItem('x-tifin-ai-token', props.apiToken ?? '');
  localStorage.setItem('tifin-ai-advisorId', props.advisorId ?? '');
  localStorage.setItem('enterpriseId', props.enterpriseId ?? '');
  localStorage.setItem('tifin-ai-user-type', props.userType ?? '');
  localStorage.setItem('dataProviderId', props.dataProviderId ?? '');
  localStorage.setItem('tifin-ai-skipCache', props.skipCache ?? '1');
  localStorage.setItem('tifin-ai-loggedUser', props.loggedUser ?? '');
};

export const isUserLoggedIn = ({
  apiToken,
  userType,
}: AuthDetailsProps): boolean => {
  return (
    apiToken !== undefined &&
    typeof apiToken === 'string' &&
    apiToken.trim().length > 0 &&
    isValidAuthUserType(userType)
  );
};

export interface ProfileDataProps extends AuthDetailsProps {
  id: string;
  name: string;
  email: string;
  firstName: string;
  lastName: string;
  initialName: string;
  userId: string;
  apiKey: string;
  advisorId: string;
  enterpriseId: string;
  dataProviderId: string;
}

export const getProfileDataForLocal = (): ProfileDataProps => {
  const tifinAiLoggedUser = localStorage.getItem('tifin-ai-loggedUser');
  const loggedUser = !isEmpty(tifinAiLoggedUser)
    ? JSON.parse(tifinAiLoggedUser as any)
    : {};
  const email: string = loggedUser?.email ?? '';
  const name: string = loggedUser?.name
    ? loggedUser?.name
    : email && email.length > 0
      ? email?.split('@').length > 0
        ? email?.split('@')[0]
        : email[0]
      : '';
  const splitName: Array<string> = name?.split(' ');
  const firstName: string = splitName ? splitName[0] : '';
  const lastName: string = splitName ? splitName[1] : '';
  const _f: string = firstName?.length > 1 ? firstName[0] : '';
  const _l: string = lastName?.length > 1 ? lastName[0] : '';
  const initialName: string = _f && _l ? `${_f}${_l}` : '';
  return {
    ...getUserAuthDetails,
    name,
    email,
    firstName,
    lastName,
    initialName,
    id: loggedUser.id,
    userId: loggedUser.userId,
    apiKey: loggedUser.apiKey,
    advisorId: loggedUser.advisorId,
    enterpriseId: loggedUser.enterpriseId,
    dataProviderId: loggedUser.dataProviderId,
  };
};

export const getInitalName = (): string => {
  const { firstName, lastName } = getProfileDataForLocal();
  const _f: string = firstName?.length > 1 ? firstName[0] : '';
  const _l: string = lastName?.length > 1 ? lastName[0] : '';
  const initialName: string = _f && _l ? `${_f}${_l}` : '';
  return initialName;
};
