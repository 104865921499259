import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataProvidersList } from './types';

export const initialState: DataProvidersList = {
  loading: false,
  data: [],
  addProviderData: [],
};

const dataProvidersSlice = createSlice({
  name: 'dataProviders',
  initialState,
  reducers: {
    getUserProvidersRequest() {},
    loadDataProvidersListFetch(state) {
      state.loading = true;
    },
    loadDataProvidersListSuccess(
      state: any,
      action: PayloadAction<{ data?: DataProvidersList; loading: boolean }>,
    ) {
      return {
        ...state,
        data: action?.payload?.data,
        loading: false,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addDataProviderRequest(state, action: PayloadAction<any>) {},
    addDataProviderFetch(state, action: PayloadAction<any>) {
      return {
        ...state,
        addProviderData: action?.payload?.data,
      };
    },
    addDataProviderSuccess(state, action: PayloadAction<any>) {
      return {
        ...state,
        addProviderData: action?.payload?.data,
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = dataProvidersSlice;
