// GlobalSlice/saga.ts
import {
  AllEffect,
  all,
  call,
  put,
  delay,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { actions as globalActions } from '@features/Global/slice';
import { INIT_LOAD_SCRIPTS, FUND_DETAILS_DATA } from '@common/constant';
import { actions } from './slice';
import { isProductTourActive } from './selector';
import { getChatInitResponseState } from '../Chat/selector';
import { getUserConfig, updateUserConfig, exitFundMode } from './api';
import { ConfigData, ConfigUpdateResponseInterface } from './types';

/*

function* connectIntegration(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    const { id, advisorId, callback }: any = payload;
    if (!id || !advisorId) {
      throw new Error("Can't find integration ID or advisor ID");
    }
    yield put(
      actions.connectIntegrationFetch({
        id,
      }),
    );
    let response: any = null;
    switch (payload.crmName) {
      case INTEGRATIONS_NAME.REDTAIL:
        response = yield call(redTailAuthenticate, {
          username: payload.username,
          password: payload.password,
          advisorId: payload.advisorId,
        });
        break;
      case INTEGRATIONS_NAME.WEALTHBOX:
        response = yield call(wealthBoxAuthenticate, {
          advisorId: payload.advisorId,
          code: payload.code,
        });
        break;
    }
    if (!response) {
      throw new Error(`Failed to connect ${payload.crmName}`);
    }
    yield put(actions.connectIntegrationSuccess(response));
    if (response && response.status === 'SYNCING') {
      yield put(actions.getIntegrationDataByIdRequest(response));
    }
    if (callback && typeof callback === 'function') {
      callback(response.data);
    }
  } catch (error) {
    console.error(error);
    if (payload?.callback && typeof payload?.callback === 'function') {
      payload.callback(error);
    }
    yield put(
      actions.integrationOperationFailed({
        id: payload.id,
      }),
    );
  }
}

function* syncIntegration(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    const { id, callback } = payload;
    if (!id) {
      throw new Error("Can't find integration ID");
    }

    yield put(
      actions.syncIntegrationFetch({
        id,
      }),
    );

    let response: any = null;
    switch (payload.crmName) {
      case INTEGRATIONS_NAME.REDTAIL:
        response = yield call(syncRedTailClient, {
          params: {
            id,
          },
        });
        break;
      case INTEGRATIONS_NAME.WEALTHBOX:
        response = yield call(syncWealthBoxClient, {
          params: {
            id,
          },
        });
        break;

      default:
        console.log('Missing api details : ', payload.crmName);
        break;
    }

    if (!response) {
      throw new Error('Sync api failed');
    }

    yield put(actions.syncIntegrationSuccess(response));
    if (response.status === 'SYNCING') {
      yield delay(1000); // Adjust the delay time as needed
      yield put(actions.getIntegrationDataByIdRequest(response));
    }
    if (callback && typeof callback === 'function') {
      callback(response.data);
    }
  } catch (error) {
    console.error(error);
    yield put(
      actions.integrationOperationFailed({
        id: payload.id,
      }),
    );
  }
}

function* disconnectIntegration(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    const { id, callback } = payload;
    if (!id) {
      throw new Error("Can't find integration ID");
    }

    yield put(
      actions.disconnectIntegrationFetch({
        id,
      }),
    );
    const response = yield call(disconnectIntegrationById, {
      id,
    });

    yield put(actions.disconnectIntegrationSuccess(response.data));
    if (callback && typeof callback === 'function') {
      callback(response.data);
    }
  } catch (error) {
    console.error(error);
    yield put(
      actions.integrationOperationFailed({
        id: payload.id,
      }),
    );
  }
}
    */

/*
function* pollForSyncedStatus(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    const { id: integrationId } = payload;
    if (!integrationId) {
      throw new Error("Can't find integration ID");
    }
    let retries = 0;
    const maxRetries = 10; // Maximum number of retry attempts

    while (retries < maxRetries) {
      yield put(
        actions.getIntegrationDataByIdFetch({
          id: integrationId,
        }),
      );

      try {
        const response = yield call(getIntegrationsDataById, {
          params: {
            id: integrationId,
          },
        });

        // Check if the status is SYNCED
        if (
          response.data.status === 'SYNCED' ||
          response.data.status !== 'SYNCING'
        ) {
          yield put(actions.getIntegrationDataByIdSuccess(response.data));
          break;
        } else if (response.data.status === 'SYNCING') {
          retries++;
          yield delay(5000); // Adjust the delay time as needed
        }
      } catch (error) {
        console.error('API error:', error);
        yield delay(5000);
        yield put(actions.pollingFailed({ id: integrationId }));
      }
    }

    // Handle the case when the maximum number of retries is reached
    if (retries === maxRetries) {
      console.error('Max retries reached. Stopping the polling.');
      // If max retries reached without success, handle the failure
      yield put(actions.pollingFailed({ id: integrationId }));
    }
  } catch (error) {
    console.error(error);
    yield put(actions.pollingFailed(payload));
  }
}

function* watchConcurrentJobs() {
  yield takeEvery(
    actions.getIntegrationDataByIdRequest.type,
    pollForSyncedStatus,
  );
}
*/

function* callDelay(time) {
  yield delay(time);
}

function* triggerProductTourActivity(): Generator<any, void, any> {
  try {
    yield put(actions.startingProductTour(true));
    const {
      finish,
      loadScripts,
      dashboardScripts,
      eductionScripts,
      fundResearchScripts,
      clientCommunicationScripts,
      marketResearchScripts,
      clientPageScripts,
      integrationPageScripts,
    } = INIT_LOAD_SCRIPTS;

    console.log('RENDERING LAYOUT...');
    if (loadScripts && loadScripts.length) {
      for (const node of loadScripts) {
        yield put(actions.updateActiveScripts('loadScripts'));
        const _isActive = yield select(isProductTourActive);
        if (!_isActive) {
          throw 'Product Tour Stopped';
        }
        yield put(
          actions.updateProductTourLoadScripts({
            transition: node.transition,
            component: node.component,
            path: node.path,
            delay: node.delay,
          }),
        );
        yield callDelay(node.delay);
      }
    }
    const loadScriptsDelay = loadScripts.reduce(
      (acc, script) => acc + script.delay,
      0,
    );

    yield callDelay(loadScriptsDelay);

    if (dashboardScripts && dashboardScripts.length) {
      yield put(actions.updateActiveScripts('dashboardScripts'));

      for (const node of dashboardScripts) {
        const _isActive = yield select(isProductTourActive);

        if (!_isActive) {
          throw 'Product Tour Stopped';
        }

        yield put(
          actions.updateProductTourDashboardScripts({
            transition: node.transition,
            component: node.component,
            path: node.path,
            delay: node.delay,
          }),
        );
        yield callDelay(node.delay);
      }
    }
    const dashboardScriptsDelay = dashboardScripts.reduce(
      (acc, script) => acc + script.delay,
      0,
    );
    yield callDelay(dashboardScriptsDelay);

    console.log('STARTING EDUCATION SCRIPTS...');
    if (eductionScripts && eductionScripts.length) {
      yield put(actions.updateActiveScripts('eductionScripts'));
      for (const node of eductionScripts) {
        const _isActive = yield select(isProductTourActive);
        if (!_isActive) {
          throw 'Product Tour Stopped';
        }
        /*
        if (node.component === 'RightSideDrawer') {
          yield put(
            globalActions.getInsightsSuccess({
              loading: false,
              data: SUGGESSTED_FUNDS_DATA,
            }),
          );
          yield put(globalActions.breadcrumbMode('Dashboard'));
        }
          */
        yield put(
          actions.updateProductTourEductionScripts({
            transition: node.transition,
            component: node.component,
            path: node.path,
            delay: node.delay,
            queryType: node.queryType,
            queryText: node.queryText,
            querySubText: node.querySubText,
          }),
        );
        yield callDelay(node.delay);
      }
    }
    yield callDelay(200);

    console.log('STARTING MARKET_RESEARCH SCRIPTS...');
    if (marketResearchScripts && marketResearchScripts.length) {
      yield callDelay(500);
      yield put(actions.updateActiveScripts('marketResearchScripts'));
      for (const node of marketResearchScripts) {
        const _isActive = yield select(isProductTourActive);
        if (!_isActive) {
          throw 'Product Tour Stopped';
        }
        yield put(
          actions.updateProductTourMarketResearchScripts({
            transition: node.transition,
            component: node.component,
            path: node.path,
            delay: node.delay,
            queryType: node.queryType,
            queryText: node.queryText,
            querySubText: node.querySubText,
            tableData: node.tableData,
          }),
        );
        yield callDelay(node.delay);
      }
    }
    yield callDelay(200);

    console.log('STARTING FUND RESEARCH SCRIPTS...');
    if (fundResearchScripts && fundResearchScripts.length) {
      yield callDelay(500);
      yield put(actions.updateActiveScripts('fundResearchScripts'));

      for (const node of fundResearchScripts) {
        const _isActive = yield select(isProductTourActive);
        if (!_isActive) {
          throw 'Product Tour Stopped';
        }
        if (node.component === 'RightSideDrawer') {
          yield put(
            globalActions.updateFundDetails({
              ticker: FUND_DETAILS_DATA.ticker,
              name: FUND_DETAILS_DATA.name,
              data: FUND_DETAILS_DATA.data,
            }),
          );
          yield put(globalActions.breadcrumbMode('Contextual'));
        }
        yield put(
          actions.updateProductTourFundResearchScripts({
            transition: node.transition,
            component: node.component,
            path: node.path,
            delay: node.delay,
            queryType: node.queryType,
            queryText: node.queryText,
            querySubText: node.querySubText,
          }),
        );
        yield callDelay(node.delay);
      }
    }
    yield callDelay(200);

    console.log('STARTING Client Communication SCRIPTS...');
    if (clientCommunicationScripts && clientCommunicationScripts.length) {
      yield callDelay(500);
      yield put(actions.updateActiveScripts('clientCommunicationScripts'));

      for (const node of clientCommunicationScripts) {
        const _isActive = yield select(isProductTourActive);
        if (!_isActive) {
          throw 'Product Tour Stopped';
        }
        if (node.component === 'RightSideDrawer') {
          yield put(globalActions.breadcrumbMode('Contextual'));
          yield put(
            globalActions.updateFundDetails({
              ticker: FUND_DETAILS_DATA.ticker,
              name: FUND_DETAILS_DATA.name,
              data: FUND_DETAILS_DATA.data,
            }),
          );
        }
        yield put(
          actions.updateProductTourClientCommunicationScripts({
            transition: node.transition,
            component: node.component,
            path: node.path,
            delay: node.delay,
            queryType: node.queryType,
            queryText: node.queryText,
            querySubText: node.querySubText,
          }),
        );
        yield callDelay(node.delay);
      }
    }
    yield callDelay(200);

    console.log('STARTING CLIENT_PAGE SCRIPTS...');
    if (clientPageScripts && clientPageScripts.length) {
      yield callDelay(500);
      yield put(actions.updateActiveScripts('clientPageScripts'));
      for (const node of clientPageScripts) {
        const _isActive = yield select(isProductTourActive);
        if (!_isActive) {
          throw 'Product Tour Stopped';
        }
        yield put(
          actions.updateProductTourClientPageScripts({
            transition: node.transition,
            component: node.component,
            path: node.path,
            delay: node.delay,
            queryType: node.queryType,
            queryText: node.queryText,
            querySubText: node.querySubText,
          }),
        );
        yield callDelay(node.delay);
      }
    }
    yield callDelay(200);

    console.log('STARTING INTEGRATIONS_PAGE SCRIPTS...');
    if (integrationPageScripts && integrationPageScripts.length) {
      yield callDelay(500);
      yield put(actions.updateActiveScripts('integrationPageScripts'));
      for (const node of integrationPageScripts) {
        const _isActive = yield select(isProductTourActive);
        if (!_isActive) {
          throw 'Product Tour Stopped';
        }
        yield put(
          actions.updateProductTourIntegrationPageScripts({
            transition: node.transition,
            component: node.component,
            path: node.path,
            delay: node.delay,
            queryType: node.queryType,
            queryText: node.queryText,
            querySubText: node.querySubText,
          }),
        );
        yield callDelay(node.delay);
      }
    }
    yield callDelay(200);

    if (finish && finish.length) {
      yield callDelay(500);
      yield put(actions.updateActiveScripts('finish'));

      for (const node of finish) {
        const _isActive = yield select(isProductTourActive);
        if (!_isActive) {
          throw 'Product Tour Stopped';
        }
        if (node.component === 'RightSideDrawer') {
          yield put(globalActions.breadcrumbMode('Dashboard'));
          yield put(
            globalActions.updateFundDetails({
              ticker: '',
              name: '',
              data: [],
            }),
          );
        }
        yield put(
          actions.updateProductTourFinishScripts({
            transition: node.transition,
            component: node.component,
            path: node.path,
            delay: node.delay,
            queryType: node.queryType,
            queryText: node.queryText,
            querySubText: node.querySubText,
          }),
        );
        yield callDelay(node.delay);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* fetchUserConfig(): Generator<any, void, any> {
  try {
    yield put(actions.getUserConfigFetch());
    const response: ConfigData = yield call(getUserConfig);
    yield put(
      actions.getUserConfigSuccess({
        data: response.data,
        loading: false,
      }),
    );
  } catch (error) {
    console.log(error);
    yield put(
      actions.getUserConfigSuccess({
        loading: false,
      }),
    );
  }
}

function* updateConfig(request: any): Generator<any, void, any> {
  try {
    const { payload } = request;
    yield put(actions.updateUserConfigFetch());
    const response: ConfigUpdateResponseInterface = yield call(
      updateUserConfig,
      payload,
    );
    if (response.status === 200) {
      yield put(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'success',
          toastMessage: 'User preferences updated',
        }),
      );
    }
    yield put(
      actions.updateUserConfigSuccess({
        data: payload,
        loading: false,
      }),
    );
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateUserConfigSuccess({
        loading: false,
      }),
    );
  }
}

function* exitFund(): Generator<any, void, any> {
  try {
    yield put(actions.exitFundModeFetch());
    const { user: chatToken } = yield select(getChatInitResponseState) || {};
    const response: any = yield call(exitFundMode, {
      headers: {
        'x-tifin-ai-auth': chatToken,
      },
    });
    console.log('response : ', response);
    yield put(actions.exitFundModeSuccess());
  } catch (error) {
    console.log(error);
    yield put(
      globalActions.displayToast({
        duration: 3000,
        toastType: 'error',
        toastMessage: `Something went wrong.`,
      }),
    );
    yield put(actions.exitFundModeSuccess());
  }
}

export function* globalSaga(): Generator<AllEffect<any>, void, unknown> {
  yield all([
    //    fork(watchConcurrentJobs),
    takeLatest(
      actions.startProductTourRequest.type,
      triggerProductTourActivity,
    ),
    takeLatest(actions.getUserConfigRequest.type, fetchUserConfig),
    takeLatest(actions.updateUserConfigRequest.type, updateConfig),
    takeLatest(actions.exitFundModeRequest.type, exitFund),
  ]);
}
