import React, { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, useTheme } from '@mui/material';
import SearchBox from '@components/SearchBox';
import { HomeButton } from '@components/Button';
import { ParagraphLightText } from '@components/Typography';
import { actions as chatActions } from '@features/Chat/slice';
import { actions as globalActions } from '@features/Global/slice';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GlitterIcon from '@icons/glitter';
import SearchIcon from '@icons/SearchIcon';
import SuggestionList from './SuggestionList';
import { motion } from 'framer-motion';
import useGroupedInsights from '@helper/CustomHooks/useGroupedInsights';
import { RightDrawerContext } from '../../layout/SecuredPageLayout';
import useCurrentUser from '@helper/CustomHooks/useCurrentUser';
import { useProfileDataForLocal } from '@common/auth';
//import useConversationHistory from '@hooks/useConversationHistory';

const listVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, x: -10 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.3 },
  },
};

function HomePage(): React.JSX.Element {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const current_user = useCurrentUser();
  const { user_type } = current_user;
  const profile_data = useProfileDataForLocal();
  console.log({ current_user });
  //  const { history } = useConversationHistory('1');

  // Preload them here
  useGroupedInsights();

  useEffect(() => {
    dispatch(globalActions.getUserConfigRequest());
  }, []);

  const { toggleRightDrawer } = useContext(RightDrawerContext);

  const onInputSubmit = (query: string) => {
    navigate('/conversations');
    dispatch(chatActions.searchQueryRequest({ query }));
  };

  /* const takeProductTour = () => {
    dispatch(globalActions.startProductTourRequest());
  }; */

  const gotoConversationsHistory = () => {
    navigate('/conversations', { state: { scroll: 1 } });
  };

  if (localStorage.getItem('terms-and-conditions') !== 'accepted') {
    navigate('/terms-and-conditions');
  }
  return (
    <Grid container flexDirection={'column'} sx={{ height: '93vh' }}>
      <motion.div
        variants={listVariants}
        initial="hidden"
        animate="visible"
        style={{
          display: 'grid',
          gridTemplateRows: '1fr 1fr 1fr',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          padding: '0px 79px',
        }}
      >
        <Grid></Grid>
        <Grid>
          <motion.div key={'user'} variants={itemVariants}>
            <ParagraphLightText
              fontSize={'20px'}
              color={'#667085'}
              sx={{ paddingBottom: 1 }}
            >
              Welcome {profile_data?.firstName},
            </ParagraphLightText>
          </motion.div>

          <motion.div key={'welcome'} variants={itemVariants}>
            <ParagraphLightText
              fontSize={'32px'}
              color={'#012E4C'}
              fontWeight={600}
              sx={{ paddingBottom: 2 }}
            >
              How can I help you today?
            </ParagraphLightText>
          </motion.div>
          <div style={{ marginBlockStart: '50px' }}>
            <ParagraphLightText
              fontSize={'12px'}
              color={'#535256'}
              sx={{ paddingBottom: 1 }}
            >
              GET STARTED
            </ParagraphLightText>

            <SuggestionList variants={itemVariants} />

            <Grid container spacing={2} pb={2} pt={0}>
              <Grid item key={'tour'} xs={6}>
                <motion.div
                  key={crypto.randomUUID()}
                  transition={{ duration: 0.8, ease: [0.4, 0, 0.2, 1] }}
                >
                  <HomeButton
                    title="Click here to continue your previous conversation"
                    subtitle=""
                    onClick={gotoConversationsHistory}
                    startIcon={<GlitterIcon />}
                    endIcon={<ArrowForwardIcon sx={{ color: '#018683' }} />}
                  />
                </motion.div>
              </Grid>

              <Grid item key={'connect'} xs={6}>
                <motion.div
                  key={crypto.randomUUID()}
                  transition={{ duration: 0.8, ease: [0.4, 0, 0.2, 1] }}
                >
                  <HomeButton
                    onClick={toggleRightDrawer}
                    title="Explore Topics"
                    subtitle=""
                    sx={{
                      textAlign: 'left',
                    }}
                    startIcon={<SearchIcon />}
                    endIcon={<ArrowForwardIcon sx={{ color: '#018683' }} />}
                  >
                    Help me connect my client data
                  </HomeButton>
                </motion.div>
              </Grid>
            </Grid>
          </div>
        </Grid>

        {/* {initHistoryState.length > 1 && (
            <Grid item>
              <motion.div key={'conversation'} variants={itemVariants}>
                <motion.div
                  key={crypto.randomUUID()}
                  whileHover={{ backgroundColor: '#f0f0f0', scale: 1.05 }}
                  transition={{ duration: 0.2 }}
                >
                  <SecondaryButton
                    sx={{
                      marginBottom: 0,
                      textTransform: 'none',
                      color: '#101828',
                    }}
                    endIcon={<ArrowForwardIcon />}
                    onClick={gotoConversationsHistory}
                  >
                    Click here to continue your previous conversation
                  </SecondaryButton>
                </motion.div>
              </motion.div>
            </Grid>
          )} */}

        {user_type !== 'SUPER_ADMIN' && (
          <motion.div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              height: '100%',
            }}
          >
            <SearchBox
              type={'input'}
              variant={'search'}
              borderWidth={'3px'}
              color={theme.palette.primary['main']}
              isStreaming={false}
              onInputSubmit={onInputSubmit}
            />
          </motion.div>
        )}
      </motion.div>
    </Grid>
  );
}

export default HomePage;
