import * as React from 'react';
import styled from 'styled-components';
import { Card, Grid, Skeleton } from '@mui/material';

const ChatSkeletonContainer = styled(Card)`
  overflow: hidden;
`;

const ChatSkeletonWrapper = styled(Card)`
  overflow: hidden;
`;

const AvatarSkeleton = styled(Grid)<{ isSender?: boolean }>`
  opacity: 0.7;
`;

function ChatSkeleton(): React.JSX.Element {
  return (
    <ChatSkeletonContainer
      sx={{
        m: 0,
        padding: '4px 20px 0',
        background: 'transparent',
        boxShadow: 'none !important',
      }}
    >
      {Array.from({ length: 4 }, (k: any, index: any) => (
        <React.Fragment key={`chat-skeletono-${k + index}`}>
          <ChatSkeletonWrapper
            sx={{
              m: 0,
              background: 'transparent',
              boxShadow: 'none !important',
            }}
          >
            <AvatarSkeleton
              container
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <Skeleton
                animation="wave"
                variant="circular"
                width={36}
                height={36}
                sx={{ marginRight: '6px' }}
              />
              <Skeleton
                animation="wave"
                height={'68px'}
                width={'80%'}
                style={{
                  marginBottom: 6,
                  borderRadius: '2px 10px 10px 10px',
                }}
              />
            </AvatarSkeleton>
          </ChatSkeletonWrapper>
          <ChatSkeletonWrapper
            sx={{
              m: 0,
              background: 'transparent',
              boxShadow: 'none !important',
            }}
          >
            <AvatarSkeleton
              container
              isSender={true}
              justifyContent={'flex-end'}
              alignItems={'center'}
            >
              <Skeleton
                animation="wave"
                height={'68px'}
                width={'80%'}
                style={{ marginBottom: 6, borderRadius: '2px 10px 10px 10px' }}
              />
              <Skeleton
                animation="wave"
                variant="circular"
                width={36}
                height={36}
                sx={{ marginLeft: '6px' }}
              />
            </AvatarSkeleton>
          </ChatSkeletonWrapper>
        </React.Fragment>
      ))}
    </ChatSkeletonContainer>
  );
}

export default ChatSkeleton;
