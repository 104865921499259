/* eslint-disable react/prop-types */

import React from 'react';

function ProfileIcon(): React.JSX.Element {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill="#DBF2FC" />
      <path
        d="M24 27C27.3137 27 30 24.3137 30 21C30 17.6863 27.3137 15 24 15C20.6863 15 18 17.6863 18 21C18 24.3137 20.6863 27 24 27Z"
        stroke="#457F8F"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M14.9062 32.2499C15.8278 30.6535 17.1533 29.3278 18.7496 28.4061C20.3459 27.4843 22.1567 26.9991 24 26.9991C25.8433 26.9991 27.6541 27.4843 29.2504 28.4061C30.8467 29.3278 32.1722 30.6535 33.0938 32.2499"
        stroke="#457F8F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoConnectIcon = React.memo(ProfileIcon);
export default MemoConnectIcon;
