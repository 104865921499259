/* eslint-disable react/prop-types */
import * as React from 'react';

function IntegrationIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.82227 15.1781L15.1785 8.8125"
        stroke="#101828"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.593 16.7719L10.9398 19.425C10.5219 19.843 10.0257 20.1745 9.47956 20.4007C8.93345 20.6269 8.34814 20.7434 7.75704 20.7434C6.56325 20.7434 5.41836 20.2691 4.57422 19.425C3.73009 18.5809 3.25586 17.436 3.25586 16.2422C3.25586 15.0484 3.73009 13.9035 4.57422 13.0594L7.22735 10.4062"
        stroke="#101828"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7719 13.594L19.425 10.9408C20.2691 10.0967 20.7434 8.9518 20.7434 7.75801C20.7434 6.56423 20.2691 5.41933 19.425 4.5752C18.5809 3.73107 17.436 3.25684 16.2422 3.25684C15.0484 3.25684 13.9035 3.73107 13.0594 4.5752L10.4062 7.22832"
        stroke="#101828"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoNoData = React.memo(IntegrationIcon);
export default MemoNoData;
