import React from 'react';
import { DragIndicator, RemoveRedEye } from '@mui/icons-material';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

// Invert rows/columns
export default function ExtTableGrouped_ReorderRow({
  groupname,
  listeners,
  is_visible = false,
  setIsVisible = () => {},
}: {
  groupname: string;
  listeners?: SyntheticListenerMap;
  is_visible: boolean;
  setIsVisible: (visible: boolean) => void;
}): JSX.Element {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        background: '#fff',
        borderRadius: '8px',
        border: '1px solid #E4E7EC',
      }}
    >
      <div style={{ display: 'flex' }} {...listeners}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <div
            style={{
              width: '9px',
              height: '100%',
              borderRadius: '8px 0px 0px 8px',
              background: '#96969B',
            }}
          ></div>

          <DragIndicator
            style={{
              color: '#101828',
              width: '24px',
              height: '24px',
              margin: 'auto',
            }}
          />
        </div>

        <div
          style={{
            padding: '18px 30px',
            color: '#172337',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: 'normal',
            letterSpacing: '0.56px',
            textTransform: 'uppercase',
          }}
        >
          {groupname}
        </div>
      </div>

      <div
        style={{
          paddingRight: '16px',
          marginTop: 'auto',
          marginBottom: 'auto',
        }}
      >
        <div
          style={{
            padding: '6px 8px 3px 8px',
            border: '1px solid #E4E7EC',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          <RemoveRedEye
            onClick={() => setIsVisible(!is_visible)}
            style={{
              color: is_visible ? '#030712' : 'lightgray',
              width: '20px',
              height: '20px',
            }}
          />
        </div>
      </div>
    </div>
  );
}
