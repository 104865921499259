import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ChatState,
  ChatActionPayloadType,
  ChatFeedbackInterface,
  ChatData,
  StreamingConversation,
  HistoricConversation,
  BackendChatInitResponse,
} from './types';
import {
  convertBackendHistoricChat,
  convertCurrentConversationIntoHistoric,
} from './utils';

export const initialState: ChatState = {
  /* Common State */
  processingQuery: false,
  streaming: false,
  query: '',
  loading: false,
  conversations: [],
  conversation_history: {
    id: '',
    history: [],
  },
  conversation_current: null,
  initIsValid: false,
  initToken: null,
  feedback: {
    loading: false,
    openModal: false,
    scale: null,
    chatLogId: null,
    comment: null,
  },
  feedbackData: [],
  last_scroll_to_bottom: 0,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    /* --- Chat History --- */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadInitialChatHistory(state) {},
    forceScrollToBottom(state) {
      state.last_scroll_to_bottom = Date.now();
    },
    setInitialChatHistoryLoading(state) {
      state.loading = true;
    },
    /*
    loadChatSuccess(state, action: PayloadAction<BackendChatInitResponse>) {
      state.loading = false;
      state.chatInitResponse = {
        ...state.chatInitResponse,
        ...action.payload,
      };
    },
    */

    /* --- CHAT FEEDBACK --- */
    toggleChatFeedbackModal(state, action: PayloadAction<any>) {
      state.feedback = {
        ...state.feedback,
        ...action.payload,
        loading: false,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    submitChatFeedbackRequest(state, action: PayloadAction<any>) {},

    submitChatFeedbackFetch(state) {
      state.feedback = {
        ...state.feedback,
        loading: true,
      };
    },

    submitChatFeedbackSuccess(
      state,
      action: PayloadAction<ChatFeedbackInterface>,
    ) {
      const CurrentChatHistory = state.conversation_history.history;
      const { response: feedbackResponse, chatLogId } = action.payload;

      // find and update the chat from chatHistory
      const chatIndex = CurrentChatHistory.findIndex(
        (k: any) => k.id === chatLogId,
      );
      if (chatIndex > -1) {
        const existingFeedback = CurrentChatHistory[chatIndex]['feedback'];
        if (existingFeedback) {
          // Merge the existing feedback with the new feedbackResponse
          CurrentChatHistory[chatIndex]['feedback'] = {
            ...existingFeedback,
            ...feedbackResponse,
          };
        } else {
          // If there was no existing feedback, set the new feedbackResponse
          CurrentChatHistory[chatIndex]['feedback'] = feedbackResponse;
        }
      }

      state = {
        ...state,
        conversation_history: {
          id: state.conversation_history.id,
          history: CurrentChatHistory,
        },
      };

      state.feedback = {
        ...state.feedback,
        ...action.payload,
        loading: false,
      };
    },

    pushNewConversation(state, action: PayloadAction<StreamingConversation>) {
      // We might move the current conversation to history
      console.log(
        'Pushing new conversation',
        action.payload,
        state.conversation_current,
      );
      if (state.conversation_current) {
        state.conversation_history.history.push(
          convertCurrentConversationIntoHistoric(state.conversation_current),
        );
      }
      console.log('Pushing new conversation 2', action.payload, state);
      state.conversation_current = { ...action.payload };
      /*
      // We might move the current conversation to history
      console.log("Pushing new conversation", action.payload, state.conversation_current);
      const new_state = { ...state };
      if (new_state.conversation_current) {
        new_state.conversation_history.history = [
          ...state.conversation_history.history,
          convertCurrentConversationIntoHistoric(
            new_state.conversation_current,
          ),
        ];
      }
      console.log("Pushing new conversation 2", action.payload, new_state);
      return {
        ...new_state,
        conversation_current: {...action.payload},
      };*/
    },

    pushHistoricConversation(
      state,
      action: PayloadAction<HistoricConversation>,
    ) {
      // We might move the current conversation to history
      const new_state = state;
      if (new_state.conversation_current) {
        console.log(
          'Moving current conversation to history',
          new_state.conversation_current,
        );
        new_state.conversation_history.history = [
          ...state.conversation_history.history,
          convertCurrentConversationIntoHistoric(
            new_state.conversation_current,
          ),
        ];
        new_state.conversation_current = null;
      } else {
        console.log('Warn: No current conversation to move to history', state);
      }
      state.loading = false;
      state.conversation_history = {
        id: state.conversation_history.id,
        history: [...state.conversation_history.history, action.payload],
      };
      /*
      return {
        ...new_state,
        loading: false,
        conversation_history: {
          id: new_state.conversation_history.id,
          history: [...new_state.conversation_history.history, action.payload],
        },
      };
      */
    },

    storeInitialChatHistory(
      state,
      action: PayloadAction<BackendChatInitResponse>,
    ) {
      const history: HistoricConversation[] =
        action.payload.default_conversation.history
          .map(convertBackendHistoricChat)
          .filter((chat): chat is HistoricConversation => chat !== null);

      const new_state: ChatState = {
        ...state,
        loading: false,
        conversations: action.payload.conversations,
        conversation_history: {
          id: action.payload.default_conversation.id,
          history,
        },
      };
      console.log('Storing init chat history', new_state);
      return new_state;
    },

    storeChatToken(
      state,
      action: PayloadAction<{
        initToken: string;
        isValid: boolean;
      }>,
    ) {
      console.log('Chat token', action.payload.initToken);
      return {
        ...state,
        initToken: action.payload.initToken,
        initIsValid: action.payload.isValid,
      };
    },

    updateConversation(state, action: PayloadAction<StreamingConversation>) {
      if (
        state.conversation_current &&
        state.conversation_current.query_id &&
        action.payload.query_id !== state.conversation_current.query_id
      ) {
        console.log(
          'Message for old query, ignoring',
          action.payload.query_id,
          state.conversation_current.query_id,
        );
        return state;
      }
      return {
        ...state,
        conversation_current: action.payload,
      };
    },

    /* --- CHAT SEARCH QUERY FETCH --- */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    searchQueryRequest(state, action: PayloadAction<any>) {
      state.query = action?.payload?.query;
    },
    searchQueryFetch(state) {
      state.processingQuery = true;
    },
    searchQuerySuccess(state, action: PayloadAction<ChatActionPayloadType>) {
      state.processingQuery = false;
      state.query = action.payload?.query;
    },

    getFeedbackListRequest() {},
    loadFeedbackListFetch(state) {
      state.loading = true;
    },
    loadFeedbackListSuccess(
      state: any,
      action: PayloadAction<{ data?: ChatData[] }>,
    ) {
      state.feedbackData = action.payload.data;
    },
    isQueryResponseStreaming(state, action: PayloadAction<boolean>) {
      state.streaming = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = chatSlice;
