import { AllEffect, all, call, put, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { getAllDataProviders, addDataProviders } from './api';
import { actions as globalActions } from '@features/Global/slice';

function* getAllDataProvidersList(): Generator<any, void, any> {
  try {
    yield put(actions.loadDataProvidersListFetch());
    const response: any = yield call(getAllDataProviders);
    // console.log('response : ', response);
    if (!response || (response.data && !response.data.length)) {
      throw new Error('Failed to fetch Data Providers list');
    }
    const sortedData = response.data.sort(
      (a: any, b: any) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );
    yield put(
      actions.loadDataProvidersListSuccess({
        data: sortedData || [],
        loading: false,
      }),
    );
  } catch (error) {
    console.log(error);
    yield put(
      actions.loadDataProvidersListSuccess({
        loading: false,
      }),
    );
  }
}

function* addNewDataProvider(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    const { name } = payload;
    yield put(actions.addDataProviderFetch(true));
    const response: any = yield call(addDataProviders, {
      name: name,
    });
    if (!response || response.error) {
      throw new Error('Failed to add Data Provider');
    }
    if (response.status === 201) {
      yield put(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'success',
          toastMessage: 'Data Provider added',
        }),
      );
    }
    yield put(actions.getUserProvidersRequest());
    yield put(actions.addDataProviderSuccess(false));
    if (payload.callback && typeof payload.callback === 'function') {
      payload.callback(response);
    }
    console.log('Data Provider added successfully:', response);
  } catch (error) {
    console.error('Error adding Data Provider:', error);
  }
}

export function* dataProvidersSaga(): Generator<AllEffect<any>, void, unknown> {
  yield all([
    takeLatest(actions.getUserProvidersRequest.type, getAllDataProvidersList),
    takeLatest(actions.addDataProviderRequest.type, addNewDataProvider),
  ]);
}
