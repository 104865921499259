import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { LightSubHeading, ParagraphBoldText } from '@components/Typography';
import AccordionTable from '@components/AccordianTable/FeedbackHistory';

import { actions as feedbackHistoryActions } from 'app/features/FeedbackHistory/slice';

import { Header } from './styles';
import { getFeedbackHistoryState } from '@features/FeedbackHistory/selector';

function FeedbackHistory(): React.JSX.Element {
  const dispatch = useDispatch();
  const feedbackHistoryState: any = useSelector(getFeedbackHistoryState);
  useEffect(() => {
    if (!feedbackHistoryState?.data?.length) {
      dispatch(feedbackHistoryActions.loadFeedbackHistoryRequest());
    }
  }, []);
  return (
    <>
      <Header container alignItems={'center'} justifyContent={'space-between'}>
        <Grid container>
          <Grid item>
            <ParagraphBoldText fontSize={'24px'}>
              Feedback History
            </ParagraphBoldText>
            <LightSubHeading margin="8px 0px 0px 0px">
              Here you can view the feedback to various queries given by users
            </LightSubHeading>
          </Grid>
        </Grid>
      </Header>
      <Box sx={{ padding: '24px 16px' }}>
        {feedbackHistoryState.loading ? (
          <Grid
            container
            minHeight={'65vh'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <AccordionTable data={feedbackHistoryState.data} />
        )}
      </Box>
    </>
  );
}

export default FeedbackHistory;
