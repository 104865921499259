import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { SkeletonContainer } from './style';

function SkeletonCard(): React.JSX.Element {
  return (
    <SkeletonContainer>
      <Skeleton height={16} borderRadius={'12px'} />
      <Skeleton height={80} borderRadius={'12px'} />
      <Skeleton height={80} borderRadius={'12px'} />
      <Skeleton height={80} borderRadius={'12px'} />
      <Skeleton height={80} borderRadius={'12px'} />
      <Skeleton height={80} borderRadius={'12px'} />
      <Skeleton height={80} borderRadius={'12px'} />
    </SkeletonContainer>
  );
}

export default SkeletonCard;
