import React, { useEffect, useState, createContext } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import ErrorBoundary from '@helper/ErrorBoundary';
import HelixLogo from '@logos/HelixByHLDark.svg';

import SidebarIndicator from '@components/SidebarIndicator';
import ContentDrawer from '@components/ContentDrawer';

import {
  getPageConfig,
  getLayoutMenus,
  PageConfigType,
  LayoutMenusType,
} from 'app/routes';

import SideNav from './SideNav';
import { LayoutProps } from './types';

import {
  LayoutContainer,
  LayoutWrapper,
  LeftSideContainer,
  RightSideContainer,
  RightSideContentBody,
  RightSideContentWrapper,
  HeaderContainer,
  HelixLogoWrapper,
  ContentWrapper,
  RightSideDrawerContainer,
} from './styles';
import { ParagraphBoldText } from '@components/Typography';
import { useDispatch } from 'react-redux';
import { actions as globalActions } from '@features/Global/slice';
import useCurrentUser from '@helper/CustomHooks/useCurrentUser';
import { useProfileDataForLocal } from '@common/auth';

interface RightDrawerContextType {
  isRightDrawerOpen: boolean;
  toggleRightDrawer: () => void;
}

const defaultContextValue: RightDrawerContextType = {
  isRightDrawerOpen: true,
  toggleRightDrawer: () => {},
};

export const RightDrawerContext =
  createContext<RightDrawerContextType>(defaultContextValue);

const SecuredPageLayout = ({ animate = false }: LayoutProps): JSX.Element => {
  const location = useLocation();
  const { pathname } = location;
  const { user_type } = useCurrentUser();
  const [isLeftSideBarCollapsed, updateLeftSideOpenState] = useState(false);
  const [isRightSideDrawerCollapsed, updateRightSideOpenState] = useState(true);

  const profileData = useProfileDataForLocal();
  const dispatch = useDispatch();

  const customMenuList: Array<LayoutMenusType> | null = user_type
    ? getLayoutMenus(user_type, '/')
    : null;
  const config: PageConfigType | null = user_type
    ? getPageConfig(user_type, '/', pathname, true)
    : null;

  const toggleRightDrawer = () => {
    updateRightSideOpenState(prev => !prev);
  };

  useEffect(() => {
    if (
      config &&
      config.path === '/' &&
      config.visible.rightSideDrawer &&
      user_type === 'SUPER_ADMIN'
    ) {
      config.visible.rightSideDrawer = false;
    }
  }, [config, profileData]);

  const onLeftArrowClick = () => {
    updateLeftSideOpenState(!isLeftSideBarCollapsed);
    dispatch(globalActions.triggerResize());
  };

  const onRightArrowClick = () => {
    dispatch(globalActions.triggerResize());
    updateRightSideOpenState(!isRightSideDrawerCollapsed);
    if (isRightSideDrawerCollapsed) {
      updateLeftSideOpenState(true);
    }
  };

  return (
    <RightDrawerContext.Provider
      value={{
        isRightDrawerOpen: isRightSideDrawerCollapsed,
        toggleRightDrawer,
      }}
    >
      <LayoutContainer container>
        <LeftSideContainer
          id="LeftSideContainer"
          item
          collapse={isLeftSideBarCollapsed}
        >
          <SideNav menus={customMenuList} collapse={isLeftSideBarCollapsed} />
          <SidebarIndicator
            isOpen={!isLeftSideBarCollapsed}
            placement={'left'}
            size={'large'}
            tooltip={{
              enable: true,
              placement: 'right',
              text: `${isLeftSideBarCollapsed ? 'Open' : 'Close'} Sidebar`,
            }}
            onClick={onLeftArrowClick}
          />
        </LeftSideContainer>

        <RightSideContainer item container collapse={isLeftSideBarCollapsed}>
          <RightSideContentWrapper container>
            <RightSideContentBody
              container
              visible={config?.visible?.rightSideDrawer}
              collapse={isRightSideDrawerCollapsed}
            >
              {config?.visible?.logoInPage ? (
                <HeaderContainer
                  container
                  animate={animate}
                  alignItems={'flex-end'}
                >
                  <HelixLogoWrapper
                    width="auto"
                    height="32px"
                    src={HelixLogo}
                  />
                </HeaderContainer>
              ) : (
                config?.visible?.titleInPage && (
                  <HeaderContainer
                    container
                    animate={animate}
                    alignItems={'flex-end'}
                  >
                    <ParagraphBoldText fontSize={'24px'}>
                      {config?.name}
                    </ParagraphBoldText>
                  </HeaderContainer>
                )
              )}

              <ErrorBoundary key={pathname}>
                <ContentWrapper animate={animate}>
                  <LayoutWrapper animate={animate}>
                    <Outlet />
                  </LayoutWrapper>
                </ContentWrapper>
              </ErrorBoundary>
            </RightSideContentBody>

            {config?.visible?.rightSideDrawer && (
              <RightSideDrawerContainer
                container
                collapse={isRightSideDrawerCollapsed}
              >
                {!isRightSideDrawerCollapsed && <ContentDrawer />}
                <SidebarIndicator
                  isOpen={!isRightSideDrawerCollapsed}
                  placement={'right'}
                  size={'large'}
                  tooltip={{
                    enable: true,
                    placement: 'left',
                    text: `${isRightSideDrawerCollapsed ? 'Open' : 'Close'} Drawer`,
                  }}
                  onClick={onRightArrowClick}
                />
              </RightSideDrawerContainer>
            )}
          </RightSideContentWrapper>
        </RightSideContainer>
      </LayoutContainer>
    </RightDrawerContext.Provider>
  );
};

export default SecuredPageLayout;
