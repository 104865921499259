import ApiInstance from 'utils/instances';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AddQueryForClient = async (payload: any) => {
  const createResponse = await ApiInstance.post('tests/queries', payload);
  return createResponse?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateQueryForClient = async (payload: any) => {
  const createResponse = await ApiInstance.put('tests/queries', payload);
  return createResponse?.data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deleteQueryForClient = async (payload: any) => {
  const createResponse = await ApiInstance.delete(
    `tests/queries?id=${payload.id}`,
  );
  return createResponse?.data;
};
