import React from 'react';
import styled from 'styled-components';

interface CardProps {
  width?: string;
  height?: string;
  flexDirection?: string;
  marginBottom?: string;
  padding?: string;
  border?: string;
  borderRadius?: string;
  alignItems?: string;
  background?: string;
  justifyContent?: string;
}

const StyledDetailsCard = styled.div<CardProps>`
  width: ${({ width }) => width || '94%'};
  height: ${({ height }) => height || '60px'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  display: flex;
  margin-bottom: ${({ marginBottom }) => marginBottom || '20px'};
  padding: ${({ padding }) => padding || '10px 10px 10px 15px'};
  border: ${({ border }) => border || '1px solid #e4e7ec'};
  border-radius: ${({ borderRadius }) => borderRadius || '5.5px'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  background: ${({ background }) => background || '#fff'};
  justify-content: ${({ justifyContent }) => justifyContent || 'normal'};
`;
interface DetailsCardProps extends CardProps {
  children?: React.ReactNode;
}

const DetailsCard: React.FC<DetailsCardProps> = ({ children, ...props }) => {
  return <StyledDetailsCard {...props}>{children}</StyledDetailsCard>;
};

export default DetailsCard;
