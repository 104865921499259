/* eslint-disable react/prop-types */
import * as React from 'react';

function Stats(props: React.SVGProps<SVGSVGElement>): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M15.75 14.625H2.25V3.375"
        stroke={props.fill || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.625 4.5L9 10.125L6.75 7.875L2.25 12.375"
        stroke={props.fill || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.625 7.3125V4.5H11.8125"
        stroke={props.fill || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoNoData = React.memo(Stats);
export default MemoNoData;
