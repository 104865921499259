import { Grid, IconButton } from '@mui/material';
import styled, { css, keyframes } from 'styled-components';
import { LayoutSize, LayoutGutter } from 'utils/styles/variables';
import { AnimatedContainerProps, AnimateWrapperProps } from './types';

export const AnimationName = 'ease-out';
export const AnimationTime = '0.5s';
const HeightAnimationTime = '1s';

interface RightsideDrawerTypes {
  animate?: boolean;
  collapse?: boolean;
  visible?: boolean;
}

export const RightToLeftAnimation = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const LeftToRightAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const LayoutContainer = styled(Grid)<{ animate: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  opacity: 0;
  transition:
    opacity ${AnimationName} ${HeightAnimationTime},
    background ${AnimationName} ${HeightAnimationTime};

  ${({ animate }: AnimatedContainerProps) =>
    animate &&
    css`
      opacity: 1;
    `};
`;

export const LayoutWrapper = styled(Grid)<AnimateWrapperProps>`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  height: 100%;

  ${({ animate }: AnimatedContainerProps) =>
    animate &&
    css`
      transition: opacity ${AnimationName} ${HeightAnimationTime};
    `}
`;

export const LeftSideContainer = styled(Grid)<any>`
  position: relative;
  width: ${LayoutGutter?.sidebar?.expandWidth}px;
  opacity: 0;
  transition:
    opacity ${AnimationName} ${AnimationTime},
    width ${AnimationName} ${AnimationTime};

  ${({ collapse }: any) =>
    collapse &&
    css`
      width: ${LayoutGutter?.sidebar?.collapseWidth}px;
    `}

  ${({ visible }: any) =>
    visible &&
    css`
      opacity: 1 !important;
    `}
`;

export const RightSideContainer = styled(Grid)<{
  collapse: boolean;
  visible: boolean;
}>`
  width: calc(100% - ${LayoutGutter?.sidebar?.expandWidth}px) !important;
  height: 100%;
  padding: ${LayoutGutter?.rightSide?.x};
  padding-left: 0;
  opacity: 0;
  transition:
    opacity ${AnimationName} ${AnimationTime},
    animation ${AnimationName} ${AnimationTime};

  ${({ collapse }: any) =>
    collapse &&
    css`
      width: calc(100% - ${LayoutGutter?.sidebar?.collapseWidth}px) !important;
    `}

  ${({ visible }: any) =>
    visible &&
    css`
      opacity: 1 !important;
    `}
`;

export const RightSideContentWrapper = styled(Grid)<RightsideDrawerTypes>`
  width: calc(
    100% - ${LayoutGutter?.rightSide?.drawer?.expandWidth}px
  ) !important;
  height: 100%;
  padding: ${LayoutGutter?.rightSide?.content?.x};
  border-radius: 16px 0px 0px 16px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  background: ${({ theme }) => theme.backgroundShade};

  transition:
    width ${AnimationName} ${AnimationTime},
    display ${AnimationName} ${AnimationTime},
    padding ${AnimationName} ${HeightAnimationTime},
    height ${AnimationName} ${HeightAnimationTime},
    opacity ${AnimationName} 0.8s;

  ${({ animate }: AnimatedContainerProps) =>
    animate &&
    css`
      opacity: 1;
      padding: ${LayoutGutter.y} 0 1rem ${LayoutGutter.y} !important;
      height: ${LayoutSize.headerHeght};
    `}

  ${({ collapse }: any) =>
    collapse &&
    css`
      border-radius: 16px;
      width: calc(
        100% - ${LayoutGutter?.rightSide?.drawer?.collapseWidth}px
      ) !important;
    `}
`;

export const RightSideDrawerContainer = styled(Grid)<RightsideDrawerTypes>`
  width: ${LayoutGutter?.rightSide?.drawer?.expandWidth}px !important;
  height: 100%;
  position: relative;

  border-radius: 0px 16px 16px 0px;
  border-top: 1px solid #e4e7ec;
  border-right: 1px solid #e4e7ec;
  border-bottom: 1px solid #e4e7ec;
  background: #f2f4f7;

  transition:
    width ${AnimationName} ${AnimationTime},
    display ${AnimationName} ${AnimationTime},
    padding ${AnimationName} ${HeightAnimationTime},
    height ${AnimationName} ${HeightAnimationTime},
    opacity ${AnimationName} 0.8s;

  ${({ animate }: AnimatedContainerProps) =>
    animate &&
    css`
      opacity: 1;
      padding: ${LayoutGutter.y} 0 1rem ${LayoutGutter.y} !important;
      height: ${LayoutSize.headerHeght};
    `}

  ${({ collapse }: any) =>
    collapse &&
    css`
      border: 0;
      width: 0px !important;
      width: ${LayoutGutter?.rightSide?.drawer?.collapseWidth}px !important;
    `}
`;

export const HeaderContainer = styled(Grid)<AnimatedContainerProps>`
  width: 100%;
  height: 0;
  padding: 0;
  opacity: 0;

  transition: opacity ${AnimationName} 0.8s;

  ${({ animate }: AnimatedContainerProps) =>
    animate &&
    css`
      opacity: 1;
      padding: ${LayoutGutter.x} ${LayoutGutter.y} !important;
      height: ${LayoutSize.headerHeght};
    `}
`;

export const HelixLogoWrapper = styled('img')``;

export const ContentWrapper = styled(Grid)<AnimatedContainerProps>`
  width: 100%;
  height: ${LayoutSize.contentHeight};
  padding: 0 ${LayoutGutter.x};
  margin: 0 auto;

  ${({ animate }: AnimatedContainerProps) =>
    animate &&
    css`
      height: ${LayoutSize.contentHeight};
    `}
`;

export const FooterContainer = styled(Grid)`
  width: 100%;
  height: ${LayoutSize.footerHeight};
`;

export const ToggleButton = styled(IconButton)<{
  collapse: boolean;
  placement: 'left' | 'right';
}>`
  position: absolute !important;
  top: 0;
  bottom: 0;
  margin: auto auto !important;
  right: -20px;
  z-index: 1;
  width: 20px;
  height: 20px;
  padding: 0 !important;
  font-size: 20px !important;
  border-radius: 4px !important;
  background: none !important;
  border: 0 !important;

  transition:
    margin 250ms ease-in 250ms,
    width 250ms ease-in 250ms,
    right ${AnimationName} ${AnimationTime},
    transform 250ms ease-in 250ms !important;

  svg {
    width: 26px !important;
    height: 26px !important;
  }

  ${({ placement }) =>
    placement === 'left'
      ? css`
          right: -24px !important;
          left: unset;
        `
      : css`
          right: unset;
          left: -24px !important;
        `}
`;
