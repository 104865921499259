/**
 * This implements a common extended table, with rows, columns, etc.
 * It has its own types and props. It uses MUIDataTable. Columns and rows definitions
 * follow MUIDataTable's.
 */

import React from 'react';
import {
  ExtTableColumn,
  ExtTableRow,
  IExtTableGroupedPreferences,
} from './types';
import ExtTableBasic from './ExtTableBasic';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { motion } from 'framer-motion';
import ExtTableGrouped_ReorderRow from './ExtTableGrouped_ReorderRow';

const itemVariants = {
  hidden: { opacity: 0, x: -10 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.3 },
  },
};

export type ExtTableGroupedProps = {
  // On CUSTOMIZE mode, we show checkboxes to toggle field visibility
  mode: 'VIEW' | 'CUSTOMIZE';
  // To be able to store preferences on local storage we need an unique key
  customization_key?: string;
  headerTitle?: string;
  headerSubtitle?: string;
  documentTitle: string;
  groups: Record<string, ExtTableColumn[]>;
  columns: ExtTableColumn[];
  rows: ExtTableRow[];
  cellRenderer?: (row: ExtTableRow, column: ExtTableColumn) => JSX.Element;
  // Notify any parent that preferences have changed
  onPreferencesSaved?: (new_preferences: IExtTableGroupedPreferences) => void;
  show_dimmed?: boolean;
  is_reorder?: boolean;
};

// Invert rows/columns
export default function ExtTableGrouped_Group({
  groupname,
  columns,
  mode,
  isRowSelected,
  setRowSelected,
  setGroupVisible,
  cellRenderer,
  getRowOrder,
  onRowReordered,
  grouprows,
  show_dimmed,
  is_reorder,
}: ExtTableGroupedProps & {
  groupname: string;
  groupcolumns: ExtTableColumn[];
  grouprows: ExtTableRow[];
  getRowOrder: (row: ExtTableRow) => number | undefined;
  isRowSelected: (row: ExtTableRow) => boolean;
  setRowSelected: (row: ExtTableRow, selected: boolean) => void;
  setGroupVisible: (visible: boolean) => void;
  onRowReordered: (start_index: number, end_index: number) => void;
}): JSX.Element {
  const { attributes, setNodeRef, listeners, transform, transition } =
    useSortable({
      id: groupname,
    });
  const new_rows = grouprows;
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  if (!new_rows) {
    return <></>;
  }
  // We will mark the group as visible if at least one row is visible
  const is_visible = new_rows.some(row => isRowSelected(row));

  return (
    <motion.div key={groupname} variants={itemVariants}>
      <div
        {...attributes}
        ref={setNodeRef}
        style={{
          display: 'flex',
          flexDirection: 'column',
          ...style,
          position: 'relative',
        }}
      >
        {is_reorder ? (
          <ExtTableGrouped_ReorderRow
            groupname={groupname}
            listeners={listeners}
            is_visible={is_visible}
            setIsVisible={setGroupVisible}
          />
        ) : null}

        {is_reorder === false && (
          <>
            <div
              style={{
                padding: '10px',
                display: 'flex',
                justifyContent: 'center',
                background: '#192335',
                position: 'relative',
              }}
            >
              <span
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: 'white',
                  fontFamily: 'inter',
                  lineHeight: 'normal',
                }}
              >
                {groupname}
              </span>
            </div>
            <div>
              <ExtTableBasic
                columns={columns}
                rows={new_rows}
                title=""
                cellRenderer={cellRenderer}
                allow_dragging={mode === 'CUSTOMIZE'}
                show_select_column={mode === 'CUSTOMIZE'}
                isRowSelected={isRowSelected}
                setRowSelected={setRowSelected}
                getRowOrder={getRowOrder}
                onRowReordered={onRowReordered}
                elevation={0}
              />
            </div>
          </>
        )}

        {show_dimmed && (
          <div
            style={{
              background: '#eee',
              width: '100%',
              position: 'absolute',
              height: '100%',
              opacity: '0.6',
              zIndex: '999',
            }}
          ></div>
        )}
      </div>
    </motion.div>
  );
}
