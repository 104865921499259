import { styled, Grid, Card } from '@mui/material';
import { css } from 'styled-components';
import { LayoutGutter } from 'utils/styles/variables';

export const AnimationName = 'ease-out';
export const AnimationTime = '0.5s';
const HeightAnimationTime = '1s';

export const Container = styled(Grid)`
  width: 100%;
  height: 100%;
`;

export const ContentWrapper = styled(Grid)<{ animate: boolean }>`
  width: 100%;
  max-width: 1440px;
  height: 0;
  padding: ${LayoutGutter.x} ${LayoutGutter.x} 0 ${LayoutGutter.x};
  margin: 0 auto;

  ${({ animate }: any) =>
    animate &&
    css`
      height: 100%;
      opacity: 1;
    `}
`;

export const CardWrapper = styled(Card)<any>(
  ({ theme }) => `
  position: relative;
  width: 100%;
  height: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid ${theme.palette?.primary?.borderColor};
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);

  svg {
    font-size: 30px;
  }
`,
);

export const CardMask = styled('div')<any>`
  position: absolute;
  top: 0;
  right: 0;
  width: 34vw !important;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(250.75, 250.75, 250.75, 1) 0%,
    rgba(242.25, 242.25, 242.25, 1) 100%
  );
  background-color: rgba(255, 255, 255, 1);
  background-position: 50% 50%;
  background-size: cover;
`;

export const HeaderContainer = styled(Grid)<{ animate: boolean }>`
  width: 100%;
  height: 0;
  padding: 0;
  opacity: 0;

  transition:
    display ${AnimationName} ${AnimationTime},
    padding ${AnimationName} ${HeightAnimationTime},
    height ${AnimationName} ${HeightAnimationTime},
    opacity ${AnimationName} 0.8s;

  ${({ animate }: any) =>
    animate &&
    css`
      height: 100%;
      opacity: 1;
      padding: ${LayoutGutter.x} ${LayoutGutter.y} !important;
    `}
`;
