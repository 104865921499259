import { styled, Grid, Card } from '@mui/material';

export const Container = styled(Grid)`
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled(Grid)`
  margin-right: 24px;
`;

export const CardWrapper = styled(Card)<any>(
  ({ theme }) => `
  position: relative;
  width: 80%;
  max-width: 659px;
  height: 130px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid ${theme.palette?.primary?.borderColor};
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);

  svg {
    font-size: 30px;
  }
`,
);

export const CardMask = styled('div')<any>`
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(250.75, 250.75, 250.75, 1) 0%,
    rgba(242.25, 242.25, 242.25, 1) 100%
  );
  background-color: rgba(255, 255, 255, 1);
  background-position: 50% 50%;
  background-size: cover;
`;
