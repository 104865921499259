/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AllEffect,
  call,
  all,
  put,
  fork,
  select,
  takeLatest,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { getDateTime } from '@common/dateTime';
import { INSIGHT_MOCK_DATA } from '@common/constant';
import { actions } from './slice';
import { actions as globalActions } from '../Global/slice';
import { getConfigState } from '../Global/selector';
import { getChatInitResponseState } from './selector';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { v4 as uuidv4 } from 'uuid';

import {
  ChatFeedbackResponse,
  BackendChatInitResponse,
  ChatInsightResponseInterface,
  ChatState,
  HistoricConversation,
  StreamedNode,
  StreamedTextNode,
  StreamingConversation,
  IBackendGroupedInsightData,
  ChatFeedbackInterface,
} from './types';
import { convertBackendHistoricChat } from './utils';
import {
  getChatInit,
  getInsights,
  submitChatFeedback,
} from './api';
import { MARKDOWN_DEMO } from './markdown-demo';

let concurrentQueryCount = 0; // Initialize the count

function* loadInitialChatHistory() {
  const initial_conversation: HistoricConversation = {
    query_id: uuidv4(),
    chat_log_id: null,
    visible_prompt: null,
    status: 'COMPLETED',
    time: getDateTime(),
    nodes: [
      {
        type: 'text',
        data: 'Hi, Welcome to Helix. Ask me anything about Private Markets!',
      },
    ],
  };
  yield put(actions.pushHistoricConversation(initial_conversation));
  try {
    const userType = localStorage.getItem('tifin-ai-user-type');
    if (userType === 'SUPER_ADMIN') {
      throw '-- Unauthorized Access --';
    }
    yield put(actions.setInitialChatHistoryLoading());
    const response: BackendChatInitResponse = yield call(getChatInit);
    yield put(
      actions.storeChatToken({
        initToken: response.user,
        isValid: response.isValid,
      }),
    );
    if (response) {
      const history: HistoricConversation[] = response.chatHistory
        .map(convertBackendHistoricChat)
        .filter((chat): chat is HistoricConversation => chat !== null);
      console.log('Initial chat history:', history);
      yield put(
        actions.storeInitialChatHistory({
          ...response,
          history,
        }),
      );
    } else {
      throw '-- Failed to response --';
    }
  } catch (error: any) {
    console.error(error);
    if (
      error &&
      error.response &&
      error.response.status &&
      error.response.status >= 400
    ) {
      const errorMessage: string =
        (error.response.data && error.response.data.message) ??
        'Session expired. Please login again';
      yield put(
        globalActions.displayToast({
          duration: 3000,
          toastMessage: errorMessage,
          toastType: 'error',
        }),
      );
    }
  }
}

function createEventSourceChannel(url: string, api_payload: any) {
  return eventChannel(emitter => {
    const eventSource = fetchEventSource(url, {
      openWhenHidden: true,
      onmessage: (event: any) => {
        if (!event.data) {
          return;
        }
        const node = JSON.parse(event.data);
        emitter(node); // Emitir el nodo para que la saga pueda reaccionar a él
      },
      onerror: (error: any) => {
        console.error('EventSource failed:', error);
        emitter({ type: 'network_error', error }); // Cerrar el canal si hay un error
      },
    });

    // La función de retorno es el 'unsubscribe' o 'cleanup'
    return () => {};
  });
}

// function waitForStreamEnd() {
//   console.log('streamHasEnded ....');
//   // yield put(actions.isQueryResponseStreaming(false));
// }

function* searchQuery(request: any): Generator<any, void, any> {
  const {
    payload: { query, queryId, selectedId },
  } = request;
  try {
    const userType = localStorage.getItem('tifin-ai-user-type');
    if (userType === 'SUPER_ADMIN') {
      throw '-- Unauthorized Access --';
    }
    let conversation: StreamingConversation;

    // test for markdown
    if (query === 'demo markdown') {
      conversation = {
        query_id: queryId || uuidv4(),
        chat_log_id: null, // We don't have Mongo id yet
        visible_prompt: query,
        status: 'COMPLETED',
        time: getDateTime(),
        nodes: [{ type: 'text', data: MARKDOWN_DEMO }],
      };
      yield put(actions.pushNewConversation(conversation));
      return;
    }

    if (!selectedId) {
      if (!query) throw 'Query is missing';

      let _label = query;
      try {
        _label = decodeURI(query);
      } catch {
        console.log('Retaining searchQuery as it is: ', query);
      }
      conversation = {
        query_id: queryId || uuidv4(),
        chat_log_id: null, // We don't have Mongo id yet
        visible_prompt: _label,
        status: 'STARTED',
        time: getDateTime(),
        nodes: [],
      };
      yield put(actions.pushNewConversation(conversation));
    } else {
      const immutable_conversation = yield select(
        (state: { chat: ChatState }) => state.chat.conversation_current,
      );
      if (
        !immutable_conversation ||
        immutable_conversation.query_id !== selectedId
      ) {
        throw 'Conversation not found';
      }
      conversation = {
        ...immutable_conversation,
        nodes: [...immutable_conversation.nodes],
        status: 'STARTED',
      };
    }
    localStorage.setItem('chat-initiated', 'true');

    yield put(actions.searchQueryFetch());

    const { initToken: chatToken } = yield select(getChatInitResponseState) ||
      {};
    const configState = yield select(getConfigState);

    if (!chatToken) {
      console.warn('No token from init!');
    }

    const api_payload: any = {
      query,
      skip_cache: configState.skipCache,
      token: ((chatToken || '') as string).replace('Bearer ', ''),
    };

    if (selectedId) {
      api_payload.selectedId = selectedId;
    }

    const queryParams = new URLSearchParams(api_payload).toString();

    const url = `${process.env.REACT_APP_API_BASE_URL}v2/chat?${queryParams}`;

    const channel1 = yield call(createEventSourceChannel, url, api_payload);

    yield put(actions.isQueryResponseStreaming(true));

    let node_history: StreamedNode[] = [];
    let working_text_node: StreamedTextNode | null = null;
    let chat_log_id: string | null = null;

    try {
      while (true) {
        const node: StreamedNode = yield take(channel1); // Tomar eventos del canal
        //console.log( { node } );

        if (node_history.length == 0) {
          yield put(
            actions.searchQuerySuccess({
              processingQuery: false,
              query: '',
            }),
          );
        }
        if (node.type == 'start_conversation') {
          console.log('Start conversation', node.chat_log_id);
          //node_history = [...node_history, node];
          node_history = [node];
          chat_log_id = node.chat_log_id;
          yield put(
            actions.updateConversation({
              ...conversation,
              status: 'IN_PROGRESS',
              nodes: node_history,
              chat_log_id,
            }),
          );
        } else if (node.type == 'end_of_conversation') {
          console.log('End of conversation');
          node_history = [...node_history, node];
          yield put(
            actions.updateConversation({
              ...conversation,
              status: 'COMPLETED',
              nodes: node_history,
              chat_log_id,
            }),
          );
          yield put(actions.isQueryResponseStreaming(false));
          break;
        } else if (node.type == 'network_error') {
          node_history.push(node);
          console.error('searchQuery error:', node.error);
          // Decrement the count when an error occurs
          concurrentQueryCount--;

          // Check if there are no more pending concurrent queries
          if (concurrentQueryCount === 0) {
            yield put(
              actions.searchQuerySuccess({
                processingQuery: false,
                query: '',
              }),
            );
          }
          yield put(
            actions.updateConversation({
              ...conversation,
              status: 'ERROR',
              nodes: node_history,
              chat_log_id,
            }),
          );
          yield put(actions.isQueryResponseStreaming(false));
          break;
        } else if (node.type == 'partial') {
          if (working_text_node === null) {
            console.log('Partial text started');
            working_text_node = {
              type: 'text',
              data: node.data,
            };
          } else {
            working_text_node.data += node.data;
          }

          yield put(
            actions.updateConversation({
              ...conversation,
              status: 'IN_PROGRESS',
              nodes: [...node_history, { ...working_text_node }],
              chat_log_id,
            }),
          );
        } else if (node.type == 'end_of_partial' && working_text_node) {
          console.log('Partial text ended', working_text_node.data);
          node_history = [...node_history, { ...working_text_node }];
          working_text_node = null;
        } else {
          console.log('New node', node, node_history);
          node_history = [...node_history, node];

          yield put(
            actions.updateConversation({
              ...conversation,
              status: 'IN_PROGRESS',
              nodes: node_history,
              chat_log_id,
            }),
          );
        }
      }
    } catch (e) {
      console.log('Error', e);
      yield put(
        actions.updateConversation({
          ...conversation,
          status: 'ERROR',
        }),
      );
    } finally {
      console.log('Channel closed or saga cancelled', node_history);
      yield put(actions.isQueryResponseStreaming(false));
    }
  } catch (error) {
    console.error('searchQuery error:', error);
    // Decrement the count when an error occurs
    concurrentQueryCount--;

    // Check if there are no more pending concurrent queries
    if (concurrentQueryCount === 0) {
      yield put(
        actions.searchQuerySuccess({
          processingQuery: false,
          query: '',
        }),
      );
    }
  } finally {
    console.log('Stream has ended!!!');
    yield put(actions.isQueryResponseStreaming(false));
  }
}

function* watchConcurrentQueries() {
  yield takeEvery(actions.searchQueryRequest.type, handleConcurrentQuery);
}

function* handleConcurrentQuery(payload: any) {
  try {
    concurrentQueryCount++; // Increment the count when a new query is started
    yield call(searchQuery, payload);
  } catch (error) {
    console.error('handleConcurrentQuery Error:', error);
    concurrentQueryCount--; // Decrement the count when an error occurs
  }
}

function* chatFeedback(request: any): Generator<any, void, any> {
  const { payload } = request as { payload: ChatFeedbackInterface };
  try {
    const { scale, comment } = payload;
    if (!scale && !comment) throw '-- Missing Payload --';

    yield put(actions.submitChatFeedbackFetch());
    const { user: chatToken } = yield select(getChatInitResponseState) || {};
    const response: ChatFeedbackResponse = yield call(submitChatFeedback, {
      payload,
      /* headers: {
        'x-tifin-ai-auth': chatToken,
      },*/
    });
    if (response && response.message && response.feedback_id) {
      yield put(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'success',
          toastMessage: response.message,
        }),
      );
    }
    yield put(
      actions.submitChatFeedbackSuccess({
        ...payload,
        response,
        loading: false,
        openModal: false,
      }),
    );
  } catch (error) {
    console.log(error);
    yield put(
      actions.submitChatFeedbackSuccess({
        loading: false,
        openModal: false,
        scale: null,
        chatLogId: null,
        comment: null,
      }),
    );
  }
}

export function* chatSaga(): Generator<AllEffect<any>, void, unknown> {
  yield all([
    takeLatest(actions.loadInitialChatHistory.type, loadInitialChatHistory),
    fork(watchConcurrentQueries),
    takeLatest(actions.submitChatFeedbackRequest.type, chatFeedback),
  ]);
}
