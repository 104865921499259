import ApiInstance from '@instances/index';

export type GetImageUrlProps = {
  image: string;
};

export type GetImageUrlResponse = {
  url: string;
};

export async function getImageUrl({
  image,
}: GetImageUrlProps): Promise<GetImageUrlResponse> {
  return ApiInstance.get(`/chat/getImageUrl`, { params: { image } }).then(
    ({ data: { data: url } }) => ({ url }),
  );
}
