import React, { Fragment } from 'react';
import { LoadingButton as MuiButton } from '@mui/lab';
import styled, { css, useTheme, keyframes } from 'styled-components';
import { makeStyles } from '@mui/styles';
import CustomTooltip from '../Tooltip';

type startIconStylesProps = {
  minWidth?: string;
  marginLeft?: string;
  marginRight?: string;
  fontSize?: string;
};

type Props = {
  sx?: any;
  blink?: boolean;
  type?: 'submit' | 'reset' | 'button';
  id?: string;
  width?: any;
  loading?: boolean;
  color?: string;
  variant?: 'contained' | 'outlined' | 'text';
  fullWidth?: boolean;
  customHeight?: string;
  cursor: string;
  active?: boolean;
  collapse?: boolean;
  notification?: boolean;
  tooltipText?: string;
  enableNotifications?: boolean;
  children?: React.ReactNode;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  dataTestId?: string;
  startIconStyles?: startIconStylesProps;
  startIcon?: React.ReactNode;
  loadingPosition?: string;
};

const pulseAnimation = keyframes`
  0%, 100% {
    background-color: rgba(204, 233, 250, 0.3);
    color: white;
  }
  50% {
    background-color: rgb(1, 46, 76);
    color: white;
  }
`;

const Button = styled(MuiButton)<any>`
  svg {
    font-size: 24px;
  }
  ${({ collapse, startIconStyles }: any) =>
    collapse &&
    css`
      transition:
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        width 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        font-size 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        min-width 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms opacity 500ms
          cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.6s;

      .MuiButton-startIcon {
        margin-left: ${startIconStyles?.marginLeft || '-2px'};
        margin-right: ${startIconStyles?.marginRight || '8px'};
        min-width: 38px;
        .MuiSvgIcon-root {
          font-size: 24px;
        }
      }
    `}

  ${({ blink }) =>
    blink &&
    css`
      animation: ${pulseAnimation} 1.5s ease-in-out infinite;
    `}

  ${({ startIconStyles }) =>
    startIconStyles &&
    css`
      .MuiButton-startIcon ${startIconStyles}
    `}
`;

const HeaderMenuNotify = styled('span')<any>`
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  right: 0;
  border-top: 8px solid transparent;
  border-right: 8px solid transparent;
  clip-path: polygon(0 0, 100% 0, 0 100%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  transform: scaleX(-1);
  transition:
    background-color cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
    transform cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s;

  ${({ enableNotifications, notification }: any) =>
    enableNotifications &&
    css`
      background-color: ${notification ? '#FF0A9D' : 'rgb(255 255 255 / 21%)'};
    `}
`;

const Label = styled('label')<{ collapse: boolean }>`
  width: auto;
  opacity: 1;

  ${({ collapse }: any) =>
    collapse &&
    css`
      width: 0px !important;
      opacity: 0 !important;
    `}

  transition:
    width cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
    opacity cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.6s;
`;

const useButtonStyles = makeStyles({
  root: (props: any) => ({
    '&.MuiButtonBase-root': {
      position: 'relative',
      width: '100%',
      maxWidth: '100%',
      height: props.collapse ? '56px' : '100px',
      minWidth: 'unset',
      padding: props.collapse ? '16px 19px' : '10px',
      display: 'flex',
      justifyContent: props.collapse ? 'flex-start' : 'space-between',
      flexDirection: props.collapse ? 'row' : 'column',
      alignItems: props.collapse ? 'center' : 'flex-start',
      overflow: 'hidden',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: 'normal',
      fontStyle: 'normal',
      textAlign: 'left',
      textTransform: 'capitalize',
      cursor: props.cursor ?? 'pointer',
      color: props.active
        ? props?.theme?.solidWhite
        : props?.theme?.inactiveWhite,

      borderRadius: '12px',
      borderWidth: '2px',
      border: props.active
        ? '2px solid rgba(174, 198, 249, 0.30)'
        : props.collapse
          ? '2px solid transparent'
          : '2px solid rgba(174, 198, 249, 0.30)',
      backgroundColor: props.active && 'rgba(204, 233, 250, 0.30)',
      boxShadow: props.active && '0px 0px 10px 0px rgba(0, 0, 0, 0.10)',

      transition: `
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        width 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        min-width 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        max-width 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
      `,

      '&:hover': {
        backgroundColor: '#012e4c',
        border: '2px solid rgba(174, 198, 249, 0.30)',
      },

      ...(props.blink && {
        animation: `${pulseAnimation} 1.5s ease-in-out infinite !important`,
      }),

      '&.MuiLoadingButton-loading': {
        color: props?.theme?.solidWhite,
        '&.MuiLoadingButton-loadingIndicator': {
          position: 'unset',
          color: props?.theme?.solidWhite,
        },
      },

      ...(props.enableNotifications && props.notification
        ? {
            '&:before': {
              content: '',
              position: 'absolute',
              width: 0,
              height: 0,
              top: 0,
              right: 0,
              borderTop: props?.notification
                ? '10.5px solid #151412'
                : '10.5px solid #151412',
              borderRight: props?.notification
                ? '10.5px solid #151412'
                : '10.5px solid #151412',
              backgroundColor: props?.notification ? '#151412' : '',
              clipPath: 'polygon(0 0, 100% 0, 0 100%)',
              transform: 'scaleX(-1)',
              transition:
                'background-color cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s, transform cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s',
            },
          }
        : {}),
    },
  }),
});

function NavButton({
  sx,
  onClick,
  children,
  className,
  width = 'auto',
  type = 'button',
  variant = 'text',
  tooltipText,
  customHeight,
  blink = false,
  active = false,
  disabled = false,
  collapse = false,
  fullWidth = false,
  dataTestId = undefined,
  notification = false,
  startIconStyles = {},
  enableNotifications = false,
  ...rest
}: Props): React.JSX.Element {
  const theme = useTheme();
  const classes = useButtonStyles({
    theme,
    active,
    collapse,
    notification,
    enableNotifications,
    ...rest,
  });
  const RenderButton = (
    <Button
      sx={sx}
      type={type}
      width={width}
      blink={blink}
      active={active}
      variant={variant}
      disabled={disabled}
      collapse={collapse}
      onClick={onClick}
      className={`${classes?.root} ${className}`}
      fullWidth={fullWidth}
      customHeight={customHeight}
      data-cy={dataTestId}
      notification={notification}
      startIconStyles={startIconStyles}
      enableNotifications={enableNotifications}
      {...rest}
    >
      <Label collapse={collapse}>{children}</Label>
      {enableNotifications && notification && <HeaderMenuNotify />}
    </Button>
  );
  return (
    <Fragment>
      {collapse && tooltipText ? (
        <CustomTooltip placement={'right'} title={tooltipText}>
          {RenderButton}
        </CustomTooltip>
      ) : (
        RenderButton
      )}
    </Fragment>
  );
}

export default NavButton;
