import styled from 'styled-components';
import { Card, CardActionArea, Grid } from '@mui/material';

const ContentHeight = 'calc(100% - 112px)';

export const SuggestionsContainer = styled(Grid)<any>`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const SuggestionsWrapper = styled(Grid)<{ height?: string }>`
  width: 100%;
  height: ${props => props.height ?? ContentHeight};
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 4px !important;
    height: 2px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.lightGrey} !important;
    border-radius: 20px !important;
    margin-bottom: 10px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
    margin-top: 12px !important;
  }
`;

export const CardContainer = styled(Card)<any>`
  &.MuiCard-root {
    width: 100%;
    height: auto;
    border-radius: 12px;
    border: 1px solid ${props => props.theme.borderColor};
    background: ${props => props.theme.solidWhite};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  }
`;

export const CardWrapper = styled(CardActionArea)`
  &.MuiCardActionArea-root {
    width: 100%;
    // min-height: 100px;
    padding: 1rem;
    cursor: pointer;
  }
`;

export const GridContainer = styled(Grid)<any>`
  .MuiDivider-root {
    .MuiDivider-wrapper {
      padding-left: 0;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: ${props => props.theme.main};
    }
    &:before {
      display: none;
    }
  }
`;

export const GridWrapper = styled(Grid)<any>``;
