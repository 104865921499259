import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actions as chatActions } from '@features/Chat/slice';
import Card from './Card';
import { SuggestionsContainer, SuggestionsWrapper } from './styles';
import SkeletonCard from '@components/Skeleton';
import { motion } from 'framer-motion';
import { InsightData } from '@features/Global/types';

const listVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, x: -10 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.3 },
  },
};

function Suggestions({
  loading,
  data,
}: {
  loading: boolean;
  data: InsightData[] | null;
}): React.JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onCardClick = (query: string) => {
    navigate('/conversations');
    dispatch(chatActions.searchQueryRequest({ query }));
  };

  return (
    <SuggestionsContainer height={'calc(100% - 86px)'}>
      {loading ? (
        <SkeletonCard />
      ) : (
        <Fragment>
          <motion.div
            variants={listVariants}
            initial="hidden"
            animate="visible"
          >
            <SuggestionsWrapper container rowGap={2} height={'100%'}>
              {data && data.map((l, idx) => (
                <Card
                  key={idx}
                  title={l.title}
                  type={l.type}
                  data={l.data}
                  onClick={onCardClick}
                  variants={itemVariants}
                />
              ))}
            </SuggestionsWrapper>
          </motion.div>
        </Fragment>
      )}
    </SuggestionsContainer>
  );
}

export default Suggestions;
