import React from 'react';
import {
  MenuItem,
  Grid,
  InputLabel,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { LightSubHeading } from '@components/Typography';
import {
  focusOptions,
  geographyOptions,
  statusOptions,
  stylesOptions,
} from './constants';
import { StyledFormControl, StyledTextField } from './style';
interface FormData {
  name: string;
  manager_name: string;
  family_name: string;
  style: string;
  focus: string;
  geography: string;
  status: string;
}

interface AddFundsFormProps {
  formData: FormData;
  setFormData: (newFormData: any) => void;
  errorMessage: boolean;
  setErroMessage: any;
}

const AddFundsForm: React.FC<AddFundsFormProps> = ({
  formData,
  setFormData,
  errorMessage,
  setErroMessage,
}) => {
  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
  ) => {
    const target = event.target as
      | HTMLInputElement
      | { name: string; value: string };
    const { name, value } = target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
    setErroMessage(false);
  };

  return (
    <div style={{ margin: 20 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LightSubHeading mb={1} color="#000">
            {`Fund Name`}
          </LightSubHeading>
          <StyledTextField
            fullWidth
            placeholder="Fund Name"
            variant="outlined"
            name="name"
            value={formData.name}
            onChange={handleChange}
            error={errorMessage}
            helperText={errorMessage ? 'Fund name already exists' : ''}
          />
        </Grid>

        <Grid item xs={6}>
          <LightSubHeading mb={1} color="#000">
            {`Manager Name`}
          </LightSubHeading>
          <StyledTextField
            fullWidth
            placeholder="Manager Name"
            variant="outlined"
            name="manager_name"
            value={formData.manager_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <LightSubHeading mb={1} color="#000">
            {`Fund Family`}
          </LightSubHeading>
          <StyledTextField
            fullWidth
            placeholder="Fund Family"
            variant="outlined"
            name="family_name"
            value={formData.family_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <StyledFormControl fullWidth>
            <InputLabel shrink={true}>Styles</InputLabel>
            <Select
              name="style"
              value={formData.style}
              onChange={handleChange}
              notched={true}
              sx={{
                '& .MuiInputBase-root': {
                  height: 40,
                },
                '& .MuiSelect-select': {
                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
                  paddingY: '9px',
                },
              }}
            >
              {stylesOptions?.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </Grid>
        <Grid item xs={6}>
          <StyledFormControl fullWidth>
            <InputLabel shrink={true}>Focus</InputLabel>
            <Select
              name="focus"
              disabled={!formData.style}
              notched={true}
              value={formData.focus}
              onChange={handleChange}
              sx={{
                '& .MuiInputBase-root': {
                  height: 40,
                },
                '& .MuiSelect-select': {
                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',

                  paddingY: '9px',
                },
              }}
            >
              {formData?.style &&
                focusOptions?.[formData?.style]?.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          </StyledFormControl>
        </Grid>
        <Grid item xs={6}>
          <StyledFormControl fullWidth>
            <InputLabel shrink={true}>Geography</InputLabel>
            <Select
              name="geography"
              value={formData.geography}
              onChange={handleChange}
              notched={true}
              sx={{
                '& .MuiInputBase-root': {
                  height: 40,
                },
                '& .MuiSelect-select': {
                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',

                  paddingY: '9px',
                },
              }}
            >
              {geographyOptions?.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </Grid>
        <Grid item xs={6}>
          <StyledFormControl fullWidth>
            <InputLabel shrink={true}>Status</InputLabel>
            <Select
              name="status"
              notched={true}
              value={formData.status}
              onChange={handleChange}
              sx={{
                '& .MuiInputBase-root': { height: 40 },
                '& .MuiSelect-select': {
                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.12)',
                  paddingY: '9px',
                },
              }}
            >
              {statusOptions?.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddFundsForm;
