// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  background-color: white;
  border: 2px solid #101828;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s, border-color 0.3s;
  margin-right: 10px;
}

.styled-checkbox input[type="checkbox"]:checked {
  background-color: #101828;
  border-color: #101828;
}

.styled-checkbox input[type="checkbox"]:checked::before {
  content: "";
  position: absolute;
  left: 4px;
  top: 1px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.styled-checkbox input[type="checkbox"]:hover {
  border-color: #101828;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/Conversation/ChatExtTable/style.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,wBAAwB;EACxB,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,oDAAoD;EACpD,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,UAAU;EACV,WAAW;EACX,mBAAmB;EACnB,yBAAyB;EACzB,wBAAwB;AAC1B;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".styled-checkbox input[type=\"checkbox\"] {\n  appearance: none;\n  -webkit-appearance: none;\n  width: 18px;\n  height: 18px;\n  background-color: white;\n  border: 2px solid #101828;\n  border-radius: 4px;\n  cursor: pointer;\n  position: relative;\n  transition: background-color 0.3s, border-color 0.3s;\n  margin-right: 10px;\n}\n\n.styled-checkbox input[type=\"checkbox\"]:checked {\n  background-color: #101828;\n  border-color: #101828;\n}\n\n.styled-checkbox input[type=\"checkbox\"]:checked::before {\n  content: \"\";\n  position: absolute;\n  left: 4px;\n  top: 1px;\n  width: 4px;\n  height: 9px;\n  border: solid white;\n  border-width: 0 2px 2px 0;\n  transform: rotate(45deg);\n}\n\n.styled-checkbox input[type=\"checkbox\"]:hover {\n  border-color: #101828;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
