import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EnterPrisesList } from './types';

export const initialState: EnterPrisesList = {
  loading: false,
  data: [],
  addEnterprise: [],
};

const enterprisesSlice = createSlice({
  name: 'enterprises',
  initialState,
  reducers: {
    getEnterprisesRequest() {},
    loadEnterprisesListFetch(state) {
      state.loading = true;
    },
    loadEnterprisesListSuccess(
      state: any,
      action: PayloadAction<{ data?: EnterPrisesList; loading: boolean }>,
    ) {
      return {
        ...state,
        data: action?.payload?.data || [],
        loading: false,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addEnterprisesRequest(state, action: PayloadAction<any>) {},
    addEnterprisesFetch(state, action: PayloadAction<any>) {
      return {
        ...state,
        addEnterprise: action?.payload?.data,
      };
    },
    addEnterprisesSuccess(state, action: PayloadAction<any>) {
      return {
        ...state,
        addEnterprise: action?.payload?.data,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateEnterpriseRequest(state, action: PayloadAction<any>) {},
  },
});

export const { actions, reducer, name: sliceKey } = enterprisesSlice;
