/* eslint-disable react/prop-types */
import * as React from 'react';

function LogoutIcon(props: React.SVGProps<SVGSVGElement>): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19.7147 3.36572L2.23971 8.28759C2.09095 8.32826 1.95835 8.4138 1.85996 8.53256C1.76157 8.65131 1.70219 8.79751 1.68989 8.95123C1.67759 9.10496 1.71298 9.25874 1.79124 9.39162C1.86949 9.52451 1.98681 9.63004 2.12721 9.69384L10.1522 13.4907C10.3094 13.5635 10.4357 13.6898 10.5085 13.847L14.3053 21.872C14.3691 22.0124 14.4747 22.1297 14.6076 22.2079C14.7404 22.2862 14.8942 22.3216 15.0479 22.3093C15.2017 22.297 15.3479 22.2376 15.4666 22.1392C15.5854 22.0408 15.6709 21.9082 15.7116 21.7595L20.6335 4.28447C20.6708 4.15695 20.6731 4.02174 20.6401 3.89302C20.6072 3.7643 20.5403 3.64681 20.4463 3.55286C20.3524 3.45891 20.2349 3.39197 20.1062 3.35904C19.9774 3.32611 19.8422 3.32842 19.7147 3.36572V3.36572Z"
        stroke={props.fill || '#1D283C'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3965 13.6032L14.634 9.36572"
        stroke={props.fill || '#1D283C'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoNoData = React.memo(LogoutIcon);
export default MemoNoData;
