import React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';
import SearchIcon from '@icons/SearchIcon';
import { SecondaryButton, TextButton } from '@components/Button';
import { UserData, UsersState } from '@features/Users/types';
import CustomSearchRender from './search';
import { SubHeading } from '@components/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { StyledTableContainer, StyledMUIDataTable } from './styles';
interface IAccordianProps extends UsersState {
  openPortfolioPopup?: (row: UserData | any) => void;
}

function AccordionTable({
  data = [],
  loading = false,
}: IAccordianProps): React.JSX.Element {
  const components = {
    SearchIcon: () => (
      <React.Fragment>
        <SecondaryButton>
          <SearchIcon />
        </SecondaryButton>
      </React.Fragment>
    ),
  };
  return (
    <StyledTableContainer sx={{ position: 'relative' }}>
      {loading ? (
        <React.Fragment>
          <Box
            sx={{
              width: '100%',
              height: '50vh',
            }}
          >
            <Skeleton
              variant="rectangular"
              width={'100%'}
              height={'100%'}
              style={{ borderRadius: '8px' }}
            />
          </Box>
        </React.Fragment>
      ) : (
        <Grid
          sx={{
            maxWidth: '100%',
            maxHeight: '80vh',
            border: '1px solid #e0e0e0',
          }}
        >
          <StyledMUIDataTable
            title={<SubHeading>List Of Users</SubHeading>}
            data={data}
            columns={[
              {
                name: 'firstName',
                label: 'First Name',
                options: {
                  setCellProps: () => ({ style: { width: '271px' } }),
                },
              },
              {
                name: 'lastName',
                label: 'Last Name',
                options: {
                  setCellProps: () => ({ style: { width: '271px' } }),
                },
              },
              {
                name: 'email',
                label: 'Email',
                options: {
                  setCellProps: () => ({ style: { width: '271px' } }),
                },
              },
              {
                name: 'role',
                label: 'Advisor',
                options: {
                  setCellProps: () => ({ style: { width: '271px' } }),
                },
              },
              {
                name: '',
                label: '',
                options: {
                  setCellProps: () => ({
                    style: { width: '50px', textAlign: 'right' },
                  }),
                  customBodyRender: () => {
                    return (
                      <TextButton>
                        {' '}
                        <MoreVertIcon />
                      </TextButton>
                    );
                  },
                },
              },
            ]}
            options={{
              filter: false,
              print: false,
              download: false,
              viewColumns: false,
              pagination: true,
              rowsPerPage: 8,
              rowsPerPageOptions: [5],
              selectableRows: 'none', // or "multiple" for checkboxes
              enableNestedDataAccess: '.',
              search: false,
              searchOpen: true,
              searchAlwaysOpen: true,

              customSearchRender: (searchText, handleSearch) => {
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: '1',
                    }}
                  >
                    <Box sx={{ width: 'calc(100% - 40px)' }}>
                      <CustomSearchRender
                        searchText={searchText}
                        onSearch={handleSearch}
                      />
                    </Box>
                  </Box>
                );
              },
              sort: false,
              expandableRows: false,
              expandableRowsOnClick: false,
            }}
            components={components as any}
          />
        </Grid>
      )}
    </StyledTableContainer>
  );
}

export default AccordionTable;
