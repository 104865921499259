import { Dialog, styled } from '@mui/material';

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '0 1.5rem',
    maxWidth: '1264px !important',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3),
    maxWidth: '1264px !important',
  },
  '& .MuiDialog-paper': {
    maxWidth: '1264px',
    width: '100%',
    overflowY: 'hidden',
  },
}));
