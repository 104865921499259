import { Grid, styled } from '@mui/material';
import Card from '@components/Card';
import LoadingButton from '@mui/lab/LoadingButton';

import { Accordion as MuiAccordion } from '@mui/material';
import { AccordionSummary as MuiAccordionSummary } from '@mui/material';
import { AccordionDetails as MuiAccordionDetails } from '@mui/material';

export const ImageWrapper = styled(Grid)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const CustomCard = styled(Card)<any>`
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12) !important;
`;

export const CustomButton = styled(LoadingButton)<{
  textColor?: string;
  borderColor?: string;
  width?: string;
}>`
  width: ${props => props.width};ˇ
  color: ${props => props.theme['black']};
  text-transform: capitalize;
  border-radius: 4px;
  border-color: ${props => props.borderColor};
  border: 1px solid ${props => props.borderColor};
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);

  &.MuiLoadingButton-loading {
    opacity: 1;
    background-color: transparent;
  }
`;

export const StyledSFAccordion = styled(MuiAccordion)`
  && {
    border: 1px solid #e4e7ec;
    border-radius: 4px;
    background: #fff;
    &:before {
      height: 0;
    }
  }
`;

export const StyledSFAccordionSummary = styled(MuiAccordionSummary)`
  && {
    height: 100px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
`;

export const StyledSFAccordionDetails = styled(MuiAccordionDetails)`
  max-height: 195px;
  overflow-y: auto;
  background: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const StyledImgDiv = styled(Grid)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  max-width: 100px;
  svg {
    width: 80px;
  }
`;

export const StyledDrawerContainer = styled(Grid)`
  padding: 32px 32px 0px;
  display: flex;
  flex-direction: column;
  width: 87%;
  height: 100%;
  background: #fff;
`;

export const StyledLoginWrapper = styled(Grid)<{
  borderColor?: string;
  background?: string;
}>`
  width: 100%;
  height: auto;
  border-radius: 4px;
  border: 1px solid ${props => props.borderColor};
  background: ${props => props.background};
`;

export const StyledLogoContainer = styled(Grid)`
  display: flex;
  padding-bottom: 30px;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const CrmLogo = styled('img')`
  width: auto;
  max-width: 100px;
  height: 50px;
  object-fit: scale-down;
`;

export const StyledTextContainer = styled(Grid)`
  width: 100%;
  flex-direction: column;
  padding-bottom: 30px;
`;

export const StyledCardContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const StyledCardWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
`;

export const StyledAccordionContainer = styled(Grid)`
  width: 100%;
  height: 435px;
`;

export const StyledFooterContainer = styled(Grid)`
  width: 100%;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-bottom: 20px;
`;

export const StyledUnderlinedText = styled(Grid)`
  color: var(--neutral-600, #475467);
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  font-family: 'General Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 5px;
`;

export const StyledTextDiv2 = styled(Grid)`
  font-size: 14px;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
  padding-left: 5px;
  line-height: normal;
`;

export const StyledTextDiv1 = styled(Grid)`
  font-size: 14px;
  color: #475467;
  font-weight: 400;
  line-height: normal;
`;

export const TextContainer = styled(Grid)`
  height: 20px;

  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-direction: row;
`;

export const DetailsContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled(Grid)`
  font-size: 14px;
  color: #98a2b3;
  padding-bottom: 2px;
`;

export const LogoContainer = styled(Grid)`
  width: 55px;
  height: 55px;
  border-radius: 4px;
  background: #f2f4f7;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

export const DetailsDataCardContainer = styled(Grid)<any>`
  width: 100%;
  height: 94px;
  border-radius: 5.5px;
  border: 1px solid ${props => props.borderColor};
  background: ${props => props.theme['solidWhite']};
`;

export const DetailsDataCardWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`;

export const DetailsDataWrapper = styled(Grid)`
  width: 100%;
  padding-top: 0.5rem;
`;

export const DetailsDataContainer = styled(Grid)`
  width: 100%;
  flex-direction: column;
  display: flex;
`;

export const LogoContainer1 = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  cursor: pointer;
`;

export const LogoWrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
  padding-top: 25px;
  padding-left: 45px;
`;

export const ChipWrapper = styled(Grid)`
  padding: 0px 0px 11px 20px;
`;

export const HeaderWrapper = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

export const IconWrapper = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 10px 0px;
`;

export const DetailsDrawerContainer = styled(Grid)`
  width: 100%;
  height: 100%;
`;

export const DetailsDrawerWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  padding: 24px 24px 0 24px;
`;

export const StyledBadge = styled(Grid)`
  width: 90px;
  height: 20px;
  border-radius: 12px;
  color: #008243;
  background: var(--success-surface, #ebf5f0);
  font-size: 12px;
  border: 1px solid #b3dac7;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DescContainer = styled(Grid)`
  display: flex;
  height: 40px;
  overflow: hidden;
  color: var(--neutral-600, #475467);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-align: left;
  padding-bottom: 10px;
`;

export const TitleContainerNew = styled(Grid)`
  display: flex;
  height: 20px;
  color: var(--neutral-900, #101828);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 2px;
`;

export const ImgContainer = styled(Grid)`
  display: flex;
  height: 50px;
  padding-bottom: 15px;
`;

export const CRMCardWrapper = styled(Grid)`
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  padding: 16px 16px 20px 16px;
  margin: 0px 3px 12px 10px;
  max-width: 300px;
  width: 230px;
  height: 165px;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
`;

export const CRMCardContainer = styled(Grid)`
  align-items: center;
  justify-content: flex-start;
  display: flex;
`;

export const HeaderPartWrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SFConatctsWrapper = styled(Grid)`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 0px 10px 12px 10px;
  align-items: center;
  color: #475467;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Inter';
`;

export const SFCardTitle = styled(Grid)`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 10px 10px 5px 10px;
  align-items: center;
  font-size: 16px;
  color: #000;
  font-weight: 500;
`;

export const SFDataCardWrapper = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SFDataCardContainer = styled(Grid)`
  width: 95%;
  height: 70px;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 3px 15px 3px 5px;
  align-items: center;
  margin-bottom: 15px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
`;

export const DataCard = styled(Grid)`
  width: 100%;
  height: 150px;
  padding: 15px 0px 22px;
`;

export const SFDataHeader = styled(Grid)`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #475467;
  font-family: Inter;
`;

export const SFIconWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const StyledContainer = styled(Grid)`
  width: 55%;
  height: 45px;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 5px 10px 5px 15px;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
`;

export const StyledWrapper = styled(Grid)`
  width: 100%;
  padding: 10px 0px;
  height: 250px;
  display: flex;
  flex-direction: column;
`;

export const SFHeaderText = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  color: #101828;
`;

export const DrawerHeaderDataWrapper = styled(Grid)`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 10px 0px;
  height: 20px;
`;

export const StyledDrawerWrapper = styled(Grid)`
  display: flex;
  height: 90%;
  width: 100%;
  flex-direction: column;
`;

export const StyledSFDrawerContainer = styled(Grid)`
  display: flex;
  width: 90%;
  height: 100%;
  flex-direction: column;
  padding: 24px 24px 0 24px;
`;
