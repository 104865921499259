import React from 'react';
import styled from 'styled-components';
import renderCell from './cells';
import { ChatExtTableProps } from './ChatExtTable';
import { ExtTableColumn, ExtTableRow } from './types';
import ExtTableGrouped from './ExtTableGrouped';

export type ChatFundAttributesTableProps = Pick<
  ChatExtTableProps,
  'columns' | 'rows'
>;

function ChatFundAttributesTableUnstyled({
  columns,
  rows,
}: ChatFundAttributesTableProps): JSX.Element {
  const row = rows[0];
  const fundName = (row['fund_name'] as string) || (row['Fund Name'] as string);

  const groups = columns.reduce(
    (acc, column) => {
      const groupname = column.group || 'Other';
      if (!acc[groupname]) {
        acc[groupname] = [];
      }
      acc[groupname].push(column);
      return acc;
    },
    {} as Record<string, typeof columns>,
  );

  const fundColumns: ExtTableColumn[] = [
    {
      label: 'Fund',
      key: 'label',
      type: 'string',
      width: 185,
      color: '#86858B',
      fontSize: '12px',
    },
    ...rows.map(row => {
      const column: ExtTableColumn = {
        label: (row['fund_name'] as string) || (row['Fund Name'] as string),
        key: (row['fund_name'] as string) || (row['Fund Name'] as string),
        type: 'mixed',
        minWidth: 295,
        color: '#86858B',
        fontSize: '12px',
      };
      return column;
    }),
  ];

  const cellRenderer = (row: ExtTableRow, column: ExtTableColumn) => {
    if (column.key === 'label') {
      return <div style={{ color: '#535256' }}>{row['label'] as string}</div>;
    }
    const field_name = row['label'] as string;
    const column_type =
      columns.find(col => col.label === field_name)?.type || 'string';
    const not_undefined =
      row[column.label] === undefined ? null : row[column.label];
    return (
      <div className="cell" style={{ width: 'auto' }}>
        {renderCell(column_type, not_undefined)}
      </div>
    );
  };

  return (
    <ExtTableGrouped
      mode={'VIEW'}
      customization_key="fund_attributes"
      headerTitle={'FUND OVERVIEW'}
      headerSubtitle={fundName}
      documentTitle={`Fund Overview - ${fundName}`}
      groups={groups}
      columns={fundColumns}
      cellRenderer={cellRenderer}
      rows={rows}
    />
  );
}

const Wrapper = styled.div`
  .cell,
  .cell span {
    color: #96969b;
  }
  &td {
    vertical-align: baseline;
  }
  .cell .show-more-less-clickable {
    color: black;
    cursor: pointer;
  }
`;

export default function ChatFundAttributesTable(
  props: ChatFundAttributesTableProps,
): JSX.Element {
  return (
    <Wrapper>
      <ChatFundAttributesTableUnstyled {...props} />
    </Wrapper>
  );
}
