import { useEffect, useRef, useState } from 'react';
import { getImageUrl } from '../api';

export type UseImageUrlProps = {
  image: string | null;
};

export function useImageUrl({ image }: UseImageUrlProps): string | null {
  const imageRef = useRef<string | null>(null);

  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    if (image && image !== imageRef.current) {
      imageRef.current = image;
      getImageUrl({ image }).then(({ url }) => {
        setUrl(url);
      });
    }
  }, [image]);

  return url;
}
