import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Drawer, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import AccordionTable from 'app/components/AccordianTable';
import DrawerData from './DrawerData';
import {
  Header,
  BodyContainder,
  ClientCardBox,
  LogoContainer,
  ContainerNew,
} from './styles';
import {
  GreyLabelText,
  Heading,
  LightSubHeading,
  PageHeading,
  ParagraphBoldText,
  ParagraphLightText,
} from '@components/Typography';
import { DetailsContainer } from '@pages/Integrations/styles';
import DetailsCard from '@components/Card/DetailsCard';
import MemoClientsIcon from '@icons/Clients';
import EmptyBox from '@icons/EmptyBox';
import { PrimaryButton } from '@components/Button';
import Integration from '@icons/integration';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useMaxHeight from '@helper/CustomHooks/intex';
import useAllClientsDetails from '@helper/CustomHooks/useAllClientsDetails';

interface data {
  logo: React.ReactElement | string;
  title: string;
  value: any;
}

const useStyles = makeStyles({
  drawer: {
    width: 575,
  },
  drawerPaper: {
    width: 575,
    borderRadius: '16px',
    margin: '9px 9px 0px 0px',
    maxHeight: '98vh',
  },
  root: {
    display: 'flex',
  },
});

function ClientsPage(): React.JSX.Element {
  const navigate = useNavigate();
  const classes = useStyles();
  const { integrationId } = useParams();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState();
  const maxHeight = useMaxHeight(200);
  const { data: clients, isLoading } = useAllClientsDetails(integrationId);
  const handleClose = () => {
    setDrawerOpen(false);
  };

  const accordionCardData: data[] = [
    {
      logo: <MemoClientsIcon />,
      title: 'Total Clients',
      value: clients?.total || 0,
    },
  ];

  return (
    <>
      <Header container alignItems={'center'} justifyContent={'space-between'}>
        <Grid item>
          <ParagraphBoldText fontSize={'24px'}>My Clients</ParagraphBoldText>
          <LightSubHeading margin="8px 0px 0px 0px">
            Here you can view all your clients and their portfolios
          </LightSubHeading>
        </Grid>
        {clients && clients.total > 0 && (
          <ClientCardBox>
            {accordionCardData.map((item, index) => (
              <DetailsCard
                key={index}
                width="308px"
                height="80px"
                borderRadius="8px"
                padding="0px 0px 0px 16px"
                marginBottom="0"
              >
                <LogoContainer>{item?.logo}</LogoContainer>
                <DetailsContainer>
                  <GreyLabelText>{item?.title}</GreyLabelText>
                  <PageHeading>{item?.value}</PageHeading>
                </DetailsContainer>
              </DetailsCard>
            ))}
          </ClientCardBox>
        )}
      </Header>
      <BodyContainder>
        {clients && clients.total > 0 ? (
          <>
            <AccordionTable
              data={clients.data}
              loading={isLoading}
              setDrawerOpen={setDrawerOpen}
              setSelectedClient={setSelectedClient}
              maxHeight={maxHeight}
            />
            {drawerOpen && (
              <div className={classes.root}>
                <Drawer
                  anchor="right"
                  variant="temporary"
                  open={drawerOpen}
                  className={classes.drawer}
                  classes={{ paper: classes.drawerPaper }}
                  onClose={handleClose}
                >
                  <DrawerData
                    handleDrawerClose={handleClose}
                    selectedClient={selectedClient}
                  />
                </Drawer>
              </div>
            )}
          </>
        ) : (
          <ContainerNew
            container
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <EmptyBox />
            <Heading margin="24px 0" width="auto">
              Looks like there is no one here yet
            </Heading>
            <ParagraphLightText>
              Sync your custodian and CRM platform to bring in your client data
              for an elevated experience
            </ParagraphLightText>
            <Box sx={{ marginTop: 3, display: ' flex', gap: '16px' }}>
              <PrimaryButton
                startIcon={<Integration />}
                endIcon={<ArrowForwardIosIcon />}
                customColor="#2C3748"
                onClick={() => navigate('/integration')}
              >
                Connect Custodian & CRM Platforms
              </PrimaryButton>
            </Box>
          </ContainerNew>
        )}
      </BodyContainder>
    </>
  );
}

export default ClientsPage;
