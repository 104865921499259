import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  styled,
} from '@mui/material';

export const DialogContainer = styled(Dialog)`
  padding: 2rem !important;
`;

export const DialogTitleWrapper = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
`;

export const DialogContentContainer = styled(DialogContent)``;

export const DialogContentTextWrapper = styled(DialogContentText)``;

export const DialogActionsWrapper = styled(DialogActions)``;
