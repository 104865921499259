import {useQuery, UseQueryResult} from 'react-query';
import ApiInstance from 'utils/instances';
import {InsightData} from 'app/features/Global/types';
import { SUGGESSTED_FUNDS_DATA } from '@common/constant';


type IUseInsightsResponse = InsightData[];

export default function useInsights(isProductTour: boolean): UseQueryResult<InsightData[]> {
    return useQuery(['insights', isProductTour], async () => {
        if( isProductTour ) {
            return SUGGESSTED_FUNDS_DATA;
        }
        return await ApiInstance.get<IUseInsightsResponse>(`/chat/insights`).then(
            res => res.data.filter((insight) => insight.data.length > 0),
        );
    }, {
        refetchOnWindowFocus: false,
        refetchInterval: 100000, 
    });
}
