import React from 'react';
import { useTheme } from 'styled-components';
import { Grid } from '@mui/material';
import { Logout } from '@mui/icons-material';

import { NavButton } from '@components/Button';
import { ParagraphBoldText, ParagraphLightText } from '@components/Typography';
import { getInitalName, getProfileDataForLocal } from '@common/auth';
import { LayoutMenusType } from 'app/routes';

import { NavMenuWrapper, ProfileIconWrapper } from './styles';

interface SideNavProps {
  scripts: any;
  menus: Array<LayoutMenusType> | null;
  collapse: boolean;
}

export default function SideNav({
  scripts = [],
  menus = [],
  collapse = false,
}: SideNavProps): React.JSX.Element {
  const theme = useTheme();
  const userDetails = getProfileDataForLocal();

  const isRouteActive = (path: string) => {
    if (scripts && scripts.length) {
      const activeOject = scripts[scripts.length - 1];
      if (
        path === '/clients' &&
        activeOject.queryType === 'ClientPage' &&
        activeOject.component === 'RenderImage'
      ) {
        return true;
      } else if (
        path === '/integration' &&
        activeOject.queryType === 'IntegrationPage' &&
        activeOject.component === 'RenderImage'
      ) {
        return true;
      } else if (path === '/' && activeOject.component !== 'RenderImage') {
        return true;
      }
    }
    return false;
  };

  const isBlinking = (path: string) => {
    if (scripts && scripts.length) {
      const activeOject = scripts[scripts.length - 1];
      if (
        path === '/clients' &&
        activeOject.queryType === 'ClientPage' &&
        activeOject.component === 'RenderImage'
      ) {
        return true;
      } else if (
        path === '/integration' &&
        activeOject.queryType === 'IntegrationPage' &&
        activeOject.component === 'RenderImage'
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <NavMenuWrapper
      container
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Grid
        item
        container
        px={2}
        flexBasis={'column'}
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        margin={'0 auto'}
        justifyContent={'flex-start'}
      >
        {menus?.map((item: any, index: number) => (
          <Grid item key={index} sm={collapse ? 12 : 6}>
            <NavButton
              key={index}
              collapse={collapse}
              cursor={item.cursor}
              tooltipText={item.name}
              startIcon={item.icon && <item.icon />}
              active={isRouteActive(item.path)}
              blink={isBlinking(item.path)}
              enableNotifications={item.enableNotifications}
            >
              {item.name}
            </NavButton>
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        container
        px={2}
        flexBasis={'column'}
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        margin={'0 auto'}
        alignItems={'flex-end'}
        justifyContent={'flex-start'}
      >
        <Grid item sm={collapse ? 12 : 6} pb={2}>
          {!collapse && (
            <>
              <ParagraphBoldText fontSize={'16px'} color={theme.solidWhite}>
                {userDetails.name}
              </ParagraphBoldText>
              <ParagraphLightText
                fontSize={'12px'}
                fontWeight={400}
                color={theme.inactiveWhite}
              >
                {userDetails.email}
              </ParagraphLightText>
            </>
          )}
        </Grid>
        <Grid
          item
          container
          pb={2}
          alignItems={'center'}
          justifyContent={'space-evenly'}
          sm={collapse ? 12 : 6}
        >
          <NavButton
            collapse={true}
            cursor={'pointer'}
            tooltipText={'Logout'}
            startIcon={<Logout />}
            active={false}
            enableNotifications={false}
            style={{
              width: '50px',
              height: '50px',
              padding: '14px',
              borderColor: 'transparent',
            }}
          />
          <NavButton
            collapse={true}
            cursor={'pointer'}
            tooltipText={'My Profile'}
            enableNotifications={false}
            active={isRouteActive('/profile')}
            startIcon={
              <ProfileIconWrapper alt={getInitalName()} src="/broken-image.jpg">
                {getInitalName()}
              </ProfileIconWrapper>
            }
            startIconStyles={{
              marginLeft: '0px',
              marginRight: '0px',
              fontSize: '24px',
            }}
            style={{
              width: '50px',
              height: '50px',
              padding: '14px',
              justifyContent: 'center',
              borderColor: 'transparent',
            }}
          />
        </Grid>
      </Grid>
    </NavMenuWrapper>
  );
}
