/* eslint-disable react/prop-types */
import * as React from 'react';

function GlitterIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M13.0035 16.453L11.2035 21.3374C11.1502 21.4809 11.0543 21.6047 10.9286 21.6921C10.8029 21.7795 10.6534 21.8264 10.5003 21.8264C10.3472 21.8264 10.1978 21.7795 10.0721 21.6921C9.94642 21.6047 9.85049 21.4809 9.79721 21.3374L7.99721 16.453C7.95923 16.3499 7.89932 16.2563 7.82163 16.1786C7.74394 16.1009 7.65031 16.041 7.54721 16.003L2.66284 14.203C2.51931 14.1497 2.39552 14.0538 2.3081 13.9281C2.22068 13.8024 2.17383 13.653 2.17383 13.4999C2.17383 13.3467 2.22068 13.1973 2.3081 13.0716C2.39552 12.9459 2.51931 12.85 2.66284 12.7967L7.54721 10.9967C7.65031 10.9587 7.74394 10.8988 7.82163 10.8211C7.89932 10.7434 7.95923 10.6498 7.99721 10.5467L9.79721 5.66235C9.85049 5.51882 9.94642 5.39503 10.0721 5.30761C10.1978 5.2202 10.3472 5.17334 10.5003 5.17334C10.6534 5.17334 10.8029 5.2202 10.9286 5.30761C11.0543 5.39503 11.1502 5.51882 11.2035 5.66235L13.0035 10.5467C13.0415 10.6498 13.1014 10.7434 13.1791 10.8211C13.2567 10.8988 13.3504 10.9587 13.4535 10.9967L18.3378 12.7967C18.4814 12.85 18.6052 12.9459 18.6926 13.0716C18.78 13.1973 18.8269 13.3467 18.8269 13.4999C18.8269 13.653 18.78 13.8024 18.6926 13.9281C18.6052 14.0538 18.4814 14.1497 18.3378 14.203L13.4535 16.003C13.3504 16.041 13.2567 16.1009 13.1791 16.1786C13.1014 16.2563 13.0415 16.3499 13.0035 16.453V16.453Z"
        stroke={props.fill || '#101828'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 1.5V6"
        stroke="#101828"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 3.75H14.25"
        stroke="#101828"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 6.75V9.75"
        stroke="#101828"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 8.25H19.5"
        stroke="#101828"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoNoData = React.memo(GlitterIcon);
export default MemoNoData;
