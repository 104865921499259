import * as React from 'react';

function DataNotAvailable(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="12.5em"
      height="12.5em"
      viewBox="0 0 200 200"
      fill="none"
      {...props}
    >
      <circle cx={100} cy={104.505} r={95.496} fill="#F9FAFB" />
      <path
        d="M27.928 66.667L100 102.703v75.675l-72.072-36.036V66.667zM172.072 66.667L100 102.703v75.675l72.072-36.036V66.667z"
        fill="#D0D5DD"
      />
      <path
        d="M100 102.703L27.928 66.667 100 30.63l72.072 36.036L100 102.703z"
        fill="#F2F4F7"
      />
      <path d="M100 102.703V30.631L27.928 66.667 100 102.703z" fill="#D0D5DD" />
      <path
        d="M72.973 133.333L100 102.703 27.928 66.667.9 97.297l72.072 36.036z"
        fill="#E4E7EC"
      />
      <path
        d="M27.928 66.667L.9 36.036 72.973 0 100 30.63 27.928 66.668zM172.072 66.667l27.027-30.631L127.027 0 100 30.63l72.072 36.037zM127.027 133.333L100 102.703l72.072-36.036 27.027 30.63-72.072 36.036z"
        fill="#E4E7EC"
      />
    </svg>
  );
}

const MemoDataNotAvailable = React.memo(DataNotAvailable);
export default MemoDataNotAvailable;
