/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ApiInstance from 'utils/instances';

export const getUserInfo = async (token: string): Promise<any> => {
  return await ApiInstance.get(`/user/userInfo`, {
    headers: {
      'x-tifin-ai-auth': token,
    },
  }).then(res => res.data);
};

export const logoutUser = async (request: any): Promise<any> => {
  const { payload, headers } = request;
  return await ApiInstance.post(`/user/logout`, payload, { headers }).then(
    res => res.data,
  );
};

export const activateAccount = async ({ payload, headers }): Promise<any> => {
  return await ApiInstance.post(`/user/updatePassword`, payload, {
    headers,
  })
    .then(res => res.data)
    .catch(({ response }: any) => response);
};

export const resetPasswordApi = async ({ payload }): Promise<any> => {
  return await ApiInstance.post(`/user/resetPassword`, payload)
    .then(res => res.data)
    .catch(({ response }: any) => response);
};

export const verifyHash = async (hashKey: string): Promise<any> => {
  return await ApiInstance.get(`/user/verify`, {
    headers: {
      'x-tifin-ai-auth': hashKey,
    },
  })
    .then(res => res.data)
    .catch(({ response }: any) => response);
};
