/**
 * React Context that holds application wide state and functions.
 * Used to avoid prop drilling and to replace Redux.
 */

import { IFundDocumentType, IFundSearchMode } from '@features/Funds/types';
import { IAddFundDocumentMode } from '@pages/MyFunds/AddFundDocumentModal/AddFundDocumentForm';
import React from 'react';

type IAppContext_Modal =
  | null
  | {
      type: 'ADD_FUND_DOCUMENT';
      internal_fund_id?: string;
      external_fund_id?: string;
      fund_name: string | null; // Sometimes we want the fund to be created in the moment
      document_type: IFundDocumentType | null; // We might not now it yet
      mode: IAddFundDocumentMode;
    }
  | {
      type: 'ADD_NEW_FUND';
      default_fund_name: string;
    };

type IAppContext = {
  // In some cases we need to show a modal AND navigate to a new page, so we need to store the modal state in the app context
  modal: IAppContext_Modal;
  setModal: (modal: IAppContext_Modal) => void;
  // Even though this is specific for the My Funds page, changes to it can be triggered from other pages
  fund_search_mode: IFundSearchMode;
  setFundSearchMode: (mode: IFundSearchMode) => void;
  getApiToken: () => string | null;
};

const initialState: IAppContext = {
  modal: null,
  setModal: () => {},
  fund_search_mode: 'DATA_PROVIDER',
  setFundSearchMode: () => {},
  getApiToken: () => null,
};

const AppContext = React.createContext<IAppContext>(initialState);

export const AppProvider = ({ children }: { children: any }): JSX.Element => {
  const [modal, setModal] = React.useState<IAppContext_Modal>(null);
  const [fund_search_mode, setFundSearchMode] =
    React.useState<IFundSearchMode>('DATA_PROVIDER');
  const getApiToken = () => {
    return localStorage.getItem('x-tifin-ai-token') || null;
  };

  return (
    <AppContext.Provider
      value={{
        modal,
        setModal,
        fund_search_mode,
        setFundSearchMode,
        getApiToken,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext: () => IAppContext = () =>
  React.useContext(AppContext);
