import React from 'react';
import { Typography } from '@mui/material';
import RowCollapse from './RowCollapse';
import { StyledTableCell, StyledTableRow } from './styles';
import { isEmpty } from 'utils/common/string';

interface PropsType {
  row: any;
  index: number;
  columns: Array<any>;
  baseLineActive: string;
}

const Row = (props: PropsType): React.JSX.Element => {
  const { row, index, columns = [], baseLineActive = '' } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment key={index}>
      <StyledTableRow
        sx={{
          '&.MuiTableRow-root': {
            cursor: 'pointer',
            '& .MuiTableCell-root': {
              ...(open && { borderBottom: 'none' }),
            },
          },
        }}
        onClick={() => setOpen(!open)}
      >
        {columns.map((column, columnIndex) => {
          return (
            <StyledTableCell
              key={`td-${index}-${columnIndex}`}
              component="td"
              align="left"
              sx={{
                '&.MuiTableCell-root': {
                  ...(columnIndex > 1 &&
                    row?.results &&
                    !row?.results[column?.value]?.response && {
                      background: 'pink',
                      textAlign: 'center',
                    }),
                  ...(row?.results &&
                    row?.results[column?.value]?.response && {
                      background: '#a6d6d433',
                    }),
                },
                border: '0.5px solid #eee',
              }}
            >
              {isEmpty(column?.value) ? (
                <React.Fragment>&nbsp;</React.Fragment>
              ) : (
                <Typography
                  component={'div'}
                  className="ellipsis-line-3"
                  dangerouslySetInnerHTML={{
                    __html: !isEmpty(row[column?.value])
                      ? column.value === 'query' ||
                        column.value === 'classification'
                        ? row[column.value]
                        : row?.results && row?.results[column?.value]?.response
                          ? ''
                          : ''
                      : '---',
                  }}
                  sx={{
                    maxWidth: '50vw',
                    height: '100%',
                    overflow: 'hidden',
                  }}
                />
              )}
            </StyledTableCell>
          );
        })}
      </StyledTableRow>
      <RowCollapse
        row={row}
        index={index}
        open={open}
        columns={columns}
        baseLineActive={baseLineActive}
      />
    </React.Fragment>
  );
};

export default Row;
