import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeedbackHistoryType } from './types';

export const initialState: FeedbackHistoryType = {
  data: [],
  loading: false,
  filterLoading: false,
  feedbackHistoryFilter: [],
};

const FeedbackHistorySlice = createSlice({
  name: 'FeedbackHistory',
  initialState,
  reducers: {
    loadFeedbackHistoryRequest() {},
    loadFeedbackHistoryFetch(state) {
      state.loading = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadFeedbackHistorySuccess(state: any, action: PayloadAction<any>) {
      return {
        ...state,
        data: action?.payload?.data,
        loading: false,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadFeedbackHistoryFilterRequest(state: any, action: PayloadAction<any>) {},
    loadFeedbackHistoryFilterFetch(state) {
      state.filterLoading = true;
    },
    loadFeedbackHistoryFilterSuccess(state: any, action: PayloadAction<any>) {
      return {
        ...state,
        feedbackHistoryFilter: action?.payload?.data,
        filterLoading: false,
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = FeedbackHistorySlice;
