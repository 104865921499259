/* eslint-disable react/prop-types */
import * as React from 'react';

function CopyIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3334 1.33322H10.3117C9.93715 0.913822 9.4782 0.578264 8.96495 0.348513C8.4517 0.118763 7.89571 0 7.33338 0C6.77105 0 6.21507 0.118763 5.70181 0.348513C5.18856 0.578264 4.72962 0.913822 4.35503 1.33322H1.33334C0.979718 1.33322 0.640577 1.4737 0.390527 1.72375C0.140477 1.9738 0 2.31294 0 2.66656V16.6667C0 17.0203 0.140477 17.3594 0.390527 17.6095C0.640577 17.8595 0.979718 18 1.33334 18H13.3334C13.687 18 14.0262 17.8595 14.2762 17.6095C14.5263 17.3594 14.6668 17.0203 14.6668 16.6667V2.66656C14.6668 2.31294 14.5263 1.9738 14.2762 1.72375C14.0262 1.4737 13.687 1.33322 13.3334 1.33322ZM7.33338 1.33322C8.04063 1.33322 8.71891 1.61418 9.21901 2.11428C9.71911 2.61438 10.0001 3.29266 10.0001 3.99991H4.6667C4.6667 3.29266 4.94765 2.61438 5.44775 2.11428C5.94785 1.61418 6.62613 1.33322 7.33338 1.33322ZM13.3334 16.6667H1.33334V2.66656H3.56252C3.41087 3.09475 3.33337 3.54566 3.33336 3.99991V4.66658C3.33336 4.84339 3.40359 5.01296 3.52862 5.13798C3.65364 5.26301 3.82321 5.33325 4.00003 5.33325H10.6667C10.8436 5.33325 11.0131 5.26301 11.1381 5.13798C11.2632 5.01296 11.3334 4.84339 11.3334 4.66658V3.99991C11.3334 3.54566 11.2559 3.09475 11.1042 2.66656H13.3334V16.6667Z"
        fill={props.color}
      />
    </svg>
  );
}

const MemoNoData = React.memo(CopyIcon);
export default MemoNoData;
