export interface FundsList {
  loading?: boolean;
  totalCount: number;
  result: IFundWithDocuments[];
  searchValue?: string;
  selectedFund?: object;
  documentFundModal?: boolean;
}

export interface FundInterface {
  carriedInterestPercent?: string | number | null;
  createdAt: string;
  createdBy: string | null;
  currency: string | null;
  dataProviderId?: string;
  emergingManager?: string;
  enterpriseId?: string | number | null;
  externalFundId?: string;
  familyId: string | null;
  familyName: string | null;
  feeOffsetPercent?: string | number | null;
  finalCloseDate?: string;
  firmInception?: string;
  firstCloseDate?: string;
  firstTimeFund?: string;
  focus: string | null;
  formalEsgPolicy?: string;
  geography: string | null;
  gics: string | null;
  gpCommitmentPercent?: string | number | null;
  hardCapMM?: string | number | null;
  hasPPM: boolean | null;
  id: string;
  impactFocus?: string;
  interimCloseDate?: string;
  investmentPeriod?: string | number | null;
  managementFeeInvestmentPeriodBase?: string;
  managementFeeInvestmentPeriodPercent?: string | number | null;
  managementFeePostInvestmentPeriodBase?: string;
  managementFeePostInvestmentPeriodPercent?: string | number | null;
  managerId: string | null;
  managerName: string | null;
  maxNoOfInvestments?: string | number | null;
  maxTargetEnterpriseValueMM?: string | number | null;
  maxTargetInvestmentSizeMM?: string | number | null;
  merits?: string | number | null;
  minNoOfInvestments?: string | number | null;
  minTargetEnterpriseValueMM?: string | number | null;
  minTargetInvestmentSizeMM?: string | number | null;
  name: string;
  needToBelieves?: string | number | null;
  noOfInvmtProfessionals?: string;
  number: number | null;
  percentOfManagementCompanyOwnedByRUP?: string;
  percentOfManagementCompanyOwnedByWomen?: string;
  percentOwnedByMinorities?: string;
  percentOwnedByWomen?: string;
  preferredReturnPercent?: string | number | null;
  priSignatory?: string;
  primaryGeography?: string;
  size: number | null;
  specialComments?: string | number | null;
  status: string | null;
  style: string | null;
  targetFundSizeMM?: string | number | null;
  targetedGrossIrr?: string | number | null;
  targetedGrossMultiple?: string | number | null;
  targetedNetIRR?: string | number | null;
  targetedTVPI?: string | number | null;
  termExtensionsChoice?: string;
  termOfTheFundYrs?: string | number | null;
  updatedAt?: string;
  vintageYear: number | null;
}

export interface IFundWithDocuments extends FundInterface {
  documents: IFundDocument[];
}

export type IFundDocumentType = 'PPM' | 'PITCH_DECK'; // TODO: review

export const FundDocumentTypeLabel: Record<IFundDocumentType, string> = {
  PPM: 'PPM',
  PITCH_DECK: 'Pitch Deck',
};

export interface IFundDocument {
  id: string;
  name: string;
  type: IFundDocumentType;
  createdAt: string;
  updatedAt: string;
  status: 'ADDED';
  fundId: string;
}

export interface GetAllFundsParams {
  q?: string;
  limit: number;
  page: number;
  mode: IFundSearchMode;
  all?: boolean;
}

export interface GetAllFundsWithDocumentsParams extends GetAllFundsParams {}

export type IFundOwner = 'DATA_PROVIDER' | 'ENTERPRISE' | 'GLOBAL';

export type IFundSearchMode =
  | 'DATA_PROVIDER'
  | 'ENTERPRISE'
  | 'GLOBAL'
  | 'MINE';

export interface FundResponseInterface {
  result: Array<FundInterface> | [];
  totalCount: number;
}

export interface IFundWithDocumentsResponse {
  result: Array<IFundWithDocuments> | [];
  totalCount: number;
}
