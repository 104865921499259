/* eslint-disable @typescript-eslint/no-unused-vars */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentProps } from './types';

export const initialState: DocumentProps = {
  inprogressDocList: [],
  docList: [],
  totalCount: 0,
  loading: true,
};

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    getDocumentsList(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    loadDocumentsListFetch(state) {
      state.loading = true;
    },
    loadDocumentsListSuccess(
      state: any,
      action: PayloadAction<{
        data?: Array<any>;
        loading: boolean;
        totalCount?: number;
      }>,
    ) {
      return {
        ...state,
        loading: false,
        inprogressDocList: action?.payload?.data?.filter(
          val =>
            val.status === 'ADDED' ||
            val.status === 'PROCESSING' ||
            !val.status,
        ),
        docList: action?.payload?.data?.filter(
          val => val.status === 'PROCESSED' || val.status === 'ERROR',
        ),
        totalCount: action?.payload?.totalCount,
      };
    },

    deleteDocumentList(
      state,
      action: PayloadAction<{ integrationId?: string } | any>,
    ) {
      return {
        ...state,
        docList: state?.docList?.filter(val => val.id !== action.payload.id),
        inprogressDocList: state?.inprogressDocList?.filter(
          val => val.id !== action.payload.id,
        ),
      };
    },

    loadDeleteDocumentsFetch(state) {
      state.loading = true;
    },
    loadDeleteDocumentsSuccess(
      state: any,
      action: PayloadAction<{
        loading: boolean;
      }>,
    ) {
      return {
        ...state,
        loading: false,
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = documentsSlice;
