/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import ApiInstance from '@instances/index';

export const listFundDocumentApi = async (payload: any): Promise<any> => {
  return await ApiInstance.post(`/enterprise/getAllDocuments`, payload).then(
    res => res.data,
  );
};

export const deleteFundDocumentApi = async (id: number): Promise<any> => {
  return await ApiInstance.delete(
    `/enterprise/deleteFundDocument?fundDocumentId=${id}`,
  )
    .then(res => res.data)
    .catch(({ response }: any) => response);
};
