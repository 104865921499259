/**
 * This implements a common extended table, with rows, columns, etc.
 * It has its own types and props. It uses MUIDataTable. Columns and rows definitions
 * follow MUIDataTable's.
 */

import React from 'react';
import { ExtTableColumn, ExtTableRow } from './types';
import ExtTableBasic_MRT from './ExtTableBasic_MRT';
import ExtTableBasic_MUI from './ExtTableBasic_MUI';

export type ExtTableBasicProps = {
  title: string;
  columns: ExtTableColumn[];
  rows: ExtTableRow[];
  cellRenderer?: (row: ExtTableRow, column: ExtTableColumn) => JSX.Element;
  elevation?: number;
  show_column_headers?: boolean;
  show_select_column?: boolean;
  isRowSelected?: (row: ExtTableRow) => boolean;
  setRowSelected?: (row: ExtTableRow, selected: boolean) => void;
  allow_dragging?: boolean; // Only MRT
  onRowReordered?: (start_index: number, end_index: number) => void; // Only MRT
  getRowOrder?: (row: ExtTableRow) => number | undefined;
};

export default function ExtTableBasic({
  ...props
}: ExtTableBasicProps): JSX.Element {
  return <ExtTableBasic_MRT {...props} />;
  return <ExtTableBasic_MUI {...props} />;
}
