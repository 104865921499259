import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { actions as globalActions } from '@features/Global/slice';
import { TourState } from '@features/Global/types';

import { TextButton } from '@components/Button';
import ContentDrawer from '@components/ContentDrawer';
import SidebarIndicator from '@components/SidebarIndicator';
import LinearProgressWithLabel from '@components/LinearProgress';
import HelixLogo from '@logos/HelixByHLDark.svg';
import { getLayoutMenus, LayoutMenusType } from 'app/routes';

import SideNav from './SideNav';
import Finish from '../Finish';
import Dashboard from '../Dashboard';
import Conversation from '../Conversation';

import { LayoutProps } from './types';
import {
  LayoutContainer,
  LayoutWrapper,
  LeftSideContainer,
  RightSideContainer,
  RightSideContentWrapper,
  HeaderContainer,
  HelixLogoWrapper,
  ContentWrapper,
  RightSideDrawerContainer,
} from './styles';

const LayoutRoute: React.FC<LayoutProps> = ({
  sx = {},
  tourProgress,
  animate = false,
  finish = [],
  loadScripts = [],
  dashboardScripts = [],
  eductionScripts = [],
  activeScripts = '',
  fundResearchScripts = [],
  clientCommunicationScripts = [],
  marketResearchScripts = [],
  clientPageScripts = [],
  integrationPageScripts = [],
}) => {
  const dispatch = useDispatch();
  const customMenuList: Array<LayoutMenusType> | null = getLayoutMenus(
    'CLIENT',
    '/',
  );
  const [isLeftSideBarCollapsed, updateLeftSideOpenState] = useState(true);

  const getComponentAnimateProps = (scripts: any, componentName: string) => {
    return scripts?.find((k: any) => k.component === componentName);
  };
  const isObjectPresent = (d: any): boolean => d && typeof d === 'object';

  const [isRightSideDrawerCollapsed, updateRightSideOpenState] = useState(true);

  useEffect(() => {
    const sc = getActiveScripts();
    if (sc) {
      const k: any = sc?.find((k: any) => k.component === 'RightSideDrawer');
      if (k) {
        updateRightSideOpenState(false);
      }
    }
    if (getComponentAnimateProps(getActiveScripts(), 'Sidebar')) {
      updateRightSideOpenState(true);
      updateLeftSideOpenState(false);
    }
  }, [
    activeScripts,
    eductionScripts,
    fundResearchScripts,
    clientPageScripts,
    integrationPageScripts,
  ]);

  const onLeftArrowClick = () => {
    updateLeftSideOpenState(!isLeftSideBarCollapsed);
  };

  const onRightArrowClick = () => {
    console.log('onRightArrowClick...');
  };

  const getRecentStateFromProductTour = (): TourState => {
    let scripts;
    switch (activeScripts) {
      case 'loadScripts':
        scripts = loadScripts[loadScripts.length - 1];
        break;
      case 'dashboardScripts':
        scripts = dashboardScripts[dashboardScripts.length - 1];
        break;
      case 'eductionScripts':
        scripts = eductionScripts[eductionScripts.length - 1];
        break;
      case 'fundResearchScripts':
        scripts = fundResearchScripts[fundResearchScripts.length - 1];
        break;
      case 'clientCommunicationScripts':
        scripts =
          clientCommunicationScripts[clientCommunicationScripts.length - 1];
        break;
      case 'marketResearchScripts':
        scripts = marketResearchScripts[marketResearchScripts.length - 1];
        break;
      case 'clientPageScripts':
        scripts = clientPageScripts[clientPageScripts.length - 1];
        break;
      case 'integrationPageScripts':
        scripts = integrationPageScripts[integrationPageScripts.length - 1];
        break;
      case 'finish':
        scripts = finish[finish.length - 1];
        break;
      default:
        break;
    }
    return scripts;
  };

  const getActiveScripts = () => {
    let scripts;
    switch (activeScripts) {
      case 'loadScripts':
        scripts = loadScripts;
        break;
      case 'dashboardScripts':
        scripts = dashboardScripts;
        break;
      case 'eductionScripts':
        scripts = eductionScripts;
        break;
      case 'fundResearchScripts':
        scripts = fundResearchScripts;
        break;
      case 'clientCommunicationScripts':
        scripts = clientCommunicationScripts;
        break;
      case 'marketResearchScripts':
        scripts = marketResearchScripts;
        break;
      case 'clientPageScripts':
        scripts = clientPageScripts;
        break;
      case 'integrationPageScripts':
        scripts = integrationPageScripts;
        break;
      case 'finish':
        resetFundDetails();
        scripts = finish;
        break;
      default:
        break;
    }
    return scripts;
  };

  const resetFundDetails = () => {
    dispatch(globalActions.resetInsightDataState());
  };

  const stopProductTour = () => {
    resetFundDetails();
    dispatch(globalActions.finishProductTour(false));
  };

  return (
    <Fragment>
      <LayoutContainer sx={sx} animate={animate} container>
        <LeftSideContainer
          item
          sx={{ pointerEvents: 'none !important' }}
          visible={getComponentAnimateProps(loadScripts, 'Sidebar')}
          collapse={isLeftSideBarCollapsed}
        >
          <SideNav
            scripts={getActiveScripts()}
            menus={customMenuList}
            collapse={isLeftSideBarCollapsed}
          />
          <SidebarIndicator
            isOpen={!isLeftSideBarCollapsed}
            placement={'left'}
            size={'large'}
            tooltip={{
              enable: true,
              placement: 'right',
              text: `${isLeftSideBarCollapsed ? 'Open' : 'Close'} Sidebar`,
            }}
            onClick={onLeftArrowClick}
          />
        </LeftSideContainer>
        <RightSideContainer
          item
          container
          collapse={isLeftSideBarCollapsed}
          visible={isObjectPresent(
            getComponentAnimateProps(loadScripts, 'RightSideContent'),
          )}
        >
          <RightSideContentWrapper
            container
            collapse={isRightSideDrawerCollapsed}
          >
            <HeaderContainer
              container
              animate={
                getRecentStateFromProductTour()?.path === '/conversations'
              }
              alignItems={'flex-end'}
              justifyContent={'space-between'}
            >
              <HelixLogoWrapper
                width="auto"
                height="32px"
                src={HelixLogo}
                style={{
                  opacity:
                    getRecentStateFromProductTour()?.path === '/conversations'
                      ? 1
                      : 0,
                }}
              />

              <LinearProgressWithLabel
                value={tourProgress}
                sx={{
                  pointerEvents:
                    getRecentStateFromProductTour()?.path ===
                      '/conversations' &&
                    !getComponentAnimateProps(getActiveScripts(), 'Finish')
                      ? 'all'
                      : 'none',
                  opacity:
                    getRecentStateFromProductTour()?.path ===
                      '/conversations' &&
                    !getComponentAnimateProps(getActiveScripts(), 'Finish')
                      ? 1
                      : 0,
                }}
                onClick={() =>
                  getRecentStateFromProductTour()?.path === '/conversations' &&
                  !getComponentAnimateProps(getActiveScripts(), 'Finish')
                    ? stopProductTour
                    : null
                }
              >
                <TextButton
                  variant={'text'}
                  customHeight={'32px'}
                  onClick={stopProductTour}
                  endIcon={
                    <ArrowForwardIcon
                      sx={{
                        width: '20px !important',
                        minHeight: '20px !important',
                        margin: '0 !important',
                        fontSize: '20px !important',
                      }}
                    />
                  }
                  sx={{
                    textTransform: 'unset',
                  }}
                >
                  Skip Product Tour
                </TextButton>
              </LinearProgressWithLabel>
            </HeaderContainer>

            {getRecentStateFromProductTour()?.path === '/dashboard' && (
              <Dashboard
                activeScripts={activeScripts}
                scripts={getActiveScripts()}
              />
            )}

            {getRecentStateFromProductTour()?.path === '/conversations' && (
              <ContentWrapper animate={animate}>
                <LayoutWrapper animate={animate}>
                  {isObjectPresent(
                    getComponentAnimateProps(getActiveScripts(), 'Finish'),
                  ) ? (
                    <Finish />
                  ) : (
                    <Conversation
                      activeScripts={activeScripts}
                      scripts={getActiveScripts()}
                    />
                  )}
                </LayoutWrapper>
              </ContentWrapper>
            )}
          </RightSideContentWrapper>
          <RightSideDrawerContainer
            container
            collapse={isRightSideDrawerCollapsed}
          >
            <ContentDrawer sx={{ pointerEvents: 'none !important' }} />
            <SidebarIndicator
              isOpen={!isRightSideDrawerCollapsed}
              placement={'right'}
              size={'large'}
              tooltip={{
                enable: true,
                placement: 'left',
                text: `${isRightSideDrawerCollapsed ? 'Open' : 'Close'} Drawer`,
              }}
              sx={{ pointerEvents: 'none !important' }}
              onClick={onRightArrowClick}
            />
          </RightSideDrawerContainer>
        </RightSideContainer>
      </LayoutContainer>
    </Fragment>
  );
};

export default LayoutRoute;
