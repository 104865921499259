import axios, { AxiosInstance } from 'axios';

const ApiInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: '*/*',
    http_host: 'TG',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  },
});

ApiInstance.interceptors.request.use(
  config => {
    const current_token = localStorage.getItem('x-tifin-ai-token');
    if (config.params && config.params.queryWithoutToken) {
      // Remove headers if queryWithoutToken is true
      console.log('====== Removing token from headers ======');
      delete config.headers['x-tifin-ai-auth'];
    } else {
      config.headers['x-tifin-ai-auth'] = current_token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

ApiInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error && error.response && error.response.status === 401) {
      // Handle 401 Unauthorized error here
      console.log('====== Auth issue 401 : Unauthorized User!! ======');
    }
    return Promise.reject(error);
  },
);

export default ApiInstance;
