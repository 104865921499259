import React, { Fragment, useEffect, useState } from 'react';
import { Grid, useTheme } from '@mui/material';
import InputField from '@components/core/InputField';
import SendIcon from '@icons/plane';
import {
  SearchBoxContainer,
  SearchBoxWrapper,
  SuffixIcon,
  TextArea,
} from './styles';

type SearchBoxTypes = {
  sx?: any;
  ref?: any;
  style?: any;
  color?: string;
  variant?: 'search' | 'input';
  borderWidth?: string;
  type: 'textarea' | 'input';
  isStreaming: boolean;
  onInputSubmit: (query: string) => void;
  force_query?: string;
  colored?: boolean;
};

function SearchBox({
  sx = {},
  ref,
  type,
  style = {},
  borderWidth = '2px',
  isStreaming,
  variant = 'search',
  onInputSubmit,
  force_query = '',
  colored = false,
  ...rest
}: SearchBoxTypes): React.JSX.Element {
  const theme = useTheme();
  const [userQuery, setUserQuery] = useState<string>('');

  useEffect(() => {
    setUserQuery(force_query);
  }, [force_query]);

  const borderColor =
    colored && userQuery.length > 0
      ? theme.palette.primary['purple']
      : theme.palette.primary['borderColor'];

  return (
    <Fragment>
      {type === 'textarea' ? (
        <SearchBoxContainer
          container
          sx={{
            height: userQuery.length > 100 ? '100%' : 'auto',
          }}
        >
          <SearchBoxWrapper
            item
            sx={{
              width: 'calc(100% - 50px)',
              height: userQuery.length > 100 ? '100%' : 'auto',
            }}
          >
            <TextArea
              ref={ref}
              style={{
                height: userQuery.length > 100 ? 'auto' : '68%',
                ...style,
              }}
              variant={variant}
              borderColor={borderColor}
              borderWidth={borderWidth}
              maxRows={userQuery.length > 100 ? 4 : 1}
              disabled={isStreaming}
              placeholder={'Begin typing to ask a question ...'}
              onChange={(e: any) => setUserQuery(e?.target?.value)}
              value={userQuery}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  onInputSubmit(userQuery);
                  setUserQuery('');
                  // props?.callback(e?.target?.value);
                }
              }}
              {...rest}
            />
          </SearchBoxWrapper>
          <Grid container alignItems={'center'} sx={{ width: '50px' }}>
            <SuffixIcon onClick={() => onInputSubmit(userQuery)}>
              <SendIcon />
            </SuffixIcon>
          </Grid>
        </SearchBoxContainer>
      ) : (
        <InputField
          ref={ref}
          sx={sx}
          style={style}
          variant={variant}
          disabled={isStreaming}
          borderWidth={borderWidth}
          padding={'0 52px 0 16px'}
          containerHeight={'56px'}
          borderColor={borderColor}
          placeholder={'Begin typing to ask a question ...'}
          onChange={(e: any) => setUserQuery(e?.target?.value)}
          value={userQuery}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              onInputSubmit(userQuery);
              setUserQuery('');
            }
          }}
          suffixIcon={<SendIcon />}
          onIconClick={() => onInputSubmit(userQuery)}
          {...rest}
        />
      )}
    </Fragment>
  );
}

export default SearchBox;
