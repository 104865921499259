import React from 'react';
import styled from 'styled-components';
import { Box, Grid, TableContainer, Skeleton } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import SearchIcon from '@mui/icons-material/Search';
import { ClientData, ClientsState } from 'app/features/Clients/types';
import { SecondaryButton } from '@components/Button';
import { getDateTime } from 'utils/common/dateTime';

interface IAccordianProps extends ClientsState {
  openPortfolioPopup?: (row: ClientData | any) => void;
  setDrawerOpen: (isOpen: boolean) => void;
  setSelectedClient: any;
  maxHeight?: number;
}

const StyledTableContainer = styled(TableContainer)`
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #999999;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

function AccordionTable({
  data,
  loading = false,
  setDrawerOpen,
  setSelectedClient,
  maxHeight,
}: IAccordianProps): React.JSX.Element {
  const components = {
    SearchIcon: () => (
      <React.Fragment>
        <SecondaryButton>
          <SearchIcon />
        </SecondaryButton>
      </React.Fragment>
    ),
  };

  const handleRowClick = (data: any) => {
    setSelectedClient(data);
    setDrawerOpen(true);
  };

  return (
    <StyledTableContainer sx={{ position: 'relative' }}>
      {loading ? (
        <React.Fragment>
          <Box
            sx={{
              width: '100%',
              height: '50vh',
            }}
          >
            <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
          </Box>
        </React.Fragment>
      ) : (
        <Grid
          sx={{
            maxWidth: '100%',
            maxHeight: maxHeight ? `${maxHeight}px` : '80vh',
            border: '1px solid #e0e0e0',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 1,
              width: '92%',
              position: 'absolute',
              top: '16px',
              zIndex: '1',
              padding: ' 0 16px',
            }}
          ></Box>

          <MUIDataTable
            title=""
            data={data}
            columns={[
              {
                name: 'full_name',
                label: 'Name',
                options: {
                  sort: false,
                  customBodyRender: value => {
                    let decodedValue;
                    try {
                      decodedValue = decodeURI(value);
                    } catch (error) {
                      console.error('Error decoding URI:', error);
                      decodedValue = '--'; // 'Malformed URI';
                    }
                    return decodedValue;
                  },
                },
              },
              {
                name: 'emails',
                label: 'Email',
                options: {
                  sort: false,
                  customBodyRender: value => (
                    <div style={{ width: '200px', textTransform: 'lowercase' }}>
                      {value && Array.isArray(value) && value.length
                        ? value[0]
                        : value || '--'}
                    </div>
                  ),
                },
              },
              {
                name: 'source',
                label: 'Source',
                options: {
                  sort: false,
                  customBodyRender: value => value || '--',
                },
              },
              {
                name: 'type',
                label: 'Type',
                options: {
                  sort: false,
                },
              },
              {
                name: 'Portfolios',
                label: 'Total Portfolios',
                options: {
                  sort: false,
                  customBodyRender: value => (
                    <div style={{ textAlign: 'center' }}>
                      {value && value.length}
                    </div>
                  ),
                },
              },
              {
                name: 'created_at',
                label: 'Created At',
                options: {
                  sort: true,
                  sortDescFirst: true,
                  customBodyRender: value => (
                    <div style={{ width: '100px' }}>{getDateTime(value)}</div>
                  ),
                },
              },
            ]}
            options={{
              filter: false,
              print: false,
              download: false,
              viewColumns: false,
              pagination: true,
              rowsPerPage: 8,
              rowsPerPageOptions: [5],
              selectableRows: 'none', // or "multiple" for checkboxes
              enableNestedDataAccess: '.',
              search: true,
              sort: false,
              expandableRows: false,
              expandableRowsOnClick: false,
              renderExpandableRow: (rowData: any) => {
                console.log(rowData);
                return <Grid>Expandable View</Grid>;
              },
              onRowClick: (rowData: any) => {
                handleRowClick(rowData);
              },
            }}
            components={{ icons: components as any }}
          />
        </Grid>
      )}
    </StyledTableContainer>
  );
}

export default AccordionTable;
