/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ApiInstance from 'utils/instances';

export const getAllFeedback = async (): Promise<any> => {
  return await ApiInstance.get(`/chat/getAllFeedback`).then(res => res.data);
};

export const getChatHistoryFilter = async (payload: any): Promise<any> => {
  return await ApiInstance.get(`/chat/getChatHistory`, {
    params: payload,
  })
    .then(res => res.data)
    .catch(({ response }: any) => response);
};
