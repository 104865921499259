// URL
export const CURRENT_WEB_URL = window.location.origin;

// HUBSPOT
const HUBSPOT_CLIENT_ID = process.env.REACT_APP_HUBSPOT_CLIENT_ID;
const HUBSPOT_AUTH_URL = 'https://app.hubspot.com/oauth/authorize';
const HUBSPOT_REDIRECT_URL = `${CURRENT_WEB_URL}/authorize/hubspot/`;
const HUBSPOT_PARAMS = `redirect_uri=${HUBSPOT_REDIRECT_URL}&scope=oauth%20crm.objects.contacts.read%20crm.import`;

// WEALTHBOX
const WEALTHBOX_CLIENT_ID = process.env.REACT_APP_WEALTHBOX_CLIENT_ID;
const WEALTHBOX_AUTH_URL = 'https://app.crmworkspace.com/oauth/authorize';
const WEALTHBOX_REDIRECT_URL = encodeURIComponent(
  `${CURRENT_WEB_URL}/authorize/wealthbox/`,
);
const WEALTHBOX_PARAMS = `scope=login+data&redirect_uri=${WEALTHBOX_REDIRECT_URL}&response_type=code`;

// SALESFORCE
const SALESFORCE_CLIENT_ID = process.env.REACT_APP_SALESFORCE_CLIENT_ID;
const SALESFORCE_AUTH_URL =
  'https://login.salesforce.com/services/oauth2/authorize';
const SALESFORCE_REDIRECT_URL = `${CURRENT_WEB_URL}/authorize/salesforce/`;
const SALESFORCE_PARAMS = `redirect_uri=${SALESFORCE_REDIRECT_URL}&response_type=code`;

export const WealthboxAuthUrl = `${WEALTHBOX_AUTH_URL}?client_id=${WEALTHBOX_CLIENT_ID}&${WEALTHBOX_PARAMS}`;
export const HubspotAuthUrl = `${HUBSPOT_AUTH_URL}?client_id=${HUBSPOT_CLIENT_ID}&${HUBSPOT_PARAMS}`;
export const SalesforceAuthUrl = `${SALESFORCE_AUTH_URL}?client_id=${SALESFORCE_CLIENT_ID}&${SALESFORCE_PARAMS}`;

export const INTEGRATIONS_NAME = {
  WEALTHBOX: 'WEALTHBOX',
  REDTAIL: 'REDTAIL',
  HUBSPOT: 'HUBSPOT',
  SALESFORCE: 'SALESFORCE',
};
