export function getSortedData(
  data: Array<any>,
  sortableKey: string,
  sortingOrder = 'asc',
  isNumber = false,
): Array<any> {
  const arr = [...data];
  arr.sort((rowA, rowB) => {
    let comparison = 0;
    // set column-value as a empty-string/zero(0) if column-value is not or column is undefiend
    let rowAColumn: string | number = '';
    let rowBColumn: string | number = '';
    if (isNumber) {
      if (rowA[sortableKey] == null || rowA[sortableKey] == undefined) {
        rowAColumn = 0;
      } else {
        rowAColumn = Number(rowA[sortableKey]);
      }
      if (rowB[sortableKey] == null || rowB[sortableKey] == undefined) {
        rowBColumn = 0;
      } else {
        rowBColumn = Number(rowB[sortableKey]);
      }

      //Sorting
      if (rowAColumn > rowBColumn) {
        comparison = 1;
      } else if (rowAColumn < rowBColumn) {
        comparison = -1;
      }
    } else {
      if (rowA[sortableKey] !== null && rowA[sortableKey] !== undefined) {
        rowAColumn = rowA[sortableKey].toLowerCase().trim();
      }
      if (rowB[sortableKey] !== null && rowB[sortableKey] !== undefined) {
        rowBColumn = rowB[sortableKey].toLowerCase().trim();
      }

      const rac: string = String(rowAColumn);
      const rbc: string = String(rowBColumn);
      comparison = rac.localeCompare(rbc, 'en', {
        ignorePunctuation: true,
      });
    }

    return sortingOrder === 'desc' ? comparison * -1 : comparison;
  });
  return arr;
}

export const checkValidArray = (arr: any[]): boolean => {
  return Array.isArray(arr) && arr.length > 0;
};
