import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getProfileDataForLocal } from '@common/auth';
import { ProfileState, AuthDetailsProps, AuthUserType } from './types';

export const initialState: ProfileState = {
  authDetails: {
    userId: getProfileDataForLocal()?.userId ?? null,
    advisorId: getProfileDataForLocal()?.advisorId ?? null,
    email: getProfileDataForLocal()?.email ?? null,
    name: getProfileDataForLocal()?.name ?? null,
    firstName: getProfileDataForLocal()?.firstName ?? null,
    lastName: getProfileDataForLocal()?.lastName ?? null,
    apiKey: localStorage.getItem('tifin-ai-api-key') ?? null,
    apiToken: localStorage.getItem('x-tifin-ai-token') ?? null,
    userType: localStorage.getItem('tifin-ai-user-type') as AuthUserType,
    enterpriseId: localStorage.getItem('enterpriseId') ?? null,
    dataProviderId: localStorage.getItem('dataProviderId') ?? null,
  },
  loading: false,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateProfile(state, action: PayloadAction<AuthDetailsProps>) {
      state.authDetails = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    userInfoRequest(state, action: PayloadAction<any>) {},
    userInfoFetch(state) {
      state.loading = true;
    },
    userInfoSuccess(state, action: PayloadAction<AuthDetailsProps>) {
      state.loading = false;
      state.authDetails = action.payload;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logoutOktaUserRequest(state) {},
    logoutOktaUserFetch(state) {
      state.loading = true;
    },
    logoutOktaUserSuccess(state, action: PayloadAction<AuthDetailsProps>) {
      state.loading = false;
      state.authDetails = action.payload;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    acceptedTermsAndConditionsRequest(state, action: PayloadAction<any>) {},
    acceptedTermsAndConditionsFetch(state, action: PayloadAction<any>) {
      state.loading = action.payload;
    },
    acceptedTermsAndConditionsSuccess(state, action: PayloadAction<any>) {
      state.loading = action.payload;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    accountActivationRequest(state, action: PayloadAction<any>) {},
    accountActivationFetch(state, action: PayloadAction<any>) {
      state.loading = action.payload;
    },
    accountActivationSuccess(state, action: PayloadAction<any>) {
      state.loading = action.payload;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    validateHashKeyRequest(state, action: PayloadAction<any>) {},
    validateHashKeyFetch(state, action: PayloadAction<any>) {
      state.loading = action.payload;
    },
    validateHashKeySuccess(state, action: PayloadAction<any>) {
      state.loading = action.payload;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetPasswordRequest(state, action: PayloadAction<any>) {},
    resetPasswordFetch(state, action: PayloadAction<any>) {
      state.loading = action.payload;
    },
    resetPasswordSuccess(state, action: PayloadAction<any>) {
      state.loading = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = profileSlice;
