import React, { Fragment } from 'react';
import { Grid } from '@mui/material';

import { Grey2SubLabelText, TextAvatar } from '@components/Typography';

import { getInitalName } from '@common/auth';

import ContentDefault from './ContentDefault';

import { AvatarWrapper, ChatBoxContent, ChatBoxContainer } from './styles';
import { getChatDateFormat } from '@common/dateTime';

type ConversationBoxPromptProps = {
  visible_prompt: string;
  time: string | null;
  chatContentRef?: React.MutableRefObject<HTMLDivElement | null>;
};

/**
 * Wraps just the prompt sent to the server
 */
function ConversationBoxPrompt({
  visible_prompt,
  time,
  chatContentRef,
}: ConversationBoxPromptProps): React.JSX.Element {
  return (
    <div>
      <ChatBoxContainer
        container
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        isSender={true}
        ref={chatContentRef}
      >
        <Grid
          item
          container
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
          columnGap={1}
          width={'calc(100% - 108px)'}
        >
          <AvatarWrapper>
            <TextAvatar
              container
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              color={'white'}
            >
              {getInitalName() || 'A'}
            </TextAvatar>
          </AvatarWrapper>
          <ChatBoxContent
            container
            sm={10}
            rowGap={2}
            minHeight={'36px'}
            justifyContent={'space-evenly'}
          >
            <Fragment>
              <ContentDefault
                key={'prompt'}
                type={'text'}
                data={visible_prompt}
                isSender={false}
              />
            </Fragment>
          </ChatBoxContent>
        </Grid>
        {time && (
          <Grid
            container
            width={'auto'}
            minHeight={'36px'}
            flexDirection={'column'}
            alignItems={'flex-end'}
            justifyContent={'space-evenly'}
          >
            <Grey2SubLabelText>{getChatDateFormat(time)}</Grey2SubLabelText>
          </Grid>
        )}
      </ChatBoxContainer>
    </div>
  );
}

export default ConversationBoxPrompt;
