/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { getProfileDataForLocal } from '@common/auth';
import ApiInstance from 'utils/instances';
import {
  ChatFeedbackResponse,
  BackendChatInitResponse,
  ChatInsightResponseInterface,
} from './types';

export const getChatInit = async (): Promise<BackendChatInitResponse> => {
  const advisorId = getProfileDataForLocal()?.advisorId ?? null;
  const payload = {
    advisorId,
  };
  return await ApiInstance.post(`/chat/init`, payload, {}).then(
    res => res.data,
  );
};

export const getInsights = async (): Promise<ChatInsightResponseInterface> => {
  return await ApiInstance.get(`/chat/insights`).then(res => res.data);
};

export const submitChatFeedback = async (
  request: any,
): Promise<ChatFeedbackResponse> => {
  const { payload, headers } = request;
  return await ApiInstance.post(`/chat/feedback`, payload, { headers }).then(
    res => res.data,
  );
};
