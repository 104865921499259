const darkenColor = (color: string, factor: number): string => {
  const r = Math.max(
    0,
    parseInt(color.slice(1, 3), 16) - Math.floor(255 * factor),
  );
  const g = Math.max(
    0,
    parseInt(color.slice(3, 5), 16) - Math.floor(255 * factor),
  );
  const b = Math.max(
    0,
    parseInt(color.slice(5, 7), 16) - Math.floor(255 * factor),
  );
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
};

export const generateDarkerShades = (
  color: string,
  numberOfShades: number,
): string[] => {
  const shades: string[] = [];
  const darkenFactor = 0.2; // Adjust this factor to change the darkness level

  for (let i = 0; i < numberOfShades; i++) {
    let darkenedColor = color;
    for (let j = 0; j <= i; j++) {
      darkenedColor = darkenColor(darkenedColor, darkenFactor);
    }
    shades.push(darkenedColor);
  }
  return shades;
};

export const generateShades = (
  color: string,
  numberOfShades: number,
): string[] => {
  const shades: string[] = [];
  for (let i = 0; i < numberOfShades; i++) {
    const shade: string = `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(color.slice(5, 7), 16)}, ${(i + 1) / numberOfShades})`;
    shades.push(shade);
  }
  return shades;
};
