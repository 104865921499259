import React, { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { actions as globalActions } from 'app/features/Global/slice';
import { getQueriesFromStore } from 'app/features/Queries/selector';
import { actions } from 'app/features/Queries/slice';
import { Box, Grid } from '@mui/material';
import { ButtonsDiv } from './styles';
import AddQuery from './AddQuery';
import { AddEditQuery } from './types';
import { useMutation } from 'react-query';
import {
  AddQueryForClient,
  deleteQueryForClient,
  updateQueryForClient,
} from './mutations';
import DeleteConfirmationDialog from './DeleteQueryDialog';
import { useNavigate } from 'react-router-dom';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { PrimaryButton } from '@components/Button';

interface ISelectedQuery {
  query: string;
  classification: string;
  id: string;
}

const defaultQuery = {
  query: '',
  classification: '',
  id: '',
};

function Tests(): React.JSX.Element {
  const [openAddQueryDialog, setOpenAddQueryDialog] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const [selectedQuery, setSelectedQuery] =
    useState<ISelectedQuery>(defaultQuery);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const addQueryMutate = useMutation(AddQueryForClient);
  const updateQueryMutate = useMutation(updateQueryForClient);
  const deleteQueryMutate = useMutation(deleteQueryForClient);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data: any = useSelector(getQueriesFromStore);
  const options: MUIDataTableOptions = {
    pagination: false,
    selectableRows: 'none',
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    filter: false,
    sort: false,
    responsive: 'vertical',
    serverSide: true,
    setTableProps: () => ({ style: { width: '100%', height: '100%' } }),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    customHeadRender: columnMeta => {
      return <th key={columnMeta.name}>{columnMeta.label}</th>;
    },
    customRowRender: (data: any, rowIndex) => {
      return (
        <tr key={rowIndex} style={{ borderBottom: '1px solid #eee' }}>
          {data.map((value: any, colIndex: any) => {
            return (
              <td key={colIndex} style={{ padding: '15px', fontSize: '16px' }}>
                {colIndex !== 3 ? (
                  colIndex === 0 ? (
                    rowIndex + 1
                  ) : (
                    value
                  )
                ) : (
                  <Grid container justifyContent={'space-between'}>
                    <EditIcon
                      style={{ fontSize: '20px', cursor: 'pointer' }}
                      onClick={() => handleEdit(data)}
                    />
                    <DeleteIcon
                      style={{
                        fontSize: '20px',
                        color: 'red',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleDelete(data)}
                    />
                  </Grid>
                )}
              </td>
            );
          })}
        </tr>
      );
    },
    tableBodyMaxHeight: 'calc(100vh - 124px)',
  };

  useEffect(() => {
    if (data?.length === 0) {
      dispatch(actions.loadQueriesRequest());
    }
  }, []);

  const columns = [
    {
      name: 'sortIdx',
      label: '',
      options: { setCellProps: () => ({ style: { width: '5%' } }) },
    },
    {
      name: 'query',
      label: 'Query',
      options: { setCellProps: () => ({ style: { width: '55%' } }) },
    },
    {
      name: 'classification',
      label: 'Type',
      options: { setCellProps: () => ({ style: { width: '20%' } }) },
    },
    {
      name: 'action',
      label: 'Action',
      options: { setCellProps: () => ({ style: { width: '20%' } }) },
    },
  ];

  const handleEdit = (selectedRowData: any[]) => {
    const selectedRow = {
      query: selectedRowData[1],
      classification: selectedRowData[2],
      id: data.filter((y: any) => y.query === selectedRowData[1])[0]._id,
    };
    setOpenAddQueryDialog(true);
    setType('update');
    setSelectedQuery(selectedRow);
  };

  const handleDelete = (selectedRowData: any[]) => {
    const selectedRow = {
      query: selectedRowData[1],
      classification: selectedRowData[2],
      id: data.filter((y: any) => y.query === selectedRowData[1])[0]._id,
    };
    setOpenDeleteDialog(true);
    setType('delete');
    setSelectedQuery(selectedRow);
  };
  const handleError = (error: any) => {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message &&
      typeof error.response.data.message === 'string'
    ) {
      dispatch(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage: error?.response?.data?.message,
        }),
      );
    } else {
      dispatch(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage: 'Something went wrong',
        }),
      );
    }
  };

  const onCloseAddQuery = () => {
    setOpenAddQueryDialog(false);
    setType('');
  };

  // onSubmitting add or edit query
  const onSubmit = ({
    query,
    classification,
  }: {
    query: string;
    classification: string;
  }) => {
    const payload: AddEditQuery = {
      query,
      classification,
    };
    if (type === 'update') {
      payload.id = selectedQuery?.id;
    }

    const typeMutate = type === 'update' ? updateQueryMutate : addQueryMutate;

    typeMutate.mutate(payload, {
      onSuccess: (res: any) => {
        if (res) {
          setOpenAddQueryDialog(false);
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'success',
              toastMessage: 'Query saved successfully',
            }),
          );
          dispatch(actions.loadQueriesRequest());
          setType('');
        }
      },
      onError: (error: any) => {
        handleError(error);
      },
    });
  };

  const openAddModal = () => {
    setOpenAddQueryDialog(true);
    setType('add');
  };

  const deleteQuery = () => {
    const payload = {
      id: selectedQuery.id,
    };
    deleteQueryMutate.mutate(payload, {
      onSuccess: (res: any) => {
        if (res) {
          setOpenDeleteDialog(false);
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'success',
              toastMessage: 'Query deleted successfully',
            }),
          );
          dispatch(actions.loadQueriesRequest());
        }
      },
      onError: (error: any) => {
        handleError(error);
      },
    });
  };

  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <Box p={2} sx={{ height: '100%' }}>
      <ButtonsDiv>
        <PrimaryButton onClick={openAddModal}>Add Query</PrimaryButton>
        <PrimaryButton
          style={{ marginLeft: '10px' }}
          onClick={() => navigate('/test/results')}
        >
          Test Runs
        </PrimaryButton>
      </ButtonsDiv>
      <div>
        <MUIDataTable
          title={''}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
      {/* Add Query */}
      <AddQuery
        open={openAddQueryDialog}
        type={type}
        onClose={onCloseAddQuery}
        onSubmit={onSubmit}
        selectedQuery={selectedQuery}
        isLoading={false}
      />

      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onClose={closeDeleteDialog}
        onConfirm={deleteQuery}
      />
    </Box>
  );
}

export default Tests;
