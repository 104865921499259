import * as React from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { BootstrapDialog } from './style';
import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  PDFDownloadLink,
  usePDF,
  Image,
} from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { ExtTableGroupedProps } from '../ExtTableGrouped';
import { ExtTableColumn, ExtTableRow } from '../types';
import HelixLogo from '@logos/HelixByHLDark.png';

type ExtTableGroupedPDFModalProps = Pick<
  ExtTableGroupedProps,
  'columns' | 'groups' | 'rows'
> & {
  onHide: () => void;
  sorted_groupnames: string[];
  visible_groups: Record<string, ExtTableColumn[]>;
  rows_per_group: Record<string, ExtTableRow[]>;
  header_title?: string;
  header_subtitle?: string;
  document_title: string;
};

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFF',
    justifyContent: 'flex-start',
  },
  logoHeader: {
    marginLeft: 50,
    marginTop: 30,
    marginBottom: 30,
    display: 'flex',
    flexDirection: 'row',
    gap: '8',
  },
  logoImage: {
    width: 100,
    height: 'auto',
  },
  titleHeader: {
    marginLeft: 50,
    marginRight: 50,
  },
  documentTitle: {
    fontSize: '16px',
    color: '#3B86C8',
  },
  documentSubtitle: {
    fontSize: '14px',
    color: '#192335',
  },
  group: {
    margin: 10,
    padding: 10,
    flexGrow: 0,
    flexDirection: 'column',
    marginLeft: 50,
    marginRight: 50,
  },
  header: {
    display: 'flex',
    border: '1px solid black',
    backgroundColor: '#96969B',
    height: '35px',
    fontSize: '14px',
  },
  headerGray: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '35px',
  },
  groupText: {
    /*    transform: 'rotate(-90deg)',
    transformOrigin: 'center',*/
    textAlign: 'center',
    textTransform: 'capitalize',
    color: 'white',
    whiteSpace: 'nowrap',
  },
  row: {
    borderBottom: '1px solid black',
    borderLeft: '1px solid black',
    fontSize: '11px',
    flexDirection: 'row',
    //    padding: '5px',
  },
  concept: {
    padding: '5px',
    fontSize: '11px',
    borderRight: '1px solid black',
    width: '35%',
  },
  value: {
    fontSize: '11px',
    width: '65%',
    flexDirection: 'row',
  },
  valueCell: {
    padding: '5px',
    fontSize: '11px',
    borderRight: '1px solid black',
  },
});

const ExtTableGroupedPDF = ({
  sorted_groupnames,
  visible_groups,
  rows_per_group,
  columns,
  header_title,
  header_subtitle,
  document_title,
}: Omit<ExtTableGroupedPDFModalProps, 'onHide'>): JSX.Element => {
  const valueColumnCount = columns.length - 1;
  const columnWidth = 100 / valueColumnCount + '%';
  return (
    <Document pageMode="fullScreen" title={document_title}>
      <Page size="A4" style={styles.page}>
        <View style={styles.logoHeader}>
          <Image style={styles.logoImage} src={HelixLogo} />
        </View>

        <View style={styles.titleHeader}>
          {header_title && (
            <Text style={styles.documentTitle}>{header_title}</Text>
          )}
          {header_subtitle && (
            <Text style={styles.documentSubtitle}>{header_subtitle}</Text>
          )}
        </View>

        {sorted_groupnames.map(groupname => {
          const groupcolumns = visible_groups[groupname];
          if (!groupcolumns) return null;
          return (
            <View style={styles.group} key={groupname} wrap={false}>
              <ExtTableGroupedPDFModal_Header groupname={groupname} />
              <View>
                <View style={styles.row}>
                  <View style={styles.concept}>
                    <Text>Fund</Text>
                  </View>
                  <View style={styles.value}>
                    {columns
                      .filter(column => column.key != 'label')
                      .map(column => {
                        return (
                          <View
                            style={{
                              ...styles.valueCell,
                              width: columnWidth,
                            }}
                            key={column.key}
                          >
                            <Text>{column.label}</Text>
                          </View>
                        );
                      })}
                  </View>
                </View>
              </View>
              <View>
                {groupcolumns.map(concept => {
                  return (
                    <View key={concept.key} style={styles.row}>
                      <View style={styles.concept}>
                        <Text>{concept.label}</Text>
                      </View>
                      <View style={styles.value}>
                        {columns
                          .filter(column => column.key != 'label')
                          .map((column, i) => {
                            const fund_name = column.label;

                            //const value = rows_per_group[groupname].find(row => row.key === column.key);
                            //                                                    console.log({rows: rows_per_group[groupname]});
                            const values_for_all_funds = rows_per_group[
                              groupname
                            ].find(c => c.key === concept.key);
                            const value = (values_for_all_funds || [])[
                              fund_name
                            ];
                            return (
                              <View
                                style={{
                                  ...styles.valueCell,
                                  width: columnWidth,
                                }}
                                key={column.key + i}
                              >
                                <Text>{value}</Text>
                              </View>
                            );
                          })}
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          );
        })}
      </Page>
    </Document>
  );
};

export const ExtTableGroupedPDFLink = ({
  document_title,
  onExported,
  ...props
}: Omit<ExtTableGroupedPDFModalProps, 'onHide'> & {
  onExported?: () => void;
}): JSX.Element => {
  const MyDoc = (
    <ExtTableGroupedPDF {...props} document_title={document_title} />
  );
  const [instance /*, updateInstance*/] = usePDF({ document: MyDoc });

  React.useEffect(() => {
    if (instance.loading) return;
    if (instance.error) return;
    if (instance.blob) {
      const url = URL.createObjectURL(instance.blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${document_title}.pdf`;
      a.click();
      window.setTimeout(() => {
        onExported && onExported();
      }, 1000);
      //      onExported && onExported();
    }
  }, [instance.loading, instance.error, instance.blob, document_title]);

  return <>DOWNLOADING</>;

  return (
    <PDFDownloadLink
      document={
        <ExtTableGroupedPDF {...props} document_title={document_title} />
      }
      fileName={`${document_title}.pdf`}
    >
      {({ loading }) => (loading ? 'Loading document...' : 'Export')}
    </PDFDownloadLink>
  );
};

const ExtTableGroupedPDFModal: React.FC<ExtTableGroupedPDFModalProps> = ({
  onHide,
  ...props
}) => {
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={onHide}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <DialogTitle id="customized-dialog-title">
          {'Export as PDF'}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onHide}
          sx={{
            position: 'absolute',
            right: 10,
            top: 8,
            color: '#101828',
          }}
        >
          <CloseIcon />
        </IconButton>

        <>
          <DialogContent>
            <PDFViewer width={'100%'} height={'500px'}>
              <ExtTableGroupedPDF {...props} />
            </PDFViewer>
          </DialogContent>
        </>
      </BootstrapDialog>
    </React.Fragment>
  );
};

const ExtTableGroupedPDFModal_Header = ({
  groupname,
}: {
  groupname: string;
}) => {
  return (
    <View style={styles.header}>
      <View style={styles.headerGray}>
        <Text style={styles.groupText}>{groupname}</Text>
      </View>
    </View>
  );
};

export default ExtTableGroupedPDFModal;
