import { Box, Grid, Paper, Typography, styled } from '@mui/material';

export const UploadBodyContainer = styled(Grid)`
  width: 100%;
  gap:1rem
  display: flex;
  width:520px;
  flex-direction: column;
  align-items: center;
  
  .file-uploader{
    font-weight:600;
  }
`;

export const Container = styled(Grid)`
  width: 100%;
  gap:1rem
  display: flex;
  width:520px;
  flex-direction: column;
  align-items: center;
  .file-uploader{
    font-weight:600;
  }
`;

export const FileDraggerContainer = styled(Grid)`
  padding: 10px;
  backgroundcolor: white;
  height: 55vh;
`;

export const StyledBox = styled(Grid)`
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;
export const StyledPaper = styled(Paper)`
  margin-top: 1.5rem;
  padding: 1rem;
  display: flex;
  gap: 12px;
  border-radius: 4px;
  background-color: #e0e4ec;
  align-items: center;
`;
export const PaperWrapper = styled(Paper)`
  margin-top: 1.5rem;
  padding: 1rem;
  gap: 10px;
  flex-direction: column;
  border-radius: 4px;
  background-color: #e0e4ec;
`;
export const StyledWrapper = styled(Paper)`
  margin-top: 1.5rem;
  padding: 1rem;
  display: flex;
  gap: 12px;
  border-radius: 4px;
  background-color: #e0e4ec;
  align-items: center;
`;
export const StyledFlexBox = styled(Box)`
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;
`;
export const StyledText = styled(Typography)`
  font-size: 1rem;
  font-weight: 500;
`;
export const StyledTextI = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
  margin-right: 6px;
`;
export const StyledTypo = styled(Typography)`
  font-size: 14px;
  color: #475467;
`;
