import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastAction } from 'app/components/ToastAlert/types';
import { GlobalState, Config, FundDetails } from './types';

export const initialState: GlobalState = {
  /* Common State */
  chatWindowCollapse: false,
  resizeTrigger: 0,

  toastInfo: {
    duration: 6000,
    showToast: false,
    toastMessage: '',
    toastType: undefined,
  },
  breadcrumbMode: 'Dashboard',
  isContextualMode: false,
  fundDetails: {
    ticker: '',
    name: '',
    data: [],
  },
  productTour: {
    start: false,
    loadScripts: [],
    dashboardScripts: [],
    eductionScripts: [],
    marketResearchScripts: [],
    fundResearchScripts: [],
    clientCommunicationScripts: [],
    clientPageScripts: [],
    integrationPageScripts: [],
    finish: [],
    activeScripts: '',
  },
  productOnboarding: {
    start: true,
  },
  config: {
    loading: false,
    tone: false,
    tAndC: false,
    feedback: false,
    skipCache: false,
    crmIntegrations: false,
    isProductTourViewed: false,
    custodialIntegration: false,
  },
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    chatWindowState(state, action: PayloadAction<boolean>) {
      state.chatWindowCollapse = action.payload;
    },
    triggerResize: state => {
      state.resizeTrigger += 1;
    },
    breadcrumbMode(state, action: PayloadAction<'Dashboard' | 'Contextual'>) {
      state.breadcrumbMode = action.payload;
    },
    updateHeaderListState() {},
    /* --- Toast Actions Start --- */
    displayToast(state, action: PayloadAction<ToastAction>) {
      state.toastInfo.showToast = true;
      state.toastInfo.toastMessage = action.payload.toastMessage;
      state.toastInfo.toastType = action.payload.toastType;
      state.toastInfo.duration = action.payload.duration;
    },
    closeToast(state) {
      state.toastInfo.showToast = false;
      state.toastInfo.toastMessage = '';
    },

    /* --- Toast Actions End --- */
    fetchUserDetails(state) {
      state.toastInfo = { ...state.toastInfo, showToast: true };
    },
    updateClientViewEnhancerRecommendations(state, action: PayloadAction<any>) {
      if (action.payload && action.payload.summaryReplacementData) {
        state.toastInfo = {
          ...state.toastInfo,
          showToast: false,
        };
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    /*
    getInsightsFetch(state) {
      state.insights.loading = true;
    },
    getInsightsSuccess(state, action: PayloadAction<Insights>) {
      state.insights = {
        data: action.payload.data || [],
        loading: false,
      };
    },
    */
    updateFundDetails(state, action: PayloadAction<FundDetails>) {
      state.fundDetails = action.payload;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getIntegrationDataByIdRequest(state) {},
    /*
    getIntegrationDataByIdFetch(state, action: PayloadAction<{ id: string }>) {
      const { data: integrationList } = state.integrations;
      const { id: payloadId } = action.payload;
      const index = integrationList.findIndex((l: any) => l.id === payloadId);
      if (index > -1) {
        integrationList[index].loading = true;
      }
      state.integrations = {
        ...state.integrations,
        data: integrationList,
      };
    },
    */
    /*
    getIntegrationDataByIdSuccess(
      state,
      action: PayloadAction<IntegrationData>,
    ) {
      const { data: integrationList } = state.integrations;
      const { id: payloadId } = action.payload;
      const index = integrationList.findIndex((l: any) => l.id === payloadId);
      if (index > -1) {
        integrationList[index] = {
          ...action.payload,
          loading: false,
        };
      }
      state.integrations = {
        ...state.integrations,
        data: integrationList,
      };
    },
    */
    /*
    pollingFailed(state, action: PayloadAction<{ id: string }>) {
      const { data: _integrationList } = state.integrations;
      const { id: payloadId } = action.payload;
      if (payloadId) {
        const index = _integrationList.findIndex(
          (l: any) => l.id === payloadId,
        );

        if (index > -1) {
          _integrationList[index].loading = false;
          _integrationList[index].status = 'SYNC';
        }
        state.integrations = {
          ...state.integrations,
          data: _integrationList,
        };
      }
    },
    */

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    /*
    connectIntegrationRequest(state, action: PayloadAction<any>) {},
    connectIntegrationFetch(state, action: PayloadAction<{ id: string }>) {
      const { data: integrationList } = state.integrations;
      const { id: payloadId } = action.payload;
      const index = integrationList.findIndex((l: any) => l.id === payloadId);

      if (index > -1) {
        integrationList[index].loading = true;
      }
      state.integrations = {
        ...state.integrations,
        data: integrationList,
      };
    },
    connectIntegrationSuccess(state, action: PayloadAction<IntegrationData>) {
      const { data: integrationList } = state.integrations;
      const { id: payloadId } = action.payload;
      const index = integrationList.findIndex((l: any) => l.id === payloadId);
      if (index > -1) {
        integrationList[index] = {
          ...action.payload,
          loading: false,
        };
      }
      state.integrations = {
        ...state.integrations,
        data: integrationList,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    disconnectIntegrationRequest(state) {},
    disconnectIntegrationFetch(state, action: PayloadAction<{ id: string }>) {
      const { data: iList } = state.integrations;
      const { id: payloadId } = action.payload;
      const index = iList.findIndex((l: any) => l.id === payloadId);

      if (index > -1) {
        iList[index].loading = true;
      }
      state.integrations = {
        ...state.integrations,
        data: iList,
      };
    },
    disconnectIntegrationSuccess(
      state,
      action: PayloadAction<IntegrationData>,
    ) {
      const { data: _integrationList } = state.integrations;
      const { id: payloadId } = action.payload;
      const index = _integrationList.findIndex((l: any) => l.id === payloadId);

      if (index > -1) {
        _integrationList[index] = {
          ...action.payload,
          loading: false,
        };
      }
      state.integrations = {
        ...state.integrations,
        data: _integrationList,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    syncIntegrationFetch(state, action: PayloadAction<{ id: string }>) {
      const { data: iList } = state.integrations;
      const { id: payloadId } = action.payload;
      const index = iList.findIndex((l: any) => l.id === payloadId);

      if (index > -1) {
        iList[index].loading = true;
      }
      state.integrations = {
        ...state.integrations,
        data: iList,
      };
    },
    syncIntegrationSuccess(state, action: PayloadAction<IntegrationData>) {
      const { data: _integrationList } = state.integrations;
      const { id: payloadId }: IntegrationData = action.payload;
      if (payloadId) {
        const index = _integrationList.findIndex(
          (l: any) => l.id === payloadId,
        );

        if (index > -1) {
          _integrationList[index] = {
            ...action.payload,
            loading: false,
          };
        }
        state.integrations = {
          ...state.integrations,
          data: _integrationList,
        };
      }
    },

    integrationOperationFailed(state, action: PayloadAction<{ id: string }>) {
      const { data: _integrationList } = state.integrations;
      const { id: payloadId } = action.payload;
      if (payloadId) {
        const index = _integrationList.findIndex(
          (l: any) => l.id === payloadId,
        );

        if (index > -1) {
          _integrationList[index].loading = false;
        }
        state.integrations = {
          ...state.integrations,
          data: _integrationList,
        };
      }
    },
*/

    /* ONBOARD ACTIONS */
    startProductOnboarding(state) {
      state.productOnboarding.start = true;
    },
    stopProductOnboarding(state) {
      state.productOnboarding.start = false;
    },

    /* PRODUCT TOUR ACTIONS */
    startProductTourRequest() {},
    startingProductTour(state, action: PayloadAction<any>) {
      state.productTour.start = action.payload || true;
    },
    updateProductTourLoadScripts(state, action: PayloadAction<any>) {
      const currentScriptState = state.productTour.loadScripts;
      if (action.payload) {
        state.productTour.loadScripts = [...currentScriptState, action.payload];
      }
    },
    updateProductTourDashboardScripts(state, action: PayloadAction<any>) {
      const currentDashboardScripts = state.productTour.dashboardScripts;
      if (action.payload) {
        state.productTour.dashboardScripts = [
          ...currentDashboardScripts,
          action.payload,
        ];
      }
    },
    updateProductTourEductionScripts(state, action: PayloadAction<any>) {
      const currentEductionScripts = state.productTour.eductionScripts;
      if (action.payload) {
        state.productTour.eductionScripts = [
          ...currentEductionScripts,
          action.payload,
        ];
      }
    },
    updateProductTourFundResearchScripts(state, action: PayloadAction<any>) {
      const currentFundResearchScripts = state.productTour.fundResearchScripts;
      if (action.payload) {
        state.productTour.fundResearchScripts = [
          ...currentFundResearchScripts,
          action.payload,
        ];
      }
    },
    updateProductTourClientCommunicationScripts(
      state,
      action: PayloadAction<any>,
    ) {
      const currentClientCommunicationScripts =
        state.productTour.clientCommunicationScripts;
      if (action.payload) {
        state.productTour.clientCommunicationScripts = [
          ...currentClientCommunicationScripts,
          action.payload,
        ];
      }
    },
    updateProductTourMarketResearchScripts(state, action: PayloadAction<any>) {
      const currentMarketResearchScripts =
        state.productTour.marketResearchScripts;
      if (action.payload) {
        state.productTour.marketResearchScripts = [
          ...currentMarketResearchScripts,
          action.payload,
        ];
      }
    },
    updateProductTourClientPageScripts(state, action: PayloadAction<any>) {
      const currentClientPageScripts = state.productTour.clientPageScripts;
      if (action.payload) {
        state.productTour.clientPageScripts = [
          ...currentClientPageScripts,
          action.payload,
        ];
      }
    },
    updateProductTourIntegrationPageScripts(state, action: PayloadAction<any>) {
      const currentIntegrationPageScripts =
        state.productTour.integrationPageScripts;
      if (action.payload) {
        state.productTour.integrationPageScripts = [
          ...currentIntegrationPageScripts,
          action.payload,
        ];
      }
    },
    updateProductTourFinishScripts(state, action: PayloadAction<any>) {
      const currentFinishScripts = state.productTour.finish;
      if (action.payload) {
        state.productTour.finish = [...currentFinishScripts, action.payload];
      }
    },
    updateActiveScripts(state, action: PayloadAction<any>) {
      state.productTour.activeScripts = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    finishProductTour(state, action: PayloadAction<any>) {
      state.productTour = {
        start: false,
        loadScripts: [],
        dashboardScripts: [],
        eductionScripts: [],
        fundResearchScripts: [],
        clientCommunicationScripts: [],
        marketResearchScripts: [],
        clientPageScripts: [],
        integrationPageScripts: [],
        finish: [],
        activeScripts: '',
      };
    },
    resetInsightDataState(state) {
      state.breadcrumbMode = 'Dashboard';
      state.fundDetails = {
        ticker: '',
        name: '',
        data: [],
      };
    },

    getUserConfigRequest() {},
    getUserConfigFetch(state) {
      state.config.loading = true;
    },
    getUserConfigSuccess(
      state,
      action: PayloadAction<{ data?: Config; loading: boolean }>,
    ) {
      state.config = {
        ...state.config,
        ...action.payload.data,
        loading: false,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateUserConfigRequest(state, action: PayloadAction<any>) {},
    updateUserConfigFetch(state) {
      state.config.loading = true;
    },
    updateUserConfigSuccess(
      state,
      action: PayloadAction<{ data?: Config; loading: boolean }>,
    ) {
      state.config = {
        ...state.config,
        ...action.payload.data,
        loading: false,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    exitFundModeRequest() {},
    exitFundModeFetch() {},
    exitFundModeSuccess(state) {
      state.isContextualMode = false;
    },
    updateContextualMode(state: any, action: PayloadAction<boolean>) {
      state.isContextualMode = action.payload;
    },
    updateConfigLoading(state, action: PayloadAction<boolean>) {
      state.config.loading = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = globalSlice;
