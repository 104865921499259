/* eslint-disable react/prop-types */

import React from 'react';

function StarFilledIcon(props: any): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M9.30943 13.4086L12.8532 15.6586C13.3102 15.9469 13.8727 15.518 13.7391 14.9906L12.7126 10.9547C12.6848 10.8428 12.6892 10.7254 12.7253 10.6159C12.7614 10.5065 12.8276 10.4094 12.9165 10.336L16.0946 7.68517C16.5094 7.34064 16.2985 6.64455 15.7571 6.60939L11.6087 6.3422C11.4955 6.33562 11.3866 6.2962 11.2955 6.22878C11.2043 6.16135 11.1348 6.06883 11.0954 5.96252L9.5485 2.0672C9.50755 1.95464 9.43295 1.85739 9.33484 1.78868C9.23672 1.71996 9.11984 1.68311 9.00006 1.68311C8.88027 1.68311 8.76339 1.71996 8.66528 1.78868C8.56716 1.85739 8.49257 1.95464 8.45162 2.0672L6.90475 5.96252C6.86532 6.06883 6.79578 6.16135 6.70463 6.22878C6.61348 6.2962 6.50466 6.33562 6.39146 6.3422L2.24303 6.60939C1.70162 6.64455 1.49068 7.34064 1.90553 7.68517L5.08365 10.336C5.17248 10.4094 5.23875 10.5065 5.27483 10.6159C5.3109 10.7254 5.31532 10.8428 5.28756 10.9547L4.33834 14.6953C4.17662 15.3281 4.85162 15.8414 5.39303 15.4969L8.69068 13.4086C8.78316 13.3498 8.89047 13.3186 9.00006 13.3186C9.10964 13.3186 9.21696 13.3498 9.30943 13.4086Z"
        fill={props.fill || '#333333'}
        stroke={props.fill || '#333333'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoConnectIcon = React.memo(StarFilledIcon);
export default MemoConnectIcon;
