/* eslint-disable react/prop-types */
import * as React from 'react';

function ChatIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4.2565 16.5938C3.13983 14.7098 2.74924 12.483 3.15804 10.3315C3.56684 8.17989 4.74693 6.25153 6.47677 4.90838C8.2066 3.56523 10.3672 2.89967 12.553 3.03666C14.7388 3.17364 16.7994 4.10374 18.348 5.65235C19.8966 7.20095 20.8267 9.26157 20.9637 11.4473C21.1007 13.6331 20.4351 15.7937 19.0919 17.5236C17.7488 19.2534 15.8204 20.4335 13.6689 20.8423C11.5173 21.2511 9.29049 20.8605 7.4065 19.7438V19.7438L4.294 20.6251C4.16648 20.6624 4.03128 20.6647 3.90256 20.6317C3.77384 20.5988 3.65635 20.5319 3.5624 20.4379C3.46845 20.344 3.4015 20.2265 3.36858 20.0978C3.33565 19.969 3.33796 19.8338 3.37525 19.7063L4.2565 16.5938Z"
        stroke={props.fill || '#D0D5DD'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 10.5H15"
        stroke={props.fill || '#D0D5DD'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 13.5H15"
        stroke={props.fill || '#D0D5DD'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoNoData = React.memo(ChatIcon);
export default MemoNoData;
