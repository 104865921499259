import { SmallText } from '@components/Typography';
import React from 'react';
import { StatsWrapper } from './styles';
import { CircularProgress, Grid } from '@mui/material';

interface IndexStats {
  dimension?: number;
  indexFullness?: number;
  totalRecordCount?: number;
}
interface StatsDetail {
  indexName: string;
  indexStats?: IndexStats;
}

interface StatsListProps {
  statsDetails: StatsDetail[];
  isLoading: boolean;
}

const StatsList: React.FC<StatsListProps> = ({
  statsDetails,
  isLoading,
}): JSX.Element => {
  return (
    <StatsWrapper>
      {!isLoading &&
        statsDetails?.map(
          ({
            indexName,
            indexStats: { dimension, indexFullness, totalRecordCount } = {},
          }) => {
            return (
              <>
                <SmallText
                  fontSize="18px"
                  color="#475467"
                  fontWeight="400"
                  marginBottom="10px"
                >
                  Index Name - {indexName}
                </SmallText>
                <ul>
                  <li>Dimension - {dimension}</li>
                  <li>Index fullness - {indexFullness}</li>
                  <li>Total Count - {totalRecordCount}</li>
                </ul>
              </>
            );
          },
        )}
      {isLoading && (
        <Grid
          container
          width={'100%'}
          height={'100%'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{ marginBottom: '24px' }}
        >
          <CircularProgress />
        </Grid>
      )}
    </StatsWrapper>
  );
};
export default StatsList;
