import React from 'react';
import styled from 'styled-components';
import renderCell from './cells';
import { ChatExtTableProps } from './ChatExtTable';
import { ExtTableColumn, ExtTableRow } from './types';
import ExtTableGrouped from './ExtTableGrouped';

export type ChatFundComparisonTableProps = Pick<
  ChatExtTableProps,
  'columns' | 'rows'
>;

/**
 * This is a special table, as the axis are exchanged and there are row groups.
 * Also, columns are grouped and we have a wrapper div with some header.
 */
function ChatFundComparisonTableUnstyled({
  columns,
  rows,
}: ChatFundComparisonTableProps): JSX.Element {
  // Classify columns by groups
  const groups = columns.reduce(
    (acc, column) => {
      const groupname = column.group || 'Other';
      if (!acc[groupname]) {
        acc[groupname] = [];
      }
      acc[groupname].push(column);
      return acc;
    },
    {} as Record<string, typeof columns>,
  );

  const fundColumns: ExtTableColumn[] = [
    {
      label: 'Fund',
      key: 'label',
      type: 'string',
      width: 205,
      color: '#86858B',
      fontSize: '12px',
    },
    ...rows.map(row => {
      const column: ExtTableColumn = {
        label: (row['fund_name'] as string) || (row['Fund Name'] as string),
        key: (row['fund_name'] as string) || (row['Fund Name'] as string),
        type: 'mixed',
        maxWidth: 305,
        color: '#86858B',
        fontSize: '12px',
      };
      return column;
    }),
  ];

  const cellRenderer = (row: ExtTableRow, column: ExtTableColumn) => {
    if (row === undefined) {
      return <i>Undefined row</i>;
    }
    if (column.key === 'label') {
      return <div style={{ color: '#535256' }}>{row['label'] as string}</div>;
    }
    const field_name = row['label'] as string;
    const column_type =
      columns.find(col => col.label === field_name)?.type || 'string';
    const not_undefined =
      row[column.label] === undefined ? null : row[column.label];
    return <div className="cell">{renderCell(column_type, not_undefined)}</div>;
  };

  const subtitle =
    rows.length > 1
      ? ((rows[0]['fund_name'] as string) || (rows[0]['Fund Name'] as string)) +
        ' vs ' +
        ((rows[1]['fund_name'] as string) || (rows[1]['Fund Name'] as string))
      : undefined;

  return (
    <ExtTableGrouped
      mode={'VIEW'}
      customization_key="fund_comparison"
      headerTitle={'FUND COMPARISON'}
      documentTitle={'Fund Comparison - ' + subtitle}
      headerSubtitle={subtitle}
      groups={groups}
      columns={fundColumns}
      cellRenderer={cellRenderer}
      rows={rows}
    />
  );
}

const Wrapper = styled.div`
  .cell,
  .cell span {
    color: #96969b;
  }
  &td {
    vertical-align: baseline;
  }
  .cell .show-more-less-clickable {
    color: black;
    cursor: pointer;
  },
`;

export default function ChatFundComparisonTable(
  props: ChatFundComparisonTableProps,
): JSX.Element {
  return (
    <Wrapper>
      <div style={{ minWidth: '900px' }}>
        <ChatFundComparisonTableUnstyled {...props} />
      </div>
    </Wrapper>
  );
}
