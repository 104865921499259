import React, { useEffect, useState } from 'react';
import { FormControl, IconButton } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { PrimaryButton, SecondaryButton } from '@components/Button';
import { Heading, LightSubHeading } from '@components/Typography';
import InputField from '@components/core/InputField';
import CheckIcon from '@mui/icons-material/Check';
import { AuthUserType } from '@features/Profile/types';

import { ButtonContainer, ModalContainer, PopupHeader } from '../styles';

interface AddModalProps {
  loading?: boolean;
  data?: Array<any>;
  enterpriseData?: string;
  onClose: () => void;
  handleSubmit: (formData: any) => void;
}

const roleList: Array<AuthUserType> = ['ADMIN', 'SUB_ADMIN', 'FEEDBACK_USER'];

const AddUserModal: React.FC<AddModalProps> = ({
  data = [],
  loading = false,
  enterpriseData = '',
  onClose,
  handleSubmit,
}) => {
  const [formData, setFormData] = useState({
    email: '',
    role: '',
    firstName: '',
    lastName: '',
    enterpriseId: '',
  });

  const onCloseModal = () => {
    onClose();
  };

  const onChange = (key: string, value: string) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };
  const [focusedInput, setFocusedInput] = useState('');
  const onFocus = (inputName: string) => {
    setFocusedInput(inputName);
  };

  const onBlur = () => {
    setFocusedInput('');
  };

  const onSubmit = () => {
    if (
      data &&
      data.length > 0 &&
      formData.email.length > 0 &&
      formData.firstName.length > 0 &&
      formData.lastName.length > 0 &&
      formData.role.length > 0 &&
      formData.enterpriseId.length > 0
    ) {
      handleSubmit(formData);
      onCloseModal();
    } else {
      console.log('error');
      console.log(formData);
    }
  };

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      enterpriseId: enterpriseData,
    }));
  }, [enterpriseData]);

  return (
    <ModalContainer open={true} onClose={() => null}>
      <FormControl
        className="modal-content"
        sx={{ width: 400, bgcolor: 'background.paper', p: 3 }}
      >
        <PopupHeader>
          <Heading>{`Add User`}</Heading>
          <IconButton className="close" onClick={onCloseModal}>
            <CloseIcon />
          </IconButton>
        </PopupHeader>
        <LightSubHeading mb={1} color="#000">
          {`Email`}
        </LightSubHeading>
        <InputField
          required
          variant={'input'}
          name={'email'}
          placeholder={`Enter email address`}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = event.target;
            onChange(name, value);
          }}
          sx={{ height: '40px', marginBottom: '2rem' }}
        />

        <LightSubHeading mb={1} color="#000">
          {`First Name`}
        </LightSubHeading>
        <InputField
          required
          variant={'input'}
          name={'firstName'}
          placeholder={`Enter first name`}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = event.target;
            onChange(name, value);
          }}
          sx={{ height: '40px', marginBottom: '2rem' }}
        />

        <LightSubHeading mb={1} color="#000">
          {`Last Name`}
        </LightSubHeading>
        <InputField
          required
          variant={'input'}
          name={'lastName'}
          placeholder={`Enter last name`}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = event.target;
            onChange(name, value);
          }}
          sx={{ height: '40px', marginBottom: '2rem' }}
        />

        <LightSubHeading mb={1} color="#000">
          Select Role
        </LightSubHeading>
        <Select
          displayEmpty
          name={'role'}
          disabled={loading}
          onFocus={() => onFocus('role')}
          onBlur={onBlur}
          onChange={(event: SelectChangeEvent) => {
            const { name, value } = event.target;
            onChange(name, value);
          }}
          inputProps={{
            'aria-label': 'Without label',
            placeholder: 'Select enterprise name',
          }}
          sx={{
            width: '100%',
            height: '40px',
            marginBottom: '2rem',
            background: '#FFFFFF',
            boxShadow: '1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset',
            '&:hover': {
              border: focusedInput === 'role' ? 'none' : '1px solid black',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: focusedInput === 'role' ? '2px solid black' : 'none',
            },
          }}
        >
          {roleList.map(k => (
            <MenuItem key={k} value={k}>
              {k}
            </MenuItem>
          ))}
        </Select>

        {data && data.length > 0 && (
          <>
            <LightSubHeading mb={1} color="#000">
              Select Enterprise
            </LightSubHeading>
            <Select
              displayEmpty
              name={'enterpriseId'}
              value={formData.enterpriseId}
              disabled={loading}
              onFocus={() => onFocus('enterprise')}
              onBlur={onBlur}
              onChange={(event: SelectChangeEvent) => {
                const { name, value } = event.target;
                onChange(name, value);
              }}
              inputProps={{
                'aria-label': 'Without label',
                placeholder: 'Select enterprise name',
              }}
              sx={{
                width: '100%',
                height: '40px',
                marginBottom: '2rem',
                background: '#FFFFFF',
                boxShadow: '1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset',
                '&:hover': {
                  border:
                    focusedInput === 'enterprise' ? 'none' : '1px solid black',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border:
                    focusedInput === 'enterprise' ? '2px solid black' : 'none',
                },
              }}
            >
              {data.map(k => (
                <MenuItem key={k.id} value={k.id}>
                  {k.name}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
        <ButtonContainer>
          <SecondaryButton width="100%" onClick={onCloseModal}>
            Cancel
          </SecondaryButton>

          <PrimaryButton
            startIcon={<CheckIcon />}
            onClick={onSubmit}
            width="100%"
          >
            Confirm
          </PrimaryButton>
        </ButtonContainer>
      </FormControl>
    </ModalContainer>
  );
};

export default AddUserModal;
