import { AuthUserType } from '../../features/Profile/types';
import { useDispatch } from 'react-redux';
import { actions } from '../../features/Profile/slice';
import { getUserInfo } from '@features/Profile/api';
import { actions as globalActions } from '@features/Global/slice';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccessToken, Tokens } from '@okta/okta-auth-js';
import { ICurrentUserData, useAppContext } from 'app/AppContext';
import { oktaAuth } from '@services/oktaAuth';

type Base = {
  onOktaSuccess: (tokens: Tokens) => void;
  onOktaError: (error: any) => void;
  logout: () => Promise<boolean>;
  is_loading_user_info: boolean;
};

export type ICurrentUser_LoggedIn = Base & {
  is_logged_in: true;
  advisor_id: string;
  data_provider_id: string | null;
  user_type: AuthUserType;
  backend_api_key: string;
  current_user_data: ICurrentUserData;
};

export type ICurrentUser_NotLoggedIn = Base & {
  is_logged_in: false;
  advisor_id: undefined;
  user_type: undefined;
  backend_api_key: null;
  current_user_data: null;
};

export type ICurrentUser = ICurrentUser_LoggedIn | ICurrentUser_NotLoggedIn;

let is_initial: boolean = true;

const useCurrentUser = (): ICurrentUser => {
  const {
    current_user_data,
    setCurrentUserData,
    loading_user_info,
    setLoadingUserInfo,
    okta_token: accessToken,
    setOktaToken: setAccessToken,
  } = useAppContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //  console.log('useCurrentUser', { is_initial, accessToken, current_user_data, loading_user_info });

  const loadUserInfo = async (oktaToken: AccessToken) => {
    //    console.log("Loading user info");
    try {
      const oktaTokenStr = oktaToken.accessToken;
      if (!oktaTokenStr) {
        throw new Error('-- Token is missing in payload --');
      }
      dispatch(actions.userInfoFetch());

      const response = await getUserInfo(oktaTokenStr);
      if (response) {
        const current_user_data: ICurrentUserData = {
          ...response,
          user_type: response.role as AuthUserType,
          data_provider_id: response.dataProviderId,
          enterprise_id: response.enterpriseId,
          backend_api_key: response.apiKey,
          advisor_id: response.advisorId,
          okta_uid: response.oktaUid,
          user_id: response.userId,
        };
        setCurrentUserData(current_user_data);

        dispatch(actions.userInfoSuccess());

        dispatch(
          globalActions.updateUserConfigSuccess({
            data: {
              tone: response?.tone || false,
              tAndC: response?.tAndC,
              feedback: response?.feedback || false,
              skipCache: response?.skipCache || false,
              crmIntegrations: response?.crmIntegrations || false,
              isProductTourViewed: response?.isProductTourViewed || false,
              custodialIntegration: response?.custodialIntegration || false,
            },
            loading: false,
          }),
        );

        if (response?.tAndC) {
          localStorage.setItem('terms-and-conditions', 'accepted');
        }
        return {
          current_user_info: response,
        };
      } else {
        throw response;
      }
    } catch (error: any) {
      console.warn('Error loading user info', error);
      setCurrentUserData(null);
      dispatch(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage: error?.message,
        }),
      );
      dispatch(actions.userInfoSuccess());
    }
  };

  const logout = async () => {
    try {
      dispatch(actions.logoutOktaUserFetch());
      if (!(await oktaAuth.signOut())) {
        return false;
      }
      dispatch(actions.logoutOktaUserSuccess());
    } catch (error: any) {
      console.error(error);
      dispatch(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage: error?.message,
        }),
      );
      dispatch(actions.logoutOktaUserSuccess());
    } finally {
      // NOTE: Lets remove this LocalStorage item dependencies from code!
      localStorage.clear();
      sessionStorage.clear();
    }
    return true;
  };

  const onOktaSuccess = async (tokens: Tokens) => {
    console.log('onOktaSuccess', tokens);
    setLoadingUserInfo(true);
    is_initial = false;

    const { accessToken } = tokens;
    if (!accessToken) {
      console.warn('No access token received from Okta');
      return;
    }

    // Deprecated
    localStorage.setItem(
      'x-tifin-ai-token',
      `Bearer ${accessToken.accessToken}`,
    );
    setAccessToken(accessToken);

    oktaAuth.handleLoginRedirect(tokens);
    const reply = await loadUserInfo(accessToken);

    redirect_by_user_type(reply?.current_user_info.role as AuthUserType);

    setLoadingUserInfo(false);
  };

  const redirect_by_user_type = (user_type: AuthUserType | undefined) => {
    if (user_type === 'SUPER_ADMIN') {
      navigate('/manage-users');
    } else {
      if (!user_type) {
        navigate('/welcome');
      } else {
        navigate('/');
      }
    }
  };

  const onOktaError = (error: any) => {
    console.log('onOktaError', error);
    setAccessToken(null);
  };

  /**
   * Make sure we recover userInfo when we reload the page
   */
  useEffect(() => {
    if (is_initial && accessToken && !current_user_data) {
      is_initial = false;
      loadUserInfo(accessToken)
        .then(data => {
          //        console.log("useCurrentUser initial call done", data);
          redirect_by_user_type(data?.current_user_info.role as AuthUserType);
        })
        .catch(error => {
          console.error('useCurrentUser initial call error', error);
        });
    }
  }, [accessToken, current_user_data]);

  const is_loading_user_info: boolean =
    loading_user_info ||
    (is_initial && accessToken !== null && current_user_data === null);

  if (accessToken && current_user_data) {
    return {
      onOktaSuccess,
      onOktaError,
      logout,
      is_loading_user_info,
      is_logged_in: true,
      data_provider_id: current_user_data.data_provider_id,
      user_type: current_user_data.user_type,
      advisor_id: current_user_data.advisor_id,
      backend_api_key: current_user_data.backend_api_key,
      current_user_data,
    };
  } else {
    return {
      onOktaSuccess,
      onOktaError,
      logout,
      is_loading_user_info,
      is_logged_in: false,
      user_type: undefined,
      advisor_id: undefined,
      backend_api_key: null,
      current_user_data: null,
    };
  }
};

export default useCurrentUser;
