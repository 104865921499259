export const COLUMNS_INITIAL = [
  {
    label: 'Query',
    value: 'query',
    baseLineColumn: false,
    baseLineActive: false,
  },
  {
    label: 'Type',
    value: 'classification',
    baseLineColumn: false,
    baseLineActive: false,
  },
];

export const COLUMNS_LOADING = [];
