import { Grid } from '@mui/material';
import styled, { css, keyframes } from 'styled-components';

const AnimationName = 'ease-in';
const AnimationTime = '0.5s';

export const animateWithKeyFrame = keyframes`
  0%,
  100% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  34% {
    border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
  }
  50% {
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  67% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
  }
`;

export const OverlayContainer = styled(Grid)`
  width: 100%;
  height: 100%;
`;

export const OverlayWrapper = styled(Grid)<{ visibleLogo: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
  opacity: 1;
  transition: opacity ${AnimationName} ${AnimationTime};

  ${props =>
    !props.visibleLogo &&
    css`
      opacity: 0;
    `}
`;

export const OverlapBackground = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-position: 50% 50%;
  background-size: cover;
`;

export const AnimatedBackDrop = styled('div')<{ animate?: boolean }>`
  width: 300px;
  height: 120px;
  opacity: 0;
  filter: blur(20px);
  mix-blend-mode: exclusion;
  background-blend-mode: soft-light, normal;
  border-radius: 574.727px;
  background: linear-gradient(343deg, #1a2b5f 16.93%, #475480 67.06%);
  transition:
    left ${AnimationName} ${AnimationTime},
    bottom ${AnimationName} ${AnimationTime},
    width ${AnimationName} ${AnimationTime},
    height ${AnimationName} ${AnimationTime},
    opacity ${AnimationName} ${AnimationTime};

  animation: ${animateWithKeyFrame} 8s infinite linear;

  ${props =>
    props.animate &&
    css`
      opacity: 0.5;
    `}
`;

export const AnimatedLogo = styled('div')<{
  animate?: boolean;
  visibleLogo?: boolean;
}>`
  position: absolute;
  margin-right: -20px;
  opacity: 0;
  transition: opacity ${AnimationName} ${AnimationTime};

  ${props =>
    props.animate &&
    css`
      opacity: 1;
    `}
`;
