/* eslint-disable react/prop-types */

import React from 'react';

function Clients(): React.JSX.Element {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="4" fill="#4ACCAA" />
      <path
        d="M24 28.875C26.0711 28.875 27.75 27.1961 27.75 25.125C27.75 23.0539 26.0711 21.375 24 21.375C21.9289 21.375 20.25 23.0539 20.25 25.125C20.25 27.1961 21.9289 28.875 24 28.875Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.375 22.875C31.2485 22.8735 32.1103 23.0762 32.8916 23.4669C33.6729 23.8575 34.3521 24.4253 34.875 25.125"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.125 25.125C13.6479 24.4253 14.3271 23.8575 15.1084 23.4669C15.8897 23.0762 16.7515 22.8735 17.625 22.875"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5999 32.25C19.0938 31.2385 19.8618 30.3861 20.8165 29.7898C21.7712 29.1935 22.8742 28.8774 23.9999 28.8774C25.1255 28.8774 26.2285 29.1935 27.1832 29.7898C28.1379 30.3861 28.906 31.2385 29.3999 32.25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6251 22.875C17.0557 22.8756 16.4979 22.7141 16.0169 22.4094C15.5358 22.1048 15.1514 21.6695 14.9086 21.1545C14.6658 20.6395 14.5745 20.066 14.6455 19.501C14.7165 18.9361 14.9467 18.403 15.3094 17.964C15.6721 17.5251 16.1523 17.1985 16.6937 17.0223C17.2352 16.8461 17.8156 16.8276 18.3671 16.969C18.9187 17.1104 19.4186 17.4059 19.8085 17.8208C20.1984 18.2358 20.4621 18.7532 20.5689 19.3125"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.4314 19.3125C27.5382 18.7532 27.8019 18.2358 28.1918 17.8208C28.5816 17.4059 29.0816 17.1104 29.6331 16.969C30.1847 16.8276 30.7651 16.8461 31.3065 17.0223C31.848 17.1985 32.3281 17.5251 32.6908 17.964C33.0535 18.403 33.2838 18.9361 33.3548 19.501C33.4258 20.066 33.3345 20.6395 33.0917 21.1545C32.8488 21.6695 32.4644 22.1048 31.9834 22.4094C31.5023 22.7141 30.9445 22.8756 30.3751 22.875"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoClientsIcon = React.memo(Clients);
export default MemoClientsIcon;
