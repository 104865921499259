/* eslint-disable react/prop-types */
import * as React from 'react';

function MicIcon(): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <rect
        width="26"
        height="26"
        rx="6"
        fill="url(#paint0_linear_329_940)"
        fillOpacity="0.1"
      />
      <path
        d="M13.2325 16.172C14.2172 16.1709 15.1614 15.7793 15.8577 15.0829C16.5541 14.3866 16.9457 13.4425 16.9468 12.4577V7.5053C16.9468 6.52021 16.5554 5.57547 15.8589 4.8789C15.1623 4.18234 14.2176 3.79102 13.2325 3.79102C12.2474 3.79102 11.3026 4.18234 10.6061 4.8789C9.90951 5.57547 9.51818 6.52021 9.51818 7.5053V12.4577C9.51921 13.4425 9.91086 14.3866 10.6072 15.0829C11.3035 15.7793 12.2477 16.1709 13.2325 16.172ZM10.7563 7.5053C10.7563 6.84857 11.0172 6.21875 11.4815 5.75437C11.9459 5.28999 12.5757 5.02911 13.2325 5.02911C13.8892 5.02911 14.519 5.28999 14.9834 5.75437C15.4478 6.21875 15.7087 6.84857 15.7087 7.5053V12.4577C15.7087 13.1144 15.4478 13.7442 14.9834 14.2086C14.519 14.673 13.8892 14.9339 13.2325 14.9339C12.5757 14.9339 11.9459 14.673 11.4815 14.2086C11.0172 13.7442 10.7563 13.1144 10.7563 12.4577V7.5053ZM13.8515 18.6172V20.5053C13.8515 20.6695 13.7863 20.8269 13.6702 20.943C13.5541 21.0591 13.3966 21.1243 13.2325 21.1243C13.0683 21.1243 12.9108 21.0591 12.7947 20.943C12.6786 20.8269 12.6134 20.6695 12.6134 20.5053V18.6172C11.0872 18.4619 9.67276 17.7462 8.64366 16.6084C7.61455 15.4707 7.04388 13.9918 7.04199 12.4577C7.04199 12.2935 7.10721 12.136 7.22331 12.0199C7.3394 11.9039 7.49686 11.8386 7.66104 11.8386C7.82522 11.8386 7.98268 11.9039 8.09877 12.0199C8.21487 12.136 8.28009 12.2935 8.28009 12.4577C8.28009 13.7711 8.80185 15.0308 9.73061 15.9595C10.6594 16.8883 11.919 17.4101 13.2325 17.4101C14.5459 17.4101 15.8056 16.8883 16.7343 15.9595C17.6631 15.0308 18.1848 13.7711 18.1848 12.4577C18.1848 12.2935 18.2501 12.136 18.3662 12.0199C18.4823 11.9039 18.6397 11.8386 18.8039 11.8386C18.9681 11.8386 19.1255 11.9039 19.2416 12.0199C19.3577 12.136 19.4229 12.2935 19.4229 12.4577C19.4211 13.9918 18.8504 15.4707 17.8213 16.6084C16.7922 17.7462 15.3777 18.4619 13.8515 18.6172Z"
        fill="url(#paint1_linear_329_940)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_329_940"
          x1="0"
          y1="0"
          x2="26.5042"
          y2="0.524574"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40CFFA" />
          <stop offset="1" stopColor="#97C1FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_329_940"
          x1="7.04199"
          y1="3.79102"
          x2="19.6655"
          y2="3.96948"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40CFFA" />
          <stop offset="1" stopColor="#97C1FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoNoData = React.memo(MicIcon);
export default MemoNoData;
