import React from 'react';
import { Grid } from '@mui/material';
import { SxProps, styled } from '@mui/material/styles';
import { useTheme } from 'styled-components';

export type ChipTypes =
  | 'success'
  | 'error'
  | 'info'
  | 'warning'
  | 'neutral'
  | 'simple'
  | undefined;

type CustomChipProps = Omit<
  React.HTMLProps<HTMLDivElement>,
  'wrap' | 'ref' | 'as'
> & {
  sx?: SxProps;
  type: ChipTypes;
  text?: string | null;
  borderRadius?: string;
  minWidth?: string;
  padding?: string;
  children?: JSX.Element | JSX.Element[] | string;
};

const StyledChip = styled(Grid)<{
  color: string;
  borderColor: string;
  backgroundColor: string;
  borderRadius?: string;
  minWidth?: string;
  padding?: string;
}>`
  width: max-content;
  min-width: ${props => props.minWidth || '70px'};
  min-height: 20px;
  height: auto;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: ${props => props.padding ?? '0px'};
  color: ${props => props.color};
  border-radius: ${props => props.borderRadius ?? '12px'};
  border: 1px solid ${props => props.borderColor};
  background-color: ${props => props.backgroundColor};
`;

function CustomChip({
  sx,
  type,
  text = '',
  borderRadius,
  minWidth,
  padding,
  children,
  ...restProps
}: CustomChipProps): React.JSX.Element {
  const theme = useTheme();

  if (text === null) {
    return <></>;
  }

  const getBackgroundColor = (type: ChipTypes) => {
    switch (type) {
      case 'simple':
        return '#F9FAFB';
      case 'success':
        return theme.successSurface;
      case 'info':
        return '#ECF2FC';
      case 'warning':
        return theme.warningLight;
      case 'error':
        return theme.dangerLight;
      case 'neutral':
        return '#FCF4DB';

      default:
        return 'rgb(255 191 155 / 50%)';
    }
  };

  const getColor = (type: ChipTypes) => {
    switch (type) {
      case 'simple':
        return '#101828';
      case 'success':
        return theme.successMain;
      case 'info':
        return ' rgba(11, 92, 215, 1)';
      case 'warning':
        return theme.warningMain;
      case 'error':
        return theme.dangerMain;
      case 'neutral':
        return '#F4D269';
      default:
        return 'rgba(97, 255, 207, 1)';
    }
  };
  const getBorder = (type: ChipTypes) => {
    switch (type) {
      case 'simple':
        return '#E4E7EC';
      case 'success':
        return theme.successLight;
      case 'info':
        return 'rgba(182, 207, 243, 1)';
      case 'warning':
        return '#FFCA99';
      case 'error':
        return '#FFD7D7';
      case 'neutral':
        return '#F4D269';
      default:
        return 'rgba(97, 255, 207, 1)';
    }
  };

  return (
    <StyledChip
      container
      sx={sx}
      alignItems={'center'}
      justifyContent={'center'}
      color={getColor(type)}
      borderColor={getBorder(type)}
      backgroundColor={getBackgroundColor(type)}
      borderRadius={borderRadius}
      minWidth={minWidth}
      padding={padding}
      {...restProps}
    >
      {text}
      {children}
    </StyledChip>
  );
}

export default CustomChip;
