import React from 'react';
import { DropzoneArea } from 'react-mui-dropzone';
import './dragger.css';
import { SmallText } from '@components/Typography';
import { ReactComponent as UploadIcon } from '../../../../assets/images/Documents/uploadIcon.svg';
import { UploadBodyContainer } from '@components/FileDragger/styles';
import { DraggerWrapper } from './style';

export type IFileDraggerFile = {
  id: string;
  name: string;
  size: number;
  type: string;
  inner_file: File;
};

interface FileDraggerProps {
  onNewFile: (file: IFileDraggerFile) => void;
  show_dropzone: boolean;
}

const FileDragger: React.FC<FileDraggerProps> = ({
  onNewFile,
  show_dropzone,
}) => {
  const onNewFiles = (new_files: File[]) => {
    for (const file of new_files) {
      const random_uuid = Math.random().toString(36).substring(0, 12);
      console.log('New file', file, random_uuid);
      const new_dragger_file: IFileDraggerFile = {
        id: random_uuid,
        inner_file: file,
        name: file.name,
        size: file.size,
        type: file.type,
      };
      onNewFile(new_dragger_file);
    }
  };

  const CustomUploadIcon = () => {
    return (
      <UploadBodyContainer>
        <UploadIcon />
        <SmallText fontSize="16px" marginTop="10px">
          Drag and drop or choose file to upload
        </SmallText>
        <SmallText color="#475467" marginTop="8px">
          Limit 1 file
        </SmallText>
      </UploadBodyContainer>
    );
  };

  return (
    <DraggerWrapper>
      {show_dropzone && (
        <DropzoneArea
          acceptedFiles={['application/pdf']}
          classes={{ root: 'custom-dropzone-area' }}
          showPreviewsInDropzone={false}
          useChipsForPreview
          showFileNames={true}
          filesLimit={1}
          maxFileSize={104857600} // 100MB
          onChange={onNewFiles}
          Icon={() => <CustomUploadIcon />}
          dropzoneText=""
          showAlerts={false}
          showPreviews={false}
          clearOnUnmount
        />
      )}
    </DraggerWrapper>
  );
};

export default React.memo(FileDragger);
