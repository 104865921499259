import React from 'react';
import { OutlineButton } from '@components/Button';
import NotFound from '@images/ErrorScreens/NotFound';
import DataNotAvailable from '@images/ErrorScreens/DataNotAvailable';
import ServerError from '@images/ErrorScreens/ServerError';
import { DEFAULT_MESSAGE, DEFAULT_TITLE } from './constants';
import { ErrorScreenProps } from './types';
import { Wrapper } from './styles';
import { Typography } from '@mui/material';

function ErrorScreen({
  message = DEFAULT_MESSAGE,
  title = DEFAULT_TITLE,
  variant = 'error',
  ctaContext = 'Try Again',
  tryAgain,
  width = '100%',
  height = 100,
  icon,
}: ErrorScreenProps): React.JSX.Element {
  return (
    <Wrapper width={width} height={height}>
      {icon
        ? icon
        : (() => {
            switch (variant) {
              case 'not-found':
                return <NotFound />;
              case 'empty-list':
                return <DataNotAvailable />;
              default:
                return <ServerError />;
            }
          })()}
      <Typography variant={'h3'} sx={{ margin: '20px 0' }}>
        {title}
      </Typography>
      <Typography sx={{ margin: '20px 0' }}>{message}</Typography>
      {ctaContext && (
        <OutlineButton onClick={tryAgain}>{ctaContext}</OutlineButton>
      )}
    </Wrapper>
  );
}

export default ErrorScreen;
