/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ApiInstance from 'utils/instances';
import { ChatFeedbackResponse, ChatInsightResponseInterface } from './types';

export const getInsights = async (): Promise<ChatInsightResponseInterface> => {
  return await ApiInstance.get(`/chat/insights`).then(res => res.data);
};

export const submitChatFeedback = async (
  request: any,
): Promise<ChatFeedbackResponse> => {
  const { payload, headers } = request;
  return await ApiInstance.post(`/chat/feedback`, payload, { headers }).then(
    res => res.data,
  );
};
