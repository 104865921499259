import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { SubHeading, SmallText } from '@components/Typography';
import DataProviderCard from '@components/Card/DataProvider';
import { Edit } from '@mui/icons-material';
import UpdateModal from './UpdateModal';
export interface FundDataCardProps {
  data: any;
  dataProviderList?: any;
  type?: string;
  onClick: (data: any) => void;
}

const MODEL_TYPE = {
  ENTERPRICE: 'enterprise',
  DATAPROVIDER: 'dataProvider',
};

const DataCard: React.FC<FundDataCardProps> = ({
  data,
  onClick,
  dataProviderList,
  type,
}) => {
  const [updateModelOpen, setUpdateModelOpen] = useState<boolean>(false);
  const handleUpdateModelOpen = (): void => {
    setUpdateModelOpen(!updateModelOpen);
  };
  const { name, status } = data;

  return (
    <>
      <DataProviderCard
        onClick={() => onClick(data)}
        style={{ position: 'relative' }}
      >
        {MODEL_TYPE.ENTERPRICE === type ? (
          <IconButton
            style={{
              position: 'absolute',
              top: '8px',
              right: '8px',
            }}
            onClick={e => {
              e.stopPropagation();
              handleUpdateModelOpen();
            }}
          >
            <Edit fontSize="small" />
          </IconButton>
        ) : null}

        <Box
          sx={{
            width: '64px',
            height: '64px',
            backgroundColor: '#F2F4F7',
            borderRadius: '4px',
            marginBottom: '16px',
            cursor: 'pointer',
          }}
        ></Box>
        <SubHeading>{name}</SubHeading>
        <SmallText sx={{ marginBottom: '8px' }}>{status}</SmallText>
      </DataProviderCard>
      {updateModelOpen ? (
        <UpdateModal
          data={dataProviderList}
          onClose={handleUpdateModelOpen}
          enterpriseData={data}
        />
      ) : null}
    </>
  );
};

export default DataCard;
