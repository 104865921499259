/**
 * This implements a common extended table, with rows, columns, etc.
 * It has its own types and props. It uses MUIDataTable. Columns and rows definitions
 * follow MUIDataTable's.
 */

import React from 'react';
import { TableHeadProps, TableRowProps } from '@mui/material';
import { ExtTableRow } from './types';
import './style.css';
import {
  //  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  MRT_TableContainer,
  //  MRT_Row
} from 'material-react-table';
import { isEmpty } from '@common/string';
import { ExtTableBasicProps } from './ExtTableBasic';

type MRT_ExtTableColumn = MRT_ColumnDef<any>;

export default function ExtTableBasic_MRT({
  title,
  columns,
  rows,
  cellRenderer,
  show_column_headers = true,
  show_select_column = false,
  isRowSelected = () => false,
  setRowSelected = () => {},
  onRowReordered = () => {},
  //  getRowOrder = () => { return undefined; },
  allow_dragging = false,
}: ExtTableBasicProps): JSX.Element {
  let final_columns = columns;
  if (show_select_column) {
    final_columns = [
      {
        key: '_checkbox',
        label: '',
        type: 'custom',
        width: 15,
        cellRenderer: (row: ExtTableRow) => {
          return (
            <>
              <div className="styled-checkbox">
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={isRowSelected(row)}
                  onChange={e => setRowSelected(row, e.target.checked)}
                />
              </div>
            </>
          );
        },
      },
      ...final_columns,
    ];
  }

  const mrt_columns = final_columns
    .filter(column => column.key)
    .map(column => {
      return {
        accessorKey: column.key.startsWith('_') ? undefined : column.key,
        id: column.key.startsWith('_') ? column.key : undefined,
        header: column.label || column.key,
        size: column.width || 140,
        minSize:
          column.minWidth || column.key === '_view_details'
            ? 140
            : column.key === 'Vintage Year'
              ? 50
              : 90,
        maxSize: column.maxWidth || undefined,
        color: column.color,
        fontSize: column.fontSize,
        title: title,
        Header: () => {
          return <div>{column.label}</div>;
        },
        Cell: props => {
          // eslint-disable-next-line react/prop-types
          const mrtRow = props.row;
          // eslint-disable-next-line react/prop-types
          const original = mrtRow.original;
          if (column.cellRenderer) {
            return column.cellRenderer(original, column);
          }
          if (cellRenderer) {
            return cellRenderer(original, column);
          }
          return (
            <div
              style={{
                color: column.color,
                fontSize: column.fontSize,
                fontWeight: 'normal',
                overflowWrap: 'break-word',
                overflow: 'auto',
              }}
            >
              {isEmpty(original[column.key]) ? '-' : original[column.key]}
            </div>
          );
        },
        muiTableHeadCellProps:
          column.key === '_view_details'
            ? {
                sx: {
                  position: 'sticky',
                  right: 0,
                  zIndex: 2,
                },
              }
            : column.key === 'compare'
              ? {
                  sx: {
                    position: 'sticky',
                    left: 0,
                    zIndex: 2,
                  },
                }
              : {},
        muiTableBodyCellProps:
          column.key === '_view_details'
            ? {
                sx: {
                  position: 'sticky',
                  right: 0,
                  zIndex: 1,
                },
              }
            : column.key === 'compare'
              ? {
                  sx: {
                    position: 'sticky',
                    left: 0,
                    zIndex: 1,
                  },
                }
              : {},
      } as MRT_ExtTableColumn;
    });

  const table = useMaterialReactTable({
    columns: mrt_columns,
    data: rows,
    enableSorting: false,
    enableColumnOrdering: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    enableColumnPinning: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableRowActions: false,
    enableRowPinning: false,
    enableRowVirtualization: true,
    enableRowOrdering: allow_dragging,
    enableRowDragging: allow_dragging,
    enableTopToolbar: false,
    enablePagination: false,
    layoutMode: 'semantic',
    muiTableHeadProps: () => {
      const props: TableHeadProps =
        show_column_headers === false
          ? {
              style: {
                display: 'none',
              },
            }
          : {};
      return props;
    },
    muiTableHeadCellProps: ({ column }) => {
      const ourColumn = final_columns.find(
        c => c.key === column.columnDef.accessorKey,
      );
      if (ourColumn) {
        return {
          style: {
            color: ourColumn.color,
            fontSize: ourColumn.fontSize,
            fontWeight: 'normal',
            padding: '12px 8px 12px 16px',
          },
        };
      }
      return {
        style: {
          padding: '12px 8px 12px 16px',
        },
      };
    },
    muiTableBodyCellProps: ({ column }) => {
      const ourColumn = final_columns.find(
        c => c.key === column.columnDef.accessorKey,
      );
      return {
        style: {
          color: ourColumn?.color,
          padding: '4px 8px 4px 16px',
          minHeight: '40px',
        },
      };
    },
    muiTableBodyRowProps: ({ row }) => {
      if (!isRowSelected(row.original)) {
        return {};
      }
      const t: TableRowProps = {
        className: 'selected-list-row',
      };

      return t;
    },
    muiRowDragHandleProps: () => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (
          draggingRow &&
          hoveredRow &&
          hoveredRow.index !== undefined &&
          hoveredRow.index !== draggingRow.index
        ) {
          onRowReordered(draggingRow.index, hoveredRow.index);
        }
      },
    }),
  });

  return (
    <div style={{ position: 'relative' }}>
      <MRT_TableContainer table={table} />
    </div>
  );
}
