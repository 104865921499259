import moment from 'moment';

const FORMAT_DATE = 'MM-DD-YYYY';
const FORMAT_TIME = 'HH:mm';
const FORMAT_DATE_TIME = `${FORMAT_DATE} ${FORMAT_TIME}`;

export const getDate = (dateTime = ''): string => {
  if (dateTime) {
    return moment(dateTime).format(FORMAT_DATE);
  } else {
    return moment().format(FORMAT_DATE);
  }
};

export const getTime = (dateTime = ''): string => {
  if (dateTime) {
    return moment(dateTime).format('HH:mm');
  } else {
    return moment().format('HH:mm');
  }
};

export const getDateTime = (dateTime = ''): string => {
  if (dateTime) {
    return moment(dateTime).format(FORMAT_DATE_TIME);
  } else {
    return moment().format(FORMAT_DATE_TIME);
  }
};

export const getDateTimeByDays = (date: string = ''): string => {
  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'days').startOf('day');
  const targetDate = moment(date);

  if (!targetDate.isValid()) {
    // Handle invalid date strings or empty dates
    return `Today ${moment().format('h:mmA')}`;
  }

  if (targetDate.isSame(today, 'd')) {
    return `Today ${targetDate.format('h:mmA')}`;
  } else if (targetDate.isSame(yesterday, 'd')) {
    return `Yesterday ${targetDate.format('h:mmA')}`;
  } else if (today.diff(targetDate, 'days') < 7) {
    return `${today.diff(targetDate, 'days')} days ago ${targetDate.format(
      'h:mmA',
    )}`;
  } else {
    return targetDate.format('Do MMM h:mmA');
  }
};

export const getChatDateFormat = (inputDate: string): string => {
  const now = moment();
  const date = moment(inputDate);
  if (!date.isValid()) {
    // Handle invalid date input
    return inputDate;
  }
  if (now.diff(date, 'minutes') < 1) {
    return 'now';
  } else if (now.diff(date, 'minutes') < 60) {
    if (now.diff(date, 'minutes') > 1)
      return now.diff(date, 'minutes') + ' minutes ago';
    else return now.diff(date, 'minutes') + ' minute ago';
  } else if (now.diff(date, 'hours') < 24) {
    if (now.diff(date, 'hours') > 1)
      return now.diff(date, 'hours') + ' hours ago';
    else return now.diff(date, 'hours') + ' hour ago';
  } else if (now.diff(date, 'days') < 2) {
    return 'yesterday';
  } else if (now.year() === date.year()) {
    return date.format('D MMM YYYY h:mmA');
  } else {
    return date.format('D MMM YYYY h:mmA');
  }
};
