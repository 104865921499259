import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProfileState } from './types';

export const initialState: ProfileState = {
  loading: false,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    userInfoFetch(state) {
      state.loading = true;
    },
    userInfoSuccess(state) {
      state.loading = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logoutOktaUserFetch(state) {
      state.loading = true;
    },
    logoutOktaUserSuccess(state) {
      state.loading = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    acceptedTermsAndConditionsRequest(state, action: PayloadAction<any>) {},
    acceptedTermsAndConditionsFetch(state, action: PayloadAction<any>) {
      state.loading = action.payload;
    },
    acceptedTermsAndConditionsSuccess(state, action: PayloadAction<any>) {
      state.loading = action.payload;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    accountActivationRequest(state, action: PayloadAction<any>) {},
    accountActivationFetch(state, action: PayloadAction<any>) {
      state.loading = action.payload;
    },
    accountActivationSuccess(state, action: PayloadAction<any>) {
      state.loading = action.payload;
    },

    validateHashKeyFetch(state, action: PayloadAction<any>) {
      state.loading = action.payload;
    },
    validateHashKeySuccess(state, action: PayloadAction<any>) {
      state.loading = action.payload;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetPasswordRequest(state, action: PayloadAction<any>) {},
    resetPasswordFetch(state, action: PayloadAction<any>) {
      state.loading = action.payload;
    },
    resetPasswordSuccess(state, action: PayloadAction<any>) {
      state.loading = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = profileSlice;
