import * as React from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonContainer } from '../styles';
import { PrimaryButton, SecondaryButton } from '@components/Button';
import { useDispatch } from 'react-redux';
import { actions as globalActions } from 'app/features/Global/slice';

import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from '@mui/material';
import AddFundDocumentForm, {
  IAddFundDocumentMode,
} from './AddFundDocumentForm';
import { BootstrapDialog } from './style';

import {
  bulkUpload,
  checkFundName,
  createNewCustomFund,
  getPresignedUrlsForFiles,
  IFundDocumentData,
  IPresignedUrlFile,
  uploadFundDocument,
} from '@features/Funds/api';
import {
  FundDocumentTypeLabel,
  IFundDocumentType,
} from '@features/Funds/types';
import { IFileDraggerFile } from './FileDragger';
import CustomPreview from './CustomPreview';

interface AddFundDocumentModalProps {
  onHide: () => void;
  internal_fund_id: string | null;
  external_fund_id: string | null;
  fund_name: string | null; // Sometimes we want the fund to be created at the same time as the document
  mode: IAddFundDocumentMode;
  document_type: IFundDocumentType | null;
}

export type IUploadedFile = IFileDraggerFile & {
  upload_status: IUploadStatus;
  presignedUrl?: string;
  fundId?: string;
  fileName?: string;
  fileType?: string;
};

/**
 * CONFIRMING_DISCLOSURE is a state for creating a Fund, not a Fund Document.
 * If we want to be able to create funds and documents together, we need to refactor this.
 */
type IAddFundDocumentStatus =
  | 'RECEIVING_FILES'
  | 'CONFIRMING_DISCLOSURE'
  | 'FINISHED';

type IUploadStatus =
  | 'PENDING'
  | 'RECEIVED'
  | 'PRESIGNING'
  | 'UPLOADING_S3'
  | 'IN_S3'
  | 'SAVING'
  | 'FINISHED'
  | 'ERROR';

const AddFundDocumentModal: React.FC<AddFundDocumentModalProps> = ({
  onHide,
  mode,
  internal_fund_id,
  external_fund_id,
  fund_name: defaultFundName,
  document_type,
}) => {
  const [status, setStatus] =
    React.useState<IAddFundDocumentStatus>('RECEIVING_FILES');
  const [error, setError] = React.useState<string | null>(null);
  const [tried_files, setTriedFiles] = React.useState<IUploadedFile[]>([]);
  const [fund_name, setFundName] = React.useState<string>(
    defaultFundName || '',
  );
  const [dataSegregated, setDataSegregated] = React.useState(false);
  const [current_document_type, setCurrentDocumentType] =
    React.useState<IFundDocumentType>(document_type || 'PPM');
  const [loader, setLoader] = React.useState(false);
  const [force_refresh, setForceRefresh] = React.useState(0);
  const dispatch = useDispatch();

  const isFormDataComplete = () => {
    return fund_name !== '' && current_document_type !== null;
  };

  const onNewFile = async (fdfile: IFileDraggerFile) => {
    console.log('onNewFile', fdfile);
    const enriched: IUploadedFile = {
      ...fdfile,
      upload_status: 'RECEIVED',
    };
    const jsonData: IPresignedUrlFile = {
      internal_fund_id: internal_fund_id || undefined,
      external_fund_id: external_fund_id || undefined,
      fileName: fdfile.name,
      fileType: fdfile.type,
      documentType: current_document_type,
    };

    const new_group = [...tried_files, enriched];
    setTriedFiles(new_group);
    setError(null);

    try {
      console.log('Presigning', enriched);
      enriched.upload_status = 'PRESIGNING';
      updateFileInGroup(new_group, enriched);
      const res = await getPresignedUrlsForFiles([jsonData]);
      console.log('Uploading to S3', enriched);
      enriched.upload_status = 'UPLOADING_S3';
      updateFileInGroup(new_group, enriched);
      await uploadFileToS3(
        enriched,
        res[0].preSignedUrl,
        current_document_type,
      );
      enriched.presignedUrl = res[0].preSignedUrl;
      enriched.upload_status = 'IN_S3';
      updateFileInGroup(new_group, enriched);
      console.log(
        `Upload to S3 successful for ${enriched.id} - ${enriched.name}`,
      );
    } catch (error: any) {
      console.error(`Upload failed for ${enriched.name}: ${error}`);
      setLoader(false);
      setError('Upload failed');
      enriched.upload_status = 'ERROR';
      updateFileInGroup(new_group, enriched);
    }
    console.log('Updating tried files', new_group);
    setTriedFiles(new_group);
    setForceRefresh(Math.random());
  };

  const updateFileStatus = (file: IFileDraggerFile, status: IUploadStatus) => {
    updateFile({
      id: file.id,
      upload_status: status,
    });
  };

  const updateFileInGroup = (
    files: IUploadedFile[],
    file: Partial<IUploadedFile>,
  ) => {
    const ret = files.map(f => {
      if (f.id === file.id) {
        return {
          ...f,
          ...file,
        } as IUploadedFile;
      }
      return f;
    });
    return ret;
  };

  const updateFile = (file: Partial<IUploadedFile>) => {
    const ret = tried_files.map(f => {
      if (f.id === file.id) {
        return {
          ...f,
          ...file,
        } as IUploadedFile;
      }
      return f;
    });
    if (tried_files.length === 0) {
      console.error('No file found', file, tried_files);
    }
    setTriedFiles(ret);
  };

  const onFileCanceled = (file: IFileDraggerFile) => {
    setTriedFiles(() => {
      console.log('File canceled', file);
      return tried_files.filter(f => f.id !== file.id);
    });
  };

  const onPrimaryButtonClick = async () => {
    if (status === 'RECEIVING_FILES') {
      await submitAccumulatedFiles();
    } else if (status === 'CONFIRMING_DISCLOSURE') {
      handleFinalConfirm();
    } else if (status === 'FINISHED') {
      handleClose();
    }
  };

  const submittable = tried_files.filter(tf => tf.upload_status === 'IN_S3');
  const confirmable = tried_files.filter(tf => tf.upload_status === 'FINISHED');

  const submitAccumulatedFiles = async () => {
    setError(null);
    if (!submittable) {
      setError('Please upload a file');
      return;
    }
    if (!submittable.find(tf => tf.presignedUrl)) {
      setError('File not uploaded');
      return;
    }
    if (!fund_name) {
      setError('Please enter a fund name');
      return;
    }
    // If we don't know the fund id, we need to call checkFundName to check that the fund does not already exist

    if (!internal_fund_id && !external_fund_id) {
      setLoader(true);
      const result = await checkFundName({ name: fund_name });
      if (result.error) {
        setError(result.error);
        setLoader(false);
        return;
      }
      // We need to create the fund then, to be able to upload the document
      const createFundResult = await createNewCustomFund({ name: fund_name });
      if (createFundResult.error !== null) {
        setError(createFundResult.error);
        setLoader(false);
        return;
      }
      internal_fund_id = createFundResult.data.id;
      external_fund_id = createFundResult.data.externalFundId || null;
      setLoader(false);
    }

    if (!internal_fund_id && !external_fund_id) {
      console.warn('Internal fund id not set', {
        internal_fund_id,
        external_fund_id,
      });
      setError('Internal fund id not set');
      return;
    }

    console.log('Submitting files', submittable);
    setError(null);
    try {
      for (const file_to_upload of submittable) {
        try {
          if (
            !(await saveUploadedFileInBackend(
              file_to_upload,
              file_to_upload.presignedUrl as string,
              internal_fund_id,
              external_fund_id,
            ))
          ) {
            continue;
          }
          updateFileStatus(file_to_upload, 'FINISHED');
          if (internal_fund_id || external_fund_id) {
            console.log('Fund should exist');
            setStatus('FINISHED');
          } else {
            // Check that the fund DOES NOT exist
          }
        } catch (error) {
          updateFileStatus(file_to_upload, 'ERROR');
          throw error;
        }
      }
    } catch (error) {
      setLoader(false);
      setError('Some error occurred');
      console.error(error);
    }
  };

  const handleFinalConfirm = () => {
    const dataToSubmit = [
      {
        name: fund_name,
        fund_id: external_fund_id,
        owner: dataSegregated ? 'ENTERPRISE' : 'GLOBAL',
        created_at: new Date(),
        updated_at: new Date(),
      },
    ];

    setLoader(true);
    bulkUpload(dataToSubmit)
      .then(res => {
        if (
          res?.created?.list &&
          res.created.list.length > 0 &&
          confirmable.length > 0
        ) {
          updateFile({
            id: confirmable[0].id,
            fundId: res.created.list[0].id,
            fileType: res.created.list[0].type,
          });
        }
      })
      .catch(error => {
        setLoader(false);
        if (error.response) {
          if (
            error.response.data &&
            error.response.data.message &&
            typeof error.response.data.message === 'string'
          ) {
            dispatch(
              globalActions.displayToast({
                duration: 3000,
                toastType: 'error',
                toastMessage: error.response.data.message || '',
              }),
            );
          }
        } else {
          console.warn('Unhandled error type', error);
          dispatch(
            globalActions.displayToast({
              duration: 3000,
              toastType: 'error',
              toastMessage: 'Error adding fund',
            }),
          );
        }
      });
  };

  const handleClose = () => {
    setLoader(false);
    setTriedFiles([]);
    setFundName('');
    onHide();
  };

  /**
   * After file has been presigned and uploaded to S3, we need to send the file path to the backend
   */
  const saveUploadedFileInBackend = async (
    file: IUploadedFile,
    filePath: string,
    param_internal_fund_id: string | null,
    param_external_fund_id: string | null,
  ): Promise<boolean> => {
    if (!param_internal_fund_id && !param_external_fund_id) {
      setError('Either internal or external fund id must be set');
      return false;
    }
    updateFileStatus(file, 'SAVING');
    const jsonData: IFundDocumentData[] = [file]
      .map(file => {
        if (!file) {
          return null;
        }
        const regex = /\.com\/(.*?)\?\s*/;
        const match = file?.presignedUrl
          ? file?.presignedUrl?.match(regex)
          : filePath.match(regex);
        const path = match ? match[1].trim() : 'No match found';
        const decodedPath = decodeURIComponent(path);
        return {
          fileName: file.name,
          internal_fund_id: param_internal_fund_id,
          external_fund_id: param_external_fund_id,
          filePath: decodedPath,
          fileType: current_document_type,
        };
      })
      .filter(file => file !== null);

    try {
      console.log('uploadFundDocument started');
      const res = await uploadFundDocument(jsonData);
      console.log('uploadFundDocument', res);
      if (res[0]?.status === 201) {
        setLoader(false);

        updateFileStatus(file, 'FINISHED');
        dispatch(
          globalActions.displayToast({
            duration: 3000,
            toastType: 'success',
            toastMessage: 'Successfully uploaded',
          }),
        );
      }
      if (res[0]?.status === 409) {
        setLoader(false);
        updateFileStatus(file, 'ERROR');
        setError('Fund document already exists');
        return false;
      }
      if (res[0].status === 500) {
        setLoader(false);
        updateFileStatus(file, 'ERROR');
        setStatus('RECEIVING_FILES');
        setError('Some error occurred');
        return false;
      }
      setLoader(false);
      return true;
    } catch (error: any) {
      setLoader(false);
      if (
        error &&
        error.response &&
        error.response.data &&
        typeof error.response.data.message === 'string'
      ) {
        setError(error.response.data.message);
      } else {
        setError('Some error occurred');
      }
      return false;
    }
  };

  const uploadFileToS3 = async (
    file: IUploadedFile,
    url: string,
    document_type: IFundDocumentType,
  ): Promise<boolean> => {
    try {
      const token = localStorage.getItem('x-tifin-ai-token') || '';
      console.log('Uploading file to S3', file, url, document_type, file.size);
      const headers = new Headers({
        'Content-Type': file.type,
        'Document-Type': document_type, // TODO: remove
        'X-Tifin-Ai-Auth': token,
      });

      const response = await fetch(url, {
        method: 'PUT',
        headers: headers,
        body: file.inner_file,
      });

      if (!response.ok) {
        throw new Error(`Failed to upload file: ${file.name}`);
      }

      return response.ok;
    } catch (error) {
      setError(`Upload failed for ${file.name}: ${error}`);
      throw error;
    }
  };

  const is_some_file_uploading =
    tried_files.filter(
      tf => ['IN_S3', 'FINISHED', 'ERROR'].includes(tf.upload_status) === false,
    ).length > 0;

  const can_add_files = !is_some_file_uploading && !submittable.length;

  console.log('Can continue', {
    form_data: !isFormDataComplete(),
    submittable: !submittable.length,
    force_refresh,
    is_some_file_uploading,
    loader,
  });
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        {status === 'CONFIRMING_DISCLOSURE' ? (
          <DialogTitle id="customized-dialog-title">
            {'Data Use Disclosure'}
          </DialogTitle>
        ) : (
          <DialogTitle id="customized-dialog-title">
            {document_type === null
              ? `Add Document`
              : `Add ${FundDocumentTypeLabel[document_type]} Document`}
          </DialogTitle>
        )}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 10,
            top: 8,
            color: '#101828',
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          {error && <Alert color="error">{error}</Alert>}

          {status === 'CONFIRMING_DISCLOSURE' ? (
            <ConfirmingDisclosurePanel
              value={dataSegregated}
              onChange={setDataSegregated}
            />
          ) : (
            <>
              {status === 'FINISHED' ? (
                <Box>
                  <Typography sx={{ fontSize: '16px', mb: 2 }}>
                    We will notify you via email when your document is ready.
                  </Typography>
                </Box>
              ) : (
                <AddFundDocumentForm
                  mode={mode}
                  fund_name={fund_name}
                  setFundName={setFundName}
                  document_type={current_document_type}
                  setDocumentType={setCurrentDocumentType}
                  showDocumentTypeDropdown={document_type === null}
                  setLoader={setLoader}
                  onNewFile={onNewFile}
                  show_dropzone={can_add_files}
                />
              )}
              {tried_files.map((file, index) => {
                return (
                  <div key={index}>
                    <CustomPreview
                      file={file}
                      running={
                        ['IN_S3', 'ERROR'].includes(file.upload_status) ===
                        false
                      }
                      onCancel={() => {
                        onFileCanceled(file);
                      }}
                    />
                  </div>
                );
              })}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <ButtonContainer width={'100%'}>
            <SecondaryButton width={'50%'} onClick={handleClose}>
              Cancel
            </SecondaryButton>

            {status == 'CONFIRMING_DISCLOSURE' ? (
              <PrimaryButton
                width={'50%'}
                onClick={onPrimaryButtonClick}
                disabled={
                  !isFormDataComplete() ||
                  !submittable.length ||
                  is_some_file_uploading ||
                  loader
                }
              >
                {loader ? (
                  <CircularProgress sx={{ color: 'white' }} size={24} />
                ) : (
                  'Continue'
                )}
              </PrimaryButton>
            ) : (
              <PrimaryButton
                width={'50%'}
                disabled={
                  (!isFormDataComplete() ||
                    !submittable.length ||
                    is_some_file_uploading ||
                    loader) &&
                  status === 'RECEIVING_FILES'
                }
                onClick={onPrimaryButtonClick}
              >
                {loader ? (
                  <CircularProgress sx={{ color: 'white' }} size={24} />
                ) : status === 'FINISHED' ? (
                  'Finish'
                ) : (
                  'Continue'
                )}
              </PrimaryButton>
            )}
          </ButtonContainer>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

type ConfirmingDisclosurePanelProps = {
  value: boolean;
  onChange: (segregated: boolean) => void;
};

const ConfirmingDisclosurePanel = ({
  value,
  onChange,
}: ConfirmingDisclosurePanelProps) => {
  return (
    <Box>
      <Typography sx={{ fontSize: '16px', mb: 2 }}>
        Data from this PPM document will be added to our fund universe to
        support fund comparison and manager research.
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          sx={{
            '& .MuiFormControlLabel-label': {
              fontWeight: 500,
              fontSize: '16px',
            },
          }}
          control={
            <Checkbox
              name="dataSegregated"
              checked={value}
              onChange={e => onChange(e.target.checked)}
            />
          }
          label="Keep my data segregated"
        />
        <Typography sx={{ fontSize: '14px', mt: -1, ml: 4, color: '#86858B' }}>
          By choosing this, note that you will not be able to compare this fund
          with others in our universe.
        </Typography>
      </Box>
    </Box>
  );
};

export default AddFundDocumentModal;
