import { Avatar, Box, Grid, ListItemIcon } from '@mui/material';
import styled, { css } from 'styled-components';
import { LayoutGutter } from 'utils/styles/variables';

export const SideNavWrapper = styled.div<any>`
  padding: ${LayoutGutter.sidebar.y} ${LayoutGutter.sidebar.x};
  display: flex;
  flex-direction: row;
`;

export const NavMenuWrapper = styled(Grid)`
  height: 100%;
  padding: ${LayoutGutter.y} 0;
`;

export const LogoContainer = styled(Grid)<any>`
  position: relative;
  flex-direction: column !important;
  padding: 2rem 18px;
  ${({ collapse }: any) =>
    collapse &&
    css`
      padding: 2rem 17px !important;
    `}
  transition:
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    width 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const LogoMask = styled(Box)<{ collapse: boolean }>`
  width: 100%;
  margin: 0 ${LayoutGutter.sidebar.x};
  overflow: hidden;

  svg {
    width: auto;
    height: 46px;
  }

  ${({ collapse }: any) =>
    collapse &&
    css`
      margin: 0;
      svg {
        transition: height 250ms ease-out;
      }
    `}
  transition:
    padding 250ms ease-out,
    width 250ms ease-out,
    margin 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const LogoutWrapper = styled(Grid)`
  width: 100%;
  height: 125px;
  margin: 0;
  padding: ${LayoutGutter.sidebar.y} ${LayoutGutter.sidebar.x};
  cursor: pointer;
`;

export const LogoutIcon = styled(ListItemIcon)<any>`
  min-width: 30px;
  height: 40px;
  align-items: center;
  padding-left: 13px;
  background: ${props => props.bgColor};
  border: ${props => props.border};
  border-radius: 4px;
`;

export const ProfileIconWrapper = styled(Avatar)`
  width: 36px;
  height: 36px;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  color: ${props => props.theme.teal} !important;
  background: ${props => props.theme.lightTeal} !important;
`;

export const ProfileWrapper = styled.div`
  width: 57%;
  height: 20px;
  padding: 0px 20px;
  margin-top: -20px;
  display: flex;
  align-itmes: center;
`;
