import React, { MutableRefObject, useEffect, useState } from 'react';
import ChatTable from './ChatTable';
import ChatOptions from './ChatOptions';
import CustomBarChart from '@components/Chart/BarChart';
import { ChatBoxContentWrapper, ImageWrapper } from './styles';
import ChatList from './ChatList';
import ChatExtTable from './ChatExtTable';
import {
  StreamedNode,
  StreamedOptionNodeItem,
  StreamedRecommendationNode,
} from '@features/Chat/types';
import { getImageUrl } from '@features/images/api/get-image-url';
import { AIAssistantMarkdown } from './AIAssistantMarkdown';
import ChatRecommendationsNode from './ChatRecommendationsNode';
import { motion } from 'framer-motion';

type ConversationBoxNodeProps = {
  node: StreamedNode;
  isSender: boolean;
  onOptionsSelect?: (option: StreamedOptionNodeItem) => void;
  onRecommendationClicked?: (
    recommendation: StreamedRecommendationNode,
  ) => void;
  chatContentRef?: MutableRefObject<HTMLDivElement | null>;
  variants?: any;
};

function ConversationBoxNode({
  isSender,
  onOptionsSelect = () => {},
  onRecommendationClicked = () => {},
  node,
  chatContentRef,
  variants,
}: ConversationBoxNodeProps): JSX.Element {
  const [imageUrl, setImageUrlState] = useState<null | string>(null);

  const { type } = node;

  useEffect(() => {
    if (type === 'image' && node.data) {
      // Fetch the image URL asynchronously
      getImageUrl({ image: node.data })
        .then((response: any) => {
          if (response.data) {
            setImageUrlState(response.data);
          }
        })
        .catch((error: any) => {
          console.error('Error fetching image URL:', error);
        });
    }
  }, [node]);

  return (
    <ChatBoxContentWrapper
      width={type === 'bar_chart' ? '100%' : 'max-content'}
      ref={chatContentRef}
      isSender={isSender}
      isTableContent={type === 'table'}
    >
      {type === 'table' ? (
        <ChatTable data={node.data} tableHeader={node.headers} />
      ) : type === 'image' ? (
        <>
          <br />
          {imageUrl && <ImageWrapper alt={node.data} src={imageUrl} />}
          <br />
        </>
      ) : type === 'options' ? (
        <motion.div key="options" variants={variants}>
          <ChatOptions data={node.data} onOptionsSelect={onOptionsSelect} />
        </motion.div>
      ) : type === 'bar_chart' ? (
        <CustomBarChart data={node.data} meta={node.metadata} />
      ) : type === 'list' ? (
        <ChatList data={node.data} />
      ) : type === 'exttable' ? (
        <ChatExtTable {...node} />
      ) : type === 'text' ? (
        <AIAssistantMarkdown>{node.data}</AIAssistantMarkdown>
      ) : type === 'recommendations' ? (
        <ChatRecommendationsNode
          node={node}
          onRecommendationClicked={onRecommendationClicked}
        />
      ) : type === 'error' ? (
        <div style={{ color: '#400000' }}>
          We apologize, but we are unable to answer your question.
          <br />
          We&apos;re continually enhancing our system, so please try again
          later.
          <br />
          In the meantime, if you have any other questions or need assistance,
          feel free to ask, and we will do our best to help.
        </div>
      ) : type === 'network_error' ? (
        <div style={{ color: '#400000' }}>
          We apologize, but we are unable to receive an answer for your
          question.
          <br />
          We&apos;re continually enhancing our system, so please try again
          later.
          <br />
          In the meantime, if you have any other questions or need assistance,
          feel free to ask, and we will do our best to help.
        </div>
      ) : (
        <></>
      )}
    </ChatBoxContentWrapper>
  );
}

export default React.memo(ConversationBoxNode);
