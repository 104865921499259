/* eslint-disable react/prop-types */
import * as React from 'react';

function FilterIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.94678 4.5H20.053C20.1985 4.50005 20.3408 4.5424 20.4627 4.62191C20.5845 4.70141 20.6806 4.81463 20.7392 4.94777C20.7978 5.08092 20.8165 5.22824 20.7928 5.37179C20.7692 5.51533 20.7044 5.64891 20.6062 5.75625L14.4468 12.5344C14.3186 12.6716 14.2481 12.8529 14.2499 13.0406V18.3469C14.2511 18.4715 14.2207 18.5944 14.1617 18.7042C14.1027 18.814 14.017 18.9072 13.9124 18.975L10.9124 20.9719C10.7999 21.046 10.6695 21.0884 10.5348 21.0947C10.4002 21.101 10.2664 21.0709 10.1475 21.0076C10.0285 20.9443 9.92884 20.8501 9.85888 20.7349C9.78892 20.6198 9.75127 20.4879 9.74991 20.3531V13.0406C9.75172 12.8529 9.68122 12.6716 9.55303 12.5344L3.39366 5.75625C3.29546 5.64891 3.2306 5.51533 3.20698 5.37179C3.18335 5.22824 3.20199 5.08092 3.26061 4.94777C3.31923 4.81463 3.41531 4.70141 3.53715 4.62191C3.65898 4.5424 3.8013 4.50005 3.94678 4.5V4.5Z"
        stroke="#101828"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoNoData = React.memo(FilterIcon);
export default MemoNoData;
