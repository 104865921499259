import React from 'react';
import { Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { LightSubHeading } from '@components/Typography';
import { StyledTextField } from './style';
import FileDragger, { IFileDraggerFile } from './FileDragger';
import { IFundDocumentType } from '@features/Funds/types';

interface AddFundDocumentFormProps {
  fund_name: string;
  setFundName: (value: string) => void;
  document_type: IFundDocumentType;
  setDocumentType: (value: IFundDocumentType) => void;
  showDocumentTypeDropdown: boolean;
  onNewFile: (file: IFileDraggerFile) => void;
  setLoader: any;
  mode: IAddFundDocumentMode;
  show_dropzone: boolean;
}

export type IAddFundDocumentMode = 'APPEND_TO_FUND' | 'CREATE_NEW_FUND';

const AddFundDocumentForm: React.FC<AddFundDocumentFormProps> = ({
  fund_name,
  setFundName,
  onNewFile,
  document_type,
  setDocumentType,
  showDocumentTypeDropdown,
  mode,
  show_dropzone,
}) => {
  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
  ) => {
    const target = event.target as
      | HTMLInputElement
      | { name: string; value: string };
    const { value } = target;
    setFundName(value);
  };
  // MR

  return (
    <div style={{ marginTop: 20 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LightSubHeading mb={1} color="#000">
            Fund Name
          </LightSubHeading>
          <StyledTextField
            fullWidth
            disabled={mode === 'APPEND_TO_FUND'}
            placeholder="Fund Name"
            variant="outlined"
            name="name"
            value={fund_name}
            onChange={handleChange}
          />
        </Grid>
        {showDocumentTypeDropdown && (
          <>
            <Grid item xs={12}>
              <LightSubHeading mb={1} color="#000">
                Document Type
              </LightSubHeading>

              <Select
                fullWidth
                placeholder="Document Type"
                variant="outlined"
                name="document_type"
                value={document_type}
                onChange={(event: SelectChangeEvent) => {
                  setDocumentType(event.target.value as IFundDocumentType);
                }}
                inputProps={{
                  'aria-label': 'Without label',
                  placeholder: 'Select document type',
                }}
                sx={{
                  width: '100%',
                  height: '40px',
                  background: '#FFFFFF',
                  boxShadow: '1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset',
                }}
              >
                <MenuItem value={'PPM'}>PPM</MenuItem>
                <MenuItem value={'PITCH_DECK'}>Pitch Deck</MenuItem>
              </Select>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FileDragger onNewFile={onNewFile} show_dropzone={show_dropzone} />
        </Grid>
      </Grid>
    </div>
  );
};

export default AddFundDocumentForm;
