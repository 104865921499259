import React, { Fragment, useEffect, useState } from 'react';
import { Grid, Button, ButtonGroup } from '@mui/material';
import { CustomizeTextField } from '@components/core';
import { LightSubHeading, ParagraphBoldText } from '@components/Typography';
import SearchIcon from '@icons/SearchIcon';
import Dataprovider from './Dataproviders';
import Enterprise from './Enterprises';
import Details from './Details';
import { Header } from './styles';
import { HeaderContainer } from '@layout/styles';
import { useDispatch, useSelector } from 'react-redux';
import { dataProviders } from '@features/DataProviders/selector';
import { actions as dataProvidersAction } from '@features/DataProviders/slice';

const buttons = [{ title: 'Data Provider' }, { title: 'Enterprise' }];

function ManageUsers(): React.JSX.Element {
  const dispatch = useDispatch();
  const [selectedData, updateSelectedData] = useState<any>(null);
  const [activeButton, setActiveButton] = useState<string>('Enterprise');
  const [inputValue, setInputValue] = useState<string>('');
  const userProviderList = useSelector(dataProviders);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(dataProvidersAction.getUserProvidersRequest());
  }, []);

  useEffect(() => {
    setLoading(userProviderList?.loading);
  }, [userProviderList?.loading]);

  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const onSelectDataProvider = props => {
    console.log('onSelectDataProvider..', props);
  };

  const onSelectEnterprise = props => {
    updateSelectedData(props);
  };

  const goto = () => {
    updateSelectedData(null);
  };

  return (
    <>
      {!selectedData && (
        <>
          <HeaderContainer container alignItems={'flex-end'}>
            <ParagraphBoldText fontSize={'24px'}>
              Manage Users
            </ParagraphBoldText>
          </HeaderContainer>
          <LightSubHeading margin="-5px 0px 32px 15px">
            Here you can add data providers or enterprises and add
            administrators and track their usage
          </LightSubHeading>
        </>
      )}

      <Grid container>
        {!selectedData ? (
          <Fragment>
            <Header item sm={12}>
              <ButtonGroup variant="text" aria-label="Basic button group">
                {buttons.map(button => (
                  <React.Fragment key={button.title}>
                    <Button
                      onClick={() => handleButtonClick(button.title)}
                      style={{
                        textTransform: 'none',
                        minWidth: '162px',
                        borderRadius:
                          button.title === buttons[0].title
                            ? '8px 0 0 8px'
                            : button.title === buttons[buttons.length - 1].title
                              ? '0 8px 8px 0'
                              : '0',
                      }}
                      variant={
                        activeButton === button.title ? 'contained' : 'outlined'
                      }
                      color={
                        activeButton === button.title ? 'primary' : 'primary'
                      }
                    >
                      {button.title}s
                    </Button>
                  </React.Fragment>
                ))}
              </ButtonGroup>
              <CustomizeTextField
                prefixIcon={<SearchIcon />}
                name={''}
                value={inputValue}
                width={'280px'}
                placeholder="Search"
                onChange={handleChange}
                sx={{
                  maxWidth: '400px',
                  border: '1px solid #E4E7EC',
                  boxShadow: '1px 2px 2px 0px #1018281F inset',
                  borderRadius: '4px',
                  marginTop: '4px',
                  marginLeft: 'auto',
                  backgroundColor: '#fff',
                }}
              />
            </Header>
            {activeButton === 'Data Provider' && (
              <Dataprovider
                onSelect={onSelectDataProvider}
                loading={loading}
                userProviderList={userProviderList}
              />
            )}
            {activeButton === 'Enterprise' && (
              <Enterprise onSelect={onSelectEnterprise} />
            )}
          </Fragment>
        ) : (
          <Details data={selectedData} goto={goto} />
        )}
      </Grid>
    </>
  );
}

export default ManageUsers;
