import styled from 'styled-components';
import { Box, Grid, Modal } from '@mui/material';

export const TourContainer = styled(Grid)`
  width: 100%;
  height: 100%;
`;

export const TourModal = styled(Modal)`
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
`;
