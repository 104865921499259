/* eslint-disable react/prop-types */
import * as React from 'react';

function Setting(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="People">
        <path
          id="Path"
          d="M9 11.25C11.4853 11.25 13.5 9.23528 13.5 6.75C13.5 4.26472 11.4853 2.25 9 2.25C6.51472 2.25 4.5 4.26472 4.5 6.75C4.5 9.23528 6.51472 11.25 9 11.25Z"
          stroke={props.stroke || '#777777'}
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          id="Path_2"
          d="M2.17969 15.1872C2.87081 13.9898 3.86496 12.9955 5.06219 12.3043C6.25941 11.613 7.61753 11.249 9 11.249C10.3825 11.249 11.7406 11.613 12.9378 12.3043C14.135 12.9955 15.1292 13.9898 15.8203 15.1872"
          stroke={props.stroke || '#777777'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

const MemoNoData = React.memo(Setting);
export default MemoNoData;
