import React from 'react';
import { Heading } from '@components/Typography';
import { ContainerNew } from '@pages/Clients/styles';
import EmptyBox from '@icons/EmptyBox';
import { ButtonContainer } from '../styles';
import { PrimaryButton, SecondaryButton } from '@components/Button';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as UploadIcon } from '../../../../assets/images/Upload.svg';

interface EmptyState {
  onClick: () => void;
  type: string;
}
const EmptyState: React.FC<EmptyState> = ({ onClick, type }) => {
  return (
    <div style={{ height: '70vh' }}>
      <ContainerNew
        container
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <EmptyBox />
        <Heading margin="24px 0" width="auto">
          {`Looks like there are no ${type} yet`}
        </Heading>

        <ButtonContainer>
          {type == 'funds' ? (
            <SecondaryButton onClick={onClick} startIcon={<AddIcon />}>
              Add New Fund
            </SecondaryButton>
          ) : (
            <PrimaryButton startIcon={<UploadIcon />} onClick={onClick}>
              Upload Documents
            </PrimaryButton>
          )}
        </ButtonContainer>
      </ContainerNew>
    </div>
  );
};

export default EmptyState;
