import { IClient } from '@features/Clients/types';
import {useQuery, UseQueryResult} from 'react-query';
import ApiInstance from 'utils/instances';

type IUseAllClientsDetailsResponse = {
    message: 'Success',
    total: number,
    crmName: string,
    data: IClient[]
}

export default function useAllClientsDetails( integration_id?: string ): UseQueryResult<IUseAllClientsDetailsResponse> {
    return useQuery(['allClientsDetails', integration_id], async () => {
        return await ApiInstance.get<IUseAllClientsDetailsResponse>(`/client/getAllClientDetails?integrationId=${integration_id||''}`);
    }, {
        refetchOnWindowFocus: false,
        refetchInterval: 100000,
    });
}
