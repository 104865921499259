export const price = (x: number | string = 0, currency = true): string => {
  let decimal = '';

  if (typeof x === 'string' && x.includes('.')) {
    [x, decimal] = x.split('.');
    decimal = '.' + decimal.substr(0, 2);
    decimal = decimal === '.00' || decimal === '.0' ? '' : decimal;
  }

  const config: Intl.NumberFormatOptions = {
    currency: 'USD',
    minimumFractionDigits: 0,
  };

  if (currency) config.style = 'currency';

  x = Number(x);

  if (x % 1 !== 0) {
    config.minimumFractionDigits = 2;
  }

  return new Intl.NumberFormat('en-US', config).format(x).toString() + decimal;
};

export const checkValidNumber = (
  num: number,
  prec: number = 0,
  suffix: any = null,
  handleReturn: any = '--',
): any => {
  return typeof num !== 'undefined' &&
    num !== null &&
    !isNaN(num) &&
    !isNaN(Number(num))
    ? prec === 0
      ? suffix
        ? Number(num) + suffix
        : Number(num)
      : suffix
        ? Number(Number(num).toFixed(prec)) + suffix
        : Number(Number(num).toFixed(prec))
    : handleReturn;
};

export const currencyFormatter = (
  value: string | number,
  decimal: number = 2,
): string => {
  const num = Number(value);
  const isNegative = num < 0;
  const absValue = Math.abs(num);

  let formattedValue = '';

  if (absValue >= 1.0e9) {
    formattedValue = (absValue / 1.0e9).toFixed(decimal) + 'B';
  } else if (absValue >= 1.0e6) {
    formattedValue = (absValue / 1.0e6).toFixed(decimal) + 'M';
  } else if (absValue >= 1.0e3) {
    formattedValue = (absValue / 1.0e3).toFixed(decimal) + 'K';
  } else {
    formattedValue = Math.round(absValue).toString();
  }

  return (isNegative ? '-' : '') + '$' + formattedValue;
};
