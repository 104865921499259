export const MARKDOWN_DEMO = `---

# h1 Heading
## h2 Heading
### h3 Heading
#### h4 Heading
##### h5 Heading
###### h6 Heading


___

A paragraph with *emphasis* and **strong importance**.
Link [http://www.google.com](http://www.google.com)
> A block quote

* Lists
* [ ] todo
* [x] done

`;
