/**
 * This implements a common extended table, with rows, columns, etc.
 * It has its own types and props. It uses MUIDataTable. Columns and rows definitions
 * follow MUIDataTable's.
 */

import React, { useState } from 'react';
import {
  ExtTableColumn,
  ExtTableRow,
  IExtTableColumnPreference,
  IExtTableGroupedPreferences,
} from './types';
import CustomizeButton from '../CustomizeFund/CustomizeButton';
import CustomizeFundModal from '../CustomizeFund/CustomizeFundModal';
import { useLocalStorage } from '@helper/CustomHooks/useLocalStorage';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { ExtTableGroupedPDFLink } from './pdf/ExtTableGroupedPDFModal';
import { prepare_ext_table_grouped } from './utils';
import { motion } from 'framer-motion';
import ExtTableGrouped_Group from './ExtTableGrouped_Group';

const listVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

export type ExtTableGroupedProps = {
  // On CUSTOMIZE mode, we show checkboxes to toggle field visibility
  mode: 'VIEW' | 'CUSTOMIZE';
  // To be able to store preferences on local storage we need an unique key
  customization_key?: string;
  headerTitle?: string;
  headerSubtitle?: string;
  documentTitle: string;
  groups: Record<string, ExtTableColumn[]>;
  columns: ExtTableColumn[];
  rows: ExtTableRow[];
  cellRenderer?: (row: ExtTableRow, column: ExtTableColumn) => JSX.Element;
  // Notify any parent that preferences have changed
  onPreferencesSaved?: (new_preferences: IExtTableGroupedPreferences) => void;
};

/**
 * This table will have a wrapper div with some header, and then a grid with the groups.
 * Each group will be an ExtTableBasic with the columns inverted.
 */
export default function ExtTableGrouped(
  props: ExtTableGroupedProps,
): JSX.Element {
  const {
    headerTitle,
    headerSubtitle,
    documentTitle,
    customization_key,
    mode,
    groups,
    cellRenderer,
    onPreferencesSaved = () => {},
    rows,
  } = props;
  // On VIEW mode, we can open a modal to customize the table
  const [dragging, setDragging] = React.useState<string | null>(null);
  const [customize_modal, setOpenCustomizeModal] = React.useState(false);
  const [pdf_modal, setPdfModal] = React.useState(false);
  const [, setForceRefresh] = React.useState<number>(0);
  const [preferences, setPreferences, reloadValue] =
    useLocalStorage<IExtTableGroupedPreferences>(
      customization_key || 'general',
      { columns: [] },
    );
  // On CUSTOMIZE mode, we toggle visibility here, until we click 'Save'
  const [dirty_columns, setDirtyColumns] = React.useState<
    IExtTableColumnPreference[]
  >(preferences.columns);
  // CUSTOMIZE mode will have two different views: normal (all rows visible) and collapsed (isReorder=true)
  const [isReorder, setIsReorder] = useState(false);
  // Marker for needs to save
  const [is_dirty, setIsDirty] = useState(false);

  const { visible_groups, sorted_groupnames, rows_per_group } =
    prepare_ext_table_grouped(
      groups,
      mode,
      {
        ...preferences,
        columns: dirty_columns,
      },
      rows,
    );

  // On CUSTOMIZE mode, we store draft group order here, until we click 'Save'
  const [dirty_sorted_groupnames, setDirtySortedGroupnames] =
    React.useState<string[]>(sorted_groupnames);

  const isRowSelected = (row: ExtTableRow) => {
    if (mode !== 'CUSTOMIZE') {
      return false;
    }
    if (!row.label) return false;
    const found = dirty_columns.find(
      pref => pref.key === row.label || pref.key === row.key,
    );
    const ret = found ? found.visible : true;
    return ret;
  };

  const changeRowsVisibility = (rows: ExtTableRow[], visible: boolean) => {
    let new_dirty = [...dirty_columns];
    for (const row of rows) {
      if (!row.label && !row.key) continue;
      if (
        !new_dirty.find(pref => pref.key === row.label || pref.key === row.key)
      ) {
        new_dirty = [
          ...dirty_columns,
          {
            key: (row.key || row.label) as string,
            visible,
            position_in_group: undefined,
          },
        ];
      } else {
        new_dirty = new_dirty.map(pref => {
          if (pref.key === row.label || pref.key === row.key) {
            return {
              ...pref,
              visible,
            };
          }
          return pref;
        });
      }
    }
    setDirtyColumns(new_dirty);
    setIsDirty(true);
  };

  const setRowSelected = (row: ExtTableRow, selected: boolean) => {
    changeRowsVisibility([row], selected);
  };

  const changeGroupVisibility = (groupname: string, visible: boolean) => {
    const rows = rows_per_group[groupname];
    console.log('Changing group visibility', groupname, visible, rows);
    changeRowsVisibility(rows, visible);
  };

  const onSavePreferences = () => {
    const new_preferences: IExtTableGroupedPreferences = {
      columns: dirty_columns,
      sorted_groupnames: dirty_sorted_groupnames,
    };
    setPreferences(new_preferences);
    onPreferencesSaved(new_preferences);
  };

  const mouseSensor = useSensor(PointerSensor);
  const sensors = useSensors(mode === 'VIEW' ? null : mouseSensor);

  const handleDragStart = (event: any) => {
    setDragging(event.active.id);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id === null) {
      console.warn('Active ID is null');
    }
    if (over.id === null) {
      console.warn('Over ID is null');
    }
    if (active.id !== over.id) {
      setDirtySortedGroupnames(sorted_groupnames => {
        const oldIndex = sorted_groupnames.indexOf(active.id);
        const newIndex = sorted_groupnames.indexOf(over.id);
        const new_order = arrayMove(sorted_groupnames, oldIndex, newIndex);
        return new_order;
      });
      setIsDirty(true);
    }
    setDragging(null);
    setForceRefresh(Date.now());
  };

  const onOrderPreferences = () => {
    if (isReorder) {
      setIsReorder(false);
    } else {
      setIsReorder(true);
    }
  };

  const onExport = () => {
    setPdfModal(true);
  };

  const getRowOrder = (
    _groupname: string,
    row: ExtTableRow,
  ): number | undefined => {
    const column = dirty_columns.find(
      pref => pref.key === row.label || pref.key === row.key,
    );
    if (!column) {
      return undefined;
    }
    return column.position_in_group;
  };

  const onRowReordered = (
    groupname: string,
    start_index: number,
    end_index: number,
  ) => {
    // We need to load all columns from the group
    console.log(rows_per_group[groupname]);
    //    let new_dirty = dirty_columns;
    const column_stack = rows_per_group[groupname].map(row => {
      let col = dirty_columns.find(
        pref => pref.key === row.label || pref.key === row.key,
      );
      if (!col) {
        col = {
          key: row.key as string,
          visible: true,
          position_in_group: undefined,
        };
        dirty_columns.push(col);
      }
      return col;
    });

    // I want a new array where the element at start_index is moved to end_index
    const new_array = arrayMove(column_stack, start_index, end_index);
    new_array.forEach((dirty_column, index) => {
      if (dirty_column) {
        dirty_column.position_in_group = index;
      }
    });

    setDirtyColumns(dirty_columns);
    setForceRefresh(Date.now());
    setIsDirty(true);
  };

  return (
    <motion.div
      variants={listVariants}
      initial="hidden"
      animate="visible"
      style={{
        backgroundColor: '#EBF5FB',
        borderRadius: '6px',
        padding: '16px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '28px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              fontSize: '14px',
              fontWeight: 700,
              color: '#282829',
              marginBottom: '8px',
            }}
          >
            {headerTitle}
          </div>
          {headerSubtitle && (
            <div
              style={{
                color: '#96969B',
                fontWeight: 400,
                fontSize: '12px',
                marginBottom: '8px',
              }}
            >
              {headerSubtitle}
            </div>
          )}
        </div>
        {mode === 'VIEW' && customization_key && (
          <div style={{ display: 'flex', justifyContent: 'end', grid: '12px' }}>
            <CustomizeButton
              label="Export"
              onClick={() => onExport()}
              disabled={pdf_modal}
            />
            <CustomizeButton
              icon="PENCIL"
              onClick={() => setOpenCustomizeModal(true)}
            />
          </div>
        )}

        {mode === 'CUSTOMIZE' && (
          <div style={{ display: 'flex', justifyContent: 'end', grid: '12px' }}>
            <CustomizeButton
              label={isReorder ? 'Back' : 'Reorder'}
              onClick={() => onOrderPreferences()}
            />
            <CustomizeButton
              disabled={!is_dirty}
              label="Save"
              onClick={() => onSavePreferences()}
            />
          </div>
        )}
      </div>

      <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            strategy={verticalListSortingStrategy}
            items={dirty_sorted_groupnames}
          >
            {dirty_sorted_groupnames.map(groupname => {
              const groupcolumns = visible_groups[groupname];
              const isDraggingThis = dragging === groupname;
              return (
                <ExtTableGrouped_Group
                  key={JSON.stringify({ groupname, groupcolumns })}
                  {...props}
                  show_dimmed={isDraggingThis}
                  groupname={groupname}
                  groupcolumns={groupcolumns}
                  grouprows={rows_per_group[groupname]}
                  getRowOrder={row => getRowOrder(groupname, row)}
                  onRowReordered={(start_index, end_index) =>
                    onRowReordered(groupname, start_index, end_index)
                  }
                  is_reorder={isReorder}
                  isRowSelected={isRowSelected}
                  setRowSelected={setRowSelected}
                  setGroupVisible={v => changeGroupVisibility(groupname, v)}
                  cellRenderer={cellRenderer}
                />
              );
            })}
          </SortableContext>
          <DragOverlay>
            {dragging ? (
              <ExtTableGrouped_Group
                key={JSON.stringify({
                  groupname: dragging,
                  groupcolumns: visible_groups[dragging],
                })}
                {...props}
                groupname={dragging}
                groupcolumns={visible_groups[dragging]}
                grouprows={rows_per_group[dragging]}
                getRowOrder={row => getRowOrder(dragging, row)}
                onRowReordered={(start_index, end_index) =>
                  onRowReordered(dragging, start_index, end_index)
                }
                isRowSelected={isRowSelected}
                setRowSelected={setRowSelected}
                setGroupVisible={v => changeGroupVisibility(dragging, v)}
                cellRenderer={cellRenderer}
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </div>

      {customize_modal && mode === 'VIEW' && (
        <CustomizeFundModal onHide={() => setOpenCustomizeModal(false)}>
          <ExtTableGrouped
            {...props}
            mode="CUSTOMIZE"
            documentTitle={documentTitle}
            onPreferencesSaved={(
              new_preferences: IExtTableGroupedPreferences,
            ) => {
              // Preferences have been saved, we need to close the modal
              setOpenCustomizeModal(false);
              setDirtySortedGroupnames(new_preferences.sorted_groupnames || []);
              setDirtyColumns(new_preferences.columns || []);
              // We also need to update the columns we're showing
              reloadValue();
            }}
          />
        </CustomizeFundModal>
      )}

      {pdf_modal && (
        <ExtTableGroupedPDFLink
          {...props}
          visible_groups={visible_groups}
          sorted_groupnames={dirty_sorted_groupnames}
          rows_per_group={rows_per_group}
          header_title={headerTitle}
          header_subtitle={headerSubtitle}
          document_title={documentTitle}
          onExported={() => setPdfModal(false)}
        />
      )}
    </motion.div>
  );
}
