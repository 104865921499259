/* eslint-disable react/prop-types */

import React from 'react';

function Arrows(): React.JSX.Element {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.019 9.34692H21.519V4.84692"
        stroke="#FCFDFD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66846 6.16885C7.43383 5.40236 8.3428 4.79429 9.34338 4.37941C10.344 3.96453 11.4165 3.75098 12.4997 3.75098C13.5829 3.75098 14.6555 3.96453 15.656 4.37941C16.6566 4.79429 17.5656 5.40236 18.331 6.16885L21.5185 9.34698"
        stroke="#FCFDFD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.98096 14.6531H3.48096V19.1531"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.331 17.8312C17.5656 18.5977 16.6566 19.2058 15.656 19.6206C14.6555 20.0355 13.5829 20.2491 12.4997 20.2491C11.4165 20.2491 10.344 20.0355 9.34338 19.6206C8.3428 19.2058 7.43383 18.5977 6.66846 17.8312L3.48096 14.6531"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoConnectIcon = React.memo(Arrows);
export default MemoConnectIcon;
