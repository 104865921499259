import * as React from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { BootstrapDialog } from './style';

interface CustomizeFundModalProps {
  onHide: () => void;
  children: React.ReactNode;
}

const CustomizeFundModal: React.FC<CustomizeFundModalProps> = ({
  onHide,
  children,
}) => {
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={onHide}
        aria-labelledby="customized-dialog-title"
        open={true}
      >
        <DialogTitle id="customized-dialog-title">{'Customize'}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onHide}
          sx={{
            position: 'absolute',
            right: 10,
            top: 8,
            color: '#101828',
          }}
        >
          <CloseIcon />
        </IconButton>

        <>
          <DialogContent>{children}</DialogContent>
        </>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default CustomizeFundModal;
