import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QueriesInterfaceProps, QueriesProps } from './types';

export const initialState: QueriesProps = {
  queries: [],
  loading: false,
};
const queriesSlice = createSlice({
  name: 'queries',
  initialState,
  reducers: {
    /* --- Chat History --- */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadQueriesRequest(state) {},
    loadQueriesFetch(state) {
      state.loading = true;
    },
    loadQueriesSuccess(state, action: PayloadAction<QueriesInterfaceProps[]>) {
      state.loading = false;
      state.queries = action.payload;
    },
    loadQueriesError(state) {
      state.loading = false;
      state.queries = [];
    },
  },
});

export const { actions, reducer, name: sliceKey } = queriesSlice;
