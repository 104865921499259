import {
  Box,
  Dialog,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
  styled,
} from '@mui/material';

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '0 1.5rem',
    maxWidth: '750px !important',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3),
    maxWidth: '750px !important',
  },
  '& .MuiDialog-paper': {
    maxWidth: '750px',
    width: '100%',
  },
}));
export const StyledFormControl = styled(FormControl)(() => ({
  marginTop: '14px',
  '& >label': {
    left: '-12px !important',
    top: '-15px !important',
    'font-size': '18px !important',
    'font-weight': '500 !important',
    color: '#000 !important',
  },
}));
export const StyledTextField = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    boxShadow: '1px 2px 2px 0px #1018281F inset',

    height: 40,
    padding: '0 5px',
  },
}));

export const Container = styled(Grid)`
  width: 100% !important;
  gap:1rem
  display: flex;
  flex-direction: column;
  align-items: center;
  .file-uploader{
    font-weight:600;
  }
`;

export const PaperWrapper = styled(Paper)`
  margin: 0;
  padding: 1rem;
  gap: 10px;
  flex-direction: column;
  border-radius: 4px;
  background-color: #e0e4ec;
`;

export const FileContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
`;
export const StyledBox = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const StyledText = styled(Typography)`
  font-size: 1rem;
  font-weight: 500;
`;
export const StyledTypo = styled(Typography)`
  color: #667085;
  font-size: 14px;
`;
export const ProgressWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
`;
export const ProgressBar = styled(Box)`
  background-color: #4e5f72;
  height: 6px;
  border-radius: 10px;
`;
export const DraggerWrapper = styled(Box)`
  padding: 10px 0;
  background-color: white;
`;
