import React, { useState, useEffect } from 'react';

import HelixBackground from '@images/bg.svg';
import HelixLogo from '@logos/helixLogo';

import {
  OverlayContainer,
  OverlayWrapper,
  AnimatedLogo,
  AnimatedBackDrop,
  OverlapBackground,
} from './styles';

interface PreLoaderProps {
  sx?: any;
  isAnimate?: boolean;
  hideLogo?: boolean;
  logoMountDelay?: number;
  logoUnmountDelay?: number;
  callback?: (...args: any[]) => void;
}

function PreLoader({
  sx = {},
  isAnimate,
  logoMountDelay = 1000,
  logoUnmountDelay = 4000,
  hideLogo = false,
  callback,
}: PreLoaderProps): React.ReactElement {
  const [visibleLogo, updateLogoVisibility] = useState<boolean>(true);
  const [animate, startAnimation] = useState<any>(isAnimate || false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      startAnimation(true);
      if (callback) {
        console.log('logoRender successfully');
        callback(true);
      }
    }, logoMountDelay);
    const timeoutHide = setTimeout(() => {
      if (hideLogo) {
        updateLogoVisibility(false);
        console.log('logo hide');
      }
    }, logoUnmountDelay);
    return () => {
      clearTimeout(timeout);
      clearTimeout(timeoutHide);
      startAnimation(false);
    };
  }, []);

  return (
    <OverlayContainer sx={sx}>
      <OverlayWrapper
        container
        justifyContent={'center'}
        alignItems={'center'}
        visibleLogo={visibleLogo}
      >
        <AnimatedBackDrop animate={animate} />
        <AnimatedLogo animate={animate}>
          <HelixLogo />
        </AnimatedLogo>
      </OverlayWrapper>
      <OverlapBackground
        style={{
          backgroundImage: `url(${HelixBackground})`,
        }}
      />
    </OverlayContainer>
  );
}

export default PreLoader;
