/* eslint-disable react/prop-types */

import React from 'react';

function TotalAUM(): React.JSX.Element {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="4" fill="#BBBEF7" />
      <path
        d="M24 18.75V20.25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 27.75V29.25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 33C28.9706 33 33 28.9706 33 24C33 19.0294 28.9706 15 24 15C19.0294 15 15 19.0294 15 24C15 28.9706 19.0294 33 24 33Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.75 27.75H25.125C25.6223 27.75 26.0992 27.5525 26.4508 27.2008C26.8025 26.8492 27 26.3723 27 25.875C27 25.3777 26.8025 24.9008 26.4508 24.5492C26.0992 24.1975 25.6223 24 25.125 24H22.875C22.3777 24 21.9008 23.8025 21.5492 23.4508C21.1975 23.0992 21 22.6223 21 22.125C21 21.6277 21.1975 21.1508 21.5492 20.7992C21.9008 20.4475 22.3777 20.25 22.875 20.25H26.25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoConnectIcon = React.memo(TotalAUM);
export default MemoConnectIcon;
