/* --- STATE --- */

export const AUTH_USER_TYPES = [
  'SUPER_ADMIN',
  'ADMIN',
  'SUB_ADMIN',
  'FEEDBACK_USER',
  'CLIENT',
  'ENTERPRISE_ADMIN',
] as const;

export type AuthUserType = (typeof AUTH_USER_TYPES)[number];

export interface AuthDetailsProps {
  email?: string | null;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  apiKey?: string | null;
  apiToken: string | null;
  userType: AuthUserType;
  userId?: string | null;
  advisorId?: string | null;
  enterpriseId?: string | null;
  dataProviderId?: string | null;
}

export interface ProfileState {
  loading: boolean;
  authDetails: AuthDetailsProps;
}

export interface FormData {
  currentPassword: {
    value: string;
    isValid: boolean;
    isBlur: boolean;
  };
  newPassword: {
    value: string;
    isValid: boolean;
    isBlur: boolean;
  };
  confirmPassword: {
    value: string;
    isValid: boolean;
    isBlur: boolean;
  };
}
