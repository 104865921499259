/* eslint-disable react/prop-types */

import React from 'react';

function ConnectIcon(props: any): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="99"
      fill="none"
      viewBox="0 0 100 99"
      style={props.style || {}}
    >
      <path
        fill={props.fill || '#D6D7FA'}
        d="M50.616 65.055c-4.466 4.466-11.706 4.466-16.171 0-4.465-4.465-4.465-11.705 0-16.17l2.695-2.695 16.17 16.17-2.694 2.695zM49.383 33.945c4.466-4.466 11.706-4.466 16.171 0 4.465 4.465 4.465 11.705 0 16.17L62.86 52.81l-16.17-16.17 2.694-2.695z"
      ></path>
      <path
        stroke="#A0A3F2"
        strokeLinecap="round"
        strokeWidth="1.782"
        d="M63.387 62.523L71.111 70.247"
      ></path>
      <path
        stroke="#A0A3F2"
        strokeLinecap="round"
        strokeWidth="1.782"
        d="M35.713 37.375L27.99 29.652"
      ></path>
      <path
        stroke="#BBBEF7"
        strokeLinecap="round"
        strokeWidth="1.782"
        d="M67.348 58.374L75.094 58.374"
      ></path>
      <path
        stroke="#BBBEF7"
        strokeLinecap="round"
        strokeWidth="1.782"
        d="M31.752 41.524L24.006 41.524"
      ></path>
      <path
        stroke="#BBBEF7"
        strokeLinecap="round"
        strokeWidth="1.782"
        d="M59.224 67.212L59.224 74.958"
      ></path>
      <path
        stroke="#BBBEF7"
        strokeLinecap="round"
        strokeWidth="1.782"
        d="M39.876 32.687L39.876 24.941"
      ></path>
      <rect
        width="19.057"
        height="3.176"
        x="36.659"
        y="65.086"
        fill="#746AD8"
        rx="1.588"
        transform="rotate(135 36.66 65.086)"
      ></rect>
      <rect
        width="19.057"
        height="3.176"
        x="63.341"
        y="33.914"
        fill="#746AD8"
        rx="1.588"
        transform="rotate(-45 63.34 33.914)"
      ></rect>
      <rect
        width="15.881"
        height="3.176"
        x="52.807"
        y="51.188"
        fill="#7B73E4"
        rx="1.588"
        transform="rotate(-45 52.807 51.188)"
      ></rect>
      <rect
        width="15.881"
        height="3.176"
        x="46.069"
        y="44.445"
        fill="#7B73E4"
        rx="1.588"
        transform="rotate(-45 46.07 44.445)"
      ></rect>
      <path
        fill="#A0A3F2"
        d="M49.663 66.008c-4.466 4.466-11.706 4.466-16.171 0-4.466-4.465-4.466-11.705 0-16.17l2.695-2.695 16.17 16.17-2.694 2.695zM50.337 32.992c4.465-4.466 11.705-4.466 16.17 0 4.466 4.465 4.466 11.705 0 16.17l-2.695 2.695-16.17-16.17 2.694-2.695z"
      ></path>
    </svg>
  );
}

const MemoConnectIcon = React.memo(ConnectIcon);
export default MemoConnectIcon;
