import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as CsvIcon } from '../../../../assets/icons/csvIcon.svg';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Container,
  FileContainer,
  PaperWrapper,
  ProgressBar,
  ProgressWrapper,
  StyledBox,
  StyledText,
  StyledTypo,
} from './style';
import { IUploadedFile } from './AddFundDocumentModal';
interface CustomPreviewProps {
  file: IUploadedFile;
  running: boolean;
  onCancel: () => void;
}

const CustomPreview = ({
  file,
  running,
  onCancel,
}: CustomPreviewProps): JSX.Element => {
  const [isVisible, setIsVisible] = useState(true);
  const [progress, setProgress] = useState(0);
  const interval = useRef<any>();

  useEffect(() => {
    if (!running) {
      if (interval.current) {
        clearInterval(interval.current);
        setProgress(100);
      }
    } else {
      if (!interval.current) {
        interval.current = setInterval(() => {
          setProgress(prev => {
            if (prev < 100) {
              return prev + 5;
            } else {
              clearInterval(interval.current);
              return 100;
            }
          });
        }, 100);
      }
    }
  }, [running]);

  const formatBytes = (bytes: number, decimals: number = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  useEffect(() => {
    if (progress === 100 || file.upload_status === 'ERROR') {
      setTimeout(() => {
        setIsVisible(false);
      }, 500);
    }
  }, [progress, file.upload_status]);

  return (
    <Container>
      <PaperWrapper
        sx={
          file.upload_status === 'ERROR'
            ? { backgroundColor: '#FFE0E0', color: '#800000' }
            : {}
        }
      >
        <FileContainer>
          <CsvIcon />
          <StyledBox>
            <StyledText>{file.name || file['0'].name}</StyledText>

            {isVisible && (
              <CloseIcon
                sx={{ pb: '4px' }}
                width={20}
                height={20}
                onClick={onCancel}
              />
            )}
          </StyledBox>
        </FileContainer>
        {isVisible && (
          <>
            {' '}
            <ProgressWrapper>
              <StyledTypo>
                {file?.size ? formatBytes(file?.size) : 0}
              </StyledTypo>
              <StyledTypo> {`${progress}%`}</StyledTypo>
            </ProgressWrapper>
            <Box sx={{ backgroundColor: '#ffffff' }}>
              <ProgressBar
                sx={{
                  width: `${progress}%`,
                }}
              ></ProgressBar>
            </Box>{' '}
          </>
        )}
      </PaperWrapper>
    </Container>
  );
};

export default CustomPreview;
