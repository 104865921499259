/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// store/configureStore.ts
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { rootReducer, rootSaga } from './reducers';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function configureAppStore() {
  // Create the store with saga middleware
  const sagaMiddleware = createSagaMiddleware();

  const middleware = [
    ...getDefaultMiddleware({
      thunk: false, // Use Redux Toolkit's createSlice with saga
    }),
    sagaMiddleware,
  ];

  const store = configureStore({
    reducer: rootReducer,
    middleware,
    devTools: process.env.NODE_ENV !== 'production',
  });

  sagaMiddleware.run(rootSaga);

  return store;
}
