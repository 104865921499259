import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { Grid } from '@mui/material';
import { ParagraphBoldText } from '@components/Typography';

import { FundDetails } from '@features/Global/types';
import { actions as chatActions } from '@features/Chat/slice';

import { getElementHeight } from '@common/string';
import { LayoutGutter } from '@styles/variables';

import Card from '../Suggestions/Card';
import { FundDetailsDrawerContainer } from './styles';
import { SuggestionsWrapper } from '../Suggestions/styles';

const DrawerHeadPadding = '1rem 2rem 0.5rem 2rem';

function FundDetailsDrawer({
  ticker,
  name,
  data = [],
}: FundDetails): React.JSX.Element {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onCardClick = (query: string) => {
    navigate('/conversations');
    dispatch(chatActions.searchQueryRequest({ query }));
  };

  const FundDetailsContainerHeight = `calc(100% - ${getElementHeight('drawer-label-container')}px)`;
  const ContentHeight = `calc(100% - calc(${getElementHeight('fund-info-container')}px + ${LayoutGutter.rightSide.content.y}))`;

  return (
    <FundDetailsDrawerContainer height={FundDetailsContainerHeight}>
      <Grid
        id={'fund-info-container'}
        container
        alignItems={'center'}
        sx={{
          padding: DrawerHeadPadding,
        }}
      >
        <Grid item sm={10}>
          <ParagraphBoldText fontSize={'20px'} lineHeight={1.5} mb={1}>
            {name}
          </ParagraphBoldText>
          {ticker && (
            <ParagraphBoldText
              color={theme.grey6}
              fontSize={'14px'}
              fontWeight={400}
              lineHeight={1.5}
            >
              Ticker Name - {ticker}
            </ParagraphBoldText>
          )}
        </Grid>
      </Grid>
      <SuggestionsWrapper container rowGap={2} height={ContentHeight}>
        {data.map((l: any, idx: number) => (
          <Card
            key={idx}
            title={l.title}
            type={l.type}
            data={l.data}
            onClick={onCardClick}
          />
        ))}
      </SuggestionsWrapper>
    </FundDetailsDrawerContainer>
  );
}

export default FundDetailsDrawer;
