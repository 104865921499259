import React from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { Box, Grid, useTheme } from '@mui/material';
import DangerousIcon from '@mui/icons-material/Dangerous';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ParagraphBoldText, ParagraphLightText } from '@components/Typography';
import { Button, InputField, CriteriaOption } from './styles';

type FormReturnType = {
  currentPassword: string;
  newPassword: string;
  retypePassword: string;
};

interface UpdatePasswordFormProps {
  gap?: string;
  email?: string;
  lastName: string;
  firstName: string;
  isFormSubmitting: boolean;
  enableCurrentPassword?: boolean;
  onSubmit: (value: FormReturnType) => void;
}
interface FormData {
  currentPassword: string;
  newPassword: string;
  retypePassword: string;
}

const UpdatePasswordForm = ({
  email = '',
  gap = '2rem',
  lastName = '',
  firstName = '',
  enableCurrentPassword = false,
  isFormSubmitting = false,
  onSubmit,
}: UpdatePasswordFormProps): React.JSX.Element => {
  const theme = useTheme();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, touchedFields },
    getValues,
  } = useForm<FormData>({
    defaultValues: {
      newPassword: '',
      retypePassword: '',
      ...(enableCurrentPassword && {
        currentPassword: '',
      }),
    },
  });

  const watchNewPassword = useWatch({
    control,
    name: 'newPassword',
    defaultValue: '',
  });

  const criteria: Array<string> = [
    'Min. 8 Characters',
    'At Least 1 Uppercase',
    '1 Special Character',
    'At Least 1 Number',
    'Password Match',
    'Does not contain parts of name and email',
    ...(enableCurrentPassword ? ['Unique Password'] : []),
  ];

  const isCriteriaMet = (password: string, criteria: string): boolean => {
    switch (criteria) {
      case 'Min. 8 Characters':
        return password.length >= 8;
      case 'At Least 1 Uppercase':
        return /[A-Z]/.test(password);
      case '1 Special Character':
        return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);
      case 'At Least 1 Number':
        return /\d/.test(password);
      case 'Password Match':
        return getValues('newPassword') === getValues('retypePassword');
      case 'Does not contain parts of name and email':
        // Where username can be a part of -> (firstName, lastName)
        // eslint-disable-next-line no-case-declarations
        const _email: string =
          email?.split('@').length > 0 ? email?.split('@')[0] : email[0];
        return (
          !password.toLowerCase().includes(_email.toLowerCase()) &&
          !password.toLowerCase().includes(firstName.toLowerCase()) &&
          !password.toLowerCase().includes(lastName.toLowerCase())
        );
      case 'Unique Password':
        if (enableCurrentPassword) {
          return password !== getValues('currentPassword');
        } else {
          return true;
        }
      default:
        return true;
    }
  };

  const handleFormSubmit: SubmitHandler<FormData> = data => {
    console.log('data : ', data);
    onSubmit(data);
  };

  return (
    <Box
      component="form"
      sx={{
        width: '100%',
        maxWidth: '400px',
        display: 'flex',
        flexDirection: 'column',
      }}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      {enableCurrentPassword && (
        <Grid container position={'relative'}>
          <ParagraphBoldText mb={'4px'}>Current password</ParagraphBoldText>
          <Controller
            name={'currentPassword'}
            control={control}
            defaultValue=""
            rules={{
              validate: {
                isNotEmpty: val => val.trim() !== '' || 'Please enter username',
              },
            }}
            render={({ field }) => (
              <InputField
                required
                fullWidth
                type={'password'}
                placeholder={'Enter current password'}
                error={
                  !!errors.currentPassword && touchedFields.currentPassword
                }
                helperText={
                  touchedFields.currentPassword &&
                  errors.currentPassword?.message
                }
                sx={{ height: '40px', marginBottom: gap }}
                {...field}
              />
            )}
          />
        </Grid>
      )}
      <Grid container position={'relative'}>
        <ParagraphBoldText mb={'4px'}>Enter new password</ParagraphBoldText>
        <Controller
          name={'newPassword'}
          control={control}
          defaultValue=""
          rules={{
            required: 'Please enter your new password',
            validate: value =>
              (isCriteriaMet(value, 'Min. 8 Characters') &&
                isCriteriaMet(
                  value,
                  'Does not contain parts of name and email',
                )) ||
              'Password must contain at least 8 characters',
          }}
          render={({ field }) => (
            <InputField
              required
              fullWidth
              type={'password'}
              placeholder={'Enter new password'}
              error={!!errors.newPassword && touchedFields.newPassword}
              helperText={
                touchedFields.newPassword && errors.newPassword?.message
              }
              sx={{ height: '40px', marginBottom: gap }}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid container position={'relative'}>
        <ParagraphBoldText mb={'4px'}>Re-enter password</ParagraphBoldText>
        <Controller
          name={'retypePassword'}
          control={control}
          defaultValue=""
          rules={{
            validate: {
              isNotEmpty: val =>
                val.trim() !== '' || 'Please enter retypePassword',
              passwordMatch: val =>
                val === watchNewPassword || 'Passwords do not match',
            },
          }}
          render={({ field }) => (
            <InputField
              required
              fullWidth
              type={'password'}
              placeholder={'Re-enter new password'}
              error={!!errors.retypePassword && touchedFields.retypePassword}
              helperText={
                touchedFields.retypePassword && errors.retypePassword?.message
              }
              sx={{ height: '40px', marginBottom: gap }}
              {...field}
            />
          )}
        />
      </Grid>
      <Button
        type={'submit'}
        variant={'contained'}
        disabled={!isValid || isFormSubmitting}
        sx={{ mt: 2, width: '100%' }}
      >
        {isFormSubmitting ? 'Submitting...' : 'Confirm'}
      </Button>
      <Grid container mt={2} spacing={1}>
        {criteria.map((criterion: string, index: number) => (
          <CriteriaOption
            key={index}
            startIcon={
              Object.keys(touchedFields).length === 0 ? (
                <CheckCircleIcon />
              ) : isCriteriaMet(watchNewPassword, criterion) ? (
                <CheckCircleIcon />
              ) : (
                <DangerousIcon />
              )
            }
            customColor={
              Object.keys(touchedFields).length === 0
                ? theme.palette?.success?.main
                : isCriteriaMet(watchNewPassword, criterion)
                  ? theme.palette?.success?.main
                  : theme.palette?.error?.main
            }
            sx={{ justifyContent: 'flex-start' }}
          >
            <ParagraphLightText color={'inherit'} textAlign={'left'}>
              {criterion}
            </ParagraphLightText>
          </CriteriaOption>
        ))}
      </Grid>
    </Box>
  );
};

export default UpdatePasswordForm;
