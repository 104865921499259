import React from 'react';
import { LoadingButton as MuiButton } from '@mui/lab';
import styled, { useTheme } from 'styled-components';
import { makeStyles } from '@mui/styles';

type Props = {
  type?: 'submit' | 'reset' | 'button';
  id?: string;
  width?: any;
  loading?: boolean;
  color?:
    | 'inherit'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | 'primary'
    | 'secondary'
    | undefined;
  textColor?: string;
  customColor?: string;
  bgColor?: string;
  borderColor?: string;
  variant?: 'contained' | 'outlined' | 'text';
  fullWidth?: boolean;
  customHeight?: string;
  children: React.ReactNode;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  style?: React.CSSProperties;
  sx?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  dataTestId?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  loadingPosition?: 'center' | 'start' | 'end' | undefined;
};

const Button = styled(MuiButton).withConfig({
  shouldForwardProp: prop =>
    ![
      'theme',
      'width',
      'customHeight',
      'disabled',
      'customColor',
      'bgColor',
      'borderColor',
      'textColor',
    ].includes(prop),
})<Props>(
  ({
    theme,
    width,
    customHeight,
    disabled,
    customColor,
    bgColor,
    borderColor,
    textColor,
  }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${width ?? 'auto'};
  height: ${customHeight ?? 'auto'};
  text-align: center;
  outline: none;
  border-radius: 4px;
  color: ${textColor || theme.grey6} !important;
  opacity: ${disabled ? '0.5' : '1'};
  background: ${customColor || 'transparent'} !important;
  background-color: ${bgColor || 'transparent'} !important;
  border: 1px solid ${borderColor || 'transparent'} !important;

  &:hover {
    cursor: pointer;
    background: ${customColor || theme.inactiveDotColor} !important;
    color: black !important;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  .MuiLoadingButton-loadingIndicator {
    color: ${theme.grey6};
    position: unset;
  }

  svg {

  }
`,
);

const useStyles = makeStyles({
  root: {
    '&.MuiButtonBase-root': {
      '&.MuiLoadingButton-loading': {
        color: (theme: any) => theme?.grey6,
        '&.MuiLoadingButton-loadingIndicator': {
          position: 'unset',
          color: (theme: any) => theme?.grey6,
        },
      },
    },
  },
});

function TextButton({
  width = 'auto',
  type = 'button',
  variant = 'text',
  fullWidth = false,
  customColor = '',
  bgColor = '',
  borderColor = '',
  textColor = '',
  customHeight,
  children,
  className,
  style,
  onClick,
  disabled = false,
  dataTestId = undefined,
  ...rest
}: Props): React.JSX.Element {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Button
      type={type}
      customColor={customColor}
      bgColor={bgColor}
      borderColor={borderColor}
      textColor={textColor}
      width={width}
      variant={variant}
      disabled={disabled}
      style={style}
      onClick={onClick}
      className={`${classes?.root} ${className}`}
      fullWidth={fullWidth}
      customHeight={customHeight}
      data-cy={dataTestId}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default TextButton;
