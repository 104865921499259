import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Breadcrumbs, Drawer, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AccordionTable from '@components/AccordianTable/Users';
import { actions as userActions } from '@features/Users/slice';
import { ParagraphBoldText, SmallText } from '@components/Typography';
import { usersList } from '@features/Users/selector';
import { enterprisesList } from '@features/Enterprises/selector';
import { PrimaryButton, TextButton } from '@components/Button';
import AddUserModal from './Users/AddUser';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from 'styled-components';
import { EnterpriseWrap } from './styles';
import MemoClientsIcon from '@icons/Clients';
import TotalAUM from '@icons/TotalAUM';
import TotalMembers from '@icons/TotalMembers';

const useStyles = makeStyles({
  drawer: {
    width: 575,
  },
  drawerPaper: {
    width: 575,
    borderRadius: '16px',
    margin: '9px 9px 0px 0px',
    maxHeight: '98vh',
  },
  root: {
    display: 'flex',
  },
});

interface data {
  logo: React.ReactElement | string;
  title: string;
  value: any;
}

const accordionCardData: data[] = [
  {
    logo: <TotalMembers />,
    title: 'Total Members',
    value: '0',
  },
  {
    logo: <TotalAUM />,
    title: 'Total AUM',
    value: '$0',
  },
  {
    logo: <MemoClientsIcon />,
    title: 'Total Clients',
    value: '0',
  },
];

interface UserListProps {
  data: any;
  goto: () => void;
}

function Users({ data, goto }: UserListProps): React.JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const UsersState = useSelector(usersList);
  const enterprisesData = useSelector(enterprisesList);
  const [openModal, updateModal] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [accordionData, setAccordionData] = useState(accordionCardData);

  useEffect(() => {
    dispatch(
      userActions.loadUsersListRequest({
        enterpriseId: data.id,
      }),
    );
  }, []);

  useEffect(() => {
    const updatedAccordionCardData = [...accordionData];
    const totalClientsIndex = updatedAccordionCardData.findIndex(
      card => card.title === 'Total Clients',
    );
    if (totalClientsIndex !== -1) {
      updatedAccordionCardData[totalClientsIndex].value =
        UsersState?.data?.length ?? 0;
      setAccordionData(updatedAccordionCardData);
    }
  }, [UsersState?.data]);

  const handleClose = () => {
    setDrawerOpen(false);
  };

  const handleUserModalOpen = () => {
    updateModal(true);
  };

  const handleUserModalClose = () => {
    updateModal(false);
  };

  const handleSubmit = (formData: any) => {
    dispatch(
      userActions.addNewAdvisorRequest({
        ...formData,
      }),
    );
  };
  const theme = useTheme();

  return (
    <Grid container rowGap={1} px={2} mt={'16px'}>
      <Grid item>
        <Breadcrumbs aria-label="breadcrumb">
          <TextButton
            startIcon={<ArrowBackIosNewIcon />}
            sx={{
              fontSize: '12px',
              textTransform: 'capitalize',
              color: `${theme.breadcrumbHead} !important`,
            }}
            onClick={goto}
          >
            Manage Users
          </TextButton>
          <TextButton
            sx={{
              fontSize: '12px',
              textTransform: 'capitalize',
              backgroundColor: `${theme.background9} !important`,
              color: `${theme.breadcrumbHead} !important`,
            }}
          >
            {data.name}
          </TextButton>
        </Breadcrumbs>
      </Grid>
      <Grid
        item
        container
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ padding: '6px 0 32px' }}
      >
        <Grid item>
          <EnterpriseWrap>
            <Box
              sx={{
                width: '48px',
                height: '48px',
                backgroundColor: '#F2F4F7',
                borderRadius: '4px 0px 0px 0px',
                cursor: 'pointer',
              }}
            />
            <div>
              <ParagraphBoldText fontSize={'20px'}>
                {data.name}
              </ParagraphBoldText>
              <SmallText>
                {UsersState && UsersState?.data && UsersState?.data.length}{' '}
                {UsersState && UsersState?.data && UsersState?.data.length > 1
                  ? 'Advisors'
                  : 'Advisor'}
              </SmallText>
            </div>
          </EnterpriseWrap>
        </Grid>
        <Grid item>
          <PrimaryButton startIcon={<AddIcon />} onClick={handleUserModalOpen}>
            Add User
          </PrimaryButton>
        </Grid>
      </Grid>

      <AccordionTable data={UsersState.data} loading={UsersState.loading} />
      {drawerOpen && (
        <div className={classes.root}>
          <Drawer
            anchor="right"
            variant="temporary"
            open={drawerOpen}
            className={classes.drawer}
            classes={{ paper: classes.drawerPaper }}
            onClose={handleClose}
          ></Drawer>
        </div>
      )}

      {openModal && (
        <AddUserModal
          loading={enterprisesData.loading || UsersState.loading}
          data={enterprisesData.data}
          enterpriseData={data.id}
          onClose={handleUserModalClose}
          handleSubmit={handleSubmit}
        />
      )}
    </Grid>
  );
}

export default Users;
