import { Box, Grid } from '@mui/material';
import { COLORS } from '@styles/colors';
import { LayoutGutter } from '@styles/variables';
import styled from 'styled-components';

export const Container = styled(Box)`
  width: 100%;
  height: 80vh;
  background-color: #ffffff;
  border-radius: 8px;
`;
export const ContainerNew = styled(Grid)`
  width: 100%;
  height: 100%;
  padding: 0 ${LayoutGutter.x} 0 ${LayoutGutter.y};
`;

export const Header = styled(Grid)`
  padding: 24px 16px;
`;

export const BodyContainder = styled(Box)`
  padding: 24px 16px;
  height: calc(100% - 104px);
`;
export const FooterButtonContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: transparent;
  height: 10vh;
  border: 1px solid #e4e7ec;
  display: flex;
  align-items: center;
  backdrop-fliter: blur(8px);
`;

export const PortfolioWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding: 10px 0px;
  align-items: center;
`;

export const StyledColumn1 = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
`;

export const Column = styled.div`
  display: flex;
`;

export const StyledColumn2 = styled.div`
  color: var(--neutral-600, #475467);
  font-size: 12px;
`;

export const PortfolioContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 34%;
`;

export const ReturnsHeader = styled.div`
  height: 250px;
`;

export const AssetAllocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const AssetAllocationHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-bottom: 5px;
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 98%;
  height: 15px;
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const CashFlowWrapper = styled.div`
  width: 20%;
  background: ${COLORS.cashColor};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-right: 2px;
`;

export const EquityFlowWrapper = styled.div`
  width: 50%;
  background: ${COLORS.seaGreen};
  margin-right: 2px;
`;

export const DebtWrapper = styled.div`
  width: 20%;
  background: ${COLORS.purple};
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const ProgressBarLegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: flex-start;
`;

export const LegendWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
`;

export const LegendColorDiv = styled.div`
  height: 12px;
  width: 12px;
  margin-right: 8px;
  background: #10e4b1;
  border-radius: 2px;
`;

export const DrawerContainer = styled.div`
  min-height: 93vh;
  padding: 25px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  overflow: hidden;
`;

export const DrawerHeaderWrapper = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--neutral-900, #101828);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const ExpectedFundDataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  row-gap: 16px;
  column-gap: 56px;
  width: 100%;
`;

export const ExpectedFundDataWrapper = styled.div`
  display: flex;
`;

export const FundDataText = styled.div`
  padding: 5px;
  color: var(--Gray-1, #818181);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const HoldingWrapper = styled.div`
  width: 94%;
  max-height: 150px;
  padding: 0px 20px 0px 10px;
  overflow-y: scroll;
  position: relative;
  top: 12%;
`;

export const AddClientBox = styled(Box)`
  float: right;
  margin-top: -20px;
  margin-bottom: 20px;
`;

export const ClientCardBox = styled(Box)`
  display: flex;
  gap: 16px;
`;

export const LogoContainer = styled(Box)`
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;
export const AccountBox = styled(Box)`
  padding: 24px 0;
  & .MuiButtonGroup-root .MuiButtonGroup-grouped {
    min-width: 172px;
  }
`;
export const DrawerHeaderBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
