import { apiEndPoints } from '@pages/UploadDocuments/_constants';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './types';
import { actions as globalActions } from '@features/Global/slice';


/**
 * This component uses SSE to listen for notifications from the server.
 * We will send the token in the query. The server can disconnect the client if the token is invalid.
 * We will also send the last notification id we received, so the server can send only new notifications.
 * The server will send the notifications in the format:
 *
 */

type IConnectionStatus = 'CONNECTING' | 'CONNECTED' | 'DISCONNECTED' | 'FAILED';

type INotification = {
    id: string;
    type: 'DOCUMENT_PROCESSED' | 'DOCUMENT_FAILED';
    message: string;
    fund_document_id: string;
    fund_document_name: string;
}

export default function NotificationListener(): JSX.Element {
  const [status, setStatus] = React.useState<IConnectionStatus>('DISCONNECTED');
  // We don't want notifications older than 10 minutes.
  const [last_notification_id, setLastNotificationId] = React.useState<string>(Math.floor(Date.now() - 600 * 1000).toString());
  const chatToken = useSelector((state: RootState) => state.chat?.initToken);
  const dispatch = useDispatch();

  useEffect(() => {
    if (chatToken && ['DISCONNECTED', 'FAILED'].includes(status)) {
      console.log('Reconnecting', status);
      setStatus('CONNECTING');
      const url = `${process.env.REACT_APP_API_BASE_URL}${apiEndPoints.getNotificationsSSE}?last=${last_notification_id}&token=${chatToken.replace('Bearer ', '')}`;
      const eventSource = new EventSource(url);

      eventSource.onopen = () => {
        console.log('Connection to SSE opened.');
        setStatus('CONNECTED');
      };

      eventSource.onmessage = (event: MessageEvent) => {
        if (!event.data) {
          return;
        }
        const node = JSON.parse(event.data) as INotification;
        if( node.id ) {
            setLastNotificationId(node.id);
            console.log('NotificationListener received:', node.id, node);
            dispatch(
                globalActions.displayToast({
                  duration: 3000,
                  toastType: node.type == 'DOCUMENT_PROCESSED' ? 'success' : 'error',
                  toastMessage: node.message,
                }),
              );
        }
      };

      eventSource.onerror = (error: Event) => {
        console.error('EventSource failed:', error);
        // Wait 5 seconds before reconnecting
        setTimeout(() => setStatus('FAILED'), 5000);
        eventSource.close();
      };

      /*        return () => {
          eventSource.close();
          setTimeout(() => setStatus('DISCONNECTED'), 500);
          console.log('Connection to SSE closed.');
        };*/
    }
  }, [chatToken, status, last_notification_id]);

  return (
    <></>
  );
}
