import { AllEffect, all, call, put, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { addNewAdvisor, getAllAdvisorsByEnterpriseId } from './api';
import { actions as globalActions } from '@features/Global/slice';

function* getUsersList(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    if (!payload.enterpriseId) {
      throw `Enterprise ID must be provided`;
    }
    yield put(globalActions.updateConfigLoading(false));
    const response: any = yield call(getAllAdvisorsByEnterpriseId, {
      enterpriseId: payload.enterpriseId,
    });

    if (!response || (response.data && !response.data.length)) {
      throw new Error('Failed to fetch Data Providers list');
    }

    const sortedData = response?.data?.sort(
      (a: any, b: any) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );
    yield put(
      actions.loadUsersListSuccess({
        data: sortedData || [],
        loading: false,
      }),
    );
  } catch (error) {
    yield put(
      actions.loadUsersListSuccess({
        data: [],
        loading: false,
      }),
    );
  }
}

function* addNewUser(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    if (!payload.enterpriseId) {
      throw `Enterprise ID must be provided`;
    }
    yield put(actions.addNewAdvisorFetch());
    const response: any = yield call(addNewAdvisor, payload);
    if (!response || response.error) {
      throw new Error('Failed to add new advisor');
    }
    if (response.status === 200) {
      yield put(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'success',
          toastMessage: 'Advisor added successfully',
        }),
      );
    }
    yield put(
      actions.loadUsersListRequest({
        enterpriseId: payload.enterpriseId,
      }),
    );
    yield put(actions.addNewAdvisorSuccess({ loading: false }));
    if (payload.callback && typeof payload.callback === 'function') {
      payload.callback(response);
    }
  } catch (error) {
    yield put(
      globalActions.displayToast({
        duration: 3000,
        toastType: 'error',
        toastMessage: 'Failed to add new advisor',
      }),
    );
    yield put(actions.addNewAdvisorSuccess({ loading: false }));
  }
}

export function* usersSaga(): Generator<AllEffect<any>, void, unknown> {
  yield all([
    takeLatest(actions.loadUsersListRequest.type, getUsersList),
    takeLatest(actions.addNewAdvisorRequest.type, addNewUser),
  ]);
}
