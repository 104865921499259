import React from 'react';

/* import { BorderWrapper } from './styles';
 */ import SuggestionCard from '@components/SuggestionCard';
import { SuggestionListJSON } from '@common/constant';
import { Grid } from '@mui/material';
import { motion } from 'framer-motion';

interface SuggestionListProps {
  variants?: any;
}

/**
 * In the Home page, this shows 6 hardcoded buttons that link to some categories.
 */
function SuggestionList({ variants }: SuggestionListProps): React.JSX.Element {
  return (
    <>
      {/*<BorderWrapper />*/}
      <Grid container spacing={2} pb={2} pt={0}>
        {SuggestionListJSON.map(data => (
          <Grid item sm={12} md={6} lg={3} key={data?.id}>
            <motion.div key={data.id} variants={variants}>
              <motion.div
                key={crypto.randomUUID()}
                transition={{ duration: 0.8, ease: [0.4, 0, 0.2, 1] }}
                style={{
                  borderRadius: '12px',
                }}
              >
                <SuggestionCard
                  id={data.id}
                  prompt={data.prompt}
                  title={data.title}
                  category={data.category}
                />
              </motion.div>
            </motion.div>
          </Grid>
        ))}
      </Grid>
      {/*  <BorderWrapper top={16} bottom={24} /> */}
    </>
  );
}

export default SuggestionList;
