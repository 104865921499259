import React from 'react';
import CustomBarChart from '@components/Chart/BarChart';
import { useImageUrl } from '@features/images';
import ChatExtTable from './ChatExtTable';
import ChatList from './ChatList';
import ChatOptions from './ChatOptions';
import ChatTable from './ChatTable';
import { ChatBoxContentWrapper, ImageWrapper } from './styles';
import Markdown from 'react-markdown';
import { ConversationNode } from './types';
import { StreamedOptionNodeItem } from '@features/Chat/types';

type ContentDefaultProps = ConversationNode & {
  isSender: boolean;
  onOptionsSelect?: (option: StreamedOptionNodeItem) => void;
  data: any;
};

/**
 * @param param0
 * @returns
 */
function ContentDefault({
  isSender,
  onOptionsSelect = () => {},
  data,
  ...restProps
}: ContentDefaultProps): React.JSX.Element {
  const { type } = restProps;

  const imageUrl = useImageUrl({
    image: type === 'image' ? data : null,
  });

  return (
    <ChatBoxContentWrapper
      width={type === 'bar_chart' ? '100%' : 'max-content'}
      isSender={isSender}
      isTableContent={type === 'table'}
    >
      {type === 'table' ? (
        <ChatTable data={data} tableHeader={restProps.headers} />
      ) : type === 'image' ? (
        <>
          <br />
          {imageUrl && <ImageWrapper alt={data} src={imageUrl} />}
          <br />
        </>
      ) : type === 'options' ? (
        <ChatOptions data={data} onOptionsSelect={onOptionsSelect} />
      ) : type === 'bar_chart' ? (
        <CustomBarChart data={data} meta={restProps.metadata} />
      ) : type === 'list' ? (
        <ChatList data={data} />
      ) : type === 'exttable' ? (
        <ChatExtTable {...restProps} />
      ) : type === 'text' ? (
        <Markdown>{data}</Markdown>
      ) : (
        <></>
      )}
    </ChatBoxContentWrapper>
  );
}

export default React.memo(ContentDefault);
