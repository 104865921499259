/* eslint-disable react/prop-types */
import * as React from 'react';

function HelixAvatar(props: any) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={props?.style || {}}
    >
      <g id="Sage Avatar">
        <circle
          id="Outer Circle"
          cx="18"
          cy="18"
          r="18"
          fill="#012E4C"
          opacity={props.borderOpacity}
        />
        <g id="Group">
          <g id="Group_2">
            <path
              id="Vector"
              d="M23.4835 20.9318V11.7998H20.543V11.8301V17.7028V21.7146C20.543 22.9818 21.527 24.0127 22.7368 24.0127H29.9996V20.9318H23.4835Z"
              fill="white"
            />
            <g id="Group_3">
              <g id="Group_4">
                <path
                  id="Vector_2"
                  d="M10.9492 12.6761V11.7998H8V18.0712C9.76428 16.9841 10.9492 14.9757 10.9492 12.6761Z"
                  fill="white"
                />
              </g>
            </g>
            <path
              id="Vector_3"
              d="M15.8143 11.7998V16.4111C15.8143 16.4111 13.4014 16.3975 13.1854 16.4111C12.3662 16.4628 11.6058 16.7864 10.926 17.1974C10.8407 17.2489 10.7572 17.3031 10.6746 17.3589C9.25805 18.3338 8.2609 19.9221 8.02893 21.7639C8.02893 21.7639 8.00029 22.3704 8 22.5655V24.043H10.9206V19.4882H10.926L15.8104 19.4875V19.4921V22.6161V24.043H15.8143H18.7154H18.7271H18.7632V19.4921V16.4111V11.7998H15.8143Z"
              fill="white"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

const MemoNoData = React.memo(HelixAvatar);
export default MemoNoData;
