import React from 'react';
import { ExtTableCellType, ExtTableCellValue } from './types';
import ShowMoreText from 'react-show-more-text';

export default function renderCell(
  type: ExtTableCellType,
  value: ExtTableCellValue,
): JSX.Element {
  if (value === null) {
    return <>-</>;
  }
  switch (type) {
    case 'string': {
      // ShowMoreText is hiding <NA>, convert to &gt;
      if (value === '<NA>') {
        return <>-</>;
      }
      return (
        <ShowMoreText
          lines={3}
          width={280}
          more="View more"
          less="View less"
          expanded={false}
          truncatedEndingComponent={'...'}
        >
          {value}
        </ShowMoreText>
      );
    }
    case 'integer': {
      const asint = value as number;
      // Return localised number
      return <>{asint.toLocaleString()}</>;
    }
    case 'money': {
      return <>${value}</>;
    }
    case 'percentage': {
      const asnumber = value as number;
      // Convert to percentage with maximum 2 digits and minimum 0
      const percentage = asnumber.toFixed(2);
      return <>{percentage}%</>;
    }
    case 'custom': {
      return <i>Column should have a custom renderer</i>;
    }
    default: {
      return <i>{JSON.stringify(value)}</i>;
    }
  }
}
