import React from 'react';
import {
  Skeleton,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Grid,
  CircularProgress,
} from '@mui/material';

const TableSkeleton = (): React.JSX.Element => {
  return (
    <React.Fragment>
      <Box
        sx={{
          width: '100%',
          height: 'calc(100vh - 124px)',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Grid
          container
          width={'100%'}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          sx={{
            marginBottom: '24px',
            position: 'absolute',
            top: '50%',
            left: '45%',
          }}
        >
          <CircularProgress />
        </Grid>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                border: '1px solid rgba(224, 224, 224, 1)',
              }}
            >
              {Array.from({ length: 5 }).map((_, index) => (
                <TableCell key={index}>
                  <Skeleton variant="text" sx={{ height: 80 }} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from({ length: 10 }).map((_, rowIndex) => (
              <TableRow
                key={rowIndex}
                sx={{
                  borderLeft: '1px solid rgba(224, 224, 224, 1)',
                  borderRight: '1px solid rgba(224, 224, 224, 1)',
                }}
              >
                {Array.from({ length: 5 }).map((_, colIndex) => (
                  <TableCell key={colIndex}>
                    <Skeleton variant="text" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </React.Fragment>
  );
};

export default TableSkeleton;
