import React from 'react';
import { Grid, CircularProgress, useTheme } from '@mui/material';
import CustomChip from '@components/Chip/CustomChip';
import { ParagraphBoldText, ParagraphLightText } from '@components/Typography';
import { toCapitalCase } from '@common/string';
import { CustomButton, CustomCard, CrmLogo, ImageWrapper } from './styles';
import { IIntegrationExt, IIntegrationStatus } from '@features/Global/types';

const INTEGRATION_STATUS: Record<IIntegrationStatus,string> = {
  COMING_SOON: 'Coming Soon',
  CONNECT: 'Connect',
  CONNECTED: 'Connected',
  SYNC: 'Sync',
  SYNCING: 'Syncing...',
  SYNCED: 'All clients are synced',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

interface CRMCardProps {
  list: IIntegrationExt[];
  sync: (integration: IIntegrationExt) => void;
  handleViewDetail: (integration: IIntegrationExt) => void;
  handleConnectClick: (integration: IIntegrationExt) => void;
}
// /wealthbox/?code=qNQPBnYuAoa_WEDGB2An1WvoEvlyo3DI_Y3bKuvIkkk
const CRMCard = ({
  list = [],
  sync,
  handleViewDetail,
  handleConnectClick,
}: CRMCardProps): React.JSX.Element => {
  const theme = useTheme();

  return (
    <Grid container spacing={2}>
      {list.map((integration, index: number) => (
        <Grid
          item
          key={index}
          sm={3}
          minHeight={'230px !imported'}
          minWidth={'225px !important'}
          maxWidth={'315px !important'}
        >
          <CustomCard
            container
            flexDirection={'column'}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
          >
            <ImageWrapper>
              <CrmLogo
                src={require(`@icons/${integration.icon}`)}
                alt={`${integration.crmName} Icon`}
              />
              {['CONNECTED', 'SYNCED'].includes(integration.status) && (
                <CustomChip
                  type={'success'}
                  text={'Connected'}
                  sx={{ padding: '5px 10px' }}
                />
              )}
            </ImageWrapper>
            <ParagraphBoldText
              pt={1}
              fontSize={'16px'}
              color={theme.palette.primary.dark}
            >
              {toCapitalCase(integration.crmName)}
            </ParagraphBoldText>
            <ParagraphLightText
              pt={1}
              minHeight={'48px'}
              fontSize={'12px'}
              lineHeight={'16px'}
              color={theme.palette.primary['charcoalBlack']}
            >
              {integration.desc}
            </ParagraphLightText>
            {['CONNECTED', 'SYNCED'].includes(integration.status) ? (
              <CustomButton
                width={'100%'}
                variant={'outlined'}
                loading={integration.loading}
                borderColor={theme.palette.primary['borderColor']}
                sx={{ marginTop: 3, color: theme.palette.primary.dark }}
                onClick={() => handleViewDetail(integration)}
              >
                View Details
              </CustomButton>
            ) : (
              <CustomButton
                width={'100%'}
                variant={'outlined'}
                loading={integration.loading}
                onClick={() =>
                  integration.status === 'SYNC'
                    ? sync(integration)
                    : handleConnectClick(integration)
                }
                borderColor={theme.palette.primary['borderColor']}
                sx={{ marginTop: 3, color: theme.palette.primary.dark }}
                disabled={['INACTIVE', 'SYNCING', 'COMING_SOON'].includes(
                  integration.status,
                )}
                loadingIndicator={
                  <CircularProgress color="inherit" size={16} />
                }
              >
                {INTEGRATION_STATUS[integration.status]}
              </CustomButton>
            )}
          </CustomCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default CRMCard;
