import React from 'react';
import { Grey2SubLabelText, ParagraphBoldText } from '@components/Typography';
import { CoversationBoxPromptWrapper, ChatBoxContainer } from './styles';
import { getChatDateFormat } from '@common/dateTime';

type ConversationBoxPromptProps = {
  visible_prompt: string;
  time: string | null;
  chatContentRef?: React.MutableRefObject<HTMLDivElement | null>;
};

/**
 * Wraps just the prompt sent to the server
 */
function ConversationBoxPrompt({
  visible_prompt,
  time,
  chatContentRef,
}: ConversationBoxPromptProps): React.JSX.Element {
  return (
    <CoversationBoxPromptWrapper>
      <ChatBoxContainer
        container
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        isSender={true}
        ref={chatContentRef}
        columnGap={2}
        width={'fit-content'}
        style={{ maxWidth: '75%', gap: '16px' }}
      >
        <ParagraphBoldText color={'#333333'} lineHeight={'normal'}>
          {visible_prompt}
        </ParagraphBoldText>

        {time && (
          <Grey2SubLabelText marginTop={'auto'} marginBottom={'auto'}>
            {getChatDateFormat(time)}
          </Grey2SubLabelText>
        )}
      </ChatBoxContainer>
    </CoversationBoxPromptWrapper>
  );
}

export default ConversationBoxPrompt;
