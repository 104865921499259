// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-dropzone-area {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5rem;
  padding-bottom: 2rem;
  border-color: #d0d5dd;
  border-radius: 4px;
  background-color: #fcfdfd;
}

.custom-csv-dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5rem;
  background-color: #fcfdfd;
}

.custom-preview-container-style {
  border-radius: 4px;
  background-color: #e0e4ec;
  padding: 14px 16px;
  margin-top: 1.5rem;
  border: none;
}
.MuiButtonBase-root-MuiChip-root {
  border: none !important;
  font-size: 20px;
}

.custom-preview-item-style {
  font-size: 16px;
  font-weight: 500;
  border: none !important;
}

[class*='MuiDropzoneArea-invalid-'] {
  background-image: repeating-linear-gradient(
    -45deg,
    #f0f0f0,
    #ffffff 25px,
    #f5f5f5 25px,
    #f0f0f0 25px
  ) !important;
  border-color: #333 !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/MyFunds/AddFundDocumentModal/dragger.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,SAAS;EACT,oBAAoB;EACpB,qBAAqB;EACrB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,SAAS;EACT,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE;;;;;;cAMY;EACZ,6BAA6B;AAC/B","sourcesContent":[".custom-dropzone-area {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  gap: 5rem;\n  padding-bottom: 2rem;\n  border-color: #d0d5dd;\n  border-radius: 4px;\n  background-color: #fcfdfd;\n}\n\n.custom-csv-dropzone {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  gap: 5rem;\n  background-color: #fcfdfd;\n}\n\n.custom-preview-container-style {\n  border-radius: 4px;\n  background-color: #e0e4ec;\n  padding: 14px 16px;\n  margin-top: 1.5rem;\n  border: none;\n}\n.MuiButtonBase-root-MuiChip-root {\n  border: none !important;\n  font-size: 20px;\n}\n\n.custom-preview-item-style {\n  font-size: 16px;\n  font-weight: 500;\n  border: none !important;\n}\n\n[class*='MuiDropzoneArea-invalid-'] {\n  background-image: repeating-linear-gradient(\n    -45deg,\n    #f0f0f0,\n    #ffffff 25px,\n    #f5f5f5 25px,\n    #f0f0f0 25px\n  ) !important;\n  border-color: #333 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
