import styled from 'styled-components';
import { Grid, IconButton } from '@mui/material';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { InputStyleProps } from '@components/core/InputField/styles';

export const SearchBoxContainer = styled(Grid)`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 5px;
  transition: 0.2s ease-in;

  border-radius: 8px;
  border: 1px solid #e4e7ec;
  background: #fff;
  box-shadow: 1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset;
`;

export const SearchBoxWrapper = styled(Grid)<any>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  background: #fff;
  border-radius: 8px;
`;

export const TextArea = styled(BaseTextareaAutosize).withConfig({
  shouldForwardProp: prop => !['color', 'theme', 'borderWidth'].includes(prop),
})<InputStyleProps>(
  ({ color, theme, borderWidth = '2px' }) => `
  width: 100%;
  height: auto;
  padding-top: 12px;
  padding-left: 8px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${theme.textBlack};
  outline: 0px;
  border-radius: 8px;
  border: ${borderWidth ?? '2px'} solid ${color ?? theme.borderColor};
  background: white;
  resize: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &:placeholder {
    color: #fff;
    align-items: center;
    padding: 50px;
  }

  &:focus ~ label,
  &:hover ~ label {
    transition: 0.2s ease-in;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    color: #7d7d7d;
  }
`,
);

export const SuffixIcon = styled(IconButton)`
  position: relative;
  padding: 15px;
  width: 50px;
  height: 50px;
`;
