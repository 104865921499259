import React, { lazy } from 'react';
import {
  StreamOutlined as StreamOutlinedIcon,
  HomeOutlined as HomeOutlinedIcon,
} from '@mui/icons-material';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import GroupIcon from '@icons/group';
import IntegrationIcon from '@icons/integration';
import ConversationIcon from '@icons/conversation';
import { AuthUserType } from '@features/Profile/types';
import Layout from 'app/layout';
import Clients from '@pages/Clients';
import Profile from '@pages/Profile';
import HomePage from '@pages/Dashboard/HomePage';
import IntegrationsPage from '@pages/Integrations';
import ConversationPage from '@pages/Conversation';
import Testing from '@pages/Testing';
import TestResults from '@pages/TestResults/TestResultList';
import Tests from '@pages/Tests';
import { getUserAuthDetails } from '@common/auth';
import UserList from '@icons/UserList';
import LayersIcon from '@icons/LayersIcon';
import MyFundsPage from '@pages/MyFunds/MyFundsPage';
import VectorMigration from '@pages/VectorMigration';
import ManageUsers from '@pages/ManageUsers';
import FeedbackHistory from '@pages/FeedbackHistory';

export interface PageVisibilityConfig {
  inMenus: boolean;
  inRoutes: boolean;
  logoInPage: boolean;
  titleInPage: boolean;
  rightSideDrawer: boolean;
}

export interface OpenRoutesProps {
  path: string;
  index: boolean;
  isSecure: boolean;
  redirectTo?: string | null;
  element: React.ComponentType<any>;
}

export interface SecuredRoutesProps {
  name?: string;
  path: string;
  index?: boolean;
  isSecure: boolean;
  visible: PageVisibilityConfig;
  access?: Array<AuthUserType> | null;
  Icon?: React.JSX.Element;
  element: React.ComponentType<any>;
  children?: Array<any>;
}

export const openRoutes: Array<OpenRoutesProps> = [
  {
    path: '/',
    index: false,
    isSecure: false,
    redirectTo: '/login',
    element: lazy(() => import('@components/Creative/Preloader')),
  },
  {
    path: '/login',
    index: true,
    isSecure: false,
    element: lazy(() => import('@pages/LoginPage')),
  },
  {
    path: '/account-activation',
    index: true,
    isSecure: false,
    element: lazy(() => import('@pages/AccountActivation')),
  },
];

export const stateRoutes: Array<OpenRoutesProps> = [
  {
    path: '/404',
    index: false,
    isSecure: false,
    element: lazy(() => import('app/helper/NotFoundPage')),
  },
  {
    path: '/welcome',
    index: true,
    isSecure: true,
    element: lazy(() => import('app/pages/Welcome')),
  },
  {
    path: '/terms-and-conditions',
    index: true,
    isSecure: true,
    element: lazy(() => import('app/pages/TermsAndConditions')),
  },
];

export const securedRoutes: Array<SecuredRoutesProps> = [
  {
    path: '/',
    index: false,
    isSecure: true,
    element: Layout,
    visible: {
      inMenus: false,
      inRoutes: true,
      logoInPage: true,
      titleInPage: true,
      rightSideDrawer: false,
    },
    children: [
      {
        name: 'Home',
        path: '/',
        index: true,
        isSecure: true,
        access: ['ADMIN', 'SUB_ADMIN', 'FEEDBACK_USER'],
        icon: HomeOutlinedIcon,
        element: HomePage,
        visible: {
          inMenus: getUserAuthDetails.userType !== 'SUPER_ADMIN',
          inRoutes: true,
          logoInPage: getUserAuthDetails.userType !== 'SUPER_ADMIN',
          titleInPage: getUserAuthDetails.userType !== 'SUPER_ADMIN',
          rightSideDrawer: getUserAuthDetails.userType !== 'SUPER_ADMIN',
        },
      },
      // This route is reached when the user click on a Suggestion card in the Home page.
      {
        name: 'Conversations',
        path: '/conversations',
        isSecure: true,
        access: ['ADMIN', 'SUB_ADMIN', 'FEEDBACK_USER'],
        icon: ConversationIcon,
        element: ConversationPage,
        visible: {
          inMenus: false,
          inRoutes: true,
          logoInPage: true,
          titleInPage: true,
          rightSideDrawer: true,
        },
      },
      {
        name: 'My Funds',
        path: '/funds',
        index: false,
        isSecure: true,
        access: ['ADMIN', 'SUB_ADMIN', 'FEEDBACK_USER'],
        icon: LayersIcon,
        element: MyFundsPage,
        visible: {
          inMenus: true,
          inRoutes: true,
          logoInPage: false,
          titleInPage: false,
          rightSideDrawer: false,
        },
      },
      {
        name: 'My Clients',
        path: '/clients',
        isSecure: true,
        access: ['ADMIN', 'SUB_ADMIN', 'FEEDBACK_USER'],
        icon: GroupIcon,
        element: Clients,
        visible: {
          inMenus: true,
          inRoutes: true,
          logoInPage: false,
          titleInPage: false,
          rightSideDrawer: false,
        },
      },
      {
        name: 'Integrations',
        path: '/integration',
        isSecure: true,
        hide: false,
        access: ['ADMIN', 'SUB_ADMIN', 'FEEDBACK_USER'],
        icon: IntegrationIcon,
        element: IntegrationsPage,
        visible: {
          inMenus: true,
          inRoutes: true,
          logoInPage: false,
          titleInPage: true,
          rightSideDrawer: false,
        },
      },
      {
        name: 'Integrations',
        path: '/authorize/:crmName',
        isSecure: true,
        hide: false,
        access: ['ADMIN', 'SUB_ADMIN', 'FEEDBACK_USER'],
        icon: IntegrationIcon,
        element: lazy(() => import('@pages/Integrations/IntegrationCallback')),
        visible: {
          inMenus: false,
          inRoutes: true,
          logoInPage: false,
          titleInPage: false,
          rightSideDrawer: false,
        },
      },
      {
        name: 'Manage Users',
        path: '/manage-users',
        index: true,
        isSecure: true,
        access: ['SUPER_ADMIN'],
        icon: UserList,
        element: ManageUsers,
        visible: {
          inMenus: true,
          inRoutes: true,
          logoInPage: false,
          titleInPage: false,
          rightSideDrawer: false,
        },
      },
      {
        name: 'Results',
        path: '/results',
        isSecure: true,
        access: ['SUPER_ADMIN'],
        icon: StreamOutlinedIcon,
        element: Testing,
        visible: {
          inMenus: false,
          inRoutes: false,
          logoInPage: false,
          titleInPage: false,
          rightSideDrawer: false,
        },
      },
      {
        name: 'Feedback History',
        path: '/feed-history',
        index: false,
        isSecure: true,
        access: ['SUPER_ADMIN'],
        icon: LayersIcon,
        element: FeedbackHistory,
        visible: {
          inMenus: true,
          inRoutes: true,
          logoInPage: false,
          titleInPage: false,
          rightSideDrawer: false,
        },
      },
      {
        name: 'Profile & Settings',
        path: '/profile',
        isSecure: true,
        access: ['ADMIN', 'SUB_ADMIN', 'SUPER_ADMIN', 'FEEDBACK_USER'],
        icon: StreamOutlinedIcon,
        element: Profile,
        visible: {
          inMenus: false,
          inRoutes: true,
          logoInPage: false,
          titleInPage: true,
          rightSideDrawer: false,
        },
      },
      {
        name: 'Test Runs',
        path: '/test/results',
        isSecure: true,
        access: ['SUPER_ADMIN'],
        icon: StreamOutlinedIcon,
        element: TestResults,
        visible: {
          inMenus: true,
          inRoutes: true,
          logoInPage: false,
          titleInPage: false,
          rightSideDrawer: false,
        },
      },
      {
        name: 'Test Queries',
        path: '/test/queries',
        isSecure: true,
        access: ['SUPER_ADMIN'],
        icon: StreamOutlinedIcon,
        element: Tests,
        visible: {
          inMenus: false,
          inRoutes: true,
          logoInPage: false,
          titleInPage: false,
          rightSideDrawer: false,
        },
      },
      {
        name: 'Vector Migration',
        path: '/vectormigration',
        isSecure: true,
        access: ['SUPER_ADMIN'],
        icon: AutoModeIcon,
        element: VectorMigration,
        visible: {
          inMenus: true,
          inRoutes: true,
          logoInPage: false,
          titleInPage: false,
          rightSideDrawer: false,
        },
      },
    ],
  },
];

export interface PageConfigType {
  name: string;
  path: string;
  isSecure: boolean;
  access: Array<AuthUserType>;
  visible: PageVisibilityConfig;
}

export interface LayoutMenusType extends PageConfigType {
  icon: Element;
}

export const getLayoutMenus = (
  path: string,
  showInvisible: boolean = false,
): Array<LayoutMenusType> | null =>
  securedRoutes
    .find((l: any) => l.path === path && l.children)
    ?.children?.filter((n: any) => {
      const userType = localStorage.getItem(
        'tifin-ai-user-type',
      ) as AuthUserType;
      const _sI = showInvisible || n.visible.inMenus;
      if (n.visible && _sI && n.access.includes(userType)) {
        return {
          name: n.name,
          path: n.path,
          icon: n.icon,
          access: n.access,
          visible: n.visible,
          isSecure: n.isSecure,
        };
      }
    })
    ?.filter(a => a) ?? null;

export const getPageConfig = (
  root: string,
  pathname: string,
  showInvisible: boolean = false,
): PageConfigType | null =>
  getLayoutMenus(root, showInvisible)
    ?.map((n: any) => ({
      name: n.name,
      path: n.path,
      access: n.access,
      visible: n.visible,
      isSecure: n.isSecure,
    }))
    ?.find((k: any) => k.path === pathname) || null;
