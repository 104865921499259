import { UseQueryResult } from 'react-query';
import useInit from './useInit';
import { IConversation } from '@features/Chat/types';

/**
 * Using ReactQuery, get a list of available conversations. It is a call to /init right now
 */
export default function useConversations(): UseQueryResult<IConversation[]> {
  const ret = useInit();
  const data = ret.data === undefined ? ret.data : ret.data.conversations;
  const qualified = {
    ...ret,
    data,
  } as UseQueryResult<IConversation[]>;
  return qualified;
}
