import React from 'react';
import { Typography, Box, Collapse } from '@mui/material';
import { fontStyles, StyledTableCell, StyledTableRow } from './styles';
import { isEmpty } from 'utils/common/string';

interface PropsType {
  row: any;
  index: number;
  columns: Array<any>;
  baseLineActive: string;
  open: boolean;
}

const RowCollapse = (props: PropsType): React.JSX.Element => {
  const { row, index, open, columns = [], baseLineActive = '' } = props;
  return (
    <StyledTableRow
      sx={{
        '&.MuiTableRow-root': {
          '& .MuiTableCell-root': {
            ...(!open && { borderBottom: 'none' }),
          },
        },
      }}
    >
      <StyledTableCell
        style={{ paddingBottom: 0, paddingTop: 0 }}
        colSpan={columns.length + 2}
      >
        <Collapse in={open} timeout="auto" unmountOnExit sx={{}}>
          <Box
            sx={{
              margin: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: 1.5,
              position: 'relative',
              '&::before': {
                position: 'absolute',
                content: "''",
                height: '1px',
                width: '100%',
                top: '-4%',
              },
            }}
          >
            {columns.map((column, columnIndex) => {
              return (
                <RowCollapseItem
                  key={`td-collapse-${index}-${columnIndex}`}
                  column={column}
                  row={row}
                  baseLineActive={baseLineActive}
                />
              );
            })}
          </Box>
        </Collapse>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default RowCollapse;

interface CollapsePropsType {
  column: any;
  row: any;
  baseLineActive: string;
}

const RowCollapseItem = (props: CollapsePropsType): React.JSX.Element => {
  const { column, row, baseLineActive = '' } = props;
  const [view_more, setViewMore] = React.useState(false);
  if (isEmpty(column.value)) return <React.Fragment />;
  const latency_obj =
    row.results &&
    row.results[column.value] &&
    row.results[column.value][`latency`];
  const Latency = latency_obj ? latency_obj.totalTime : null;
  const preprocessing_time = latency_obj ? latency_obj.preprocessingTime : null;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex' }}>
        <Typography
          component={'div'}
          sx={{
            color: '#464646',
            width: '144px',
            ...fontStyles,
            ...(baseLineActive === column.value && {
              fontWeight: 600,
            }),
          }}
        >
          {column.label}:
        </Typography>
        <Typography
          component={'div'}
          sx={{
            color: '#464646',
            width: '800px',
            wordBreak: 'break-all',
            ...fontStyles,
            ...(baseLineActive === column.value && {
              fontWeight: 400,
            }),
          }}
          dangerouslySetInnerHTML={{
            __html: !isEmpty(row[column.value])
              ? column.value === 'query'
                ? row[column.value]
                : row[column.value]
              : 'NA',
          }}
        />
      </Box>
      {latency_obj && (
        <>
          {Latency && (
            <LatencyLine
              label={'Latency'}
              value={Latency}
              baseLineIsActive={baseLineActive === column.value}
            />
          )}
          {preprocessing_time && (
            <LatencyLine
              label={'Preprocessing time'}
              value={preprocessing_time}
              baseLineIsActive={baseLineActive === column.value}
            />
          )}
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              setViewMore(!view_more);
            }}
          >
            {view_more ? 'View less' : 'View more'}
          </a>
          {view_more && (
            <>
              {Object.keys(latency_obj)
                .filter(
                  key =>
                    ![
                      'totalTime',
                      'preprocessingTime',
                      'queryRequestStartTime',
                      'streamStartTime',
                      'streamEndTime',
                      'user_message',
                    ].includes(key),
                )
                .map(key => {
                  return (
                    <LatencyLine
                      key={key}
                      label={key}
                      value={latency_obj[key]}
                      baseLineIsActive={baseLineActive === column.value}
                    />
                  );
                })}
            </>
          )}
        </>
      )}
    </Box>
  );
};

function LatencyLine({
  label,
  value,
  baseLineIsActive,
}: {
  label: string;
  value: string;
  baseLineIsActive: boolean;
}) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Typography
        component={'div'}
        sx={{
          color: '#464646',
          width: '144px',
          ...fontStyles,
          ...(baseLineIsActive && {
            fontWeight: 600,
          }),
          overflow: 'hidden',
        }}
        title={label}
      >
        {label}:
      </Typography>
      <Typography
        component={'div'}
        sx={{
          color: '#464646',
          width: '800px',
          wordBreak: 'break-all',
          ...fontStyles,
          ...(baseLineIsActive && {
            fontWeight: 400,
          }),
        }}
      >
        {!isEmpty(value) ? value : 'NA'}
      </Typography>
    </Box>
  );
}
