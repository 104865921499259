import { Grid, Switch, Typography, styled } from '@mui/material';

export const Container = styled(Grid)<any>`
  position: relative;
  right: -14px;
  width: 100%;
  height: 100%;
  padding-right: 26px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px !important;
    height: 2px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.grey4} !important;
    border-radius: 20px !important;
    margin-bottom: 10px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
    margin-top: 12px !important;
  }
`;

export const Wrapper = styled(Grid)`
  width: 820px;
  height: 100%;
  margin: 0 auto;
`;

export const HeadingWrap = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
`;

export const ConfigurationItem = styled('div')``;
export const ProfileImg = styled('img')`
  width: 140px;
  height: 124px;
  border-radius: 12px;
`;
export const ProfileWrap = styled('div')`
  display: flex;
  align-items: center;
  gap: 18px;
  margin-bottom: 24px;
`;
export const FileDraggerWrap = styled('div')`
  width: 100%;
  .custom-dropzone {
    min-height: 124px;
    padding-bottom: 0rem;
  }
`;
export const LabelText = styled('label')`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
`;

export const ProfileFormBox = styled(Typography)`
  && {
    & .MuiGrid-root {
      padding-top: 0;
    }
  }
`;

export const CustomSwitch = styled(Switch)`
  && {
    padding: 8px;
    & .MuiSwitch-switchBase {
      padding: 11px;
      &.Mui-checked {
        transform: translateX(20px);
        color: ${({ theme }) => theme.palette.common.white};
        & + .MuiSwitch-track {
          background-color: #101828;
          opacity: 1;
          border: none;
        }
        &.Mui-disabled + .MuiSwitch-track {
          opacity: 0.5;
        }
      }
    }
    & .MuiSwitch-thumb {
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }
    & .MuiSwitch-track {
      border-radius: 20px;
      background-color: #c8c7cd;
      opacity: 1;
      ${({ theme }) =>
        theme.transitions.create(['background-color'], {
          duration: theme.transitions.duration.shortest,
        })};
    }
  }
`;
