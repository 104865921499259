import { Grid, IconButton } from '@mui/material';
import styled from 'styled-components';

export interface InputStyleProps {
  color?: string;
  padding?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  borderWidth?: string;
  borderColor?: string;
  variant?: 'search' | 'input';
}

export const InputBoxContainer = styled(Grid)`
  position: relative;
  width: 100%;
  height: auto;
  transition: 0.2s ease-in;
`;

export const InputField = styled.input.withConfig({
  shouldForwardProp: prop =>
    ![
      'color',
      'theme',
      'padding',
      'fontSize',
      'fontWeight',
      'lineHeight',
      'borderColor',
      'borderWidth',
      'variant',
    ].includes(prop),
})<InputStyleProps>(
  ({
    color,
    theme,
    padding,
    fontSize,
    fontWeight,
    lineHeight,
    borderColor,
    borderWidth = '2px',
    variant = 'input',
  }) => `
  width: 100%;
  height: 100%;
  padding: ${padding ?? '0 40px 0 8px'};
  font-family: Inter;
  font-size: ${fontSize ?? '14px'};
  font-weight: ${fontWeight ?? '400'};
  line-height: ${lineHeight ?? 'normal'};
  color: ${color ?? theme['textBlack']};
  outline: 0px;
  border-radius: ${variant === 'search' ? '8px' : '4px'};
  border: ${borderWidth ?? '2px'} solid ${borderColor ?? theme['borderColor']};
  background: ${theme['solidWhite']};
  box-shadow: 1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset;

  transition:
    border cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
    background cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s;

  &:placeholder {
    color: #fff;
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    border-radius: ${variant === 'search' ? '8px' : '4px'};
    border: ${borderWidth ?? '2px'} solid ${variant === 'search' ? theme['purple'] : theme['main']} !important;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 16px 0px rgba(110, 91, 229, 0.1);
    &:placeholder {
      opacity: 0.5;
    }
  }

  &:hover {
    border-radius: ${variant === 'search' ? '8px' : '4px'};
    border: ${borderWidth ?? '2px'} solid ${variant === 'search' ? theme['lightPurple'] : theme['main']};
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 16px 0px rgba(110, 91, 229, 0.1);
  }

  &:focus ~ label,
  &:hover ~ label {
    transition: 0.2s ease-in;
    font-size: ${fontSize ?? '10px'};
    font-weight: ${fontWeight ?? '600'};
    line-height: ${lineHeight ?? '12px'};
    color: ${theme['grey5']};
  }
`,
);

export const SuffixIcon = styled(IconButton)`
  position: absolute !important;
  top: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto 15px auto auto !important;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  transition: 0.2s ease-in;
`;
