import React from 'react';
import Slide from '@mui/material/Slide';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import {
  DialogContainer,
  DialogTitleWrapper,
  DialogContentContainer,
  DialogContentTextWrapper,
  DialogActionsWrapper,
} from './styles';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AlertDialogProps {
  open: boolean;
  children?: React.ReactElement<any, any>;
  dialogTitle?: React.ReactElement<any, any> | string;
  dialogContent?: React.ReactElement<any, any>;
  dialogContentText?: React.ReactElement<any, any>;
  dialogActions?: React.ReactElement<any, any>[];
  handleClose: () => void;
  maxWidth?: any;
}

function CustomModal({
  open,
  children,
  dialogTitle,
  dialogContent,
  dialogActions,
  dialogContentText,
  handleClose,
  maxWidth,
}: AlertDialogProps): React.ReactElement {
  return (
    <React.Fragment>
      <DialogContainer
        keepMounted
        open={open}
        TransitionComponent={Transition}
        onClose={() => null}
        maxWidth={maxWidth}
      >
        {dialogTitle && (
          <DialogTitleWrapper>
            {dialogTitle}
            <IconButton
              className="close"
              sx={{ zIndex: 1 }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitleWrapper>
        )}
        {dialogContent && (
          <DialogContentContainer>
            {dialogContent}
            {dialogContentText && (
              <DialogContentTextWrapper id="alert-dialog-slide-description">
                {dialogContentText}
              </DialogContentTextWrapper>
            )}
          </DialogContentContainer>
        )}
        {children}
        {dialogActions && (
          <DialogActionsWrapper>{dialogActions}</DialogActionsWrapper>
        )}
      </DialogContainer>
    </React.Fragment>
  );
}

export default CustomModal;
