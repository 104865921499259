import React, { Fragment, useEffect, useState } from 'react';
import { Markup } from 'interweave';
import { Grid, useTheme } from '@mui/material';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

import { TourState } from '@features/Global/types';

import Stats from '@icons/stats';
import HelixAvatar from '@icons/HelixAvatar';
import IntegrationIcon from '@icons/integration';
import {
  ParagraphBoldText,
  ParagraphLightText,
  TextAvatar,
  Grey2SubLabelText,
} from '@components/Typography';
import SearchBox from '@components/SearchBox';
import { FooterContainer } from '@layout/styles';
import ContentDefault from '@pages/Conversation/ContentDefault';

import {
  ImageWrapper,
  AvatarWrapper,
  ChatBoxContainer,
  ChatPageContainer,
  ChatContentContainer,
  ChatBoxContent,
  ChatContentChip,
} from './styles';
import { useProfileDataForLocal } from '@common/auth';

function ChatContent({
  activeScripts = '',
  scripts = [],
}: any): React.JSX.Element {
  const theme = useTheme();
  const [forced_query, setForcedQuery] = useState('');
  const [suggestionChipStyles, setSuggestionChipStyles] = useState({
    icon: <AutoStoriesOutlinedIcon sx={{ marginRight: '10px' }} />,
    borderColor: theme.palette.primary['aquaGreen'],
    background: theme.palette.primary['aquaGreenBackground'],
  });
  const profile = useProfileDataForLocal();

  const getComponentAnimateProps = (componentName: string) => {
    return scripts?.find((k: any) => k.component === componentName);
  };
  const isObjectPresent = (d: any): boolean => d && typeof d === 'object';

  useEffect(() => {
    const d: any = getRecentStateFromProductTour();
    console.log('d : ', d?.component);
    if (d.queryText && d.component === 'ActiveInputField') {
      setForcedQuery(d.queryText);
    }
    if (d.component === 'SendQueryFromInputField') {
      setForcedQuery('');
    }
    switch (activeScripts) {
      case 'eductionScripts':
        setSuggestionChipStyles({
          icon: <AutoStoriesOutlinedIcon />,
          borderColor: theme.palette.primary['aquaGreen'],
          background: theme.palette.primary['aquaGreenBackground'],
        });
        break;
      case 'fundResearchScripts':
        setSuggestionChipStyles({
          icon: <Stats />,
          borderColor: theme.palette.primary['purpleBrand'],
          background: theme.palette.primary['purpleBrandBackground'],
        });
        break;
      case 'clientCommunicationScripts':
        setSuggestionChipStyles({
          icon: <ContentCopyOutlinedIcon />,
          borderColor: theme.palette.primary['yellow2'],
          background: theme.palette.primary['yellowBackground'],
        });
        break;
      case 'marketResearchScripts':
        setSuggestionChipStyles({
          icon: <IntegrationIcon />,
          borderColor: theme.palette.primary['blue2'],
          background: theme.palette.primary['blueBackground'],
        });
        break;
      default:
        break;
    }
  }, [activeScripts, scripts]);

  const onInputSubmit = () => {
    console.log('onInputSubmit...');
  };

  const getRecentStateFromProductTour = (): TourState => {
    return scripts[scripts.length - 1];
  };

  return (
    <Fragment>
      <ChatPageContainer>
        <ChatContentContainer p={2} pb={0}>
          <ChatBoxContainer
            container
            justifyContent={'space-between'}
            alignItems={'flex-start'}
            isSender={false}
            animate={isObjectPresent(
              getComponentAnimateProps('HelixSuggestion'),
            )}
            sx={{
              marginBottom:
                getRecentStateFromProductTour()?.component === 'RenderImage'
                  ? 0
                  : '8px',
              paddingBottom:
                getRecentStateFromProductTour()?.component === 'RenderImage'
                  ? 0
                  : '1rem',
            }}
          >
            {isObjectPresent(getComponentAnimateProps('HelixSuggestion')) && (
              <Grid
                item
                container
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                columnGap={1}
                width={'80%'}
              >
                <AvatarWrapper>
                  <HelixAvatar
                    borderOpacity={1}
                    fill={theme.palette.primary.main}
                  />
                </AvatarWrapper>
                <ChatBoxContent
                  container
                  sm={10}
                  rowGap={2}
                  minHeight={'36px'}
                  justifyContent={'space-evenly'}
                >
                  {getComponentAnimateProps('HelixSuggestion')?.queryType !==
                    'ClientPage' &&
                    getComponentAnimateProps('HelixSuggestion')?.queryType !==
                      'IntegrationPage' && (
                      <ChatContentChip
                        container
                        width={'auto'}
                        alignItems={'center'}
                        borderColor={suggestionChipStyles?.borderColor}
                        background={suggestionChipStyles?.background}
                      >
                        {suggestionChipStyles?.icon}
                        <ParagraphBoldText
                          color={theme.palette.primary['solidWhite']}
                          fontSize={'15px'}
                          fontWeight={500}
                          marginLeft={'5px'}
                        >
                          {
                            getComponentAnimateProps('HelixSuggestion')
                              ?.queryType
                          }
                        </ParagraphBoldText>
                      </ChatContentChip>
                    )}
                  <ParagraphBoldText fontSize={'16px'}>
                    <Markup
                      className={'interweave-markup-container'}
                      content={
                        getComponentAnimateProps('HelixSuggestion')?.queryText
                      }
                    />
                  </ParagraphBoldText>
                  <ParagraphLightText fontSize={'16px'} color={'#667085'}>
                    {getComponentAnimateProps('HelixSuggestion')?.querySubText}
                  </ParagraphLightText>
                </ChatBoxContent>
              </Grid>
            )}
          </ChatBoxContainer>
          {getRecentStateFromProductTour()?.component === 'RenderImage' && (
            <Grid
              item
              container
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
              columnGap={1}
              padding={'0 1rem'}
              width={'100%'}
            >
              <ImageWrapper
                src={require(
                  `@images/${getRecentStateFromProductTour()?.querySubText}`,
                )}
              />
            </Grid>
          )}
          <ChatBoxContainer
            container
            isSender={true}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
            animate={isObjectPresent(
              getComponentAnimateProps('RequestedQuery'),
            )}
          >
            {isObjectPresent(getComponentAnimateProps('RequestedQuery')) && (
              <>
                <Grid
                  item
                  container
                  justifyContent={'flex-start'}
                  alignItems={'flex-start'}
                  columnGap={1}
                  width={'calc(100% - 108px)'}
                >
                  <AvatarWrapper>
                    <TextAvatar
                      container
                      flexDirection={'column'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      {profile.initialName || 'A'}
                    </TextAvatar>
                  </AvatarWrapper>
                  <ChatBoxContent
                    container
                    sm={10}
                    rowGap={2}
                    minHeight={'36px'}
                    justifyContent={'space-evenly'}
                  >
                    <ContentDefault
                      type={'text'}
                      data={
                        getComponentAnimateProps('RequestedQuery')?.queryText
                      }
                      isSender={false}
                    />
                  </ChatBoxContent>
                </Grid>
                <Grid
                  container
                  width={'auto'}
                  minHeight={'36px'}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                >
                  <Grey2SubLabelText>4:38PM</Grey2SubLabelText>
                </Grid>
              </>
            )}
          </ChatBoxContainer>

          <ChatBoxContainer
            container
            isSender={false}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
            animate={isObjectPresent(
              getComponentAnimateProps('ResponsedTable'),
            )}
          >
            {isObjectPresent(getComponentAnimateProps('ResponsedTable')) && (
              <>
                <Grid
                  item
                  container
                  justifyContent={'flex-start'}
                  alignItems={'flex-start'}
                  columnGap={1}
                  width={'calc(100% - 108px)'}
                >
                  <AvatarWrapper>
                    <HelixAvatar
                      borderOpacity={1}
                      fill={theme.palette.primary.main}
                    />
                  </AvatarWrapper>
                  <ChatBoxContent
                    container
                    sm={10}
                    rowGap={2}
                    minHeight={'36px'}
                    justifyContent={'space-evenly'}
                  >
                    <ContentDefault
                      type={'table'}
                      headers={[]}
                      data={
                        getComponentAnimateProps('ResponsedTable')?.tableData
                      }
                      isSender={false}
                    />
                  </ChatBoxContent>
                </Grid>
                <Grid
                  container
                  width={'auto'}
                  minHeight={'36px'}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                >
                  <Grey2SubLabelText>4:38PM</Grey2SubLabelText>
                </Grid>
              </>
            )}
          </ChatBoxContainer>

          <ChatBoxContainer
            container
            isSender={false}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
            animate={isObjectPresent(getComponentAnimateProps('ResponsedText'))}
          >
            {isObjectPresent(getComponentAnimateProps('ResponsedText')) && (
              <>
                <Grid
                  item
                  container
                  justifyContent={'flex-start'}
                  alignItems={'flex-start'}
                  columnGap={1}
                  width={'calc(100% - 108px)'}
                >
                  <AvatarWrapper>
                    <HelixAvatar
                      borderOpacity={1}
                      fill={theme.palette.primary.main}
                    />
                  </AvatarWrapper>
                  <ChatBoxContent
                    container
                    sm={10}
                    rowGap={2}
                    minHeight={'36px'}
                    justifyContent={'space-evenly'}
                  >
                    <ContentDefault
                      type={'text'}
                      data={
                        getComponentAnimateProps('ResponsedText')?.queryText
                      }
                      isSender={false}
                    />
                  </ChatBoxContent>
                </Grid>
                <Grid
                  container
                  width={'auto'}
                  minHeight={'36px'}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                >
                  <Grey2SubLabelText>4:38PM</Grey2SubLabelText>
                </Grid>
              </>
            )}
          </ChatBoxContainer>
        </ChatContentContainer>

        <FooterContainer container alignItems={'flex-end'} pb={1}>
          <SearchBox
            type={'input'}
            borderWidth={'3px'}
            color={theme.palette.primary['main']}
            isStreaming={false}
            onInputSubmit={onInputSubmit}
            colored={true}
            force_query={forced_query}
          />
        </FooterContainer>
      </ChatPageContainer>
    </Fragment>
  );
}

export default React.memo(ChatContent);
