import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UsersState } from './types';

export const initialState: UsersState = {
  loading: false,
  data: [],
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadUsersListRequest(_state, _action: PayloadAction<any>) {},
    loadUsersListSuccess(
      state: any,
      action: PayloadAction<{ data?: UsersState | any; loading: boolean }>,
    ) {
      return {
        ...state,
        data: action?.payload?.data,
        loading: false,
      };
    },

    addNewAdvisorRequest() {},
    addNewAdvisorFetch(state) {
      state.loading = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addNewAdvisorSuccess(state: any, _action: PayloadAction<any>) {
      state.loading = false;
    },
    resetUsersState(state) {
      return {
        ...state,
        data: [],
        loading: false,
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = usersSlice;
