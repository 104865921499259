import React, { useState } from 'react';
import { Box } from '@mui/material';
import styled, { css, keyframes } from 'styled-components';
import { PrimaryButton } from '@components/Button';
import { ReactComponent as PromptVector } from '@images/promptVector.svg';

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const RotatingPromptVector = styled(PromptVector).withConfig({
  shouldForwardProp: prop => !['isHovered', 'fill'].includes(prop),
})<{
  isHovered: boolean;
  fill: string;
}>`
  /* Apply animation to the icon */
  transition: transform 0.3s;
  ${props =>
    props.isHovered &&
    css`
      transform: rotate(360deg);
      animation: ${css`
        ${rotateAnimation} 2s linear infinite
      `};
    `}
`;

interface CustomCellProps {
  style?: any;
  query?: string;
  label?: string;
  buttonProps?: any;
  disabled?: boolean;
  containerStyle?: any;
  buttonWidth?: string;
  onClick: () => void;
}

const ExplorerWithAIButton = ({
  style = {},
  label = 'Explore with AI',
  disabled = false,
  buttonProps = {},
  containerStyle = {},
  onClick,
}: CustomCellProps): React.JSX.Element => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box sx={containerStyle}>
      <PrimaryButton
        isHover
        variant="outlined"
        textColor="#2C3748"
        customColor="transparent"
        {...buttonProps}
        disabled={disabled}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
        sx={{
          color: isHovered ? '#FFFFFF' : '#2C3748',
          ...style,
        }}
        startIcon={
          <RotatingPromptVector
            isHovered={isHovered}
            fill={isHovered ? 'white' : 'inherit'}
          />
        }
      >
        {label}
      </PrimaryButton>
    </Box>
  );
};

export default ExplorerWithAIButton;
