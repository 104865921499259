import { Box, Grid } from '@mui/material';
import styled from 'styled-components';
export const AppContainer = styled(Grid)``;

export const Header = styled(Grid)`
  padding: 14px 16px;
  display: flex;
  align-items: center !important;
  gap: 12px;
  justify-content: space-between;
`;

export const ContentWrapper = styled(Grid)<{
  maxHeight?: number;
}>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  padding: 2px 15px 32px 15px;
  overflow-y: auto;
  max-height: ${props => props?.maxHeight || 80}px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #999999;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const PopupHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const DataProviderCardHeader = styled('div')<{
  display?: string;
  alignItems?: string;
  gap?: string;
}>`
  display: ${props => props?.display ?? ''};
  align-items: ${props => props?.alignItems ?? ''};
  gap: ${props => props?.gap ?? ''};
`;
export const ChipBox = styled(Box)`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  justify-content: end;
`;

export const FundsBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
