import styled from 'styled-components';
import { Box, Modal, FormHelperText } from '@mui/material';
import { PrimaryButton, TextButton } from '@components/Button';
import Input from '@components/core/InputField';

export const ModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PopupHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InputField = styled(Input)<any>`
  border-radius: 4px !important;
  box-shadow: 1px 2px 2px 0px rgba(16, 24, 40, 0.12) inset;
`;

export const Button = styled(PrimaryButton)<any>`
  height: 40px;
  color: #fcfdfd;
  text-transform: capitalize !important;
  border-radius: 4px;
  background: linear-gradient(
      180deg,
      rgba(0, 87, 255, 0.2) 0%,
      rgba(0, 0, 0, 0) 135%
    ),
    #2c3748 !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12) !important;
`;

export const ErrorMessage = styled(FormHelperText)<any>`
  position: absolute;
  bottom: 10px;
  left: 0;
  margin: 0 !important;
`;

export const CriteriaOption = styled(TextButton)<{ customColor: string }>`
  min-width: 185px;
  color: ${props => props.customColor} !important;
  text-transform: none !important;
  border-color: transparent !important;
  background: ${props => props.theme.solidWhite} !important;
  &:hover {
    background: ${props => props.theme.solidWhite} !important;
  }
`;
