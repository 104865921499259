import React from 'react';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

type ScrollButtonProps = {
  style?: any;
  direction: 'up' | 'down';
  onClick: () => void;
};

const ScrollButton: React.FC<ScrollButtonProps> = ({
  style,
  direction,
  onClick,
}) => {
  return (
    <IconButton
      color="primary"
      sx={{
        position: 'absolute',
        bottom: '75px',
        right: '5px',
        ...style,
      }}
      onClick={onClick}
    >
      {direction === 'up' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
  );
};

export default React.memo(ScrollButton);
