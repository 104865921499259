/* eslint-disable react/prop-types */

import React from 'react';

function AUM(): React.JSX.Element {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="5.50175" fill="#FFF4E3" />
      <path
        d="M27.5088 30.8772C33.7099 30.8772 38.7369 28.615 38.7369 25.8245C38.7369 23.0341 33.7099 20.7719 27.5088 20.7719C21.3077 20.7719 16.2808 23.0341 16.2808 25.8245C16.2808 28.615 21.3077 30.8772 27.5088 30.8772Z"
        stroke="#FFA32C"
        strokeWidth="2.69474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2808 25.8245V31.4386C16.2808 34.2315 21.3053 36.4912 27.5088 36.4912C33.7123 36.4912 38.7369 34.2315 38.7369 31.4386V25.8245"
        stroke="#FFA32C"
        strokeWidth="2.69474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.0176 30.4562V36.0702"
        stroke="#FFA32C"
        strokeWidth="2.69474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.7371 27.6071C43.8599 28.0842 47.7196 30.1193 47.7196 32.5614C47.7196 35.3544 42.695 37.6141 36.4915 37.6141C33.7406 37.6141 31.2143 37.165 29.2634 36.4351"
        stroke="#FFA32C"
        strokeWidth="2.69474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.2634 36.393V38.1754C25.2634 40.9684 30.288 43.2281 36.4915 43.2281C42.695 43.2281 47.7196 40.9684 47.7196 38.1754V32.5614"
        stroke="#FFA32C"
        strokeWidth="2.69474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.9824 37.193V42.807"
        stroke="#FFA32C"
        strokeWidth="2.69474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.0002 30.4562V42.8071"
        stroke="#FFA32C"
        strokeWidth="2.69474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoConnectIcon = React.memo(AUM);
export default MemoConnectIcon;
