/* eslint-disable react/prop-types */
import * as React from 'react';

function CircleAddIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      style={props.style}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 6.03125H8.0625"
        stroke="#101828"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.03125 4V8.0625"
        stroke="#101828"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
        stroke="#101828"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoNoData = React.memo(CircleAddIcon);
export default MemoNoData;
