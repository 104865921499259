import {
  AuthDetailsProps,
  AUTH_USER_TYPES,
  AuthUserType,
} from '@features/Profile/types';
import useCurrentUser from '@helper/CustomHooks/useCurrentUser';
import { ICurrentUserData } from 'app/AppContext';

export interface UserDetails extends AuthDetailsProps {
  advisorId: string | null;
  skipCache: string | null;
  loggedUser: string | null;
}

export const isValidAuthUserType = (
  userType: string,
): userType is AuthUserType => {
  return AUTH_USER_TYPES.includes(userType as AuthUserType);
};

export interface ProfileDataProps extends AuthDetailsProps {
  id: string;
  name: string;
  email: string;
  firstName: string;
  lastName: string;
  initialName: string;
  userId: string;
  advisorId: string;
  enterpriseId: string;
  dataProviderId: string;
}

const convertToProfileData = (data: ICurrentUserData): ProfileDataProps => {
  const {
    email,
    id,
    user_id,
    advisor_id,
    enterprise_id,
    data_provider_id,
    name: srcName,
  } = data;
  const name: string = srcName
    ? srcName
    : email && email.length > 0
      ? email?.split('@').length > 0
        ? email?.split('@')[0]
        : email[0]
      : '';
  const splitName: Array<string> = name?.split(' ');
  const firstName: string = splitName ? splitName[0] : '';
  const lastName: string = splitName ? splitName[1] : '';
  const _f: string = firstName?.length > 1 ? firstName[0] : '';
  const _l: string = lastName?.length > 1 ? lastName[0] : '';
  const initialName: string = _f && _l ? `${_f}${_l}` : '';

  return {
    name,
    email,
    firstName,
    lastName,
    initialName,
    id,
    userId: user_id,
    advisorId: advisor_id,
    enterpriseId: enterprise_id,
    dataProviderId: data_provider_id,
  };
};

// Deprecated, move this to useCurrentUser()
export const useProfileDataForLocal = (): ProfileDataProps => {
  const { current_user_data } = useCurrentUser();
  if (!current_user_data) {
    throw new Error('current_user_data is not present yet');
  }
  return convertToProfileData(current_user_data);
};
