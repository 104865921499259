import styled from 'styled-components';
import { ButtonUnstyled as Button } from '@mui/core';

const OutlineButton = styled(Button).withConfig({
  shouldForwardProp: prop => prop !== 'theme',
})`
  font-family: 'Inter', sans-serif;
  border: 1px solid ${props => props.theme.main};
  background-color: #fff;
  color: ${props => props.theme.main};
  display: flex;
  text-transform: none;
  min-width: 90px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 6px 20px;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
    color: #98a2b3;
    background-color: #f2f4f7;
  }
`;

export default OutlineButton;
