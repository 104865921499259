import React from 'react';

import { BorderWrapper } from './styles';
import SuggestionCard from '@components/SuggestionCard';
import { SuggestionListJSON } from '@common/constant';
import { Grid } from '@mui/material';
import { motion } from 'framer-motion';

interface SuggestionListProps {
  variants?: any;
}

/**
 * In the Home page, this shows 6 hardcoded buttons that link to some categories.
 */
function SuggestionList({ variants }: SuggestionListProps): React.JSX.Element {
  return (
    <>
      <BorderWrapper />
      <Grid container spacing={2}>
        {SuggestionListJSON.map(data => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={data?.id}>
            <motion.div key={data.id} variants={variants}>
              <motion.div
                key={crypto.randomUUID()}
                whileHover={{ backgroundColor: '#f0f0f0', scale: 1.05 }}
                transition={{ duration: 0.2 }}
              >
                <SuggestionCard
                  id={data.id}
                  prompt={data.prompt}
                  category={data.category}
                />
              </motion.div>
            </motion.div>
          </Grid>
        ))}
      </Grid>
      <BorderWrapper top={16} bottom={24} />
    </>
  );
}

export default SuggestionList;
