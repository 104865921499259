import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, useTheme } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SearchBox from '@components/SearchBox';
import { SecondaryButton } from '@components/Button';
import { ParagraphLightText } from '@components/Typography';
import { actions as chatActions } from '@features/Chat/slice';
import { actions as globalActions } from '@features/Global/slice';
import { getConversationsState } from '@features/Chat/selector';
import GlitterIcon from '@icons/glitter';
import LinkIcon from '@icons/LinkIcon';
import { getProfileDataForLocal } from '@common/auth';
import { FooterContainer } from '@layout/styles';
import { Container } from './styles';
import SuggestionList from './SuggestionList';
import { motion } from 'framer-motion';
import useGroupedInsights from '@helper/CustomHooks/useGroupedInsights';

const listVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, x: -10 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.3 },
  },
};

function HomePage(): React.JSX.Element {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { conversation_history: initHistoryState } = useSelector(
    getConversationsState,
  );
  const UserDetails = getProfileDataForLocal();
  // Preload them here
  useGroupedInsights();

  useEffect(() => {
    dispatch(globalActions.getUserConfigRequest());
    if (initHistoryState?.length === 0) {
      dispatch(chatActions.loadInitialChatHistory());
    }
  }, []);

  const onInputSubmit = (query: string) => {
    navigate('/conversations');
    dispatch(chatActions.searchQueryRequest({ query }));
  };

  const takeProductTour = () => {
    dispatch(globalActions.startProductTourRequest());
  };

  const gotoConversationsHistory = () => {
    navigate('/conversations', { state: { scroll: 1 } });
  };

  if (localStorage.getItem('terms-and-conditions') !== 'accepted') {
    navigate('/terms-and-conditions');
  }
  return (
    <Container
      container
      flexDirection={'column'}
      alignItems={'flex-start'}
      justifyContent={'flex-end'}
    >
      <motion.div
        variants={listVariants}
        initial="hidden"
        animate="visible"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-end',
        }}
      >
        <Grid>
          <motion.div key={'user'} variants={itemVariants}>
            <ParagraphLightText
              fontSize={'20px'}
              color={'#667085'}
              sx={{ paddingBottom: 1 }}
            >
              Welcome {UserDetails?.firstName},
            </ParagraphLightText>
          </motion.div>

          <motion.div key={'welcome'} variants={itemVariants}>
            <ParagraphLightText
              fontSize={'32px'}
              color={'#012E4C'}
              fontWeight={600}
              sx={{ paddingBottom: 2 }}
            >
              How can I help you today?
            </ParagraphLightText>
          </motion.div>
        </Grid>

        <SuggestionList variants={itemVariants} />

        <Grid container spacing={2}>
          <Grid item>
            <motion.div key={'tour'} variants={itemVariants}>
              <motion.div
                key={crypto.randomUUID()}
                whileHover={{ backgroundColor: '#f0f0f0', scale: 1.05 }}
                transition={{ duration: 0.2 }}
              >
                <SecondaryButton
                  onClick={takeProductTour}
                  sx={{
                    textTransform: 'none',
                    color: '#101828',
                  }}
                  startIcon={<GlitterIcon />}
                >
                  Take the product tour again
                </SecondaryButton>
              </motion.div>
            </motion.div>
          </Grid>

          <Grid item>
            <motion.div key={'connect'} variants={itemVariants}>
              <motion.div
                key={crypto.randomUUID()}
                whileHover={{ backgroundColor: '#f0f0f0', scale: 1.05 }}
                transition={{ duration: 0.2 }}
              >
                <SecondaryButton
                  onClick={() => navigate('/integration')}
                  sx={{
                    textTransform: 'none',
                    color: '#101828',
                  }}
                  startIcon={<LinkIcon />}
                >
                  Help me connect my client data
                </SecondaryButton>
              </motion.div>
            </motion.div>
          </Grid>

          {initHistoryState.length > 1 && (
            <Grid item>
              <motion.div key={'conversation'} variants={itemVariants}>
                <motion.div
                  key={crypto.randomUUID()}
                  whileHover={{ backgroundColor: '#f0f0f0', scale: 1.05 }}
                  transition={{ duration: 0.2 }}
                >
                  <SecondaryButton
                    sx={{
                      marginBottom: 0,
                      textTransform: 'none',
                      color: '#101828',
                    }}
                    endIcon={<ArrowForwardIcon />}
                    onClick={gotoConversationsHistory}
                  >
                    Click here to continue your previous conversation
                  </SecondaryButton>
                </motion.div>
              </motion.div>
            </Grid>
          )}
        </Grid>

        {UserDetails.userType !== 'SUPER_ADMIN' && (
          <FooterContainer container alignItems={'flex-end'} pb={1}>
            <SearchBox
              type={'input'}
              variant={'search'}
              borderWidth={'3px'}
              color={theme.palette.primary['main']}
              isStreaming={false}
              onInputSubmit={onInputSubmit}
            />
          </FooterContainer>
        )}
      </motion.div>
    </Container>
  );
}

export default HomePage;
