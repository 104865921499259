/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ApiInstance from 'utils/instances';

type IGetUserInfoReply = {
  advisorId: string;
  userId: string;
  oktaUid: string;
  id: string;
  name: string;
  role: string;
  email: string;
  apiKey: string;
  enterpriseId: string;
  dataProviderId: string;
  apiKeyGeneratedAt: string;
  createdAt: string;
  tone: boolean;
  tAndC: boolean;
  feedback: boolean;
  skipCache: boolean;
  crmIntegrations: boolean;
  isProductTourViewed: boolean;
  custodialIntegration: boolean;
};
export const getUserInfo = async (
  token: string,
): Promise<IGetUserInfoReply> => {
  return await ApiInstance.get(`/user/userInfo`, {
    headers: {
      'x-tifin-ai-auth': token,
    },
  }).then(res => res.data);
};

export const logoutUser = async (request: any): Promise<any> => {
  const { payload, headers } = request;
  return await ApiInstance.post(`/user/logout`, payload, { headers }).then(
    res => res.data,
  );
};

export const activateAccount = async ({ payload, headers }): Promise<any> => {
  return await ApiInstance.post(`/user/updatePassword`, payload, {
    headers,
  })
    .then(res => res.data)
    .catch(({ response }: any) => response);
};

export const resetPasswordApi = async ({ payload }): Promise<any> => {
  return await ApiInstance.post(`/user/resetPassword`, payload)
    .then(res => res.data)
    .catch(({ response }: any) => response);
};

export const verifyHash = async (hashKey: string): Promise<any> => {
  if (!hashKey) {
    throw new Error('Hash key is required');
  }
  console.log('Headers', hashKey);
  return await ApiInstance.get(`/user/verify`, {
    headers: {
      'activation-hashkey': hashKey,
    },
  })
    .then(res => res.data)
    .catch(({ response }: any) => response);
};
