/* eslint-disable react/prop-types */
import * as React from 'react';

function SectionDivider(
  props: React.SVGProps<SVGSVGElement>,
): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M9.42383 2.6875L6.57682 13.3127"
        stroke={props.fill || '#98A2B3'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoNoData = React.memo(SectionDivider);
export default MemoNoData;
