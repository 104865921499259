import styled from 'styled-components';
import { Grid } from '@mui/material';
import { LayoutSize } from '@styles/variables';

export const ContentDrawerContainer = styled(Grid)<any>`
  width: 100%;
  height: 100%;
`;

export const ContentDrawerWrapper = styled(Grid)<any>`
  width: 100%;
  height: ${LayoutSize.drawer.scrollableHeight};
  overflow: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px !important;
    height: 2px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.lightGrey} !important;
    border-radius: 20px !important;
    margin-bottom: 10px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
    margin-top: 12px !important;
  }
`;

export const CardContainer = styled(Grid).withConfig({
  shouldForwardProp: prop => prop !== 'theme',
})<any>`
  height: 100px;
  padding: 1rem;
  border-radius: 12px;
  border: 1px solid ${props => props.theme.borderColor};
  background: ${props => props.theme.solidWhite};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
`;

export const GridContainer = styled(Grid)<any>`
  .MuiDivider-root {
    &:before {
      display: none;
    }
  }
`;

export const GridWrapper = styled(Grid)<any>``;
