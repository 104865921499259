import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress, Grid } from '@mui/material';
import { actions as dataProvidersAction } from '@features/DataProviders/slice';

import { SubHeading } from '@components/Typography';
import DataProviderCard from '@components/Card/DataProvider';
import AddRoundIcon from '@icons/AddRoundIcon';

import DataCard from '../DataCard';
import AddModal from '../AddModal';
import { ContentWrapper } from '../styles';

interface PageProps {
  onSelect: (event: Event) => void;
  userProviderList?: any;
  loading?: boolean;
}

function Dataproviders({
  onSelect,
  userProviderList,
  loading,
}: PageProps): React.JSX.Element {
  const dispatch = useDispatch();
  const [openModal, handleModalState] = useState(false);

  const handlePopupOpen = () => {
    handleModalState(!openModal);
  };

  const handleCloseModal = () => {
    handleModalState(false);
  };

  const handleSubmit = (formData: any) => {
    console.log('formData : ', formData);
    dispatch(
      dataProvidersAction.addDataProviderRequest({
        ...formData,
      }),
    );
  };

  return (
    <Grid item sm={12}>
      <ContentWrapper>
        {loading ? (
          <DataProviderCard
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid sx={{ marginBottom: '24px' }}>
              <CircularProgress />
            </Grid>
          </DataProviderCard>
        ) : (
          userProviderList.data.map((data: any, index) => (
            <DataCard key={index} data={data} onClick={() => onSelect(data)} />
          ))
        )}

        <DataProviderCard alignItems="center" onClick={handlePopupOpen}>
          <Grid sx={{ marginBottom: '24px' }}>
            <AddRoundIcon />
          </Grid>
          <SubHeading>Add Data Provider</SubHeading>
        </DataProviderCard>
      </ContentWrapper>

      {openModal && (
        <AddModal
          title={'Data Provider'}
          onClose={handleCloseModal}
          handleSubmit={handleSubmit}
        />
      )}
    </Grid>
  );
}

export default Dataproviders;
