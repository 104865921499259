import {
  AllEffect,
  ForkEffect,
  all,
  call,
  put,
  takeLatest,
  Effect,
} from 'redux-saga/effects';
import { getQueries } from './api';
import { actions } from './slice';

function* loadQueries(): Generator<Effect, void, any[]> {
  try {
    const response: any = yield call(getQueries);
    if (response) {
      const queries = [...response.data];
      yield put(actions.loadQueriesSuccess(queries));
    }
  } catch (error) {
    yield put(actions.loadQueriesError());
  }
}

export function* queriesSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    // Define your sagas here
    takeLatest(actions.loadQueriesRequest.type, loadQueries),
  ]);
}
