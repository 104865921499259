import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';

interface Option {
  value: string;
  label: string;
  disabled?: boolean;
}

interface CustomizeSelectProps {
  id?: string;
  name?: string;
  title?: string;
  options?: Option[];
  value: any;
  onChange: (
    event: SelectChangeEvent<{ value: string }>,
    child: React.ReactNode,
  ) => void;
  onBlur?: () => void;
  width?: string;
  height?: string;
  placeholder?: string;
}

const CustomizeSelect: React.FC<CustomizeSelectProps> = ({
  id = 'input-select-' + Math.round(Math.random() * 123456),
  options = [],
  value,
  onChange,
  onBlur = () => {},
  width = '100%',
  height = '40px',
  placeholder,
  name,
}) => {
  return (
    <Select
      labelId={`${id}-label`}
      id={id}
      name={name}
      value={value}
      label=""
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      sx={{
        '&.MuiInputBase-root': {
          '&.MuiOutlinedInput-root': {
            width: width,
            height: height,
          },
        },
      }}
    >
      {options &&
        options.map(({ label, value, disabled = false }) => {
          return (
            <MenuItem
              key={`MenuItem-${value}`}
              value={value}
              disabled={disabled}
            >
              {label}
            </MenuItem>
          );
        })}
    </Select>
  );
};

export default React.memo(CustomizeSelect);
