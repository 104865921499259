import { AllEffect, all, call, put, takeLatest } from 'redux-saga/effects';
import { getAllFeedback, getChatHistoryFilter } from './api';
import { actions } from './slice';
import { actions as globalActions } from '@features/Global/slice';

function* getAllFeedbackList(): Generator<any, void, any> {
  try {
    yield put(actions.loadFeedbackHistoryFetch());
    const response: any = yield call(getAllFeedback);

    if (!response || (response.data && !response.data.length)) {
      throw new Error('Failed to fetch Feedback list');
    }
    const sortedData = response.data.sort(
      (a: any, b: any) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );
    yield put(
      actions.loadFeedbackHistorySuccess({
        data: sortedData || [],
      }),
    );
  } catch (error) {
    console.log(error);
    yield put(actions.loadFeedbackHistorySuccess({}));
  }
}

function* getChatHistoryFilterList(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    yield put(actions.loadFeedbackHistoryFilterFetch());
    const response: any = yield call(getChatHistoryFilter, payload);
    if (!response || response.error) {
      throw new Error('Failed to fetch the chat history');
    }
    if (!response.data.length) {
      yield put(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage: 'No records found',
        }),
      );
    }
    const sortedData = response.data.sort(
      (a: any, b: any) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );
    yield put(actions.loadFeedbackHistoryFilterSuccess({ data: sortedData }));
    if (payload.callback && typeof payload.callback === 'function') {
      payload.callback(response);
    }
  } catch (error) {
    yield put(
      globalActions.displayToast({
        duration: 3000,
        toastType: 'error',
        toastMessage: 'Failed to fetch',
      }),
    );
    yield put(
      actions.loadFeedbackHistoryFilterSuccess({ filterLoading: false }),
    );
  }
}

export function* feedbackHistorySaga(): Generator<
  AllEffect<any>,
  void,
  unknown
> {
  yield all([
    takeLatest(actions.loadFeedbackHistoryRequest.type, getAllFeedbackList),
    takeLatest(
      actions.loadFeedbackHistoryFilterRequest.type,
      getChatHistoryFilterList,
    ),
  ]);
}
