import { CustomizeSelect } from '@components/core';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ContentWrapper, LabelText } from './styles';
import { PrimaryButton } from '@components/Button';
import StatsList from './StatsList';
import { PageHeading } from '@components/Typography';
import ProcessingMethods from './ProcessingMethods';
import { useDispatch, useSelector } from 'react-redux';
import { actions as statsListActions } from '@features/VectorMigration/slice';
import { getVectorMigrationStreamData } from 'app/features/VectorMigration/selector';
import { actions as migrationActions } from '@features/VectorMigration/slice';
import { actions as globalActions } from 'app/features/Global/slice';

const optionsList = [
  { label: 'DEV', value: 'DEV' },
  { label: 'DEMO', value: 'DEMO' },
  { label: 'PROD', value: 'PROD' },
];

const VectorMigration: React.FC = () => {
  const dispatch = useDispatch();
  const [formValue, setFormValue] = useState({
    from: '',
    to: '',
  });
  const { migrationStreamData, statsList, statsLoading } = useSelector(
    getVectorMigrationStreamData,
  );
  useEffect(() => {
    if (!!formValue.from && !!formValue.to && formValue.from === formValue.to) {
      dispatch(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'error',
          toastMessage: 'The From and To values should not be the same.',
        }),
      );
    }
  }, [formValue]);

  const handleStatsList = (formName, formType) => {
    const payload = {
      type: formType,
      formName,
    };
    dispatch(statsListActions.getStatsList(payload));
  };

  const handleForm = ({ target: { name, value } }) => {
    setFormValue(prev => {
      return {
        ...prev,
        [name]: value,
      };
    });
    handleStatsList(name, value);
  };

  const handleSubmit = () => {
    if (formValue.from !== formValue.to) {
      const payload = {
        source: formValue.from,
        target: formValue.to,
      };
      dispatch(migrationActions.searchQueryRequest(payload));
    }
  };
  useEffect(() => {
    return () => {
      handleRest();
    };
  }, []);
  const handleRest = () => {
    setFormValue({
      from: '',
      to: '',
    });
    dispatch(migrationActions.restMigration());
  };

  return (
    <ContentWrapper>
      <Grid
        item
        container
        xs={12}
        pb={3}
        flexDirection="row"
        justifyContent="flex-end"
      >
        <Grid item xs={3} pr={3}>
          <PrimaryButton
            customColor="#FCFDFD"
            textColor="#101828"
            width="100%"
            sx={{ marginRight: '10px' }}
            onClick={handleRest}
            disabled={!formValue.from || !formValue.to}
          >
            Cancel
          </PrimaryButton>
        </Grid>
        <Grid item container xs={3}>
          <PrimaryButton
            width="100%"
            onClick={handleSubmit}
            disabled={
              !formValue.from ||
              !formValue.to ||
              !!migrationStreamData.length ||
              formValue.from === formValue.to
            }
          >
            Confirm
          </PrimaryButton>
        </Grid>
      </Grid>
      <Grid item container xs={6} pt={0} pr={3}>
        <LabelText marginBottom="4px">From</LabelText>
        <CustomizeSelect
          name="from"
          value={formValue.from}
          options={optionsList}
          onChange={handleForm}
        />
      </Grid>
      <Grid item container xs={6} pt={0}>
        <LabelText marginBottom="4px">To</LabelText>
        <CustomizeSelect
          name="to"
          value={formValue.to}
          options={optionsList}
          onChange={handleForm}
        />
      </Grid>
      {(!!formValue.from || !!formValue.to) && (
        <Grid item container xs={12} pt={2}>
          <Grid item container xs={12} pb={2}>
            <PageHeading>Stats</PageHeading>
          </Grid>
          {!!formValue.from && (
            <Grid item container xs={6} pr={2}>
              <StatsList
                statsDetails={statsList.from}
                isLoading={statsLoading.from}
              />
            </Grid>
          )}
          {!!formValue.to && (
            <Grid item container xs={6}>
              <StatsList
                statsDetails={statsList.to}
                isLoading={statsLoading.to}
              />
            </Grid>
          )}
        </Grid>
      )}
      {!!migrationStreamData.length && <ProcessingMethods />}
    </ContentWrapper>
  );
};

export default VectorMigration;
