import React from 'react';
import { DotPulseContainer } from './styles';
import { useTheme } from 'styled-components';

// Include all styled props for Do
type PulseLoaderProps = {
  style?: object;
  color?: string;
};

function PulseLoader({
  style = {},
  color = '',
}: PulseLoaderProps): React.JSX.Element {
  const theme = useTheme();
  return <DotPulseContainer style={style} color={color || theme.darkBlue3} />;
}

export default PulseLoader;
