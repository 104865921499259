/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { getDateTime } from 'utils/common/dateTime';
import {
  BackendHistoricChat,
  HistoricConversation,
  BackendHistoricChatNode,
  StreamedNode,
  StreamingConversation,
} from './types';
// import { parseData } from 'utils/common/string';

export const convertBackendHistoricChat = (
  chat: BackendHistoricChat,
): HistoricConversation | null => {
  try {
    return {
      visible_prompt: chat.query,
      nodes:
        typeof chat.response === 'string'
          ? [{ type: 'text', data: 'Unsupported' }]
          : chat.response.data.map(convertBackendHistoricChatNode),
      time: getDateTime(chat.createdAt),
      status: 'COMPLETED',
      query_id: null,
      chat_log_id: chat.chat_log_id,
      feedback: {
        scale: '0',
        comment: '',
        isSubmitted: false,
      },
      contextual_data: chat.contextual_data,
    };
  } catch (e) {
    return null;
  }
};

export const convertBackendHistoricChatNode = (
  node: BackendHistoricChatNode,
): StreamedNode => {
  return node as StreamedNode; // TODO: verify
};

export const convertCurrentConversationIntoHistoric = (
  node: StreamingConversation,
): HistoricConversation => {
  return {
    ...node,
    status:
      node.status == 'COMPLETED' || node.status == 'ERROR'
        ? node.status
        : 'INTERRUPTED',
  };
};
