/* eslint-disable react/prop-types */
import * as React from 'react';

function Swap(props: React.SVGProps<SVGSVGElement>): React.JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
    >
      <path
        d="M20.2043 17.4381L23.8643 21.0981L20.2043 24.7581"
        stroke={props.fill || '#101828'}
        strokeWidth="2.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.56433 21.0981H23.8643"
        stroke={props.fill || '#101828'}
        strokeWidth="2.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.22433 13.7781L5.56433 10.1181L9.22433 6.4581"
        stroke={props.fill || '#101828'}
        strokeWidth="2.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.8643 10.1181H5.56433"
        stroke={props.fill || '#101828'}
        strokeWidth="2.44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoNoData = React.memo(Swap);
export default MemoNoData;
