import { ButtonProps } from '@mui/base';
import { Grid, Button as MuiButton } from '@mui/material';
import styled from 'styled-components';

export type HeaderMenuNotifyProps = {
  enableNotifications: boolean;
  notification: boolean;
};

export const Button = styled(MuiButton)<ButtonProps>`
  color: ${props => props.theme.white};
  background: ${props => props.theme.main};
`;

export const HeaderMenu = styled(Grid)<HeaderMenuNotifyProps>`
  position: relative;
  max-height: 26px;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  font-style: normal;
  letter-spacing: 6px;
  text-transform: uppercase;
  transition:
    background-color cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
    transform cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    right: 0;
    border-top: ${(props: HeaderMenuNotifyProps) =>
      props.notification ? '10.5px solid #151412' : '10.5px solid #151412'};
    border-right: ${(props: HeaderMenuNotifyProps) =>
      props.notification ? '10.5px solid #151412' : '10.5px solid #151412'};
    background-color: ${(props: HeaderMenuNotifyProps) =>
      props.notification ? '#151412' : ''};
    clip-path: polygon(0 0, 100% 0, 0 100%);
    transform: scaleX(-1);
    transition:
      background-color cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
      transform cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s;
  }
`;

export const HeaderMenuNotify = styled('span')<HeaderMenuNotifyProps>`
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  right: 0;
  border-top: 8px solid transparent;
  border-right: 8px solid transparent;
  background-color: ${(props: HeaderMenuNotifyProps) =>
    props.enableNotifications
      ? props.notification
        ? '#FF0A9D'
        : 'rgb(255 255 255 / 21%)'
      : 'none'};
  clip-path: polygon(0 0, 100% 0, 0 100%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  transform: scaleX(-1);
  transition:
    background-color cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s,
    transform cubic-bezier(0.48, 0.65, 0.6, 0.79) 0.5s;
`;
