import { Grid, IconButton } from '@mui/material';
import styled, { css, keyframes } from 'styled-components';
import { LayoutSize, LayoutGutter } from 'utils/styles/variables';
import { AnimatedContainerProps, AnimateWrapperProps } from './types';

export const AnimationName = 'ease-out';
export const AnimationTime = '0.5s';
const HeightAnimationTime = '1s';

interface RightsideDrawerTypes {
  collapse?: boolean;
  visible?: boolean;
}

export const RightToLeftAnimation = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const LeftToRightAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const LayoutContainer = styled(Grid)`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 9;
  background: ${props => props.theme.themeBackgroundGradient};
`;

export const LayoutWrapper = styled(Grid)<AnimateWrapperProps>`
  width: 100%;
  margin: 0 auto;
  height: 100%;

  ${({ animate }: AnimatedContainerProps) =>
    animate &&
    css`
      transition: opacity ${AnimationName} ${HeightAnimationTime};
    `}
`;

export const LayoutPageContainer = styled(Grid)<AnimatedContainerProps>`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;

  ${({ animate, navState }: AnimatedContainerProps) =>
    animate &&
    css`
      animation: 0.4s
        ${navState === 'Prev' ? LeftToRightAnimation : RightToLeftAnimation};
      transition: animation cubic-bezier(0.48, 0.65, 0.6, 0.79) ${AnimationTime};
    `}
`;

export const LeftSideContainer = styled(Grid)<any>`
  position: relative;
  width: ${LayoutGutter?.sidebar?.expandWidth}px;
  transition: width ${AnimationName} ${AnimationTime};

  ${({ collapse }: any) =>
    collapse &&
    css`
      width: ${LayoutGutter?.sidebar?.collapseWidth}px;
    `}
`;

export const RightSideContainer = styled(Grid)<{ collapse: boolean }>`
  width: calc(100% - ${LayoutGutter?.sidebar?.expandWidth}px) !important;
  height: 100%;
  padding: ${LayoutGutter?.rightSide?.x};
  padding-left: 0;
  transition: width ${AnimationName} ${AnimationTime};

  ${({ collapse }: any) =>
    collapse &&
    css`
      width: calc(100% - ${LayoutGutter?.sidebar?.collapseWidth}px) !important;
    `}
`;

export const RightSideContentWrapper = styled(Grid)<RightsideDrawerTypes>`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: ${props => props.theme.background9};
`;

export const RightSideContentBody = styled(Grid)<RightsideDrawerTypes>`
  width: calc(
    100% -
      ${props =>
        props?.visible ? LayoutGutter?.rightSide?.drawer?.expandWidth : 0}px
  ) !important;
  height: 100%;
  padding: ${LayoutGutter?.rightSide?.content?.x};
  border-radius: ${props => (props?.visible ? '16px 0px 0px 16px' : '16px')};
  border: 1px solid ${({ theme }) => theme.borderColor};
  background: ${({ theme }) => theme.backgroundShade};
  transition: width ${AnimationName} ${AnimationTime};

  ${({ collapse, visible }: any) =>
    collapse &&
    css`
      border-radius: 16px;
      width: calc(
        100% - ${visible ? LayoutGutter?.rightSide?.drawer?.collapseWidth : 0}px
      ) !important;
    `}
`;

export const RightSideDrawerContainer = styled(Grid)<RightsideDrawerTypes>`
  width: ${LayoutGutter?.rightSide?.drawer?.expandWidth}px !important;
  height: 100%;
  position: relative;
  border-radius: 0px 16px 16px 0px;
  border-top: 1px solid ${props => props.theme['borderColor']};
  border-right: 1px solid ${props => props.theme['borderColor']};
  border-bottom: 1px solid ${props => props.theme['borderColor']};
  background: ${props => props.theme.background9};
  transition: width ${AnimationName} ${AnimationTime};

  ${({ collapse }: any) =>
    collapse &&
    css`
      border: 0;
      width: 0px !important;
      transition: width ${AnimationName} ${AnimationTime};
    `}
`;

export const HeaderContainer = styled(Grid)<AnimatedContainerProps>`
  width: 100%;
  height: ${LayoutSize.headerHeght};
  padding: ${LayoutGutter.x} ${LayoutGutter.y};

  svg {
    width: auto;
    max-width: 460px;
    height: 20%;
    min-height: 36px;
    max-height: 165px;
  }

  ${({ animate }: AnimatedContainerProps) =>
    animate &&
    css`
      transition:
        display ${AnimationName} ${AnimationTime},
        height ${AnimationName} ${HeightAnimationTime},
        opacity ${AnimationName} 0.8s;
    `}
`;

export const HelixLogoWrapper = styled('img')``;

export const ContentWrapper = styled(Grid)<AnimatedContainerProps>`
  width: 100%;
  height: ${LayoutSize.contentHeight};
  margin: 0 auto;

  ${({ animate, navState }: AnimatedContainerProps) =>
    animate &&
    css`
      animation: 0.4s
        ${navState === 'Prev' ? LeftToRightAnimation : RightToLeftAnimation};
      transition: animation cubic-bezier(0.48, 0.65, 0.6, 0.79) ${AnimationTime};
    `}
`;

export const FooterContainer = styled(Grid)`
  width: 100%;
  height: ${LayoutSize.footerHeight};
`;

export const ToggleButton = styled(IconButton)<{
  collapse: boolean;
  placement: 'left' | 'right';
}>`
  position: absolute !important;
  top: 0;
  bottom: 0;
  margin: auto auto !important;
  right: -20px;
  z-index: 1;
  width: 20px;
  height: 20px;
  padding: 0 !important;
  font-size: 20px !important;
  border-radius: 4px !important;
  background: none !important;
  border: 0 !important;

  transition:
    margin 250ms ease-in 250ms,
    width 250ms ease-in 250ms,
    right ${AnimationName} ${AnimationTime},
    transform 250ms ease-in 250ms !important;

  svg {
    width: 26px !important;
    height: 26px !important;
  }

  ${({ placement }) =>
    placement === 'left'
      ? css`
          right: -24px !important;
          left: unset;
        `
      : css`
          right: unset;
          left: -24px !important;
        `}
`;
