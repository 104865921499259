import React from 'react';
import { Divider } from '@mui/material';
import { ParagraphBoldText, ParagraphLightText } from '@components/Typography';
import {
  CardContainer,
  CardWrapper,
  GridContainer,
  GridWrapper,
} from './styles';
import { motion } from 'framer-motion';

interface DataProps {
  label: string;
  context: string;
}

interface CardProps {
  title?: string;
  type?: string;
  data: Array<DataProps>;
  onClick: (query: string) => void;
  variants?: any;
}

const CardWrapperPadding = '1rem 2rem 0 2rem';

function Card({
  title,
  data,
  onClick,
  variants,
}: CardProps): React.JSX.Element {
  return (
    <GridContainer item sm={12}>
      <motion.div key={'title'} variants={variants}>
        <ParagraphLightText
          fontSize={'12px'}
          color={'#667085'}
          sx={{ padding: CardWrapperPadding }}
        >
          <Divider textAlign="left">{title}</Divider>
        </ParagraphLightText>
      </motion.div>
      <GridWrapper container rowGap={2} sx={{ padding: CardWrapperPadding }}>
        {data?.map((l: any, indx: number) => (
          <motion.div key={indx} variants={variants}>
            <motion.div
              key={crypto.randomUUID()}
              whileHover={{ backgroundColor: '#f0f0f0', x: 10 }}
              transition={{ duration: 0.2 }}
              style={{
                background: '#fff',
                borderRadius: '12px',
                transition: 'background-color 0.2s ease-in-out',
              }}
            >
              <CardContainer
                key={indx}
                sm={12}
                onClick={() => onClick(l.context)}
              >
                <CardWrapper>
                  <ParagraphBoldText fontSize={'14px'}>
                    {l.context}
                  </ParagraphBoldText>
                </CardWrapper>
              </CardContainer>
            </motion.div>
          </motion.div>
        ))}
      </GridWrapper>
    </GridContainer>
  );
}

export default Card;
