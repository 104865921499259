import {
  ExtTableColumn,
  ExtTableRow,
  IExtTableGroupedPreferences,
} from './types';

export function prepare_ext_table_grouped(
  groups: Record<string, ExtTableColumn[]>,
  mode: 'VIEW' | 'CUSTOMIZE',
  preferences: IExtTableGroupedPreferences,
  rows: ExtTableRow[],
): {
  visible_groups: Record<string, ExtTableColumn[]>;
  sorted_groupnames: string[];
  rows_per_group: Record<string, ExtTableRow[]>;
} {
  let visible_groups = groups;

  if (mode === 'VIEW' && preferences.columns.length) {
    visible_groups = {};
    Object.keys(groups).forEach(groupkey => {
      const group = groups[groupkey];
      const visible_columns = group.filter(column => {
        const preference = preferences.columns.find(
          pref => pref.key === column.key || pref.key === column.label,
        );
        return preference ? preference.visible : true;
      });
      if (!visible_columns.length) return null;
      visible_groups[groupkey] = visible_columns.sort((a, b) => {
        const prefcol_a = preferences.columns.find(
          dirty_col => dirty_col.key === a.key,
        );
        const prefcol_b = preferences.columns.find(
          dirty_col => dirty_col.key === b.key,
        );
        if (
          prefcol_a &&
          prefcol_b &&
          prefcol_a.position_in_group !== undefined &&
          prefcol_b.position_in_group !== undefined
        ) {
          return prefcol_a.position_in_group - prefcol_b.position_in_group;
        }
        return visible_columns.indexOf(a) - visible_columns.indexOf(b);
      });

      //visible_groups[groupkey] = visible_columns;
    });
  }

  const rows_per_group: Record<string, ExtTableRow[]> = {};
  Object.keys(visible_groups).forEach(groupname => {
    const groupcolumns = visible_groups[groupname];
    const new_rows: ExtTableRow[] = groupcolumns.map(column => {
      const colvalues = rows.reduce((acc, row) => {
        acc[(row['fund_name'] as string) || (row['Fund Name'] as string)] =
          row[column.key];
        return acc;
      }, []);
      const row: ExtTableRow = {
        label: column.label,
        key: column.key,
        ...colvalues,
      };
      return row;
    });
    rows_per_group[groupname] = new_rows.sort((a, b) => {
      const prefcol_a = preferences.columns.find(
        dirty_col => dirty_col.key === a.key,
      );
      const prefcol_b = preferences.columns.find(
        dirty_col => dirty_col.key === b.key,
      );
      if (
        prefcol_a &&
        prefcol_b &&
        prefcol_a.position_in_group !== undefined &&
        prefcol_b.position_in_group !== undefined
      ) {
        return prefcol_a.position_in_group - prefcol_b.position_in_group;
      }
      return new_rows.indexOf(a) - new_rows.indexOf(b);
      return -1; //new_rows.indexOf(a) - new_rows.indexOf(b);
    });
  });

  const sorted_groupnames = preferences.sorted_groupnames
    ? [
        ...preferences.sorted_groupnames,
        ...Object.keys(groups).filter(
          groupname => !preferences.sorted_groupnames?.includes(groupname),
        ),
      ]
    : Object.entries(visible_groups).map(([groupname]) => groupname);

  return { visible_groups, sorted_groupnames, rows_per_group };
}
