import React from 'react';
import { ExtTableNode } from '../types';
import ChatFundAttributesTable from './ChatFundAttributesTable';
import ChatFundComparisonTable from './ChatFundComparisonTable';
import ChatFundListTable from './ChatFundListTable';
import ExtTableBasic from './ExtTableBasic';

export type ChatExtTableProps = ExtTableNode;

/**
 * Adapt a received exttable node to be rendered as an extended table.
 * We have right now:
 * - FUNDS_COMPARISON: three column, rows as columns, grouped rows
 * - FUNDS_LIST: checkboxes, rows as columns
 */
export default function ChatExtTable({
  subtype,
  columns,
  rows,
  title,
}: ChatExtTableProps): JSX.Element {
  if (
    subtype === 'FUNDS_ATTRIBUTES' ||
    (subtype === 'FUNDS_COMPARISON' && rows.length === 1)
  ) {
    return <ChatFundAttributesTable columns={columns} rows={rows} />;
  } else if (subtype === 'FUNDS_COMPARISON') {
    return <ChatFundComparisonTable columns={columns} rows={rows} />;
  } else if (subtype === 'FUNDS_LIST') {
    return <ChatFundListTable columns={columns} rows={rows} />;
  }
  return <ExtTableBasic columns={columns} rows={rows} title={title || ''} />;
}
