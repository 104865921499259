import React from 'react';
import styled, { useTheme } from 'styled-components';
import { makeStyles } from '@mui/styles';
import { LoadingButton as MuiButton, LoadingButtonProps } from '@mui/lab';
import { ButtonBaseProps, SxProps } from '@mui/material';

export interface PrimaryButtonProps
  extends Omit<LoadingButtonProps, 'classes'>,
    ButtonBaseProps {
  type?: 'submit' | 'reset' | 'button';
  id?: string;
  width?: any;
  loading?: boolean;
  color?:
    | 'inherit'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | 'primary'
    | 'secondary'
    | undefined;
  customColor?: string;
  customBorderColor?: string;
  isHover?: boolean;
  textColor?: string;
  variant?: 'contained' | 'outlined' | 'text';
  fullWidth?: boolean;
  customHeight?: string;
  children: React.ReactNode;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  sx?: SxProps;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  dataTestId?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  loadingPosition?: 'center' | 'start' | 'end' | undefined;
}

const Button = styled(MuiButton).withConfig({
  shouldForwardProp: prop =>
    ![
      'theme',
      'width',
      'customHeight',
      'disabled',
      'customColor',
      'textColor',
      'isHover',
      'customBorderColor',
    ].includes(prop),
})<PrimaryButtonProps>(
  ({
    theme,
    width,
    customHeight,
    disabled,
    customColor,
    textColor,
    isHover,
    customBorderColor,
  }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${width ?? 'auto'};
  height: ${customHeight ?? '40px'};
  text-align: center;
  outline: none;
  border-radius: 4px;
  color: ${textColor || theme.solidWhite} !important;
  opacity: ${disabled ? '0.5' : '1'};
  background: ${customColor || theme.main} !important;
  background-color: ${customColor || theme.main} !important;
  border: 1px solid ${customBorderColor || customColor || theme.main} !important;

  &:hover {
    cursor: pointer;
    ${
      isHover &&
      `color: ${theme.solidWhite} !important;
      background: ${theme.main} !important;`
    }
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  .MuiLoadingButton-loadingIndicator {
    color: ${theme.solidWhite};
    position: unset;
  }

`,
);

const useStyles = makeStyles({
  root: {
    '&.MuiButtonBase-root': {
      textTransform: 'none',
      '&.MuiLoadingButton-loading': {
        color: (theme: any) => theme?.solidWhite,
        '&.MuiLoadingButton-loadingIndicator': {
          position: 'unset',
          color: (theme: any) => theme?.solidWhite,
        },
      },
    },
  },
});

function PrimaryButton({
  width = 'auto',
  type = 'button',
  variant = 'contained',
  fullWidth = false,
  customColor = '',
  customBorderColor = '',
  textColor = '',
  customHeight,
  children,
  className,
  style,
  onClick,
  disabled = false,
  dataTestId = undefined,
  isHover = false,
  ...rest
}: PrimaryButtonProps): React.JSX.Element {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Button
      type={type}
      customColor={customColor}
      textColor={textColor}
      customBorderColor={customBorderColor}
      width={width}
      variant={variant}
      disabled={disabled}
      style={style}
      onClick={onClick}
      className={`${classes?.root} ${className}`}
      fullWidth={fullWidth}
      customHeight={customHeight}
      data-cy={dataTestId}
      isHover={isHover}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default PrimaryButton;
