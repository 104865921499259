import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Radio, FormControlLabel } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import {
  GreyLabelText,
  Heading,
  ParagraphBoldText,
} from '@components/Typography';
import CustomCard from '@components/Card';
import { SecondaryButton } from '@components/Button';
import { CustomizeTextField } from '@components/core';
import CustomModal from '@components/Modal';
import { ParagraphLightText } from '@components/Typography';
import UpdatePasswordForm from '@components/UpdatePasswordForm';
import { getConfigState } from '@features/Global/selector';
import { actions as globalActions } from '@features/Global/slice';
import { actions as profileActions } from '@features/Profile/slice';
import {
  ProfileDataProps,
  getProfileDataForLocal,
  getInitalName,
} from '@common/auth';
import { ProfileIconWrapper } from '@layout/SideNav/styles';
import {
  ConfigurationItem,
  Container,
  Wrapper,
  CustomSwitch,
  FileDraggerWrap,
  LabelText,
  ProfileFormBox,
  ProfileWrap,
} from './styles';

function Profile(): React.JSX.Element {
  const dispatch = useDispatch();
  const [loading, updateFormSubmittingState] = useState(false);
  const configState = useSelector(getConfigState);
  const [openConfirmPasswordPopup, setOpenConfirmPasswordPopup] =
    useState(false);

  const profileData: ProfileDataProps = getProfileDataForLocal();

  const handleResetPassword = () => {
    setOpenConfirmPasswordPopup(!openConfirmPasswordPopup);
  };

  const handleCloseModal = () => {
    setOpenConfirmPasswordPopup(false);
  };

  useEffect(() => {
    dispatch(globalActions.getUserConfigRequest());
  }, []);

  const onChangeCacheSwitch = (event: any) => {
    dispatch(
      globalActions.updateUserConfigRequest({
        skipCache: event.target?.checked,
      }),
    );
  };

  const onChangeFeedbackSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      globalActions.updateUserConfigRequest({
        feedback: event.target?.checked,
      }),
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      globalActions.updateUserConfigRequest({
        tone: event.target.value === 'b',
      }),
    );
  };

  const onSubmit = ({
    currentPassword,
    newPassword,
  }: {
    currentPassword: string;
    newPassword: string;
  }) => {
    console.log('props... ', currentPassword, newPassword);
    updateFormSubmittingState(false);
    if (currentPassword && newPassword) {
      dispatch(
        profileActions.resetPasswordRequest({
          oldPassword: currentPassword,
          newPassword,
        }),
      );
    }
  };

  return (
    <>
      <Container>
        <Wrapper p={1} pt={2}>
          <Heading mb={2}>Personal Information</Heading>
          <ProfileWrap>
            <ProfileIconWrapper
              alt={getInitalName()}
              sx={{
                width: '125px',
                height: '125px',
                borderRadius: '12px',
                fontSize: '28px !important',
              }}
            >
              {getInitalName()}
            </ProfileIconWrapper>
            <FileDraggerWrap></FileDraggerWrap>
          </ProfileWrap>
          <ProfileFormBox pt={2}>
            <Grid container spacing={2}>
              <Grid
                item
                container
                xs={6}
                pt={0}
                pb={3}
                flexDirection={'column'}
              >
                <LabelText>First Name*</LabelText>
                <CustomizeTextField
                  disabled
                  name={''}
                  value={profileData.firstName}
                  width={'inherit'}
                  onChange={function (): void {
                    throw new Error('Function not implemented.');
                  }}
                  sx={{
                    maxWidth: '400px',
                    border: '1px solid #E4E7EC',
                    boxShadow: '1px 2px 2px 0px #1018281F inset',
                    borderRadius: '4px',
                    marginTop: '4px',
                  }}
                />
              </Grid>
              <Grid
                item
                container
                xs={6}
                pt={0}
                pb={3}
                flexDirection={'column'}
              >
                <LabelText>Last Name*</LabelText>
                <CustomizeTextField
                  disabled
                  name={''}
                  value={profileData.lastName}
                  width={'inherit'}
                  onChange={function (): void {
                    throw new Error('Function not implemented.');
                  }}
                  sx={{
                    maxWidth: '400px',
                    border: '1px solid #E4E7EC',
                    boxShadow: '1px 2px 2px 0px #1018281F inset',
                    borderRadius: '4px',
                    marginTop: '4px',
                  }}
                />
              </Grid>
              <Grid
                item
                container
                xs={6}
                pt={0}
                pb={3}
                flexDirection={'column'}
              >
                <LabelText>Email*</LabelText>
                <CustomizeTextField
                  disabled
                  name={''}
                  width={'inherit'}
                  value={profileData.email}
                  onChange={function (): void {
                    throw new Error('Function not implemented.');
                  }}
                  sx={{
                    maxWidth: '400px',
                    border: '1px solid #E4E7EC',
                    boxShadow: '1px 2px 2px 0px #1018281F inset',
                    borderRadius: '4px',
                    marginTop: '4px',
                  }}
                />
              </Grid>
              <Grid
                item
                container
                xs={6}
                pt={0}
                pb={3}
                flexDirection={'column'}
              >
                <LabelText>Phone</LabelText>
                <CustomizeTextField
                  disabled
                  name={''}
                  value={''}
                  width={'inherit'}
                  onChange={function (): void {
                    throw new Error('Function not implemented.');
                  }}
                  sx={{
                    maxWidth: '400px',
                    border: '1px solid #E4E7EC',
                    boxShadow: '1px 2px 2px 0px #1018281F inset',
                    borderRadius: '4px',
                    marginTop: '4px',
                  }}
                />
              </Grid>
              <Grid item xs={12} pt={0} pb={4}>
                <SecondaryButton
                  startIcon={<CachedIcon />}
                  sx={{ width: '168px', height: '40px' }}
                  onClick={handleResetPassword}
                >
                  {' '}
                  Reset Password
                </SecondaryButton>
              </Grid>
            </Grid>
            <CustomModal
              open={openConfirmPasswordPopup}
              dialogTitle={'Reset Password'}
              dialogContentText={
                <ParagraphLightText
                  variant="caption"
                  display="block"
                  gutterBottom
                  mt={2}
                  fontStyle={'italic'}
                >
                  Note: Password reset redirects to login.
                </ParagraphLightText>
              }
              handleClose={handleCloseModal}
              dialogContent={
                <Grid mt={2}>
                  <UpdatePasswordForm
                    gap={'1rem'}
                    email={profileData.email}
                    firstName={profileData.firstName}
                    lastName={profileData.lastName}
                    enableCurrentPassword
                    isFormSubmitting={loading}
                    onSubmit={(props: any) => onSubmit(props)}
                  />
                </Grid>
              }
            />
          </ProfileFormBox>
          {profileData.userType !== 'SUPER_ADMIN' && (
            <Fragment>
              <Heading>Chat Configuration</Heading>
              <Grid pt={2} pb={4}>
                <CustomCard justifyContent={'space-between'}>
                  <ConfigurationItem>
                    <ParagraphBoldText>Skip Cache</ParagraphBoldText>
                    <GreyLabelText>
                      Enable or disable option to get response from cache or
                      not.
                    </GreyLabelText>
                  </ConfigurationItem>
                  <ConfigurationItem>
                    <FormControlLabel
                      control={
                        <CustomSwitch
                          onChange={onChangeCacheSwitch}
                          checked={configState.skipCache}
                        />
                      }
                      label={configState.skipCache ? 'On' : 'Off'}
                    />
                  </ConfigurationItem>
                </CustomCard>

                <CustomCard
                  justifyContent={'space-between'}
                  sx={{ marginBottom: 0 }}
                >
                  <ConfigurationItem>
                    <ParagraphBoldText>Feedback</ParagraphBoldText>
                    <GreyLabelText>
                      Enable or disable option to provide feedback for
                      responses.
                    </GreyLabelText>
                  </ConfigurationItem>
                  <ConfigurationItem>
                    <FormControlLabel
                      control={
                        <CustomSwitch
                          onChange={onChangeFeedbackSwitch}
                          checked={configState.feedback}
                        />
                      }
                      label={configState.feedback ? 'On' : 'Off'}
                    />
                  </ConfigurationItem>
                </CustomCard>
              </Grid>

              {false && (
                <>
                  <Heading>Language Mode</Heading>
                  <Grid container spacing={2} pt={2}>
                    <Grid item xs={6}>
                      <CustomCard
                        sx={{ minHeight: '60px' }}
                        justifyContent={'space-between'}
                      >
                        <ConfigurationItem>
                          <ParagraphBoldText>Plain Language</ParagraphBoldText>
                          <GreyLabelText>
                            Use plain language to explain private market
                            concepts{' '}
                          </GreyLabelText>
                        </ConfigurationItem>
                        <ConfigurationItem>
                          <Radio
                            checked={!configState.tone}
                            onChange={handleChange}
                            value="a"
                            name="radio-buttons"
                            inputProps={{ 'aria-label': 'A' }}
                          />
                        </ConfigurationItem>
                      </CustomCard>
                    </Grid>
                    <Grid item xs={6}>
                      <CustomCard
                        sx={{ minHeight: '60px' }}
                        justifyContent={'space-between'}
                      >
                        <ConfigurationItem>
                          <ParagraphBoldText>
                            Financial Jargon
                          </ParagraphBoldText>
                          <GreyLabelText>
                            I have experience with private markets
                          </GreyLabelText>
                        </ConfigurationItem>
                        <ConfigurationItem>
                          <Radio
                            checked={configState.tone}
                            onChange={handleChange}
                            value="b"
                            name="radio-buttons"
                            inputProps={{ 'aria-label': 'B' }}
                          />
                        </ConfigurationItem>
                      </CustomCard>
                    </Grid>
                  </Grid>
                </>
              )}
            </Fragment>
          )}
        </Wrapper>
      </Container>
    </>
  );
}

export default Profile;
