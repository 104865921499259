import { Box, Grid } from '@mui/material';
import { LayoutGutter } from '@styles/variables';
import styled from 'styled-components';

export const Container = styled(Box)`
  width: 100%;
  height: 80vh;
  background-color: #ffffff;
  border-radius: 8px;
`;
export const ContainerNew = styled(Grid)`
  width: 100%;
  height: 100%;
  padding: 0 ${LayoutGutter.x} 0 ${LayoutGutter.y};
`;

export const Header = styled(Grid)`
  padding: 24px 16px;
`;

export const Column = styled.div`
  display: flex;
`;

export const StyledColumn2 = styled.div`
  color: var(--neutral-600, #475467);
  font-size: 12px;
`;
