import { TableCell, TableContainer } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import styled from 'styled-components';

export const TableHeader = styled(TableCell)`
  font-size: 13px !important;
  padding: 8px 15px !important;
`;

export const TableColumn = styled(TableCell)`
  font-size: 12px !important;
  padding: 10px 15px !important;
`;

export const StyledTableContainer = styled(TableContainer)`
  overflow-y: 'auto';
  border-radius: 8px !important;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
`;

export const StyledMUIDataTable = styled(MUIDataTable)`
  border-radius: 8px !important;
  border: 1px solid #e4e7ec;
  box-shadow: 0px 1px 2px 0px #1018281f;
  .MuiOutlinedInput-input {
    height: 40px;
    padding: 0px;
  }
  th {
    background-color: #f9fafb;

    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
  }
  td {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    padding: 8px 16px;
  }
  .MuiTableRow-root {
    border-bottom: 1px solid #e4e7ec;
    height: 47px;
  }
`;
