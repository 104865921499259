/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AllEffect,
  call,
  all,
  put,
  take,
  takeEvery,
  fork,
  takeLatest,
} from 'redux-saga/effects';
import { EventChannel } from 'redux-saga';
import { MigrationResponse } from './types';
import { actions } from './slice';
import { getStatsListApi, getVictorMigrationResponse } from './api';

function* getMirationStatusList(request: any): Generator<any, void, any> {
  const {
    payload: { formName, type },
  } = request;
  const data = {
    type: type,
  };
  try {
    yield put(actions.loadStatsListFetch(formName));
    const response: any = yield call(getStatsListApi, data);
    if (
      !response ||
      (response.indexStatsList && !response.indexStatsList.length)
    ) {
      throw new Error('Failed to fetch Index Statslist');
    }

    yield put(
      actions.loadStatsListSuccess({
        data: response.indexStatsList || [],
        statsLoading: false,
        formName,
      }),
    );
  } catch (error) {
    yield put(
      actions.loadStatsListSuccess({
        data: [],
        statsLoading: false,
        formName,
      }),
    );
  }
}

let concurrentQueryCount = 0; // Initialize the count

function* searchQuery(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    const userType = localStorage.getItem('tifin-ai-user-type');
    if (userType !== 'SUPER_ADMIN') {
      throw '-- Unauthorized Access --';
    }

    const channel: EventChannel<MigrationResponse> = yield call(
      getVictorMigrationResponse,
      {
        body: JSON.stringify(payload),
        responseType: 'stream',
        headers: {
          'x-tifin-ai-auth': localStorage.getItem('x-tifin-ai-token'),
        },
      },
    );

    yield put(actions.isQueryResponseStreaming(true));

    try {
      while (true) {
        const event: MigrationResponse = yield take(channel);
        // Set query success
        if (event) {
          yield put(actions.pushToChatHistory(event));
        }
      }
    } finally {
      console.log('Stream has ended!!!');
      yield put(actions.isQueryResponseStreaming(false));
    }
  } catch (error) {
    console.error('searchQuery error:', error);
  } finally {
    console.log('Stream has ended!!!');
    yield put(actions.isQueryResponseStreaming(false));
  }
}

function* watchConcurrentQueries() {
  yield takeEvery(actions.searchQueryRequest.type, handleConcurrentQuery);
}

function* handleConcurrentQuery(payload: any) {
  try {
    concurrentQueryCount++; // Increment the count when a new query is started
    yield call(searchQuery, payload);
  } catch (error) {
    console.error('handleConcurrentQuery Error:', error);
    concurrentQueryCount--; // Decrement the count when an error occurs
  }
}

export function* vectorMigrationSaga(): Generator<
  AllEffect<any>,
  void,
  unknown
> {
  yield all([
    takeLatest(actions.getStatsList.type, getMirationStatusList),
    fork(watchConcurrentQueries),
  ]);
}
