import React from 'react';
import { Box, Grid } from '@mui/material';
import { ClientData, ClientsState } from 'app/features/Clients/types';
import { PrimaryButton, SecondaryButton } from '@components/Button';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Heading } from '@components/Typography';

import { StyledTableContainer, StyledMUIDataTable } from './styles';
import CustomChip, { ChipTypes } from '@components/Chip/CustomChip';
import FilterRange from './FilterRange';
import useMaxHeight from '@helper/CustomHooks/intex';
import moment from 'moment';
interface IAccordianProps extends ClientsState {
  openPortfolioPopup?: (row: ClientData | any) => void;
}

const decodeURIComponentWithSpaces = (str: string) => {
  return str && decodeURIComponent(str.replace(/\+/g, '%20'));
};

function AccordionTable({ data }: IAccordianProps): React.JSX.Element {
  const maxHeight = useMaxHeight(180);
  const components = {
    FilterIcon: () => (
      <React.Fragment>
        <SecondaryButton startIcon={<FilterAltOutlinedIcon />}>
          Filter
        </SecondaryButton>
      </React.Fragment>
    ),
    DownloadIcon: () => (
      <React.Fragment>
        <PrimaryButton startIcon={<FileDownloadOutlinedIcon />}>
          Download Feedback
        </PrimaryButton>
      </React.Fragment>
    ),
  };

  return (
    <StyledTableContainer sx={{ position: 'relative' }}>
      <Grid
        sx={{
          maxWidth: '100%',
          maxHeight: `${maxHeight}px`,
          border: '1px solid #e0e0e0',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 1,
            width: '92%',
            position: 'absolute',
            top: '16px',
            zIndex: '1',
            padding: ' 0 16px',
          }}
        ></Box>
        <StyledMUIDataTable
          title={<Heading>{''}</Heading>}
          data={data}
          columns={[
            {
              name: 'chatLogId.query',
              label: 'Query',
              options: {
                customBodyRender: value => {
                  return <div> {decodeURIComponentWithSpaces(value)}</div>;
                },
              },
            },

            {
              name: 'comment',
              label: 'Comment',
            },
            {
              name: 'email',
              label: 'User Name',
            },
            {
              name: 'chatLogId._id',
              label: 'Chat log id',
            },

            {
              name: 'scale',
              label: 'Scale',
              options: {
                customBodyRender: value => {
                  const numericValue = parseInt(value);
                  if (isNaN(numericValue)) {
                    return null;
                  }
                  let type: ChipTypes = 'info';

                  switch (numericValue) {
                    case 2:
                      type = 'success';
                      break;
                    case 1:
                      type = 'info';
                      break;
                    case 0:
                      type = 'neutral';
                      break;
                    case -1:
                      type = 'warning';
                      break;
                    case -2:
                      type = 'error';
                      break;
                    default:
                      break;
                  }

                  return (
                    <CustomChip type={type} text={value} minWidth="30px" />
                  );
                },
              },
            },
          ]}
          options={{
            download: true,
            downloadOptions: {
              filename: `TIFIN-Feedback_history_Results-${moment().format(
                'DD-MM-YYYY_hh-mm-ss',
              )}.csv`,
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
            },
            filter: true,
            print: false,
            viewColumns: false,
            pagination: true,
            rowsPerPage: 8,
            rowsPerPageOptions: [5],
            selectableRows: 'none',
            enableNestedDataAccess: '.',
            search: false,
            sort: false,
            expandableRows: false,
            expandableRowsOnClick: false,
            customToolbar: () => <FilterRange />,
          }}
          components={{ icons: components as any }}
        />
      </Grid>
    </StyledTableContainer>
  );
}

export default AccordionTable;
