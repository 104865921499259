import { Dialog, FormControl, TextField, styled } from '@mui/material';

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '0 1.5rem',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3),
  },
}));
export const StyledFormControl = styled(FormControl)(() => ({
  marginTop: '14px',
  '& >label': {
    left: '-12px !important',
    top: '-15px !important',
    'font-size': '18px !important',
    'font-weight': '500 !important',
    color: '#000 !important',
  },
}));
export const StyledTextField = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    boxShadow: '1px 2px 2px 0px #1018281F inset',

    height: 40,
    padding: '0 5px',
  },
}));
