/* eslint-disable react/prop-types */

import React from 'react';

function Disconnect(): React.JSX.Element {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 6.75V4.5"
        stroke="#E00000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 19.5V17.25"
        stroke="#E00000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.25 9H5"
        stroke="#E00000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 15H17.75"
        stroke="#E00000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.15614 12.0376L6.09676 13.097C5.39311 13.8006 4.9978 14.755 4.9978 15.7501C4.9978 16.2428 5.09485 16.7307 5.28341 17.186C5.47197 17.6412 5.74835 18.0548 6.09676 18.4032C6.44518 18.7516 6.8588 19.028 7.31403 19.2166C7.76925 19.4051 8.25716 19.5022 8.74989 19.5022C9.745 19.5022 10.6994 19.1069 11.403 18.4032L12.4624 17.3438"
        stroke="#E00000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8436 11.9625L18.903 10.9031C19.6066 10.1995 20.0019 9.24512 20.0019 8.25001C20.0019 7.25489 19.6066 6.30054 18.903 5.59689C18.1993 4.89323 17.245 4.49792 16.2499 4.49792C15.2547 4.49792 14.3004 4.89323 13.5967 5.59689L12.5374 6.65626"
        stroke="#E00000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoConnectIcon = React.memo(Disconnect);
export default MemoConnectIcon;
