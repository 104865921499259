import { PrimaryButton } from '@components/Button';
import DataProviderCard from '@components/Card/DataProvider';
import CustomChip from '@components/Chip/CustomChip';
import { SmallText, SubHeading } from '@components/Typography';
import {
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import React, { useRef } from 'react';
import { ReactComponent as PromptVector } from '@images/promptVector.svg';
import { ChipBox, DataProviderCardHeader, PopupHeader } from './styles';
import { IFundDocument, IFundWithDocuments } from '@features/Funds/types';
import { AddDocumentsButton } from './AddDocumentsButton';

export interface FundDataCardProps {
  data: IFundWithDocuments;
  onAddDocumentClick: () => void;
  onViewDetailsClick: (query: string) => void;
  disabled: boolean;
}

const FundDataCard: React.FC<FundDataCardProps> = ({
  data,
  onAddDocumentClick,
  onViewDetailsClick,
  disabled,
}) => {
  const { name, managerName } = data;
  const isPresent = _d => _d && _d !== 'nan' && _d !== 'None';

  const [rotation, setRotation] = React.useState(0);
  const [isHovered, setIsHovered] = React.useState(false);

  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Adjust the type here

  const handleMouseEnter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current); // Clear existing interval if it exists
    }
    setIsHovered(true);
    intervalRef.current = setInterval(() => {
      setRotation(rotation => rotation + 360);
    }, 1000);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
    setRotation(0);
    if (intervalRef.current) {
      clearInterval(intervalRef.current); // Clear the interval when mouse leaves
    }
  };

  return (
    <DataProviderCard width="100%" height="auto" padding="16px" cursor="auto">
      <PopupHeader width="100%" sx={{ alignItems: ' flex-start' }}>
        <DataProviderCardHeader>
          <SubHeading sx={{ marginBottom: '6px' }}>{name}</SubHeading>
          <SmallText color="#98A2B3">Fund Manager - {managerName}</SmallText>
        </DataProviderCardHeader>
        <Box sx={{ textAlign: 'right' }}>
          <ChipBox>
            {isPresent(data.style) && (
              <>
                <CustomChip
                  type={'simple'}
                  text={data.style}
                  padding="4px 8px"
                  borderRadius="8px"
                />
                {isPresent(data.focus) && (
                  <CustomChip
                    type={'simple'}
                    text={data.focus}
                    padding="4px 8px"
                    borderRadius="8px"
                  />
                )}
              </>
            )}
            {isPresent(data.geography) && (
              <CustomChip
                type={'simple'}
                text={data.geography}
                padding="4px 8px"
                borderRadius="8px"
              />
            )}
            {data.documents.length > 0 && (
              <>
                <DocumentsTooltip documents={data.documents}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <CustomChip
                      type={'simple'}
                      padding="4px 8px"
                      borderRadius="8px"
                      sx={{
                        backgroundColor: '#F9FAFB',
                        color: '#101828',
                        border: '1px solid #101828',
                        cursor: 'default',
                      }}
                    >
                      Document(s)
                    </CustomChip>
                  </div>
                </DocumentsTooltip>
              </>
            )}
            <AddDocumentsButton onAdd={onAddDocumentClick} />
          </ChipBox>

          <PrimaryButton
            isHover
            variant="outlined"
            customColor="#FFFFFF"
            customBorderColor="#2C3748"
            textColor="#2C3748"
            sx={{ color: isHovered ? '#FFFFFF !important' : '#2C3748' }}
            startIcon={
              <PromptVector
                style={{
                  ...(isHovered && {
                    transition: isHovered && 'transform 3s',
                    transform: isHovered && `rotate(${rotation}deg)`,
                  }),
                }}
                fill={isHovered ? 'white' : 'inherit'} // Change SVG fill color when hovering
              />
            }
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => {
              const final_name = name.toLowerCase().includes('fund')
                ? name
                : name + ' fund';
              return onViewDetailsClick(
                data.hasPPM
                  ? `Tell me more about ${final_name}`
                  : `View details on ${final_name}`,
              );
            }}
            disabled={disabled}
          >
            {disabled
              ? 'Processing...'
              : data.hasPPM
                ? 'Explore with AI'
                : 'View details'}
          </PrimaryButton>
        </Box>
      </PopupHeader>
    </DataProviderCard>
  );
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const DocumentsTooltip = ({
  documents,
  ...restProps
}: {
  documents: IFundDocument[];
} & Omit<TooltipProps, 'title'>): JSX.Element => {
  return (
    <HtmlTooltip
      title={
        <>
          <div style={{ marginBottom: '8px' }}>
            <b>Uploaded documents:</b>
          </div>
          {documents.map(doc => (
            <div key={doc.id}>
              {doc.name} ({(doc.type || 'PPM').replace('_', ' ')})
            </div>
          ))}
        </>
      }
      {...restProps}
    />
  );
};

export default FundDataCard;
