/* eslint-disable react/prop-types */
import * as React from 'react';

function EmptyBox() {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="100" cy="104.505" r="95.4955" fill="#EFEFFD" />
      <path
        d="M27.9279 66.6666L100 102.703V178.378L27.9279 142.342V66.6666Z"
        fill="#A0A3F2"
      />
      <path
        d="M172.072 66.6666L100 102.703V178.378L172.072 142.342V66.6666Z"
        fill="#7B73E4"
      />
      <path
        d="M100 102.703L27.9279 66.6667L100 30.6306L172.072 66.6667L100 102.703Z"
        fill="#D6D7FA"
      />
      <path
        d="M100 102.703V30.6306L27.9279 66.6667L100 102.703Z"
        fill="#A0A3F2"
      />
      <path
        d="M72.973 133.333L100 102.703L27.9279 66.6666L0.900879 97.2973L72.973 133.333Z"
        fill="#BBBEF7"
      />
      <path
        d="M27.9279 66.6667L0.900902 36.036L72.973 0L100 30.6306L27.9279 66.6667Z"
        fill="#BBBEF7"
      />
      <path
        d="M172.072 66.6667L199.099 36.036L127.027 0L100 30.6306L172.072 66.6667Z"
        fill="#BBBEF7"
      />
      <path
        d="M127.027 133.333L100 102.703L172.072 66.6666L199.099 97.2973L127.027 133.333Z"
        fill="#BBBEF7"
      />
    </svg>
  );
}

const MemoNoData = React.memo(EmptyBox);
export default MemoNoData;
