// GlobalSlice/saga.ts
import {
  AllEffect,
  all,
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { actions } from './slice';
import { documents } from './selector';
import { listFundDocumentApi, deleteFundDocumentApi } from './api';
import { actions as globalActions } from '@features/Global/slice';

function* getFundDocumentsList(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    yield put(actions.loadDocumentsListFetch());
    const response: any = yield call(listFundDocumentApi, payload);
    if (!response || (response.documents && !response.documents.length)) {
      throw new Error('Failed to fetch Fund Documents list');
    }
    const sortedData = response.documents?.sort(
      (a: any, b: any) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

    const currentFundsList = yield select(documents);
    const _collectedData =
      payload.page > 1
        ? [
            ...currentFundsList.inprogressDocList,
            ...currentFundsList.docList,
            ...sortedData,
          ]
        : sortedData;

    yield put(
      actions.loadDocumentsListSuccess({
        data: _collectedData || [],
        totalCount: response.totalCount,
        loading: false,
      }),
    );
  } catch (error) {
    yield put(
      actions.loadDocumentsListSuccess({
        loading: false,
      }),
    );
  }
}

function* deleteFundDocument(request: any): Generator<any, void, any> {
  const { payload } = request;
  try {
    yield put(actions.loadDeleteDocumentsFetch());
    const response: any = yield call(deleteFundDocumentApi, payload.id);
    if (!response || response.error) {
      throw new Error('Failed to delete document');
    }
    if (response.statusCode === 200) {
      yield put(
        globalActions.displayToast({
          duration: 3000,
          toastType: 'success',
          toastMessage: 'Deleted successfully',
        }),
      );
    }

    yield put(
      actions.loadDeleteDocumentsSuccess({
        loading: false,
      }),
    );
    if (payload.callback && typeof payload.callback === 'function') {
      payload.callback(response);
    }
  } catch (error) {
    yield put(
      actions.loadDeleteDocumentsSuccess({
        loading: false,
      }),
    );
  }
}

export function* documentsSaga(): Generator<AllEffect<any>, void, unknown> {
  yield all([
    takeLatest(actions.getDocumentsList.type, getFundDocumentsList),
    takeLatest(actions.deleteDocumentList.type, deleteFundDocument),
  ]);
}
