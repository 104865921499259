/* eslint-disable react/prop-types */
import * as React from 'react';

function IntegrationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M8.82202 15.1781L15.1783 8.8125"
        stroke={props.fill || '#D0D5DD'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5935 16.7719L10.9403 19.425C10.5224 19.843 10.0262 20.1745 9.48005 20.4007C8.93394 20.6269 8.34863 20.7434 7.75752 20.7434C6.56374 20.7434 5.41885 20.2691 4.57471 19.425C3.73058 18.5809 3.25635 17.436 3.25635 16.2422C3.25635 15.0484 3.73058 13.9035 4.57471 13.0594L7.22784 10.4062"
        stroke={props.fill || '#D0D5DD'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7719 13.5937L19.425 10.9406C20.2691 10.0965 20.7434 8.95159 20.7434 7.7578C20.7434 6.56401 20.2691 5.41912 19.425 4.57499C18.5809 3.73085 17.436 3.25662 16.2422 3.25662C15.0484 3.25662 13.9035 3.73085 13.0594 4.57499L10.4062 7.22811"
        stroke={props.fill || '#D0D5DD'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoNoData = React.memo(IntegrationIcon);
export default MemoNoData;
