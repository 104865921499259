/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, Fragment } from 'react';
import { CircularProgress, Grid, Stack } from '@mui/material';
import { LightSubHeading, SubHeading } from '@components/Typography';
import throttle from 'lodash/throttle';
import { ContentWrapper, FundsBox } from '../styles';
import { useNavigate } from 'react-router-dom';
import EmptyState from './EmptyState';
import useMaxHeight from '@helper/CustomHooks/intex';
import { FundsList, IFundWithDocuments } from '@features/Funds/types';
import FundDataCard from '../FundDataCard';
import { useAppContext } from 'app/AppContext';
import useFundsList from '@features/Funds/useFundsList';

interface MyFundsListProps {
  searchQuery: string;
  onAddNewFundClick: () => void;
  setInputValue: (inputValue: string) => void;
}

function MyFundsList({
  searchQuery,
  onAddNewFundClick,
  setInputValue,
}: MyFundsListProps): React.JSX.Element {
  const navigate = useNavigate();
  const { fund_search_mode, setModal } = useAppContext();
  const { data, isLoading, fetchNextPage } = useFundsList({
    q: searchQuery,
    mode: fund_search_mode,
  });
  const funds = data?.pages.flatMap(page => page.funds);
  const fundsData: FundsList = {
    result: funds || [],
    totalCount:
      (data?.pages.length || 0) > 0 ? data?.pages[0].totalCount || 0 : 0,
    loading: isLoading,
  };

  //const fundsData = useSelector(getfundsState);
  const maxHeight = useMaxHeight(180); // Subtract 100 pixels from the viewport height
  const { fund_search_mode: activeButton } = useAppContext();

  const contentWrapperRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    setInputValue('');

    if (contentWrapperRef.current) {
      contentWrapperRef.current.scrollTop = 0;
    }
  }, [activeButton]);

  useEffect(() => {
    const handleScroll = throttle(() => {
      if (
        contentWrapperRef.current &&
        contentWrapperRef.current.scrollTop +
          contentWrapperRef.current.clientHeight >=
          contentWrapperRef.current.scrollHeight - 100 &&
        !isLoading
      ) {
        fetchNextPage();
      }
    }, 200);

    if (contentWrapperRef.current) {
      contentWrapperRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (contentWrapperRef.current) {
        contentWrapperRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [fundsData.loading]);

  const handleViewDetails = async (
    _fund_id: string,
    _fund_name: string,
    prompt: string,
  ) => {
    navigate('/conversations', { state: { forcedPrompt: prompt } }); // TODO: review this
  };

  const onAddDocumentClick = (internal_fund_id: string, fund_name: string) => {
    const prompt = `Add document for fund ${fund_name}`;
    navigate('/conversations', {
      state: { forcedPrompt: prompt, internal_fund_id },
    }); // TODO: review this

    /*
      setModal({
        type: 'ADD_FUND_DOCUMENT',
        fund_name,
        internal_fund_id,
        mode: 'APPEND_TO_FUND',
        document_type: null
      });
*/
  };

  const listData = fundsData.result;

  const isDisabled = (item: IFundWithDocuments) => {
    const createdAtTime = new Date(item.createdAt).getTime();
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - createdAtTime;
    return timeDifference < 120000; // Under 2 minutes
  };

  const label = searchQuery
    ? `Showing results for "${searchQuery}"`
    : activeButton === 'DATA_PROVIDER'
      ? 'Showing funds uploaded by your data provider'
      : 'Showing funds uploaded by your enterprise';
  const count = fundsData.totalCount;
  return (
    <>
      <Grid item sm={12}>
        {fundsData.loading && data === undefined ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '60vh',
              width: '100%',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {activeButton === 'ENTERPRISE' &&
            !fundsData.result &&
            !searchQuery ? (
              <EmptyState onClick={onAddNewFundClick} type={'funds'} />
            ) : (
              <ContentWrapper maxHeight={maxHeight} ref={contentWrapperRef}>
                <FundsBox>
                  <LightSubHeading color="#475467">{label}</LightSubHeading>
                  <LightSubHeading>
                    {searchQuery ? 'Total Results' : 'Total Funds'}:{' '}
                    {new Intl.NumberFormat().format(count)}
                  </LightSubHeading>
                </FundsBox>
                <Fragment>
                  {listData?.map((k, index) => (
                    <FundDataCard
                      data={k}
                      key={index}
                      onViewDetailsClick={prompt =>
                        handleViewDetails(k.id, k.name, prompt)
                      }
                      onAddDocumentClick={() =>
                        onAddDocumentClick(k.id, k.name)
                      }
                      disabled={isDisabled(k)}
                    />
                  ))}
                  {fundsData.loading && (
                    <Grid
                      container
                      width={'100%'}
                      height={'84px'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      sx={{ marginBottom: '24px' }}
                    >
                      <CircularProgress />
                    </Grid>
                  )}
                  {!fundsData.loading && !listData?.length && count === 0 && (
                    <Stack
                      direction="row"
                      justifyContent="center"
                      sx={{ width: '100%' }}
                    >
                      <SubHeading sx={{ marginTop: '18px' }}>
                        Sorry, no matching records found
                      </SubHeading>
                    </Stack>
                  )}
                </Fragment>
              </ContentWrapper>
            )}{' '}
          </>
        )}
      </Grid>
    </>
  );
}

export default MyFundsList;
