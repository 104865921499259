import React from 'react';
import HelixBackground from '@images/bg.svg';
import { BackdropContainer, OverlapGroupWrapper } from './styles';

const LayoutBackdrop = ({
  sx,
  zIndex,
}: {
  sx?: any;
  zIndex?: number | string;
}): React.JSX.Element => {
  return (
    <BackdropContainer sx={sx} zIndex={zIndex}>
      <OverlapGroupWrapper
        style={{ backgroundImage: `url(${HelixBackground})` }}
      />
    </BackdropContainer>
  );
};

export default LayoutBackdrop;
