/* eslint-disable react/prop-types */

import React from 'react';

function TotalClients(): React.JSX.Element {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="5.50175" fill="#EFEFFD" />
      <path
        d="M32 39.2983C35.1006 39.2983 37.6141 36.7848 37.6141 33.6842C37.6141 30.5837 35.1006 28.0702 32 28.0702C28.8995 28.0702 26.386 30.5837 26.386 33.6842C26.386 36.7848 28.8995 39.2983 32 39.2983Z"
        stroke="#7B73E4"
        strokeWidth="2.69474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.5439 30.3158C42.8517 30.3136 44.1418 30.617 45.3114 31.2019C46.4811 31.7867 47.4979 32.6367 48.2808 33.6842"
        stroke="#7B73E4"
        strokeWidth="2.69474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7195 33.6842C16.5024 32.6367 17.5192 31.7867 18.6888 31.2019C19.8585 30.617 21.1486 30.3136 22.4563 30.3158"
        stroke="#7B73E4"
        strokeWidth="2.69474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9158 44.3509C24.6552 42.8366 25.805 41.5604 27.2343 40.6678C28.6636 39.7751 30.3148 39.3018 32 39.3018C33.6851 39.3018 35.3364 39.7751 36.7657 40.6678C38.195 41.5604 39.3448 42.8366 40.0842 44.3509"
        stroke="#7B73E4"
        strokeWidth="2.69474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4565 30.3158C21.604 30.3167 20.769 30.0749 20.0488 29.6188C19.3287 29.1627 18.7532 28.5111 18.3897 27.7401C18.0261 26.9691 17.8895 26.1105 17.9957 25.2647C18.102 24.4189 18.4468 23.6209 18.9897 22.9638C19.5327 22.3066 20.2515 21.8176 21.0621 21.5538C21.8727 21.29 22.7416 21.2624 23.5673 21.4741C24.3931 21.6858 25.1415 22.1281 25.7252 22.7494C26.3088 23.3706 26.7037 24.1452 26.8635 24.9825"
        stroke="#7B73E4"
        strokeWidth="2.69474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.1372 24.9825C37.297 24.1452 37.6919 23.3706 38.2755 22.7494C38.8592 22.1281 39.6076 21.6858 40.4334 21.4741C41.2591 21.2624 42.128 21.29 42.9386 21.5538C43.7492 21.8176 44.468 22.3066 45.011 22.9638C45.5539 23.6209 45.8987 24.4189 46.005 25.2647C46.1112 26.1105 45.9746 26.9691 45.611 27.7401C45.2475 28.5111 44.672 29.1627 43.9519 29.6188C43.2317 30.0749 42.3967 30.3167 41.5442 30.3158"
        stroke="#7B73E4"
        strokeWidth="2.69474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoConnectIcon = React.memo(TotalClients);
export default MemoConnectIcon;
