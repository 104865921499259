import React, { useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import { PrimaryButton } from '@components/Button';
import { ReactComponent as PromptVector } from '@images/promptVector.svg';
import { StreamedOptionNodeItem } from '@features/Chat/types';

export type Options_AddFund = {
  type: 'add-fund';
  fund_name: string | null;
  to_display: boolean;
  display_text: string;
  id: string;
};

// TODO: this is part of StreamingOptions, move it there
export type Options =
  | Options_AddFund
  | {
      display_text: string;
      to_display: boolean;
      id: string;
      type: 'callback' | 'redirect' | 'new-insight-question';
      route?: string;
    };

interface ChatOptionsProps {
  data: Array<StreamedOptionNodeItem>;
  onOptionsSelect: (options: StreamedOptionNodeItem) => void;
  variants?: any;
}

const ChatOptions: React.FC<ChatOptionsProps> = ({
  data = [],
  onOptionsSelect,
}: ChatOptionsProps) => {
  const [rotations, setRotations] = React.useState<{ [key: string]: number }>(
    {},
  );
  const [hoverStates, setHoverStates] = React.useState<{
    [key: string]: boolean;
  }>({});
  const intervalRefs = useRef<{ [key: string]: number | null }>({});
  if (!data) {
    return <div>No data available</div>;
  }

  useEffect(() => {
    return () => {
      Object.keys(intervalRefs.current).forEach(key => {
        if (intervalRefs.current[key])
          clearInterval(intervalRefs.current[key] as number);
      });
    };
  }, []);

  const handleMouseEnter = (id: string) => {
    if (intervalRefs.current[id]) {
      clearInterval(intervalRefs.current[id] as number);
    }
    setHoverStates(prev => ({ ...prev, [id]: true }));

    intervalRefs.current[id] = window.setInterval(() => {
      setRotations(prev => ({ ...prev, [id]: (prev[id] || 0) + 360 }));
    }, 1000);
  };

  const handleMouseLeave = (id: string) => {
    setHoverStates(prev => ({ ...prev, [id]: false }));
    setRotations(prev => ({ ...prev, [id]: 0 }));

    if (intervalRefs.current[id]) {
      clearInterval(intervalRefs.current[id] as number);
      intervalRefs.current[id] = null;
    }
  };
  return (
    <Grid container direction="row" rowGap={1} columnGap={1}>
      {data
        .filter(l => l.to_display)
        .map(k => (
          <Grid item key={k.id}>
            <PrimaryButton
              isHover
              variant="outlined"
              customColor="#FFFFFF"
              customBorderColor="#2C3748"
              textColor="#2C3748"
              sx={{
                minHeight: '40px',
                height: 'auto',
                color: hoverStates[k.id] ? '#FFFFFF !important' : '#2C3748',
                textAlign: 'left !important',
                lineHeight: `1.2 !important`,
              }}
              startIcon={
                <PromptVector
                  style={{
                    transition: 'transform 3s',
                    transform: `rotate(${rotations[k.id] || 0}deg)`,
                    fill: hoverStates[k.id] ? 'white' : 'inherit',
                  }}
                />
              }
              onMouseEnter={() => handleMouseEnter(k.id)}
              onMouseLeave={() => handleMouseLeave(k.id)}
              onClick={() => onOptionsSelect(k)}
            >
              {k.display_text}
            </PrimaryButton>
          </Grid>
        ))}
    </Grid>
  );
};

export default React.memo(ChatOptions);
