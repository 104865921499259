/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ApiInstance from 'utils/instances';
import {
  ConfigData,
  ConfigUpdateResponseInterface,
  IntegrationOverallResponseInterface,
  IntegrationSingleResponseInterface,
} from './types';

export const getAllIntegrations = async (
  rest: any,
): Promise<IntegrationOverallResponseInterface> => {
  return await ApiInstance.get(`/integrations/getAllIntegrations`, rest).then(
    res => res.data,
  );
};

export const getIntegrationsDataById = async (
  rest: any,
): Promise<IntegrationSingleResponseInterface> => {
  return await ApiInstance.get(`/integrations/getIntegrationData`, rest).then(
    res => res.data,
  );
};

export const syncRedTailClient = async (rest: any): Promise<any> => {
  return await ApiInstance.get(`/integrations/redTail/sync`, rest).then(
    res => res.data,
  );
};

export const redTailAuthenticate = async (payload: any): Promise<any> => {
  return await ApiInstance.post(
    `/integrations/redTail/authenticate`,
    payload,
  ).then(res => res.data);
};

export const wealthBoxAuthenticate = async (payload: any): Promise<any> => {
  return await ApiInstance.post(
    `/integrations/wealthbox/authenticate`,
    payload,
  ).then(res => res.data);
};

export const syncWealthBoxClient = async (rest: any): Promise<any> => {
  return await ApiInstance.get(`/integrations/wealthBox/sync`, rest).then(
    res => res.data,
  );
};

export const disconnectIntegrationById = async (rest: any): Promise<any> => {
  return await ApiInstance.put(`/integrations/disconnect`, rest).then(
    res => res.data,
  );
};

export const getUserConfig = async (): Promise<ConfigData> => {
  return await ApiInstance.get(`/config`).then(res => res.data);
};

export const updateUserConfig = async (
  payload: any,
): Promise<ConfigUpdateResponseInterface> => {
  return await ApiInstance.post(`/config/update`, payload).then(
    res => res.data,
  );
};

// Enterprises
export const getAllEnterprises = async (): Promise<any> => {
  return await ApiInstance.get(`/enterprise/getAllEnterprises`).then(
    res => res.data,
  );
};

export const addEnterprises = async (payload: any): Promise<any> => {
  return await ApiInstance.post(`/enterprise/createEnterprise`, payload)
    .then(res => res.data)
    .catch(({ response }: any) => response);
};

export const getAllUsers = async (): Promise<any> => {
  return await ApiInstance.get(`/user/getAllUsers?page=1&limit=10`).then(
    res => res.data,
  );
};

export const exitFundMode = async (request: any): Promise<any> => {
  const { payload, headers } = request;
  return await ApiInstance.post(`/chat/exitFundMode`, payload, {
    headers,
  }).then(res => res.data);
};
