export const stylesOptions = [
  { label: 'Buyout', value: 'Buyout' },
  { label: 'Venture Capital', value: 'Venture Capital' },
  { label: 'Growth Equity', value: 'Growth Equity' },
  { label: 'Credit', value: 'Credit' },
  { label: 'Real Estate', value: 'Real Estate' },
  { label: 'Natural Resources', value: 'Natural Resources' },
  { label: 'Infrastructure', value: 'Infrastructure' },
  { label: 'Co-Investment', value: 'Co-Investment' },
  { label: 'Fund of Funds', value: 'Fund of Funds' },
  { label: 'Secondaries', value: 'Secondaries' },
];

export const focusOptions = {
  Buyout: [
    { label: 'Large Cap', value: 'Large Cap' },
    { label: 'Mid Cap', value: 'Mid Cap' },
    { label: 'Small Cap', value: 'Small Cap' },
    { label: 'Turnaround', value: 'Turnaround' },
  ],
  'Venture Capital': [
    { label: 'Balanced', value: 'Balanced' },
    { label: 'Seed/Early Stage', value: 'Seed/Early Stage' },
    { label: 'Late Stage', value: 'Late Stage' },
    { label: 'Expansion Stage', value: 'Expansion Stage ' },
    { label: 'Lending & Leasing', value: 'Lending & Leasing' },
  ],
  'Growth Equity': [{ label: 'NA', value: 'NA' }],
  Credit: [
    {
      label: 'Senior Debt',
      value: 'Senior Debt',
    },
    {
      label: 'Distressed',
      value: 'Distressed',
    },
    { label: 'Special Situations', value: 'Special Situations' },
    { label: 'Mezzanine', value: 'Mezzanine' },
    { label: 'Real Estate', value: 'Real Estate' },
    { label: 'Royalty', value: 'Royalty' },
  ],
  'Natural Resources': [
    { label: 'Agriculture', value: 'Agriculture' },
    { label: 'Timber', value: 'Timber' },
    { label: 'Mining', value: 'Mining' },
    { label: 'Energy', value: 'Energy' },
  ],
  'Real Estate': [
    { label: 'Value Add', value: 'Value Add' },
    { label: 'Opportunistic', value: 'Opportunistic' },
    { label: 'Core', value: 'Core' },
  ],
  Infrastructure: [{ label: 'NA', value: 'NA' }],
  'Fund of Funds': [
    { label: 'Multi Focus', value: 'Multi Focus' },
    { label: 'Level 2', value: 'Level 2' },
  ],
  'Co-Investment': [
    { label: 'Multi Manager', value: 'Multi Manager' },
    { label: 'Single Manager', value: 'Single Manager' },
  ],
  Secondaries: [
    { label: 'Secondaries', value: 'Secondaries' },
    { label: 'Fund Interests', value: 'Fund Interests' },
    { label: 'Direct Interests', value: 'Direct Interests' },
  ],
};

export const statusOptions = [
  { label: 'Fundraising', value: 'FUNDRAISING' },
  { label: 'Out of Market', value: 'Out Of Market' },
  { label: 'Projected', value: 'Projected' },
];

export const geographyOptions = [
  { label: 'Asia - Developed', value: 'Asia - Developed' },
  { label: 'Asia - Emerging', value: 'Asia - Emerging' },
  {
    label: 'Emerging Markets',
    value: 'Emerging Markets',
  },
  {
    label: 'Europe - CEE & CIS',
    value: 'Europe - CEE & CIS',
  },
  { label: 'Europe - Western', value: 'Europe - Western' },
  { label: 'Global', value: 'Global' },
  { label: 'Latin America', value: 'Latin America' },
  { label: 'MENA', value: 'MENA' },
  { label: 'North America', value: 'North America' },
  { label: 'Sub-Saharan Africa', value: 'Sub-Saharan Africa' },
];
