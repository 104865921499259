import { getConversationsState } from '@features/Chat/selector';
import { HistoricConversation } from 'app/features/Chat/types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as chatActions } from '@features/Chat/slice';
import { useLocalStorage } from '@helper/CustomHooks/useLocalStorage';

/**
 * This hook provides a full conversation history, given a conversation id.
 *
 */

export type IConversationHistoryReply =
  | {
      conversation_id: string;
      is_loading: true;
      error: null;
      history: null;
    }
  | {
      conversation_id: string;
      is_loading: false;
      error: string | null;
      history: HistoricConversation[];
    };

const useConversationHistory = (
  conversation_id: string,
): IConversationHistoryReply => {
  const [welcomed, setWelcomed] = useLocalStorage('welcomed', false);
  const dispatch = useDispatch();
  const { conversation_history } = useSelector(getConversationsState);
  React.useEffect(() => {
    if (!welcomed) {
      dispatch(chatActions.loadInitialChatHistory());
      setWelcomed(true);
    }
  }, [welcomed]);

  if (!conversation_history) {
    return {
      conversation_id,
      is_loading: true,
      error: null,
      history: null,
    };
  }
  return {
    conversation_id,
    is_loading: false,
    error: null,
    history: conversation_history.history,
  };
};

export default useConversationHistory;
