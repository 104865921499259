import React from 'react';
import PropTypes from 'prop-types';
import Grow from '@mui/material/Grow';
import TextField from '@mui/material/TextField';
import { withStyles } from 'tss-react/mui';
import SearchIcon from '@icons/SearchIcon';
import InputAdornment from '@mui/material/InputAdornment';

const defaultSearchStyles = theme => ({
  searchText: {
    flex: '0.8 0',
    marginTop: 10,
  },
  searchIcon: {
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
});

const CustomSearchRender = props => {
  const { onSearch, searchText } = props;
  const handleTextChange = event => {
    onSearch(event.target.value);
  };

  return (
    <Grow appear in={true} timeout={300}>
      <TextField
        placeholder="Search"
        sx={{
          width: '280px',
          border: '1px solid #E4E7EC',
          boxShadow: '1px 2px 2px 0px #1018281F inset',
          borderRadius: '4px',
          marginTop: '4px',
          backgroundColor: '#fff',
          float: 'right',
        }}
        size="medium"
        value={searchText || ''}
        onChange={handleTextChange}
        fullWidth
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Grow>
  );
};

CustomSearchRender.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
};

export default withStyles(CustomSearchRender, defaultSearchStyles, {
  name: 'CustomSearchRender',
});
