import React, { useEffect, useRef, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { PrimaryButton } from '@components/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CustomModal from '@components/Modal';
import moment, { Moment } from 'moment';
import { getFeedbackHistoryState } from '@features/FeedbackHistory/selector';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { actions as feedbackHistoryActions } from '@features/FeedbackHistory/slice';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const headers = [
  { label: 'Query', key: 'query' },
  { label: 'User Name', key: 'advisor.name' },
  { label: 'User Email', key: 'advisor.email' },
  { label: 'Chat log id', key: '_id' },
];

const FilterRange: React.FC = () => {
  const dispatch = useDispatch();
  const csvLinkRef: any = useRef(null);
  const [isRangeOpen, setIsRangeOpen] = useState(false);
  const [startDate, setStartDate] = React.useState<Moment | null>(null);
  const [endDate, setEndDate] = React.useState<Moment | null>(null);

  const feedbackHistoryState: any = useSelector(getFeedbackHistoryState);

  const handleModel = () => {
    setIsRangeOpen(prev => !prev);
  };
  const handleClose = () => {
    setStartDate(null);
    setEndDate(null);
    handleModel();
  };
  const handleFilter = () => {
    if (startDate && endDate) {
      dispatch(
        feedbackHistoryActions.loadFeedbackHistoryFilterRequest({
          startDate: startDate,
          endDate: endDate,
        }),
      );
    }
  };
  useEffect(() => {
    if (
      !feedbackHistoryState.filterLoading &&
      feedbackHistoryState.feedbackHistoryFilter.length
    ) {
      csvLinkRef?.current?.link?.click();
    }
  }, [feedbackHistoryState.feedbackHistoryFilter]);
  return (
    <>
      <PrimaryButton
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={handleModel}
      >
        Download Chat Log
      </PrimaryButton>
      <CustomModal
        open={isRangeOpen}
        dialogTitle="Download Chat Log"
        handleClose={handleClose}
        maxWidth="lg"
        dialogContent={
          <Grid mt={2}>
            <Stack direction="row" justifyContent="space-between">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={newValue => {
                    setStartDate(newValue);
                  }}
                  disableFuture
                  sx={{ marginRight: '10px' }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={newValue => {
                    setEndDate(newValue);
                  }}
                  disableFuture
                  minDate={startDate || undefined}
                />
              </LocalizationProvider>
            </Stack>
            <CSVLink
              data={feedbackHistoryState.feedbackHistoryFilter}
              headers={headers}
              filename={`TIFIN-Chatlog_history_Results-${moment().format(
                'DD-MM-YYYY_hh-mm-ss',
              )}.csv`}
              style={{
                visibility: 'hidden',
                opacity: 0,
                width: 0,
                height: 0,
              }}
              ref={csvLinkRef}
            />
            <Stack direction="row" justifyContent="flex-end">
              <PrimaryButton
                startIcon={<FileDownloadOutlinedIcon />}
                onClick={handleFilter}
                sx={{ marginTop: '20px' }}
                disabled={
                  !startDate || !endDate || feedbackHistoryState.filterLoading
                }
              >
                Download CSV
              </PrimaryButton>
            </Stack>
          </Grid>
        }
      />
    </>
  );
};
export default FilterRange;
