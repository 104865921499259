/* eslint-disable react/prop-types */
import * as React from 'react';

function CircleCheckIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      style={props.style || {}}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.29159 4.75L5.23429 7.66667L3.70825 6.20833"
        stroke="#101828"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
        stroke="#101828"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoNoData = React.memo(CircleCheckIcon);
export default MemoNoData;
