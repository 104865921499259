/* eslint-disable react/prop-types */
import * as React from 'react';

function BellIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      style={props.style || {}}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Essentials">
        <path
          id="Path"
          d="M6 14L10 14"
          stroke={props.stroke || '#475467'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Path_2"
          d="M3.51269 6.50007C3.51186 5.90731 3.62831 5.32024 3.85533 4.77267C4.08236 4.2251 4.41547 3.72786 4.83549 3.30959C5.25551 2.89131 5.75413 2.56027 6.30263 2.33552C6.85114 2.11078 7.43869 1.99677 8.03144 2.00007C10.5064 2.01882 12.4877 4.07507 12.4877 6.55632L12.4877 7.00007C12.4877 9.23757 12.9564 10.5376 13.3689 11.2501C13.4128 11.326 13.4359 11.412 13.4359 11.4996C13.436 11.5872 13.4131 11.6734 13.3694 11.7493C13.3257 11.8253 13.2628 11.8884 13.1871 11.9324C13.1113 11.9764 13.0253 11.9998 12.9377 12.0001L3.06269 12.0001C2.97507 11.9998 2.88907 11.9764 2.81331 11.9324C2.73754 11.8884 2.67468 11.8253 2.631 11.7493C2.58732 11.6734 2.56438 11.5872 2.56445 11.4996C2.56453 11.412 2.58763 11.3259 2.63144 11.2501C3.04394 10.5376 3.51269 9.23757 3.51269 7.00007L3.51269 6.50007Z"
          stroke={props.stroke || '#475467'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

const MemoNoData = React.memo(BellIcon);
export default MemoNoData;
