import React from 'react';
import CustomChip from '@components/Chip';
import AddIcon from '@mui/icons-material/Add';

interface AddDocumentsDropdownProps {
  onAdd: () => void;
}

export const AddDocumentsButton = ({
  onAdd,
}: AddDocumentsDropdownProps): React.JSX.Element => {
  return (
    <CustomChip
      type="simple"
      padding="4px 8px"
      borderRadius="8px"
      sx={{
        backgroundColor: '#F9FAFB',
        color: '#101828',
        border: '1px solid #101828',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: 'black',
          color: 'white',
        },
      }}
      onClick={onAdd}
    >
      <>
        Add Document(s)
        <AddIcon
          sx={{
            fontSize: '16px',
            marginLeft: '8px',
          }}
        />
      </>
    </CustomChip>
  );
};

export default AddDocumentsButton;
