/* eslint-disable react/prop-types */
import * as React from 'react';

function HlLogo() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 229.5235748 116"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <path
              className="st0"
              d="M180.2305756,64.9969254c-5.0021362,0-8.3639069-2.8431549-8.3639069-7.0748596
               c0-4.8179321,3.6611023-7.9316635,9.3303223-7.9316635h12.2216339v0.9632835
               C193.4186249,59.4847183,188.2423401,64.9969254,180.2305756,64.9969254 M179.479599,41.5184021
               c-12.2488708,0-20.1634521,6.6496429-20.1634521,16.9408722c0,4.5115776,1.7547302,8.6002655,4.9407806,11.5141678
               c3.2217865,2.9458008,7.7885895,4.5683365,12.8638153,4.5683365c7.6300049,0,13.9468689-3.4674377,16.0934143-8.8326874
               l0.2044678-0.5092545v8.4836273h12.8716431v-34.628788c0-11.9931087-9.3948517-20.0507088-23.3790131-20.0507088
               c-6.618515,0-12.3903351,1.8775959-16.6935883,5.4290257c-4.0490265,3.3423061-6.5213318,8.0365772-6.9714661,13.2284336
               h11.6976624c0.352951-4.3560791,4.0614471-9.00527,11.4309998-9.00527c6.7094269,0,11.0437622,3.5762959,11.0437622,9.1110439
               v3.7512016H179.479599z"
            />
          </g>
          <g>
            <rect
              x="216.544632"
              y="20.050827"
              className="st0"
              width="12.9789314"
              height="53.8211746"
            />
          </g>
          <g>
            <rect
              x="137.0716248"
              y="61.7884598"
              className="st0"
              width="12.0835419"
              height="12.0835419"
            />
          </g>
        </g>
        <g>
          <path
            className="st0"
            d="M7.3982949,92.4410858H0.7498572v17.4003906h1.6347656v-6.8813477h5.0136719
           c2.9760737,0,5.054687-2.1376953,5.054687-5.1987305C12.4529819,95.1173553,10.7166538,92.4410858,7.3982949,92.4410858z
            M10.7698765,97.7613983c0,2.1977539-1.4462891,3.7329102-3.5166011,3.7329102H2.3846228v-7.5639648h4.8686523
           C9.3235874,93.9303436,10.7698765,95.5055389,10.7698765,97.7613983z"
          />
          <path
            className="st0"
            d="M21.0418491,97.1007538c-3.5991211,0-6.5273438,2.9282227-6.5273438,6.527832
           c0,3.5717773,2.9282227,6.4780273,6.5273438,6.4780273c3.6259766,0,6.5761719-2.90625,6.5761719-6.4780273
           C27.618021,100.0289764,24.6678257,97.1007538,21.0418491,97.1007538z M25.9832554,103.6285858
           c0,2.7509766-2.2167969,4.9887695-4.9414062,4.9887695c-2.6977539,0-4.8925781-2.237793-4.8925781-4.9887695
           c0-2.730957,2.2407227-5.0385742,4.8925781-5.0385742C23.7664585,98.5900116,25.9832554,100.8502655,25.9832554,103.6285858z"
          />
          <polygon
            className="st0"
            points="42.7176323,107.4679413 39.4036674,97.8248749 38.0086479,97.8248749 34.6722221,107.4679413 
           30.7283726,97.3663788 29.0306187,97.3663788 33.9607964,109.8414764 35.3953667,109.8414764 38.6951714,100.3766327 
           42.0179253,109.8414764 43.451519,109.8414764 48.3816948,97.3663788 46.6844292,97.3663788 		"
          />
          <path
            className="st0"
            d="M62.6150932,103.5153046l-0.0058594-0.2558594c-0.1064453-4.5458984-3.3618164-6.1586914-6.1171875-6.1586914
           c-3.7553711,0-6.6967773,2.8774414-6.6967773,6.5512695c0,3.7402344,2.9282227,6.4545898,6.9624023,6.4545898
           c2.0981445,0,3.8237305-0.6835938,5.1289062-2.03125l0.1621094-0.1674805l-0.1557617-0.1733398
           c-0.1142578-0.1274414-0.2348633-0.2416992-0.3554688-0.355957c-0.1088867-0.1030273-0.2177734-0.2060547-0.3208008-0.3198242
           l-0.1713867-0.1894531l-0.1855469,0.1748047c-1.3798828,1.300293-2.9921875,1.5732422-4.1020508,1.5732422
           c-3.0869141,0-5.3276367-2.0576172-5.3276367-4.8930664c0-0.0737305-0.0004883-0.1425781,0.0019531-0.2089844H62.6150932z
            M51.6497612,102.1227264c0.6323242-2.1259766,2.5400391-3.5327148,4.8422852-3.5327148
           c2.3520508,0,3.9824219,1.3144531,4.4125977,3.5327148H51.6497612z"
          />
          <path
            className="st0"
            d="M71.4393082,97.1007538c-1.659668,0-2.9418945,0.8085938-3.7094727,2.3071289v-2.0415039h-1.6108398
           v12.4750977h1.6108398v-4.8583984c0.0244141-1.9003906,0.4301758-6.3198242,3.6611328-6.3198242
           c0.2333984,0,0.6088867,0.0483398,0.8330078,0.1293945l0.3349609,0.1206055v-1.6708984l-0.2011719-0.0400391
           C72.2054214,97.1715546,71.8104019,97.1007538,71.4393082,97.1007538z"
          />
          <path
            className="st0"
            d="M86.7869644,103.5153046l-0.0058594-0.2558594c-0.1064453-4.5458984-3.3618164-6.1586914-6.1166992-6.1586914
           c-3.7553711,0-6.6967773,2.8774414-6.6967773,6.5512695c0,3.7402344,2.9282227,6.4545898,6.9624023,6.4545898
           c2.0981445,0,3.8237305-0.6835938,5.1293945-2.03125l0.1625977-0.1674805l-0.1567383-0.1738281
           c-0.1137695-0.1264648-0.234375-0.2407227-0.3544922-0.3544922c-0.109375-0.1035156-0.2182617-0.206543-0.3217773-0.3208008
           l-0.1713867-0.1894531l-0.1855469,0.1748047c-1.3798828,1.300293-2.9921875,1.5732422-4.1020508,1.5732422
           c-3.0869141,0-5.3276367-2.0576172-5.3276367-4.8930664c0-0.0737305-0.0004883-0.1425781,0.0019531-0.2089844H86.7869644z
            M75.8221207,102.1227264c0.6318359-2.1259766,2.5395508-3.5327148,4.8422852-3.5327148
           c2.3520508,0,3.9824219,1.3144531,4.4125977,3.5327148H75.8221207z"
          />
          <path
            className="st0"
            d="M101.5911636,99.6334686c-1.0581055-1.5830078-2.8637695-2.5327148-4.9169922-2.5327148
           c-3.7260742,0-6.4306641,2.7250977-6.4306641,6.4794922c0,3.7817383,2.6943359,6.5263672,6.4067383,6.5263672
           c2.0537109,0,3.8696289-0.9609375,4.940918-2.5625v2.2973633h1.6103516V91.88591h-1.6103516V99.6334686z M101.6155777,103.580246
           c0,2.871582-2.1137695,5.0371094-4.9169922,5.0371094c-2.793457,0-4.8208008-2.1186523-4.8208008-5.0371094
           c0-2.9379883,1.972168-4.9902344,4.7963867-4.9902344C99.5374527,98.5900116,101.6155777,100.6886444,101.6155777,103.580246z"
          />
          <path
            className="st0"
            d="M124.0096207,97.1007538c-2.0537109,0-3.859375,0.949707-4.9169922,2.5327148V91.88591h-1.6103516v17.9555664
           h1.6103516v-2.2973633c1.0712891,1.6010742,2.8876953,2.5625,4.9414062,2.5625
           c3.7128906,0,6.4072342-2.7446289,6.4072342-6.5263672C130.4412689,99.8258514,127.7361832,97.1007538,124.0096207,97.1007538z
            M128.8055267,103.580246c0,2.918457-2.0273514,5.0371094-4.8203201,5.0371094
           c-2.8027344,0-4.9160156-2.1655273-4.9160156-5.0371094c0-2.8916016,2.078125-4.9902344,4.9404297-4.9902344
           C126.8338394,98.5900116,128.8055267,100.6422577,128.8055267,103.580246z"
          />
          <polygon
            className="st0"
            points="142.2430267,97.3663788 137.9881439,107.8278046 133.5701752,97.3663788 131.7703705,97.3663788 
           137.0936127,109.7872772 134.8680267,115.2501678 136.5652924,115.2501678 144.0662689,97.3663788 		"
          />
        </g>
        <g>
          <g>
            <path
              className="st0"
              d="M228.8273926,88.781868c-0.0048676-0.8972702,0.0097656-1.463974-1.0607758-1.463974
               c-1.0856628,0-1.1015625,0.5447617-1.1015625,1.467453c-0.0468445,3.7256317-0.0259094,7.5749054-0.0260315,11.3011246
               c-0.0000916,2.5495148,0.0107574,5.0991516-0.0151367,7.648407c-0.0031128,0.3055038-0.0657806,0.8469696-0.3166504,0.9133759
               c-0.282074,0.0629501-0.6377869-0.4085159-0.7980042-0.6999969c-0.3946838-0.7180786-0.691864-1.4894714-1.0337982-2.2368851
               c-2.5086517-5.483223-5.0151215-10.967453-7.5307312-16.4474792c-0.6402435-1.3947067-1.5269012-1.9800797-2.799881-1.9015274
               c-1.5726166,0.0970383-2.4730988,1.1131744-2.4802399,2.9734879c-0.0240631,6.2756424-0.0102539,12.9821777-0.0074768,19.2579117
               c0,0.6613388,0.5071259,1.0568161,1.040329,1.0568161c0.5806274,0,1.1508179-0.3374634,1.1508179-1.0336075
               c0.0015411-6.0403976-0.0058594-12.9064178,0.0160675-18.9467239c0.0015411-0.4261017-0.0502472-1.1774902,0.3316498-1.277092
               c0.4507599-0.1214523,1.2099152,1.5945129,1.3401642,1.8791962c2.6710663,5.8389435,5.3370361,11.6802216,8.0175781,17.5148315
               c0.6887512,1.4991684,1.8219757,2.1505814,3.1405945,1.8711777c1.4497223-0.3071976,2.1296234-1.2550659,2.1365814-3.0493393
               c0.0118866-3.0593643,0.0032654-6.1188278,0.0028992-9.1782455
               C228.8334045,95.2144623,228.8447571,91.998085,228.8273926,88.781868z M196.1454773,89.3137589
               c0.7344208-0.0000153,1.419693-0.2026138,1.4135284-1.0483246c-0.0062561-0.8600159-0.6989594-0.9970551-1.4350281-0.994751
               c-3.6059265,0.0113068-7.2119598-0.0055466-10.8178406,0.0109329c-1.3182983,0.0060196-1.8404694,0.5229416-1.8851776,1.8585358
               c-0.0393219,1.1745834-0.012085,2.3514175-0.012085,3.5272675c-0.0000458,5.4873276,0.0017395,10.9746552-0.0001068,16.4619827
               c-0.0002594,0.7733612,0.1405792,1.46418,1.1048279,1.4626465c0.9705963-0.0015411,1.0927124-0.7059708,1.0908966-1.4709778
               c-0.002533-1.0582657-0.000412-2.116539-0.0003815-3.1748123c0.0000458-2.1035843,0.0000153-4.2071686,0.0000153-6.4714355
               l8.6961212,0.0044327c0.7470703,0.0028839,1.4102325-0.1678925,1.3835449-1.0391769
               c-0.0239716-0.7824554-0.6541748-0.9689026-1.3545837-0.9663544c-2.2234344,0.0080719-8.2106628,0.0023956-8.6663055,0.0019531
               c0,0,0.0000305-5.4146652,0.0000305-8.1618881C189.2515717,89.3137894,192.6985168,89.3138351,196.1454773,89.3137589z
                M168.0147705,89.3142242c0.6431122,0,1.1699219-0.0564117,1.1699219-1.0210953
               c0-0.9372177-0.6052399-1.0143356-1.1549988-1.0143356c-4.6248016-0.0062256-14.785675-0.0013962-15.0220642-0.0013962
               c-0.5933533,0-0.7457275,0.6868668-0.7457275,1.0008087c0,0.3419571,0.2096558,1.0302963,0.8664551,1.0302963
               c0,0,4.5825195,0.0059509,6.5850372,0.0059509c0,0.5507812,0,0.9703217,0,1.3898697
               c0.0000305,6.1533585,0.0053558,12.3067169-0.0066071,18.4600525c-0.0014801,0.7662201,0.0589294,1.5233765,1.0108948,1.5235519
               c0.9746094,0.0001678,1.1255798-0.7390442,1.121933-1.5752182c-0.0186768-4.2719955-0.0085907-8.5441208-0.0085602-12.8161926
               c0.0000153-2.2680817,0-4.536171,0-6.9822083C163.8642578,89.3143082,166.1409912,89.3142242,168.0147705,89.3142242z
                M176.5085297,88.8848572c0.0029907-0.7684555-0.0671844-1.4911804-1.0392151-1.5014801
               c-1.0305328-0.0109329-1.128891,0.7396317-1.1278839,1.5560532c0.0081787,6.7069473,0.0097809,13.4139252-0.0021667,20.1208649
               c-0.0014496,0.8095779,0.0744629,1.6113815,1.0632782,1.6312637c1.1031342,0.0221786,1.1078033-0.8572464,1.1050262-1.6858521
               c-0.0111389-3.3338318-0.0046082-6.667717-0.0046387-10.0015869
               C176.5028992,95.6310272,176.4954529,92.2578964,176.5085297,88.8848572z M203.0160522,109.1238632
               c-0.0039215,0.8082886,0.0506134,1.6032028,1.0843811,1.569664c0.9051666-0.0293732,1.0490875-0.7416458,1.0475159-1.5304031
               c-0.0133972-6.743927-0.0110931-13.4879074-0.0031586-20.2318497c0.0009613-0.8104553-0.0721436-1.564827-1.1199951-1.5494537
               c-1.0010376,0.0146866-1.0091095,0.7600098-1.0067139,1.5102158c0.0108795,3.4111481,0.004776,6.8223572,0.0047607,10.2335434
               C203.0228424,102.4583588,203.0322418,105.7911682,203.0160522,109.1238632z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              className="st0"
              d="M89.272049,56.9152184V6.7090588H72.3334808v0.1666918v32.2873306V61.219368
               c0,6.9667435,5.6686554,12.6344147,12.6373749,12.6344147h41.8369598V56.9152184H89.272049z"
            />
          </g>
          <g>
            <g>
              <g>
                <path
                  className="st0"
                  d="M17.0667953,11.5269527v-4.817852H0.0781346v34.4789963
                       C10.2411442,35.2114677,17.0667953,24.1694546,17.0667953,11.5269527z"
                />
              </g>
            </g>
          </g>
          <g>
            <path
              className="st0"
              d="M45.0921211,6.7091007V32.061451c0,0-13.8993702-0.0748482-15.1438599,0
               c-4.7189598,0.2838211-9.0990067,2.0634003-13.0149574,4.3228989c-0.4912853,0.2833099-0.9723349,0.5809441-1.4480629,0.8880005
               C7.3249974,42.632267,1.5810573,51.3640404,0.2447621,61.490242c0,0-0.1649899,3.3341904-0.1666275,4.4068298v8.1233902
               h16.8240547V48.9788513h0.0311146l28.135891-0.0040932v0.025383v17.1753197v7.8450012h0.0229263h16.7114677h0.0671425h0.2083855
               V49.0001411V32.061451V6.7091007H45.0921211z"
            />
          </g>
        </g>
        <g>
          <path
            className="st1"
            d="M229.5235748,0H216.544632v7.7508221c0,2.887475,2.3407593,5.2282276,5.2282257,5.2282276h7.7507172V0z"
          />
        </g>
      </g>
    </svg>
  );
}

const MemoNoData = React.memo(HlLogo);
export default MemoNoData;
