import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material';
import SearchBox from '@components/SearchBox';
import ScrollButton from '@components/ScrollButton';
import throttle from 'lodash/throttle';

import {
  getChatInitResponseState,
  getQueryLoaderState,
  getSearchedQuery,
  isQueryResponseStreaming,
  getConversationsState,
} from '@features/Chat/selector';
import {
  isContextualModeEnable,
} from '@features/Global/selector';
import { actions as globalActions } from '@features/Global/slice';
import { actions, actions as chatActions } from '@features/Chat/slice';
import {
  ChatFeedbackInterface,
  HistoricConversation,
  IBackendGroupedInsightData,
  StreamedNode,
  StreamedOptionsNode,
  StreamedRecommendationNode,
  StreamedTextNode,
} from '@features/Chat/types';

import { FooterContainer } from '@layout/styles';

import ChatSkeleton from './ChatSkeleton';
// import ChatFeedback from './ChatFeedback';

import { getDateTime } from '@common/dateTime';
import { TextButton } from '@components/Button';
import { ParagraphLightText } from '@components/Typography';
import {
  ChatBoxContainer,
  ChatContentContainer,
  ChatPageContainer,
  ExitFundContainer,
  ExitFundWrapper,
} from './styles';
import WelcomeBack from './WelcomeBack';
import ConversationBox from './ConversationBox';
import useGroupedInsights from 'app/helper/CustomHooks/useGroupedInsights';

function areNumbersClose(a: any, b: any, tolerance = 4) {
  return Math.abs(a - b) <= tolerance;
}

/**
 * Refactor from index.tsx, probably this page needs to be rebuilt
 */
function ConversationPage(): React.JSX.Element {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const stateQuery: string = useSelector(getSearchedQuery);
  const isQueryLoading: boolean = useSelector(getQueryLoaderState);
  const isStreaming: boolean = useSelector(isQueryResponseStreaming);

  const { isContextualMode, fundDetails } = useSelector(isContextualModeEnable);
  const { loading: isLoading, initToken: user } = useSelector(
    getChatInitResponseState,
  );
  const { conversation_history, conversation_current } = useSelector(
    getConversationsState,
  );
  const {data: groupedInsights, isLoading: isGroupedInsightsLoading} = useGroupedInsights();

  const [showScrollUpButton, setShowScrollUpButton] = useState(false);
  const chatInitiated = localStorage.getItem('chat-initiated');
  const chatContentContainerRef = useRef<HTMLDivElement | null>(null);
  const messageEndRef = useRef<HTMLDivElement | null>(null);
  const chatContentRef = useRef<HTMLDivElement | null>(null);

  const lastResponse = useMemo(() => {
    if (conversation_current) {
      return conversation_current;
    }
    return conversation_history?.[conversation_history.length - 1];
  }, [conversation_history, conversation_current]);

  const skipScroll = useMemo(
    () =>
      lastResponse?.nodes.find(
        response =>
          response.type === 'exttable' &&
          ['FUNDS_COMPARISON', 'FUNDS_ATTRIBUTES', 'FUNDS_LIST'].includes(
            response.subtype,
          ),
      )
        ? true
        : false,
    [lastResponse],
  );

  const scrollToMessageEnd = useCallback(
    throttle(() => {
      if (chatContentContainerRef.current) {
        const container = chatContentContainerRef.current;
        if (!skipScroll) {
          container.scrollTo({ top: container.scrollHeight });
        }
      }
    }, 100),
    [skipScroll, chatContentContainerRef.current?.scrollHeight],
  );

  useEffect(() => {
    scrollToMessageEnd();
  }, []);

  useEffect(() => {
    dispatch(globalActions.getUserConfigRequest());
    if (
      localStorage.getItem('x-tifin-ai-token') &&
      conversation_history.length === 0
    ) {
      dispatch(chatActions.loadInitialChatHistory());
    }
  }, []);
  const formatFilteredData = (
    filtered: IBackendGroupedInsightData[],
  ): StreamedNode[] => {
    const introText: StreamedTextNode = {
      type: 'text',
      data: `Here are few of the questions you can try for ${location.state.prompt}`,
      format: 'bold',
    };

    const questionOptions: StreamedOptionsNode[] = filtered.map(item => ({
      type: 'options',
      data: [
        {
          type: 'callback',
          id: item.question,
          display_text: item.question,
          to_display: true,
          format: 'bold',
          route: null,
          fund_name: null,
          fund_id: null,
        },
      ],
    }));

    return [introText, ...questionOptions];
  };

  useEffect(() => {
    if (location?.state?.forcedPrompt && user) {
      dispatch(
        chatActions.searchQueryRequest({
          query: location?.state?.forcedPrompt,
        }),
      );
      navigate(location.pathname, { replace: true });
    }
    if (location?.state?.category) {
      scrollToMessageEnd();

      if( !isGroupedInsightsLoading ) {
        const filteredItems = (groupedInsights||[]).filter(
          item => item.category === location.state.category,
        );

        const formattedData = formatFilteredData(filteredItems);
        const userInput: HistoricConversation = {
          query_id: '',
          nodes: formattedData,
          time: getDateTime(),
          visible_prompt: '',
          status: 'COMPLETED',
          chat_log_id: null,
        };
        if (filteredItems.length > 0) {
          // Dispatch the filtered data to the chat history
          console.log('Adding insight to chat history');
          dispatch(actions.pushHistoricConversation(userInput));
        }
      }
    }
  }, [location?.state, user, isGroupedInsightsLoading]);

  useEffect(() => {
    if (stateQuery) {
      scrollToMessageEnd();
    }

    const checkHeightAndScroll = () => {
      if (chatInitiated === 'true') {
        const chatContainer = chatContentContainerRef.current;
        const chatContent = chatContentRef.current?.offsetHeight || 0;
        if (chatContainer) {
          const adjustedHeight = isQueryLoading
            ? chatContainer.offsetHeight - 190
            : chatContainer.offsetHeight - 100;
          if (chatContent + 100 > adjustedHeight) {
            console.log('scrollToMessageEnd()');
          }
          scrollToMessageEnd();
        }
      }
    };

    checkHeightAndScroll();
  }, [
    isQueryLoading,
    chatInitiated,
    conversation_history,
    conversation_current,
    stateQuery,
  ]);

  const onFeedbackSubmit = (data: ChatFeedbackInterface) => {
    const { scale, comment, chatLogId } = data;
    if (!chatLogId) {
      console.warn('Chat log id is missing');
      return;
    }
    if (!scale && !comment) {
      dispatch(
        chatActions.toggleChatFeedbackModal({
          openModal: false,
        }),
      );
    } else {
      dispatch(chatActions.submitChatFeedbackRequest(data));
    }
  };

  const handleScroll = () => {
    const chatContainer: HTMLDivElement | null =
      chatContentContainerRef.current;
    if (
      chatContainer &&
      areNumbersClose(
        Number(chatContainer?.scrollHeight - chatContainer?.scrollTop),
        Number(chatContainer?.clientHeight),
      )
    ) {
      setShowScrollUpButton(false);
    } else {
      setShowScrollUpButton(true);
    }
  };

  const onInputSubmit = (query: string) => {
    dispatch(chatActions.searchQueryRequest({ query }));
    navigate('/conversations');
  };

  const exitFundMode = () => {
    dispatch(globalActions.exitFundModeRequest());
  };

  const onRecommendationClicked = (
    recommendation: StreamedRecommendationNode,
  ) => {
    if (recommendation.type === 'callback') {
      dispatch(
        chatActions.searchQueryRequest({ query: recommendation.display_text }),
      );
    } else {
      window.alert('This recommendation is not supported');
    }
  };

  return (
    <Fragment>
      {isLoading ? (
        <ChatSkeleton />
      ) : (
        <ChatPageContainer>
          <ChatContentContainer
            customHeight={0}
            sx={{
              ['&>div:nth-last-child(2)']: {
                minHeight:
                  location?.state?.scroll !== 1
                    ? chatInitiated === 'true'
                      ? chatContentContainerRef?.current?.offsetHeight
                        ? isQueryLoading
                          ? chatContentContainerRef?.current?.offsetHeight -
                            100 +
                            90
                          : chatContentContainerRef?.current?.offsetHeight -
                            100 +
                            90
                        : '50vh'
                      : 0
                    : 0,
                [`& .ratings-container`]: {
                  display: 'flex',
                },
              },
              ['&>div:not(:nth-last-child(2))']: {
                [`& .ratings-container`]: {
                  display: 'none',
                },
              },
            }}
            pt={0}
            pl={2}
            pr={2}
            pb={0}
            ref={chatContentContainerRef}
            onScroll={handleScroll}
          >
            {conversation_history.length > 0 &&
              conversation_history.map((chat, index: number) => {
                return (
                  <React.Fragment key={'chat-history-' + index}>
                    <ConversationBox
                      conversation={chat}
                      /* chatContentRef={chatContentRef}*/
                      isStreaming={isStreaming}
                      onFeedbackSubmit={onFeedbackSubmit}
                      onRecommendationClicked={onRecommendationClicked}
                    />
                  </React.Fragment>
                );
              })}
            {conversation_current && (
              <React.Fragment key={'current_conversation'}>
                <ConversationBox
                  conversation={conversation_current}
                  chatContentRef={chatContentRef}
                  isStreaming={isStreaming}
                  onFeedbackSubmit={onFeedbackSubmit}
                  onRecommendationClicked={onRecommendationClicked}
                />
              </React.Fragment>
            )}

            {conversation_history.length > 0 && chatInitiated !== 'true' && (
              <ChatBoxContainer
                container
                key={'chat-history-response' + conversation_history.length + 1}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                columnGap={1}
              >
                <WelcomeBack />
              </ChatBoxContainer>
            )}

            <div id="idMessageEnd" ref={messageEndRef} />
          </ChatContentContainer>

          {showScrollUpButton && (
            <ScrollButton
              style={{
                bottom: isContextualMode ? '105px' : '75px',
              }}
              onClick={() => scrollToMessageEnd()}
              direction="down"
            />
          )}

          <FooterContainer
            container
            pb={1}
            pl={2}
            pr={2}
            position={'relative'}
            alignItems={'flex-end'}
          >
            <ExitFundContainer px={2} visible={isContextualMode}>
              <ExitFundWrapper
                container
                px={2}
                justifyContent={'space-between'}
              >
                <ParagraphLightText>
                  You are currently exploring: <b>{fundDetails.name}</b>
                </ParagraphLightText>
                <TextButton onClick={exitFundMode}>Exit Fund Mode</TextButton>
              </ExitFundWrapper>
            </ExitFundContainer>
            <SearchBox
              type={'input'}
              borderWidth={'3px'}
              style={{
                borderTopLeftRadius: isContextualMode ? 0 : '8px',
                borderTopRightRadius: isContextualMode ? 0 : '8px',
              }}
              color={theme.palette.primary['main']}
              isStreaming={false}
              onInputSubmit={onInputSubmit}
            />
          </FooterContainer>
        </ChatPageContainer>
      )}{' '}
    </Fragment>
  );
}

export default ConversationPage;
